import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="articles" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn field="manufacturer.manufacturerKey" />
        <AgGridColumn field="sku" />
        <AgGridColumn field="license" />
        <AgGridColumn field="productFamily" />
        <AgGridColumn field="licenseFamily" />
        <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
        <AgGridColumn {...useAgGridEnumCol('licensemodels', 'licenseModel')} />
        <AgGridColumn field="isPlatform" />
        <AgGridColumn field="hasPerpetualUseRight" columnGroupShow="open" />
        <AgGridColumn field="hasUpgradeRight" columnGroupShow="open" />
        <AgGridColumn field="hasWorkAtHomeRight" columnGroupShow="open" />
        <AgGridColumn field="hasVirtualMachineMobilityRight" columnGroupShow="open" />
        <AgGridColumn field="hasExternalAccessRight" columnGroupShow="open" />
        <AgGridColumn field="hasActivePassiveRight" columnGroupShow="open" />
        <AgGridColumn field="hasUnlimitedInstancesRight" columnGroupShow="open" />
        <AgGridColumn field="hasUnlimitedVirtualMachinesRight" columnGroupShow="open" />
        <AgGridColumn field="hasLimitOnNumberOfCores" />
      </DataGrid>
    </List>
  );
};
