
export const dateFormatter = (params) => {
  if (!params.value) return null;
  return new Date(params.value).toLocaleString('nl-NL', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

export const dateTimeFormatter = (params) => {
  if (!params.value) return null;
  return new Date(params.value).toLocaleString('nl-NL', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute:'numeric',
    second:'numeric',
  });
};

export const formatPrice = (price) => {
  let roundedPrice = price.toFixed(2).toString();
  return roundedPrice.replace('.', ',')
}

export const priceFormatter = (params) => {
  if (!params.value) return null;

  return formatPrice(params.value)
}


