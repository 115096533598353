import * as React from 'react';
import { Annotations } from '../../../components/Annotations';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  ReferenceManyField,
  BooleanField,
  BooleanInput,
  SimpleForm,
  FunctionField,
} from 'react-admin';
import DataGrid from '../../../components/DataGrid';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import CrudWrapper from '../../../components/CrudWrapper';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import { NewEnumField } from '../../../types/NewEnumField';
import { DeviceInput } from '../../../components/CustomInputs';
import { DeviceSoftwareInput } from '../../../components/SoftwareCustomField';
import { sourceTab } from '../../../components/SourceTab';
import { AuditLog } from '../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import ActionBarShow from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

const inputs = (
  <AccordionForm autoClose redirect="show" toolbar={<EditToolbar message={'mail server'} />}>
    <AccordionFormPanel label="Claims">
      <DeviceInput />
      {DeviceSoftwareInput}
      <TextInput halfWidth source="applicationRoles" />
      <BooleanInput halfWidth source="isExternalAccessRequired" label="isExternalAccessRequired" />
      <BooleanInput
        halfWidth
        source="isVirtualMachineMobilityEnabled"
        label="isVirtualMachineMobilityEnabled"
      />

      <BooleanInput halfWidth source="isCostAvoidanceCandidate" label="isCostAvoidanceCandidate" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput fullWidth source="notes" Label="" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput fullWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput fullWidth source="feedback" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Link Documents">
      <FileUpload source="documents" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const MailServerShow = (props) => {
  const [hasDocuments, setHasDocuments] = React.useState(false);

  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="deviceId" reference="devices">
            <TextField source="deviceName" />
          </ReferenceField>
          <TextField halfWidth source="productName" />

          <TextField halfWidth source="version" label="version" />
          <TextField halfWidth source="applicationRoles" label="applicationRoles" />
          <BooleanField
            halfWidth
            source="isExternalAccessRequired"
            label="isExternalAccessRequired"
          />
          <BooleanField
            halfWidth
            source="isVirtualMachineMobilityEnabled"
            label="isVirtualMachineMobilityEnabled"
          />
          <BooleanField
            halfWidth
            source="isCostAvoidanceCandidate"
            label="isCostAvoidanceCandidate"
          />
          <TextField halfWidth source="fullyQualifiedDomainName" label="fullyQualifiedDomainName" />
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        <Tab label="Mail usage">
          <ReferenceManyField reference="mailusage" target="mailserver" addLabel={false}>
            <DataGrid source="mailusage">
              <AgGridColumn headerName="Claims">
                <AgGridColumn field="user.userName" />
                <AgGridColumn field="mailServerDevice.deviceName" />
              </AgGridColumn>

              <AgGridColumn headerName="Properties">
                <AgGridColumn field="isEnterpriseFeaturesEnabled" />
                <AgGridColumn field="isUnifiedMessagingEnabled" />
                <AgGridColumn field="isInPlaceArchiveEnabled" />
                <AgGridColumn field="isInPlaceHoldEnabled" />
                <AgGridColumn field="isDataLossPreventionEnabled" />
                <AgGridColumn field="isPersonalRetentionPolicyEnabled" />
                <AgGridColumn field="isOWAEnabled" />
                <AgGridColumn field="isActiveSyncEnabled" />
                <AgGridColumn field="isOWAInUse" />
                <AgGridColumn field="isActiveSyncInUse" />
                <AgGridColumn field="owaDevices" />
                <AgGridColumn field="activeSyncDevices" />
                <AgGridColumn field="isCostAvoidanceCandidate" />
              </AgGridColumn>
              <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
              <AgGridColumn headerName="Annotation">
                <AgGridColumn field="notes" />
                <AgGridColumn field="interpretations" />
                <AgGridColumn field="feedback" />
              </AgGridColumn>
              <AgGridColumn headerName="Sources">
                <AgGridColumn
                  headerName="Created by"
                  field="sources"
                  valueGetter={(e) => createdBy(e)}
                />
                <AgGridColumn
                  field="sources"
                  headerName="Updated by"
                  valueGetter={(e) => updatedBy(e)}
                />
              </AgGridColumn>
            </DataGrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Rules">
          <NewEnumField source="deviceRole" label="deviceRole" reference="deviceroles" />
          <NewEnumField source="deviceType" label="deviceType" reference="devicetypes" />
          <NewEnumField source="deviceClass" label="deviceClass" reference="deviceclasses" />
          <NewEnumField source="deviceState" label="deviceState" reference="devicestates" />
          <NewEnumField source="deviceStatus" label="deviceStatus" reference="devicestatuses" />
        </Tab>

        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        {sourceTab}

        {hasDocuments && (
          <Tab label="Linked Documents">
            <SimpleForm toolbar={<EmptyToolbar />}>
              <FileUpload source="documents" show />
            </SimpleForm>
          </Tab>
        )}
        <FunctionField
          style={{ diplay: 'none' }}
          label=""
          render={(record) => setHasDocuments(record?.documents?.length > 0)}
        />
      </TabbedShowLayout>
    </Show>
  );
};

export const MailServerEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {inputs}
      </Edit>
    </CrudWrapper>
  );
};

export const MailServerCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {inputs}
    </Create>
  </CrudWrapper>
);
