const apiUrl = process.env.REACT_APP_API_URL;

const getRequestOptionsJson = () => {
  return {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
  };
};

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.title);
  }
  return response;
};

export default {
  setActiveLanguage: (data) => {
    const request = new Request(apiUrl + `/platformusers/currentlanguage`, {
      method: 'POST',
      ...getRequestOptionsJson(),
      body: JSON.stringify(data),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
};
