import * as React from 'react';
import DataGrid from '../../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../../types/NewEnumCell';
import { dateFormatter } from '../../../../utils/formatters';
import { dateColumnFilter, numberColumnFilter } from '../../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton
        basePath={basePath}
        onClick={() => sessionStorage.setItem('agreementId', props.agreementId)}
      />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();
  const url = props.location.pathname;
  const urlArray = url.split('/');
  const agreementId = urlArray[2];

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Agreements" actions={<ListActions agreementId={agreementId} />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn field="sku" />
        <AgGridColumn field="productName" />
        <AgGridColumn field="productVersion" />
        <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
        <AgGridColumn {...useAgGridEnumCol('licensemodels', 'licenseModel')} />
        <AgGridColumn
          field="currencyCode"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn
          field="licenseQuantity"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn
          field="packageQuantity"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn
          field="netUnitPrice"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn
          field="extendedAmount"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn field="countryOfUsage" />
        <AgGridColumn
          field="usageStartDate"
          valueFormatter={dateFormatter}
          filter="agMultiColumnFilter"
          filterParams={dateColumnFilter}
        />
        <AgGridColumn {...useAgGridEnumCol('agreementlineusagetypes', 'usageTypeCode')} />
        <AgGridColumn {...useAgGridEnumCol('agreementlinemeasureunits', 'measureUnitCode')} />
        <AgGridColumn {...useAgGridEnumCol('agreementstatuses', 'statusCode')} />
        <AgGridColumn {...useAgGridEnumCol('agreementlinetypes', 'typeCode')} />
        <AgGridColumn
          field="measureUnitQuantity"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn
          field="futureUnitPriceA"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn
          field="futureUnitPriceB"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn
          field="futureUnitPriceC"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn
          field="futureUnitPriceD"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn headerName="Annotanions">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" />
          <AgGridColumn field="feedback" />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
