import * as React from 'react';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Report';
import History from '@material-ui/icons/History';
import { Title } from 'react-admin';
import { useHistory } from 'react-router';

const MyError = (props) => {
  const history = useHistory();
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Title title="Error" />
      <h1>
        <ErrorIcon /> No {props.action} Selected{' '}
      </h1>
      <div>Please select a {props.action.toLowerCase()} to continue !</div>
      <div style={{ paddingTop: '20px' }}>
        <Button variant="contained" startIcon={<History />} onClick={() => history.go(-1)}>
          Back
        </Button>
      </div>
    </div>
  );
};

export default MyError;
