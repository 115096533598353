import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlConnect = process.env.REACT_APP_API_CONNECT;
const httpClient = fetchUtils.fetchJson;

//URLPARAMS
const getQueryValues = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const initialValues = {};
  for (let [key, value] of urlParams.entries()) {
    initialValues[key] = value;
  }
  return initialValues;
};

const getRequestOptions = () => {
  return {
    headers: new Headers({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }),
  };
};

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.title);
  }
  return response;
};

export default {
  getLimitOffsetList: (resource, { limit, offset, filter, sort }) => {
    // const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;

    const query = stringify({ ...getQueryValues(), sort: JSON.stringify(sort), limit, offset });
    const url = `${apiUrl}/${resource}/list?${query}`;

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(filter),
      ...getRequestOptions(),
    }).then(({ headers, json }) => ({
      data: json.results,
      total: json.count,
    }));
  },

  getList: (resource, params) => {
    // const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;

    const query = stringify({ ...getQueryValues(), ...params.filter });
    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url, getRequestOptions()).then(({ headers, json }) => ({
      data: json,
      total: 10,
    }));
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, getRequestOptions()).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const urlParams = new URLSearchParams(window.location.search);
    const initialValues = {};
    for (let [key, value] of urlParams.entries()) {
      initialValues[key] = value;
    }

    const query = stringify(initialValues);
    /* const query = {
      filter: JSON.stringify({ id: params.ids }),
    }; */
    const url = `${apiUrl}/${resource}?${query}`;
    return httpClient(url, getRequestOptions()).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const urlParams = new URLSearchParams(window.location.search);
    const initialValues = {};
    for (let [key, value] of urlParams.entries()) {
      initialValues[key] = value;
    }
    console.log(params);

    const query = `?${stringify(initialValues)}&${params.target}=${params.id}`;
    const filterQuery = `?${stringify(initialValues)}&${stringify(params.filter)}`;

    const finalQuery = params.target === 'filter' ? filterQuery : query;
    /* const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    }; */
    const url = `${apiUrl}/${resource}${finalQuery}`;

    return httpClient(url, getRequestOptions()).then(({ headers, json }) => ({
      data: json,
      total: 10,
    }));
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/update/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      ...getRequestOptions(),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      ...getRequestOptions(),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/create`, {
      method: 'POST',
      body: JSON.stringify({ ...params.data, ...getQueryValues() }),
      ...getRequestOptions(),
    }).then(({ json }) => {
      console.log(json);
      return {
        data: { ...json, id: json.id },
      };
    }),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/delete/${params.id}`, {
      method: 'DELETE',
      ...getRequestOptions(),
    }).then(() => ({ data: {} })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
      ...getRequestOptions(),
    }).then(({ json }) => ({ data: json }));
  },

  import: (resource, postData) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: postData,
      ...getRequestOptions(),
    }).then(({ json }) => ({ data: json }));
  },
  getUserRole: () => {
    const request = new Request(apiUrlConnect + '/connect/userinfo', {
      method: 'GET',
      ...getRequestOptions(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
};
