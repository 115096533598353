import * as React from 'react';
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Edit,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  TextInput,
  required,
  SelectInput,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import CrudWrapper from '../../../components/CrudWrapper';
import Chip from '@material-ui/core/Chip';
import { FormSpy } from 'react-final-form';
import { NewEnumField } from '../../../types/NewEnumField';
import EditToolbar from '../../../components/EditToolbar';

const TextArrayField = ({ record, source }) => {
  const array = record[source];
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div />;
  } else {
    return (
      <>
        {array.map((item) => (
          <Chip label={item} key={item} />
        ))}
      </>
    );
  }
};
TextArrayField.defaultProps = { addLabel: true };

const PageTitle = ({ record }) => {
  return <span>{record ? record.rawProductName : ''}</span>;
};

const fields = (
  <TabbedShowLayout>
    <Tab label="Basic">
      <TextField source="agreement.agreementNumber" label="agreement.agreementNumber" />
      <NewEnumField source="agreementType" reference="agreementtypes" />
      <NewEnumField source="agreementLineType" reference="agreementlinetypes" />
      <TextArrayField source="productGroups">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </TextArrayField>
      <TextArrayField source="productCategories">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </TextArrayField>
      <TextArrayField source="editions">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </TextArrayField>
      <BooleanField source="isPlatform" />
      <TextField source="bundles" />
      <TextField source="currencyCode" />
      <TextField source="priceType" />
      <TextField source="priceCategory" />
      <TextField source="priceLevelApplicationPool" />
      <TextField source="priceLevelServerPool" />
      <TextField source="priceLevelSystemPool" />
      <NumberField source="discount" />
      <NumberField source="vat" />
    </Tab>
  </TabbedShowLayout>
);

const choices = [{ id: 'A' }, { id: 'B' }, { id: 'C' }, { id: 'D' }];

export const PricingOptionsShow = (props) => {
  return (
    <Show title={<PageTitle />} {...props}>
      {fields}
    </Show>
  );
};

const AutocompleteArrays = () => {
  return (
    <FormSpy>
      {(formProps) => {
        return (
          <>
            <ReferenceInput
              source="agreementId"
              label="Agreement"
              reference="agreements"
              onChange={(e) => {
                formProps.form.change('productGroups', []);
                formProps.form.change('productCategories', []);
                formProps.form.change('editions', []);
              }}
              validate={required()}
            >
              <SelectInput optionText="agreementNumber" />
            </ReferenceInput>
            <ReferenceArrayInput
              source="productGroups"
              reference="productgroups"
              disabled={!formProps.values.agreementId}
              filter={{ agreementId: formProps.values.agreementId }}
            >
              <AutocompleteArrayInput />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              source="productCategories"
              reference="productcategories"
              disabled={!formProps.values.agreementId}
              filter={{ agreementId: formProps.values.agreementId }}
            >
              <AutocompleteArrayInput />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              source="editions"
              reference="producteditions"
              disabled={!formProps.values.agreementId}
              filter={{ agreementId: formProps.values.agreementId }}
            >
              <AutocompleteArrayInput />
            </ReferenceArrayInput>
          </>
        );
      }}
    </FormSpy>
  );
};

const inputs = (
  <AccordionForm redirect="show" toolbar={<EditToolbar message={'pricing option'} />}>
    <AccordionFormPanel label="Basic" defaultExpanded>
      <AutocompleteArrays />
      <BooleanInput source="isPlatform" />
      <SelectInput
        source="priceLevelApplicationPool"
        choices={choices}
        optionText="id"
        optionValue="id"
        validate={required()}
      />
      <SelectInput
        source="priceLevelServerPool"
        choices={choices}
        optionText="id"
        optionValue="id"
        validate={required()}
      />
      <SelectInput
        source="priceLevelSystemPool"
        choices={choices}
        optionText="id"
        optionValue="id"
        validate={required()}
      />

      <BooleanInput source="isPlatform" />
      <TextInput source="bundles" />
      <TextInput source="currencyCode" />
      <TextInput source="priceType" />
      <TextInput source="priceCategory" />
      <NumberInput source="discount" />
      <NumberInput source="vat" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const PricingOptionsEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title="New" {...props}>
        {inputs}
        {/* <AccordionForm redirect="show">

          <AccordionFormPanel label="Basic" defaultExpanded>

            <AutocompleteArrays />
            <BooleanInput source="isPlatform" />
            <SelectInput
              source="priceLevelApplicationPool"
              choices={choices}
              optionText="id"
              optionValue="id"
            />
            <SelectInput
              source="priceLevelServerPool"
              choices={choices}
              optionText="id"
              optionValue="id"
            />
            <SelectInput
              source="priceLevelSystemPool"
              choices={choices}
              optionText="id"
              optionValue="id"
            />

            <NumberInput source="discount" />
            <NumberInput source="vat" />
          </AccordionFormPanel>
        </AccordionForm> */}
      </Edit>
    </CrudWrapper>
  );
};

export const PricingOptionsCreate = (props) => {
  return (
    <CrudWrapper>
      <Create title="New" {...props}>
        {inputs}

        {/* <AccordionForm redirect="show">
          <AccordionFormPanel label="Basic" defaultExpanded>
            <ReferenceInput
              source="agreementId"
              label="Agreement"
              reference="agreements"
              onChange={(e) => agreementForFilter(e)}
            >
              <SelectInput optionText="agreementNumber" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
              source="productGroups"
              reference="productgroups"
              disabled={!agreement?.value.length}
              filter={{ agreementId: agreement?.value }}
            >
              <SelectArrayInput onChange={(value) => { }} />
            </ReferenceInput>
            <ReferenceInput
              source="productCategories"
              reference="productcategories"
              disabled={!agreement?.value.length}
              filter={{ agreementId: agreement?.value }}
            >
              <SelectArrayInput />
            </ReferenceInput>
            <ReferenceInput
              source="editions"
              reference="producteditions"
              disabled={!agreement?.value.length}
              filter={{ agreementId: agreement?.value }}
            >
              <SelectArrayInput />
            </ReferenceInput>

            <BooleanInput source="isPlatform" />
            <SelectInput
              source="priceLevelApplicationPool"
              choices={choices}
              optionText="id"
              optionValue="id"
            />
            <SelectInput
              source="priceLevelServerPool"
              choices={choices}
              optionText="id"
              optionValue="id"
            />
            <SelectInput
              source="priceLevelSystemPool"
              choices={choices}
              optionText="id"
              optionValue="id"
            />

            <NumberInput source="discount" />
            <NumberInput source="vat" />
          </AccordionFormPanel>

        </AccordionForm> */}
      </Create>
    </CrudWrapper>
  );
};
