// in src/MyLoginPage.js
import React from 'react'
import { useState } from 'react';
import { Login, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation} from "react-router-dom";
import {Button, Typography, TextField} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        padding: theme.spacing(3)
      },
    },
    inputField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    }
  }));

  const ConfirmEmail = ({ theme }) => {
      const [password, setPassword] = useState('');
      const [confirmPassword, setConfirmPassword] = useState('');
      const classes = useStyles();
      const apiUrl = process.env.REACT_APP_API_URL; 
      const notify = useNotify();      
      let history = useHistory();

      const url =  apiUrl +  '/platformusers/confirmemail';

      let { search } = useLocation();

      const query = new URLSearchParams(search);
      const user = query.get('user');
      const token = query.get('token');
      
      const data = {
        "user": user,
        "token": token,
        "password" : password
      }
      
      const request = new Request( url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data) 
      });
      

    const handleClick = (e) => {
        e.preventDefault();
        if (password !== confirmPassword){
            notify("Please check that the passwords match!")
        } else {
          return fetch(request)
            .then((response) => {
              if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
              }
              notify("Your password has been set!")
              history.push("/login")
              return response.json();
            })
            .catch(() => {
              console.log("err");
            });
    }}
    

    return (
        <Login className={classes.root}>
            <Typography align="center" variant="subtitle1" component="h2">
                Please enter your desired password in order to reset it.
            </Typography>

            <form onSubmit={handleClick} noValidate autoComplete="off">
                <p><TextField onChange={e => setPassword(e.target.value)} fullWidth id="password" label="Password" type="password" /></p>
                <p><TextField onChange={e => setConfirmPassword(e.target.value)} fullWidth id="confirm_password" label="Confirm Password" type="password" /></p>
                <Button variant="contained" type="submit" fullWidth color="primary" disableElevation>
                Confirm password
                </Button>
            </form>
        </Login>
    );
};

export default ConfirmEmail;
