import * as React from 'react';
import ServerSideDataGrid from '../../../components/ServerSideDataGrid';
import { useHistory } from 'react-router';
import {
  useAgGridEnumCol,
  observationValueGetter,
  useNewAgGridEnumCol,
} from '../../../types/NewEnumCell';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  Button,
  useRefresh,
  useNotify,
  sanitizeListRestProps,
} from 'react-admin';
import { SelectedSoftwareDialog, SoftwareDialog } from '../../../components/SoftwareDialog';
import { useState } from 'react';
import { getScope } from '../../../utils/scopeQuery';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import { useFetch, useFetchData } from '../../../utils/useFetch';
import { numberColumnFilter } from '../../../utils/filterParams';
import SSRWrapper from '../../../components/SSRWrapper';
import DatagridAPI from '../../../providers/dataGridProvider';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const fetchProductComponent = useFetch(
    `/measurements/applyproductcomponents/${getScope().measurementId}`,
    'POST',
    true,
  );

  const setProductComponents = async () => {
    await fetchProductComponent().then((response) =>
      notify(
        `Components applied, removed liability from ${response?.removedLiability} software lines`,
      ),
    );
    refresh();
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button label="Apply product components" onClick={setProductComponents} />
      <SoftwareDialog resource="devicesoftwaremodal" urlResource={props.resource} />
      <SelectedSoftwareDialog resource={props.resource} callBulkApply={props.callBulkApply} />
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();
  const [gridApi, setGridApi] = useState(null);
  const refresh = useRefresh();
  const notify = useNotify();
  const softwareRuleIndicator = 0;
  const fetchApplyLicenses = useFetchData(`/${props.resource}/bulkapplylicenses`, 'POST', true);

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  const callBulkApply = async (licenseMetric, productId, licenseQuantity) => {
    const data = {
      licenseMetric: licenseMetric,
      productId: productId,
      licenseQuantity: licenseQuantity,
      softwareIds: gridApi.getSelectedRows().map((row) => row.id),
    };
    await fetchApplyLicenses(data);
    refresh();
    notify(`Bulk apply started`);
  };

  const ruleValueGetter = (params) => {
    return params.data.rules
      .filter((rule) => rule.ruleIndicator === softwareRuleIndicator)
      .map((rule) => rule.ruleTag)
      .join(', ');
  };

  const ruleFilterParams = (ruleTag, columnName = 'rules') => {
    return {
      filters: [
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            excelMode: 'windows',
            values: async (params) => {
              const response = await DatagridAPI.getFilterValues(props.resource, columnName);
              const ruleCategory = response.filter((rule) => rule.substring(0, 2) === ruleTag);
              params.success(ruleCategory);
            },
            refreshValuesOnOpen: true,
          },
        },
      ],
    };
  };

  return (
    <SSRWrapper>
      <ComplexActionBarShow
        id={props.id}
        {...props}
        oldToolbar={<ListActions callBulkApply={callBulkApply} resource={props.resource} />}
      />
      <ServerSideDataGrid
        {...props}
        enableSelection
        setGridApi={setGridApi}
        rowDoubleClicked={onRowClicked}
      >
        <AgGridColumn
          field=""
          checkboxSelection
          headerCheckboxSelection
          maxWidth={35}
          suppressMenu
          sortable={false}
          lockPosition
          resizable={false}
          checkbox
          pined
        />
        <AgGridColumn headerName="Claims" pivot={true} enablePivot={true}>
          <AgGridColumn field="device_DeviceTag" headerName="Device Tag" />
          <AgGridColumn field="device_DeviceName" headerName="Device Name" />
          <AgGridColumn field="softwareTag" />
          <AgGridColumn
            field="device_FullyQualifiedDomainName"
            headerName="Fully Qualified Domain Name"
          />
        </AgGridColumn>

        <AgGridColumn headerName="Validations">
          <AgGridColumn field="installProduct_IsLicensable" headerName={'Is Licensable'} />
          <AgGridColumn field="hasLiability" />
          <AgGridColumn field="isMaintenanceRequired" />
          <AgGridColumn field="isCoveredByHostInstall" />
          <AgGridColumn field="isCostSavingsCandidate" />
          <AgGridColumn field="isCostAvoidanceCandidate" />
          <AgGridColumn field="fingerprint_IsIgnorable" columnGroupShow="open" />
          <AgGridColumn field="fingerprint_isReconciled" columnGroupShow="open" />
          <AgGridColumn
            field="installProduct_TrackUsage"
            headerName={'Track Usage'}
            columnGroupShow="open"
          />
          <AgGridColumn
            field="processors"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="coresPerProcessor"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="totalCores"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
        </AgGridColumn>
        <AgGridColumn headerName="Classifications">
          <AgGridColumn
            field="rules"
            valueGetter={(e) => ruleValueGetter(e)}
            filterParams={() => ruleFilterParams('SR')}
          />
          <AgGridColumn
            field="baseObservations"
            valueGetter={(e) => observationValueGetter(e)}
            filterParams={() => ruleFilterParams('OB', 'baseObservations')}
          />
          <AgGridColumn
            {...useNewAgGridEnumCol('devicescopes', 'device_DeviceScope', 'deviceSoftware')}
            headerName="Device scope"
          />
          <AgGridColumn
            {...useNewAgGridEnumCol('deviceroles', 'device_DeviceRole', 'deviceSoftware')}
            headerName="Device role"
          />
          <AgGridColumn
            {...useNewAgGridEnumCol('devicetypes', 'device_DeviceType', 'deviceSoftware')}
            headerName="Device types"
          />
          <AgGridColumn
            {...useNewAgGridEnumCol('deviceclasses', 'device_DeviceClass', 'deviceSoftware')}
            headerName="Device class"
          />
          <AgGridColumn
            {...useNewAgGridEnumCol('devicestates', 'device_DeviceState', 'deviceSoftware')}
            headerName="Device state"
          />
          <AgGridColumn
            {...useNewAgGridEnumCol('devicestatuses', 'device_DeviceStatus', 'deviceSoftware')}
            headerName="Device status"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Install product">
          <AgGridColumn
            field="installProduct_Manufacturer_ManufacturerKey"
            headerName={'Manufacturer'}
          />
          <AgGridColumn field="installProduct_ProductName" headerName={'Product Name'} />
          <AgGridColumn
            field="installProduct_ProductPool"
            columnGroupShow="open"
            headerName={'Product Pool'}
          />
          <AgGridColumn
            field="installProduct_ProductCategory"
            columnGroupShow="open"
            headerName={'Product Category'}
          />
          <AgGridColumn
            field="installProduct_ProductGroup"
            columnGroupShow="open"
            headerName={'Product Group'}
          />
          <AgGridColumn
            field="installProduct_ProductFamily"
            columnGroupShow="open"
            headerName={'Product Family'}
          />
          <AgGridColumn field="installProduct_Version" headerName={'Version'} />
          <AgGridColumn field="installProduct_Edition" headerName={'Edition'} />
          <AgGridColumn
            field="installQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
        </AgGridColumn>
        <AgGridColumn headerName="Licensed product">
          <AgGridColumn field="licenseProduct_ProductName" headerName={'Product Name'} />
          <AgGridColumn
            field="licenseProduct_ProductPool"
            columnGroupShow="open"
            headerName={'Product Pool'}
          />
          <AgGridColumn
            field="licenseProduct_ProductCategory"
            columnGroupShow="open"
            headerName={'Product Category'}
          />
          <AgGridColumn
            field="licenseProduct_ProductGroup"
            columnGroupShow="open"
            headerName={'Product Group'}
          />
          <AgGridColumn
            field="licenseProduct_ProductFamily"
            columnGroupShow="open"
            headerName={'Product Family'}
          />
          <AgGridColumn field="licenseProduct_Version" headerName={'Version'} />
          <AgGridColumn field="licenseProduct_Edition" headerName={'Edition'} />
          <AgGridColumn
            field="licenseQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'installMetric')} />
          <AgGridColumn {...useAgGridEnumCol('licensemodels', 'installModel')} />
        </AgGridColumn>
        <AgGridColumn headerName="Organization">
          <AgGridColumn
            field="device_BusinessUnit_BusinessUnitName"
            headerName={'Business Unit Name'}
          />
          <AgGridColumn
            field="device_BusinessUnit_City"
            columnGroupShow="open"
            headerName={'City'}
          />
          <AgGridColumn
            field="device_BusinessUnit_Country"
            columnGroupShow="open"
            headerName={'Country'}
          />
          <AgGridColumn
            field="device_BusinessUnit_Region"
            columnGroupShow="open"
            headerName={'Region'}
          />
          <AgGridColumn
            field="device_BusinessUnit_Organization_OrganizationName"
            columnGroupShow="open"
            headerName={'Organization Name'}
          />
        </AgGridColumn>
        <AgGridColumn headerName="Annotation">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </ServerSideDataGrid>
    </SSRWrapper>
  );
};
