import MailServerIcon from '@material-ui/icons/Mail';
import MailServerList from './MailServerList';
import { MailServerCreate, MailServerEdit, MailServerShow } from './MailServerForm';

export default {
  list: MailServerList,
  show: MailServerShow,
  edit: MailServerEdit,
  icon: MailServerIcon,
  create: MailServerCreate,
};
