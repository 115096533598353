import * as React from 'react';
import {
  Edit,
  TextInput,
  Create,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  SimpleForm,
  FunctionField,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import CrudWrapper from '../../../components/CrudWrapper';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import { Annotations } from '../../../components/Annotations';
import { sourceTab } from '../../../components/SourceTab';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.businessUnitName : ''}</span>;
};

const inputs = (
  <AccordionForm redirect="show" toolbar={<EditToolbar message={'organizational unit'} />}>
    <AccordionFormPanel label="Details">
      <TextInput source="distinguishedName" label="distinguishedName" />
      <TextInput source="adObjectGuid" />
      <TextInput source="ouPath" />
      <TextInput source="domain" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput fullWidth source="notes" Label="" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput fullWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Link Documents">
      <FileUpload source="documents" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const OrganizationalUnitShow = (props) => {
  const [hasDocuments, setHasDocuments] = React.useState(false);

  const fields = (
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="organizationalUnitTag" />
        <TextField source="ouPath" label="ouPath" />
        <TextField source="adObjectGuid" label="adObjectGuid" />
        <TextField source="distinguishedName" label="distinguishedName" />
        <TextField source="domain" label="domain" />
      </Tab>
      <Tab label="Observations">
        <RuleDisplayObservation />
      </Tab>
      <Tab label="Annotations">
        <Annotations noFeedback />
      </Tab>
      {sourceTab}
      {hasDocuments && (
        <Tab label="Linked Documents">
          <SimpleForm toolbar={<EmptyToolbar />}>
            <FileUpload source="documents" show />
          </SimpleForm>
        </Tab>
      )}
      <FunctionField
        style={{ diplay: 'none' }}
        label=""
        render={(record) => setHasDocuments(record?.documents?.length > 0)}
      />
    </TabbedShowLayout>
  );
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      {fields}
    </Show>
  );
};

export const OrganizationalUnitEdit = (props) => (
  <CrudWrapper>
    <Edit undoable={false} title={<PageTitle />} {...props}>
      {inputs}
    </Edit>
  </CrudWrapper>
);

export const OrganizationalUnitCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {inputs}
    </Create>
  </CrudWrapper>
);
