import {
  ArrayField,
  Datagrid,
  FunctionField,
  ReferenceField,
  SingleFieldList,
  TextField,
} from 'ra-ui-materialui';
import React from 'react';

const ruleTable = {
  marginTop: 10,
  borderBottom: '1px solid rgba(30,30,98,0.1)',
  padding: 10,
};

export const RuleDisplay = ({ whereReference, ruleConfigurationKey }) => {
  const configStorage = JSON.parse(localStorage.getItem('configuration'));
  const setObjectWhere = configStorage[ruleConfigurationKey].models;

  return (
    <FunctionField
      label="rules"
      render={(record) => {
        return (
          <ArrayField source="rules">
            <SingleFieldList style={{ display: 'flex', flexDirection: 'column', margin: '40' }}>
              <ReferenceField label="Where" source="id" reference={whereReference} link="show">
                <FunctionField
                  label="Where"
                  render={(record) => {
                    const whereField = record.where
                      .map(
                        (item) =>
                          `  ${item.joinOperator ? item.joinOperator : ''} 
                          ${item.source}  ${item.field}  ${item.operator}  ${
                            setObjectWhere[item.source]?.[item.field]?.values?.[item.value] ??
                            item.value ??
                            null
                          } `,
                      )
                      .join(' ');

                    return (
                      <div style={ruleTable}>
                        <TextField style={{ marginRight: 10 }} fullWidth source="ruleTag" />
                        {whereField}
                      </div>
                    );
                  }}
                />
              </ReferenceField>
            </SingleFieldList>
          </ArrayField>
        );
      }}
    />
  );
};

const observationTypeMap = [
  'assumption',
  'attention',
  'clarification',
  'recommendation',
  'validation',
  'warning',
];

export const RuleDisplayObservation = () => {
  return (
    <FunctionField
      label="Observations"
      render={(record) => {
        const observations = record.observations
          ? record.observations.map((observation) => observation).flat()
          : null;
        return (
          <ArrayField record={{ observations }} source="observations" label="observations">
            <Datagrid>
              <ReferenceField label="Tag" source="id" reference="baseobservation" link="show">
                <TextField fullWidth source="observationTag" />
              </ReferenceField>
              <FunctionField
                label="observationType"
                render={(record) => observationTypeMap[record.observationType]}
              />
              <TextField fullWidth source="observation" label="observation" />
              <TextField fullWidth source="action" label="action" />
            </Datagrid>
          </ArrayField>
        );
      }}
    />
  );
};
