import * as React from 'react';
import DataGrid from '../../../../../components/DataGrid';
import { List } from 'react-admin';

export default (props) => {
 
  return (
    <List {...props} title="Organization Classes" >
      <DataGrid {...props}   />
    </List>
  );
};
