import AgreementIcon from '@material-ui/icons/Edit';
import AgreementList from './AgreementList.js';
import {
    AgreementShow,
    AgreementEdit,
    AgreementCreate,
} from './AgreementForm';

export default {
  list: AgreementList,
  show: AgreementShow,
  edit: AgreementEdit,
  create: AgreementCreate,
  icon: AgreementIcon,
};
