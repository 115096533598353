import React from 'react';
import { useState, useRef } from 'react';
import { useListContext, Loading, useTranslate, useLocale, useRefresh } from 'react-admin';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import 'ag-grid-enterprise';
import AG_GRID_LOCALE_NL from './locale.nl';
import { usePreferences } from '@react-admin/ra-preferences';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import DatagridAPI from '../providers/dataGridProvider';
import '../App.css';
import * as $ from 'jquery';
import { useFetch } from '../utils/useFetch';
import { isObject } from 'lodash';

const GRID_CONFIG_KEY = 'gridConfiguration';
const GRID_COLUMN_STATE_KEY = 'columnState';
const GRID_COLUMN_GROUP_STATE_KEY = 'columnGroupState';
const GRID_FILTER_STATE_KEY = 'filterState';
const GRID_PAGE_SIZE_KEY = 'pageSize';

function setGridConfig(path, key, data) {
  const configItem = localStorage.getItem(GRID_CONFIG_KEY);

  let config;
  try {
    config = JSON.parse(configItem);
    if (!isObject(config)) {
      config = {};
    }
  } catch {
    config = {};
  }

  if (!isObject(config[path])) {
    config[path] = {};
  }
  config[path][key] = data;
  localStorage.setItem(GRID_CONFIG_KEY, JSON.stringify(config));
}

function getGridConfig(path, key) {
  const configItem = localStorage.getItem(GRID_CONFIG_KEY);
  try {
    const config = JSON.parse(configItem);
    return config?.[path]?.[key];
  } catch {
    return null;
  }
}

const AGGridBody = (props) => {
  React.useEffect(() => {
    const keydown = (e) => {
      if (e.keyCode === 27) {
        if (isFullscreenRef.current) toggleFullscreen();
      }
    };

    window.addEventListener('keydown', keydown);
    return () => {
      window.removeEventListener('keydown', keydown);
    };
  }, []);

  const translate = useTranslate();
  const locale = useLocale();
  const { data, loading } = useListContext();
  const refresh = useRefresh();
  const [displayFilter, setDisplayFilter] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [theme] = usePreferences('theme');
  const dataGridTheme = theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham';
  const translations = locale === 'nl' ? AG_GRID_LOCALE_NL : null;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isFullscreenRef = useRef(false);
  const path = props.basePath.replace(/\//g, '');
  const fetchConfig = useFetch(`/configurations/` + path, 'GET');

  const rowData = loading ? null : Object.keys(data).map((key) => data[key]);

  const onRowClicked = (table) => {
    if (!table?.data?.id) return;
    if (table?.event.ctrlKey || table?.event.shiftKey) return;

    if (props.rowDoubleClicked) {
      props.rowDoubleClicked(table.data.id);
    }
    if (props.onRowClickedFullData) {
      props.onRowClickedFullData(table.data);
    }
  };

  const selectionChanged = (params) => {
    if (!props.onRowSelection) return;
    props.onRowSelection(gridApi.getSelectedRows());
  };

  let keys = [];
  if (props.columnDefs) {
    keys = props.columnDefs;
  } else if (rowData?.length > 0) {
    keys = Object.keys(rowData[0]).map((key) => {
      return {
        field: key,
        headerName: key,
      };
    });
  }

  const onGridReady = async (params) => {
    if (props.setGridApi) {
      props.setGridApi(params.api);
    }
    setGridApi(params.api);

    if (props.onGridReady) {
      props.onGridReady();
    }
  };

  const autoSizeAll = (columnApi) => {
    var allColumnIds = [];
    columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    columnApi.autoSizeColumns(allColumnIds);
  };

  const saveGridState = (params) => {
    if (params.finished === undefined || params.finished === true) {
      const columnState = params.columnApi.getColumnState();
      setGridConfig(path, GRID_COLUMN_STATE_KEY, columnState);

      const columnGroupState = params.columnApi.getColumnGroupState();
      setGridConfig(path, GRID_COLUMN_GROUP_STATE_KEY, columnGroupState);

      const filterModel = params.api.getFilterModel();
      setGridConfig(path, GRID_FILTER_STATE_KEY, filterModel);
    }
  };

  const onPageSizeChanged = async (e) => {
    var value = e.target.value;

    gridApi.paginationSetPageSize(Number(value));
    setGridConfig(path, GRID_PAGE_SIZE_KEY, Number(value));
  };

  const onFirstDataRendered = (params) => {
    const columnState = getGridConfig(path, GRID_COLUMN_STATE_KEY);
    console.log(columnState);
    if (columnState) {
      params.columnApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    const filterModel = getGridConfig(path, GRID_FILTER_STATE_KEY);
    if (filterModel) {
      params.api.setFilterModel(filterModel);
    }

    const columnGroupState = getGridConfig(path, GRID_COLUMN_GROUP_STATE_KEY);
    if (columnGroupState) {
      params.columnApi.setColumnGroupState(columnGroupState);
    }

    const pageSize = getGridConfig(path, GRID_PAGE_SIZE_KEY);
    if (pageSize) {
      params.api.paginationSetPageSize(Number(pageSize));
      document.getElementById('page-size').value = pageSize;
    }
  };

  const resetToolPanel = () => {
    const resetConfig = async (key) => {
      setGridConfig(path, key, {});
      refresh();
    };

    return (
      <div>
        <button style={{ margin: 10 }} onClick={() => resetConfig(GRID_FILTER_STATE_KEY)}>
          Reset filters
        </button>
        <button style={{ margin: 10 }} onClick={() => resetConfig(GRID_COLUMN_STATE_KEY)}>
          Reset columns
        </button>
      </div>
    );
  };

  const rowHeight = 20;

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      $('.list-page .MuiPaper-root').addClass('grid-fullscreen');
      $('body').addClass('overflow-hidden');
      setIsFullscreen(true);
    } else {
      $('.list-page .MuiPaper-root').removeClass('grid-fullscreen');
      $('body').removeClass('overflow-hidden');
      setIsFullscreen(false);
    }
  };

  function getContextMenuItems(params) {
    var result = [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      {
        name: 'Export CSV',
        action: () => {
          params.api.exportDataAsCsv({ allColumns: true });
        },
      },
    ];
    return result;
  }

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === 'Escape' && isFullscreen) {
          toggleFullscreen();
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <button onClick={() => toggleFullscreen()} class="btn-fullscreen">
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </button>
      </div>
      <div className={dataGridTheme} style={{ height: isFullscreen ? '93vh' : '72vh' }}>
        <AgGridReact
          defaultExportParams={{
            processCellCallback: (params) => {
              if (params.column.getColId().toLowerCase().endsWith('date')) {
                const date = new Date(params.value);
                if (!isNaN(date.getTime())) {
                  return date.toISOString().split('T')[0];
                }
              }
              return params.value;
            },
          }}
          style={{ padding: 0, position: 'sticky', width: '100%', height: '100%', top: 0, left: 0 }}
          defaultColDef={{
            tooltipValueGetter: (params) => {
              return params.value;
            },
            minWidth: 50,
            width: 150,
            sortable: true,
            editable: false,
            rowHeight: 150,
            resizable: true,
            enableRowGroup: true,
            floatingFilter: false,
            headerCheckboxSelectionFilteredOnly: true,
            filter: 'agMultiColumnFilter',
            menuTabs: ['filterMenuTab', 'columnsMenuTab'],
            filterParams: {
              filters: [
                {
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    excelMode: 'windows',
                  },
                },
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'contains',
                    caseSensitive: false,
                  },
                },
              ],
            },
          }}
          rowHeight={rowHeight}
          autoSizePadding
          autoGroupColumnDef={{ minWidth: 200 }}
          groupUseEntireRow={true}
          suppressDragLeaveHidesColumns={true}
          suppressMakeColumnVisibleAfterUnGroup={true}
          suppressRowClickSelection={true}
          allowContextMenuWithControlKey={true}
          getContextMenuItems={getContextMenuItems}
          rowGroupPanelShow={'always'}
          onRowDoubleClicked={onRowClicked}
          suppressCellSelection={true}
          // onCellMouseDown={onCellClicked}
          onSelectionChanged={selectionChanged}
          //columnDefs={columnDefs}
          rowData={rowData}
          pagination={true}
          accentedSort={true}
          paginationNumberFormatter={function (params) {
            return params.value.toLocaleString();
          }}
          components={{
            rowNodeIdRenderer: function (params) {
              return params.node.id + 1;
            },
          }}
          enableRangeSelection={true}
          rowSelection={'multiple'}
          onRowClicked={(e) => e.node.setSelected(!e.node.isSelected())}
          statusBar={{
            statusPanels: [
              { statusPanel: 'agFilteredRowCountComponent' },
              { statusPanel: 'agSelectedRowCountComponent' },
              { statusPanel: 'agAggregationComponent' },
            ],
          }}
          sideBar={{
            toolPanels: [
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              },
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
              {
                id: 'reset',
                labelDefault: 'Reset',
                labelKey: 'reset',
                iconKey: 'reset',
                toolPanel: 'resetToolPanel',
              },
            ],
            position: 'right',
            hiddenByDefault: false,
          }}
          frameworkComponents={{ resetToolPanel: resetToolPanel }}
          localeText={translations}
          onGridReady={onGridReady}
          onFilterChanged={saveGridState}
          onColumnVisible={saveGridState}
          onColumnGroupOpened={saveGridState}
          onColumnMoved={saveGridState}
          onColumnPinned={saveGridState}
          onColumnResized={saveGridState}
          onSortChanged={saveGridState}
          // onRangeSelectionChanged={onRangeSelectionChanged}
          onRowDataUpdated={props.onRowDataUpdated}
          onRowDataChanged={props.onRowDataChanged}
          onFirstDataRendered={onFirstDataRendered}
        >
          {props.children}
        </AgGridReact>
      </div>
      <div
        style={{
          fontSize: '12px',
          padding: '4px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        {displayFilter?.length < 1 ? (
          <div></div>
        ) : (
          <div style={{ fontWeight: '500' }}>
            <strong>Active filters:{'   '} </strong>
            {displayFilter?.map((filter) =>
              filter === displayFilter[displayFilter.length - 1]
                ? ` ${translate(filter)}`
                : ` ${translate(filter)},`,
            )}
          </div>
        )}
        <div>
          Page Size:
          <select onChange={(e) => onPageSizeChanged(e)} id="page-size">
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">500</option>
            <option value="1000">1.000</option>
            <option value="5000">5.000</option>
            <option value="10000">10.000</option>
            <option value="50000">50.000</option>
            <option value="100000">100.000</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default AGGridBody;
