import React from 'react';
import {
  List,
  Table,
  TableRow,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useNotify } from 'ra-core';
import constants from '../../../utils/constants';

const selectValueTypes = constants.IMPORT_TYPES;

function WizardList({ files, changedType, nextEnabled }) {
  nextEnabled();
  const notify = useNotify();

  const apiUrl = process.env.REACT_APP_API_URL;
  const urlSplit = window.location.href.split('/');
  const measurement_id = urlSplit[urlSplit.length - 1];

  const onChange = (e, file) => {
    const id = file.id;
    const fileType = e.target.value;
    const newFile = {
      fileName: file.fileName,
      fileType: fileType,
      id: id,
      parsingStatus: file.parsingStatus,
    };

    changedType(newFile);

    const data = [
      {
        id: id,
        fileType: fileType,
      },
    ];

    const request = new Request(apiUrl + `/measurements/files/${measurement_id}`, {
      method: 'PUT',
      headers: new Headers({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          notify('Something went wrong, please try again');
        }
        return response.json();
      })
      .then((res) => {
        notify('Updated successfully!');
      })
      .catch(() => {
        notify('Something went wrong, please try again');
      });
  };

  return (
    <div style={{ listStyle: 'none' }}>
      <List component="nav" aria-label="main mailbox folders">
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ borderBottom: '2px solid #000' }}>
                <TableRow>
                  <TableCell>File Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file) => (
                  <TableRow key={file.id}>
                    <TableCell component="th" scope="row">
                      {file.fileName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControl style={{ width: 150 }}>
                        <InputLabel id="demo-simple-select-label">
                          {file.fileType === null ? 'File Type' : selectValueTypes[file.fileType]}
                        </InputLabel>
                        <Select
                          key={file.id}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={(e) => {
                            onChange(e, file);
                          }}
                        >
                          {selectValueTypes.map((type, i) => (
                            <MenuItem value={i}>{type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </List>
    </div>
  );
}

export default WizardList;
