import * as React from 'react';
import { ArrayField, Datagrid, Tab, TextField, FunctionField } from 'react-admin';
import DateTimeFormatter from '../utils/dateTimeFormatter';

export const sourceTab = (
  <Tab label="Sources">
    <ArrayField source="sources">
      <Datagrid>
        <TextField source="sourceFile" label="Source File" />
        <TextField source="sourceType" label="Source Type" />
        <FunctionField
          label="Import Date"
          render={(record) => <DateTimeFormatter date={record.importDate} />}
        />
      </Datagrid>
    </ArrayField>
  </Tab>
);
