import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { ArrowRight, ViewHeadlineRounded } from '@material-ui/icons';
import constants from '../../../utils/constants';
import MeasurementAPI from '../../../providers/APIProviders/measurementProvider';
import {
    Edit,
    useTranslate,
    Show,
    SimpleForm,
    TextInput,
    Create,
    ReferenceInput,
    SelectInput,
    DateInput,
    Tab,
    TextField,
    DateField,
    ReferenceField,
    TabbedShowLayout,
    required,
    TopToolbar,
    EditButton,
    Button,
    Notification,
    Toolbar,
    useNotify,
    useRefresh,
    usePermissions,
    DeleteButton,
    SaveButton,
    useGetOne,
    BooleanInput,
} from 'react-admin';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout/lib/src/forms';
import WizardDropzone from '../wizardForm/DropzoneComponent';
import WizardDraggable from '../wizardForm/WizardDraggable';
import MeasurementInventoryTable from './MeasurementInventoryTable';
import AssignUsers, { AssignUsersShow } from '../../../components/AssignUsers';
import { NullableDateInput } from '../../../components/NullableDateInput';
import measurementProvider from '../../../providers/APIProviders/measurementProvider';
import { CrudWrapperProject } from '../../../components/CrudWrapper';
import { NewEnumInput } from '../../../types/NewEnumInput';
import { NewEnumField } from '../../../types/NewEnumField';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {
    ComplexActionBarShow,
    LockDialog,
    MeasurementDeleteDialog,
    useIsLocked,
} from '../../../components/ActionBarShow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Lock from '@material-ui/icons/Lock';
import LayersClear from '@material-ui/icons/LayersClear';
import { getScope } from '../../../utils/scopeQuery';
import { useFetch } from '../../../utils/useFetch';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import MyToolbarMenuDropdown from '../../../components/MyToolbarMenuDropdown';

const fileTypes = constants.FILE_TYPES;
const apiUrl = process.env.REACT_APP_API_URL;

const PageTitle = ({ record }) => {
    return <span>{record ? record.measurementName : ''}</span>;
};

const useStyles = makeStyles({
    tab: {
        maxWidth: '40em',
        display: 'block',
    },
    toolbarStyles: {
        backgroundColor: 'white',
    },
});

export const InventoryAction = ({ basePath, id, refresh }) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const notify = useNotify();
    const measurementId = getScope().measurementId;
    const { data } = useGetOne('measurements', measurementId);
    const fetchAllDelete = useFetch(`/measurements/deletecontents/${id}`, 'DELETE');

    const handeleDelete = async () => {
        await fetchAllDelete().then((response) => notify('Content deleted'));
        refresh();
    };

    const reassignFingerprints = async () => {
        await measurementProvider.reassignFingerprints(id);
        notify('Reassigned Fingerprints');
        refresh();
    };

    const callAnonymize = async () => {
        notify('Anonymization started');
        await measurementProvider.anonymize(id);
        notify('Anonymized');
    };

    return (
        <TopToolbar>
            {(permissions === 'admin' || permissions === 'planner' || permissions === 'analyst') && (
                <MyToolbarMenuDropdown>
                    <LockDialog icon={<Lock />} id={measurementId} data={data} label={'lock'} />
                    <Button
                        href={`${apiUrl}/measurements/exportquestionnaire/${id}`}
                        label={translate('Export questionnaire')}
                        children={<CloudDownloadIcon />}
                    />
                    <Button
                        label={translate('Reassign fingerprints')}
                        children={<FingerprintIcon />}
                        onClick={reassignFingerprints}
                    />
                    <Button
                        label={translate('Anonymize')}
                        children={<PersonOutlineIcon />}
                        onClick={callAnonymize}
                    />
                    <EditButton basePath={basePath} record={data} />
                    <MeasurementDeleteDialog
                        icon={<LayersClear />}
                        label={'delete'}
                        handleDelete={handeleDelete}
                    />
                </MyToolbarMenuDropdown>
            )}
        </TopToolbar>
    );
};

export const MeasurementShow = (props) => {
    const notify = useNotify();
    const { permissions } = usePermissions();
    const apiUrl = process.env.REACT_APP_API_URL;
    const refresh = useRefresh();
    const [files, setFiles] = useState([]);
    const [listOfFiles, setListOfFiles] = useState([]);
    const [isWizard] = useState(false);
    const timeouts = useRef([]);
    const translate = useTranslate();
    const [editRow, setEditRow] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [triggerChange, setTriggerChange] = useState(false);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);
    const isMounted = useRef(false);
    const locked = useIsLocked();
    const fetchList = useFetch(`/measurements/files/${props.id}`, 'GET');
    const fetchSaveChanges = useFetch(`/measurements/files/${props.id}`, 'PUT', listOfFiles);
    const fetchParse = useFetch(`/measurements/parse/${props.id}`, 'POST', true);

    const onDrop = async (uploadedFile) => {
        uploadedFile.map((i) => postUploadFiles([i]));
        setEditRow(true);
        clearTimeouts();
    };

    const postUploadFiles = async (data) => {
        setIsFileUploading(true);

        let formData = new FormData();

        data.forEach((file) => {
            formData.append('file', file);
        });

        //formData.forEach(async (file) => {
        try {
            let response = await postDataWithPromise(formData);
            setIsFileUploading(false);
            setFileUploadProgress(0);
            setFiles((files) => [...files, JSON.parse(response)]);
            setListOfFiles((files) => [...files, JSON.parse(response)]);
            setSaveButtonEnabled(false);
        } catch (err) {
            console.log(err);
        }
        //  });
    };

    const postDataWithPromise = async (data) => {
        const xhr = new XMLHttpRequest();

        return new Promise(function (resolve, reject) {
            xhr.upload.onprogress = (event) => {
                const percentage = parseInt((event.loaded / event.total) * 100);
                setFileUploadProgress(percentage);
            };

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status >= 300) {
                        reject('Error, status code = ' + xhr.status);
                    } else {
                        resolve(xhr.response);
                    }
                }
            };
            xhr.open('POST', apiUrl + `/measurements/file/upload/${props.id}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
            xhr.send(data);
        });
    };

    const handleDelete = (name) => {
        const removeItem = files.filter((file) => {
            deleteAction(name.id);

            return file !== name;
        });
        setFiles(removeItem);
    };

    const handleParse = async () => {
        await fetchParse();

        setTriggerChange(!triggerChange);
        setFiles([]);
        notify('Files successfully added to import queue!');
    };

    const triggerEdit = () => {
        setEditRow(!editRow);
        clearTimeouts();
    };

    const saveEdit = async () => {
        await fetchSaveChanges();
        setEditRow(!editRow);
        startTimeouts();
    };

    const onRowChange = async (e, file) => {
        const id = file.id;
        const fileType = e.target.value;
        const data = [{ id: id, fileType: fileType }];
        for (var i in listOfFiles) {
            if (listOfFiles[i].id === file.id) {
                listOfFiles[i].fileType = fileType;
                break;
            }
        }

        const response = await MeasurementAPI.updateSingleFile(props.id, data);
        setSaveButtonEnabled(!(response.findIndex((file) => file.fileType === null) > -1));
    };

    const getList = async (withTimeout = true) => {
        const response = await fetchList();
        setListOfFiles(response);
        setSaveButtonEnabled(!(response.findIndex((file) => file.fileType === null) > -1));
        if (withTimeout) startTimeouts();
    };

    const clearTimeouts = () => {
        timeouts.current.forEach((timeout) => {
            clearTimeout(timeout);
        });
    };

    const startTimeouts = () => {
        timeouts.current = [
            ...timeouts.current,
            setTimeout(() => {
                if (isMounted.current) getList();
            }, 1000),
        ];
    };

    useEffect(() => {
        getList(false);
    }, [triggerChange]);

    useEffect(() => {
        isMounted.current = true;
        getList();
        return () => {
            clearTimeouts();
            isMounted.current = false;
        };
    }, []);

    const handleSortReset = () => {
        let sortedArray = listOfFiles.sort((a, b) => a.fileType - b.fileType)

        let filteredArray = sortedArray.filter(file => file.fileType !== 30)
        let specificFileTypeArray = sortedArray.filter(file => file.fileType === 30)

        specificFileTypeArray.forEach(file => filteredArray.unshift(file))
        setListOfFiles(reorder(filteredArray));
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        setListOfFiles(reorder(listOfFiles, result.source.index, result.destination.index));
    };


    const classes = useStyles();

    return (
        <Show
            title={<PageTitle />}
            actions={
                <ComplexActionBarShow
                    measurement={true}
                    id={props.id}
                    {...props}
                    oldToolbar={<InventoryAction id={props.id} data={{}} refresh={refresh} />}
                />
            }
            {...props}
        >
            <TabbedShowLayout>
                <Tab label="Details" contentClassName={classes.tab}>
                    <ReferenceField source="projectId" reference="projects">
                        <TextField source="projectName" />
                    </ReferenceField>
                    <TextField fullWidth source="measurementName" />
                    <DateField fullWidth source="plannedStartDate" />
                    <DateField fullWidth source="plannedEndDate" />
                    <DateField fullWidth source="collectionDate" />
                    <DateField fullWidth source="actualStartDate" />
                    <DateField fullWidth source="actualEndDate" />
                    <NewEnumField fullWidth source="status" reference="measurementstatuses" />
                </Tab>
                <Tab label="Members">
                    <AssignUsersShow />
                </Tab>
                <Tab label="Inventory">
                    {(permissions === 'admin' || permissions === 'analyst') && !locked && (
                        <AccordionForm toolbar={<FileUploadToolbar />}>
                            <AccordionFormPanel
                                label={translate('Click here to upload files')}
                                defaultExpanded={true}
                            >
                                {/* WIZARD DROPZONE ELEMENT FOR IMPORTING INVENTORY TRACKERS */}
                                <WizardDropzone
                                    isWizard={isWizard}
                                    urlId={props.id}
                                    files={files}
                                    onDrop={onDrop}
                                    handleDelete={handleDelete}
                                    isFileUploading={isFileUploading}
                                    fileUploadProgress={fileUploadProgress}
                                />
                            </AccordionFormPanel>
                            <Notification />
                        </AccordionForm>
                    )}

                    {listOfFiles && listOfFiles.length && !locked ? (
                        editRow ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 15 }}>
                                <Button
                                    variant="contained"
                                    children={<ViewHeadlineRounded />}
                                    color="primary"
                                    onClick={handleSortReset}
                                    label={translate('Reset Order to Default')}
                                    size="small"
                                    style={{ marginRight: 10 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={saveEdit}
                                    label={translate('Save')}
                                    size="small"
                                    children={<SaveIcon />}
                                    style={{ margin: 0 }}
                                />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 15 }}>
                                <Button
                                    variant="contained"
                                    children={<ArrowRight />}
                                    color="primary"
                                    onClick={handleParse}
                                    label={translate('Import')}
                                    size="small"
                                    style={{ marginRight: 10 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={triggerEdit}
                                    label={translate('Edit')}
                                    size="small"
                                    children={<EditIcon />}
                                    style={{ margin: 0 }}
                                />
                            </div>
                        )
                    ) : (
                        <div>{translate('No inventory trackers')}</div>
                    )}

                    {!editRow ? (
                        <MeasurementInventoryTable measurementId={props.id} listOfFiles={listOfFiles} />
                    ) : (
                        <WizardDraggable
                            fileList={listOfFiles}
                            setFileList={setListOfFiles}
                            isWizard={isWizard}
                            files={files}
                            onDragEnd={onDragEnd}
                            onRowChange={onRowChange}
                            editRow={editRow}
                            triggerEdit={triggerEdit}
                        />
                    )}
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const MeasurementEdit = (props) => {
    const ArchiveToolbar = (props) => (
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
            <SaveButton />
            <DeleteButton label="Archive" />
        </Toolbar>
    );

    return (
        <CrudWrapperProject>
            <Edit undoable={false} title={<PageTitle />} {...props}>
                <SimpleForm toolbar={<ArchiveToolbar />}>
                    <TextInput fullWidth source="measurementName" validate={required()} />
                    <DateInput fullWidth source="plannedStartDate" validate={required()} />
                    <DateInput fullWidth source="plannedEndDate" validate={required()} />
                    <NullableDateInput fullWidth source="collectionDate" />
                    <NullableDateInput fullWidth source="actualStartDate" />
                    <NullableDateInput fullWidth source="actualEndDate" />
                    <BooleanInput source="isArchived" />
                    <NewEnumInput fullWidth source="status" reference="measurementstatuses" />
                    <AssignUsers />
                </SimpleForm>
            </Edit>
        </CrudWrapperProject>
    );
};

export const MeasurementCreate = (props) => {
    const classes = useStyles();
    const refresh = useRefresh();
    const history = useHistory();

    return (
        <CrudWrapperProject>
            <Create
                title="New"
                {...props}
                onSuccess={(value) => {
                    history.push(`${value.data.id}/show`);
                    refresh();
                }}
            >
                <SimpleForm className={classes.tab} redirect="show">
                    <ReferenceInput fullWidth source="inheritedMeasurementId" reference="measurements">
                        <SelectInput optionText="measurementName" />
                    </ReferenceInput>
                    <TextInput fullWidth source="measurementName" validate={required()} />
                    <DateInput fullWidth source="plannedStartDate" validate={required()} />
                    <DateInput fullWidth source="plannedEndDate" validate={required()} />
                    <NullableDateInput fullWidth source="collectionDate" />
                    <NullableDateInput fullWidth source="actualStartDate" />
                    <NullableDateInput fullWidth source="actualEndDate" />
                    <NewEnumInput fullWidth source="status" reference="measurementstatuses" />
                    <AssignUsers />
                </SimpleForm>
            </Create>
        </CrudWrapperProject>
    );
};

const FileUploadToolbar = (props) => {
    const classes = useStyles();

    return (
        <Toolbar className={classes.toolbarStyles} {...props}>
            <hr />
        </Toolbar>
    );
};

const deleteAction = async (id) => {
    await MeasurementAPI.delete(id);
};
