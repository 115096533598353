import { useNotify } from 'react-admin';

const apiUrl = process.env.REACT_APP_API_URL;

const getRequestOptionsJson = () => {
  return {
    headers: new Headers({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }),
  };
};

export const useFetch = (path, method, data, isJson) => {
  const notify = useNotify();
  const url = apiUrl + path;

  const handleErrors = (response) => {
    if (!response.ok) {
      notify(response.title);
    }
    return response;
  };

  const getData = () => {
    const request = new Request(url, {
      method,
      ...getRequestOptionsJson(),
      body: data ? JSON.stringify(data) : null,
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.text().then((text) => {
            let json;
            try {
              json = JSON.parse(text);
            } catch (e) {}
            return json;
          });
        }
      })
      .catch((error) => {
        notify('Something went wrong!', 'warning');
        console.log(error);
      });
  };

  return getData;
};

export const useFetchData = (path, method, isJson) => {
  const notify = useNotify();
  const url = apiUrl + path;

  const handleErrors = (response) => {
    if (!response.ok) {
      notify(response.title);
    }
    return response;
  };

  const getData = (data) => {
    const request = new Request(url, {
      method,
      ...getRequestOptionsJson(),
      body: data ? JSON.stringify(data) : null,
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.text().then((text) => {
            let json;
            try {
              json = JSON.parse(text);
            } catch (e) {}
            return json;
          });
        }
      })
      .catch((error) => {
        notify('Something went wrong!', 'warning');
        console.log(error);
      });
  };

  return getData;
};
