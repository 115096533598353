const compare = (a, b) => {
  if (a.importDate > b.importDate) {
    return 1;
  }
  if (a.importDate < b.importDate) {
    return -1;
  }
  return 0;
};

export const createdBy = (params) => {
  return params.data?.sources
    ?.sort(compare)
    .slice(0, 1)
    .map((item) => `${item.sourceTag} - ${item.sourceType} [${item.sourceFile}]`)
    .join(', ');
};

export const updatedBy = (params) => {
  return params.data.sources
    ?.sort(compare)
    .slice(1)
    .map((item) => `${item.sourceTag} - ${item.sourceType} [${item.sourceFile}]`)
    .join(', ');
};
