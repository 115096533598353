import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';

import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Product Components" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn headerName="Composed Product">
          <AgGridColumn
            field="componentProduct"
            headerName="Composed component product"
            valueGetter={(params) => {
              const component = params.data.componentProduct;
              return `${component.productName} ${component.version}`;
            }}
          />
          <AgGridColumn
            field="componentProduct"
            headerName="Composed product"
            valueGetter={(params) => {
              const product = params.data.product;
              return `${product.productName} ${product.version}`;
            }}
          />
        </AgGridColumn>
        <AgGridColumn headerName="Properties">
          <AgGridColumn field="sequenceNumber" />
          <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
          <AgGridColumn field="hasLiability" />
          <AgGridColumn field="isRequired" />
          <AgGridColumn field="suiteName" />
          <AgGridColumn field="suiteGroup" />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
