import React, { useEffect, useState } from 'react';
import {
  Edit,
  Show,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  Create,
  SimpleForm,
  useNotify,
  RefreshButton,
  useRedirect,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ActionBarShow from '../../../components/ActionBarShow';
import EditToolbar from '../../../components/EditToolbar';
import { useFetch } from '../../../utils/useFetch';

const useStyles = makeStyles((theme) => ({
  resetBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const PageTitle = ({ record }) => {
  return <span>{record ? record.userName : ''}</span>;
};

export const UserShow = (props) => {
  const classes = useStyles();
  const fetchResetPassword = useFetch(`/platformusers/${props.id}/requestpasswordreset`, 'POST');

  const handleRedirect = async () => {
    let response = await fetchResetPassword();
    return response;
  };

  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField halfWidth source="firstName" label="firstName" />
          <TextField halfWidth source="lastName" label="lastName" />
          <TextField fullwidth source="email" label="email" />
          <TextField fullwidth source="role" label="role" />

          <RefreshButton
            className={classes.resetBtn}
            label="Reset password"
            onClick={handleRedirect}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const UserEdit = (props) => {
  const notify = useNotify();
  const [userRoles, setUserRoles] = useState([]);
  const fetchRole = useFetch('/roles', 'GET');

  useEffect(() => {
    const getUserRoles = async () => {
      let response = await fetchRole();
      const roleList = response.map((str) => ({ name: str.name }));
      setUserRoles(roleList);
    };

    getUserRoles();
  }, []);

  const onSuccess = (response) => {
    notify('User successfully created!');
    return response.json();
  };

  const onFailure = (error) => {
    notify(`Something went wrong!`);
  };

  return (
    <Edit undoable={false} title={<PageTitle />} {...props}>
      <SimpleForm
        onSuccess={onSuccess}
        onFailure={onFailure}
        redirect="show"
        toolbar={<EditToolbar message={'platform user'} />}
      >
        <TextInput type="email" source="email" label="Email" />
        <TextInput type="text" source="firstName" label="First name" />
        <TextInput type="text" source="lastName" label="Last name" />
        <SelectInput source="role" choices={userRoles} optionValue="name" />
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [userRoles, setUserRoles] = useState([]);
  const fetchRole = useFetch('/roles', 'GET');

  useEffect(() => {
    const getUserRoles = async () => {
      let response = await fetchRole();
      const roleList = response.map((str) => ({ name: str.name }));
      setUserRoles(roleList);
    };

    getUserRoles();
  }, []);

  const onSuccess = (response) => {
    notify('User successfully created!');
    redirect('/platformUsers');

    return response.json();
  };

  const onFailure = (error) => {
    notify(`Something went wrong!`);
  };

  return (
    <Create {...props} title={<PageTitle />}>
      <SimpleForm onSuccess={onSuccess} onFailure={onFailure} redirect="show">
        <TextInput type="email" source="email" label="Email" />
        <TextInput type="text" source="firstName" label="First name" />
        <TextInput type="text" source="lastName" label="Last name" />
        <SelectInput source="role" choices={userRoles} optionValue="name" />
      </SimpleForm>
    </Create>
  );
};
