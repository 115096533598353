import DatabaseServerIcon from '@material-ui/icons/DataUsage';
import { DatabaseServerCreate, DatabaseServerEdit, DatabaseServerShow } from './DatabaseServerForm';
import DatabaseServerList from './DatabaseServerList';

export default {
  list: DatabaseServerList,
  icon: DatabaseServerIcon,
  show: DatabaseServerShow,
  edit: DatabaseServerEdit,
  create: DatabaseServerCreate,
};
