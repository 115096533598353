import * as React from 'react';
import { useHistory } from 'react-router';

import {
  Edit,
  Show,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  NumberInput,
  NumberField,
  Create,
  ReferenceField,
  BooleanField,
  BooleanInput,
} from 'react-admin';

import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import ActionBarShow from '../../../components/ActionBarShow';
import DataGrid from '../../../components/DataGrid';
import { Annotations } from '../../../components/Annotations';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import { DeviceInput } from '../../../components/CustomInputs';
import { sourceTab } from '../../../components/SourceTab';
import CrudWrapper from '../../../components/CrudWrapper';
import { AuditLog } from '../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import EditToolbar from '../../../components/EditToolbar';
import { numberColumnFilter } from '../../../utils/filterParams';

const PageTitle = ({ record }) => {
  return <span>{record ? record.deviceName : ''}</span>;
};

const inputs = (
  <AccordionForm redirect="show" autoClose toolbar={<EditToolbar message={'hardware'} />}>
    <AccordionFormPanel label="Claims">
      <DeviceInput />
      <TextInput source="make" />
      <TextInput source="model" />
      <TextInput source="serialNumber" label="serialNumber" />
      <TextInput source="ipAddress" label="ipAddress" />
      <TextInput source="macAddress" label="MACAddress" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Processors">
      <NumberInput source="physicalProcessors" />
      <NumberInput source="physicalCores" />
      <NumberInput source="threads" />
      <NumberInput source="logicalProcessors" />
      <TextInput source="cpuModel" />
      <TextInput source="cpuSpeed" />
      <TextInput source="ram" />
      <TextInput source="host" />
      <BooleanInput source="hasInstalls" />
      <BooleanInput source="isManual" />
      <BooleanInput source="isArchived" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Classification">
      <NewEnumInput source="deviceRole" reference="deviceroles" />
      <NewEnumInput source="deviceType" reference="devicetypes" />
      <NewEnumInput source="deviceClass" reference="deviceclasses" />
      <NewEnumInput source="deviceState" reference="devicestates" />
      <NewEnumInput source="deviceStatus" reference="devicestatuses" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput source="notes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput source="feedback" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const HardwareShow = (props) => {
  const history = useHistory();
  const softwareRuleIndicator = 0;

  const ruleValueGetter = (params) => {
    return params.data?.rules
      ?.filter((rule) => rule.ruleIndicator === softwareRuleIndicator)
      .map((rule) => rule.ruleTag)
      .join(', ');
  };

  const onRowClicked = (id) => {
    const url = `/software/${id}/show`;
    history.push(url);
  };

  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="deviceId" reference="devices" label="deviceName">
            <TextField source="deviceName" />
          </ReferenceField>
          <ReferenceField source="deviceId" reference="devices" label="deviceTag">
            <TextField source="deviceTag" />
          </ReferenceField>
          <ReferenceField source="deviceId" reference="devices" label="businessUnit">
            <TextField source="businessUnitName" />
          </ReferenceField>
          <TextField source="make" label="make" />
          <TextField source="model" label="model" />
          <TextField source="version" label="version" />
        </Tab>
        <Tab label="Extended">
          <AccordionFormPanel label="Claims" defaultExpanded="true">
            <TextField source="deviceTag" label="deviceTag" />
            <TextField source="deviceName" label="deviceName" />
            <TextField source="fullyQualifiedDomainName" label="fullyQualifiedDomainName" />
            <TextField source="domain" />
            <TextField source="serialNumber" label="serialNumber" />
            <TextField source="ipAddress" label="ipAddress" />
            <TextField source="macAddress" label="MACAddress" />
            <TextField source="operatingSystem" label="operatingSystem" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Classification">
            <NewEnumField source="deviceRole" reference="deviceroles" />
            <NewEnumField source="deviceType" reference="devicetypes" />
            <NewEnumField source="deviceClass" reference="deviceclasses" />
            <NewEnumField source="deviceState" reference="devicestates" />
            <NewEnumField source="deviceStatus" reference="devicestatuses" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Processors">
            <NumberField source="totalCores" />
            <NumberField source="physicalProcessors" />
            <NumberField source="physicalCores" />
            <NumberField source="threads" />
            <NumberField source="logicalProcessors" />
            <TextField source="cpuModel" />
            <TextField source="cpuSpeed" />
            <TextField source="ram" />
            <TextField source="host" />
            <BooleanField source="hasInstalls" />
            <BooleanField source="isManual" />
            <BooleanField source="isArchived" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Organization">
            <TextField source="businessUnitName" label="businessUnitName" />
            <TextField source="city" label="city" />
            <TextField source="country" label="country" />
            <TextField source="continent" label="continent" />
            <TextField source="region" label="region" />
          </AccordionFormPanel>
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        <Tab label="Software">
          <ReferenceManyField reference="devicesoftware" target="hardwareId" addLabel={false}>
            <DataGrid source="devicesoftware" rowDoubleClicked={onRowClicked}>
              <AgGridColumn headerName="Claims">
                <AgGridColumn field="deviceTag" />
                <AgGridColumn field="deviceName" />
                <AgGridColumn field="softwareTag" />
                <AgGridColumn field="fullyQualifiedDomainName" />
              </AgGridColumn>

              <AgGridColumn headerName="Validations">
                <AgGridColumn field="isLicensable" />
                <AgGridColumn field="hasLiability" />
                <AgGridColumn field="isMaintenanceRequired" />
                <AgGridColumn field="isCoveredByHostInstall" />
                <AgGridColumn field="isCostSavingsCandidate" />
                <AgGridColumn field="isCostAvoidanceCandidate" />
                <AgGridColumn field="trackUsage" />
                <AgGridColumn
                  field="processors"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="coresPerProcessor"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="totalCores"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
              </AgGridColumn>
              <AgGridColumn headerName="Classifications">
                <AgGridColumn field="softwareRule" valueGetter={(e) => ruleValueGetter(e)} />
                <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
                <AgGridColumn {...useAgGridEnumCol('devicescopes', 'deviceScope')} />
                <AgGridColumn {...useAgGridEnumCol('deviceroles', 'deviceRole')} />
                <AgGridColumn {...useAgGridEnumCol('devicetypes', 'deviceType')} />
                <AgGridColumn {...useAgGridEnumCol('deviceclasses', 'deviceClass')} />
                <AgGridColumn {...useAgGridEnumCol('devicestates', 'deviceState')} />
                <AgGridColumn {...useAgGridEnumCol('devicestatuses', 'deviceStatus')} />
              </AgGridColumn>
              <AgGridColumn headerName="Install product">
                <AgGridColumn field="installProductName" />
                <AgGridColumn field="installProductPool" />
                <AgGridColumn field="installProductCategory" />
                <AgGridColumn field="installProductGroup" />
                <AgGridColumn field="installProductFamily" />
                <AgGridColumn field="installVersion" />
                <AgGridColumn field="installEdition" />
                <AgGridColumn
                  field="installQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
              </AgGridColumn>
              <AgGridColumn headerName="Licensed product">
                <AgGridColumn field="licenseProductName" />
                <AgGridColumn field="licenseProductPool" />
                <AgGridColumn field="licenseProductCategory" />
                <AgGridColumn field="licenseProductGroup" />
                <AgGridColumn field="licenseProductFamily" />
                <AgGridColumn field="licenseVersion" />
                <AgGridColumn field="licenseEdition" />
                <AgGridColumn
                  field="licenseQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'installMetric')} />
                <AgGridColumn {...useAgGridEnumCol('licensemodels', 'installModel')} />
              </AgGridColumn>
              <AgGridColumn headerName="Organization">
                <AgGridColumn field="businessUnitName" />
                <AgGridColumn field="city" />
                <AgGridColumn field="country" />
                <AgGridColumn field="region" />
              </AgGridColumn>
              <AgGridColumn headerName="Annotation">
                <AgGridColumn field="notes" />
                <AgGridColumn field="interpretations" />
                <AgGridColumn field="feedback" />
              </AgGridColumn>
              <AgGridColumn headerName="Sources">
                <AgGridColumn
                  headerName="Created by"
                  field="sources"
                  valueGetter={(e) => createdBy(e)}
                />
                <AgGridColumn
                  field="sources"
                  headerName="Updated by"
                  valueGetter={(e) => updatedBy(e)}
                />
              </AgGridColumn>
            </DataGrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        {sourceTab}
      </TabbedShowLayout>
    </Show>
  );
};

export const HardwareEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {inputs}
      </Edit>
    </CrudWrapper>
  );
};
export const HardwareCreate = (props) => {
  return (
    <CrudWrapper>
      <Create title={<PageTitle />} {...props}>
        {inputs}
      </Create>
    </CrudWrapper>
  );
};
