import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import { TopToolbar, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {/* <CreateButton basePath={basePath} /> */}
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Sources" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn field="sourceTag" />
        <AgGridColumn field="sourceFile" />
        <AgGridColumn field="sourceType" />
        <AgGridColumn
          field="sourceCount"
          filter={'agMultiColumnFilter'}
          filterParams={numberColumnFilter}
        />
        <AgGridColumn headerName="Annotation">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" />
          <AgGridColumn field="feedback" />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
