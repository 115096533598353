import * as React from 'react';
import {
  Edit,
  TextInput,
  Create,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  required,
  BooleanField,
  BooleanInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import CrudWrapper from '../../../components/CrudWrapper';

import { Annotations } from '../../../components/Annotations';
import FilteringArrayOfProducts from '../../../components/FilteringArrayOfProducts';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.legalEntity : ''}</span>;
};

const fields = (
  <AccordionForm redirect="show" toolbar={<EditToolbar message={'article'} />}>
    <AccordionFormPanel label="Basic" defaultExpanded>
      <FilteringArrayOfProducts source="productIds" />
      <TextInput source="productFamily" validate={required()} />
      <TextInput source="sku" label="sku" validate={required()} />
      <TextInput source="license" validate={required()} />
      <TextInput source="licenseFamily" validate={required()} />
      <NewEnumInput source="licenseModel" reference="licensemodels" />
      <NewEnumInput source="licenseMetric" reference="licensemetrics" />
      <BooleanInput source="isPlatform" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Claims">
      <BooleanInput source="hasPerpetualUseRight" />
      <BooleanInput source="hasUpgradeRight" />
      <BooleanInput source="hasWorkAtHomeRight" />
      <BooleanInput source="hasVirtualMachineMobilityRight" />
      <BooleanInput source="hasExternalAccessRight" />
      <BooleanInput source="hasActivePassiveRight" />
      <BooleanInput source="hasUnlimitedInstancesRight" />
      <BooleanInput source="hasUnlimitedVirtualMachinesRight" />
      <BooleanInput source="hasLimitOnNumberOfCores" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput halfWidth source="notes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput halfWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput halfWidth source="feedback" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const ArticlesShow = (props) => (
  <Show title={<PageTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <ReferenceArrayField reference="products" source="productIds">
          <SingleFieldList>
            <ChipField source="productName" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="sku" label="sku" validate={required()} />
        <TextField source="license" validate={required()} />
        <TextField source="productFamily" validate={required()} />
        <TextField source="licenseFamily" validate={required()} />
        <NewEnumField source="licenseModel" reference="licensemodels" />
        <NewEnumField source="licenseMetric" reference="licensemetrics" />
        <BooleanField source="isPlatform" />
      </Tab>

      <Tab label="Metrics">
        <BooleanField source="hasPerpetualUseRight" />
        <BooleanField source="hasUpgradeRight" />
        <BooleanField source="hasWorkAtHomeRight" />
        <BooleanField source="hasVirtualMachineMobilityRight" />
        <BooleanField source="hasExternalAccessRight" />
        <BooleanField source="hasActivePassiveRight" />
        <BooleanField source="hasUnlimitedInstancesRight" />
        <BooleanField source="hasUnlimitedVirtualMachinesRight" />
        <BooleanField source="hasLimitOnNumberOfCores" />
      </Tab>
      <Tab label="">
        <Annotations />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ArticlesEdit = (props) => (
  <CrudWrapper>
    <Edit undoable={false} title={<PageTitle />} {...props}>
      {fields}
    </Edit>
  </CrudWrapper>
);

export const ArticlesCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {fields}
    </Create>
  </CrudWrapper>
);
