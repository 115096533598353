import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextField,
  TabbedShowLayout,
  Tab,
  NumberField,
  BooleanField,
  BooleanInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput,
  FunctionField,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import { Annotations } from '../../../components/Annotations';
import { AccordionFormPanel } from '@react-admin/ra-form-layout/lib/src/forms';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { sourceTab } from '../../../components/SourceTab';
import { UserInput } from '../../../components/CustomInputs';
import { AuditLog } from '../../../components/AuditLog';
import CrudWrapper from '../../../components/CrudWrapper';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

const inputs = (
  <SimpleForm redirect="show" autoClose toolbar={<EditToolbar message={'mail usage'} />}>
    <UserInput />
    <ReferenceInput source="mailServerId" reference="mailservers">
      <SelectInput optionText="deviceName" />
    </ReferenceInput>
    <ReferenceInput source="mailServerDeviceId" reference="mailservers">
      <SelectInput optionText="deviceName" optionValue="deviceId" />
    </ReferenceInput>
    <BooleanInput source="isActiveSyncEnabled" />
    <BooleanInput source="isActiveSyncInUse" />
    <BooleanInput source="isCostAvoidanceCandidate" />
    <BooleanInput source="isDataLossPreventionEnabled" />
    <BooleanInput source="isEnterpriseFeaturesEnabled" />
    <BooleanInput source="isInPlaceArchiveEnabled" />
    <BooleanInput source="isInPlaceHoldEnabled" />
    <BooleanInput source="isOWAEnabled" />
    <BooleanInput source="isOWAInUse" />
    <BooleanInput source="isPersonalRetentionPolicyEnabled" />
    <BooleanInput source="isPersonalRetentionPolicyEnabled" />
    <BooleanInput source="isUnifiedMessagingEnabled" />
    <NumberInput source="owaDevices" />
    <NumberInput source="activeSyncDevices" />
    <AccordionFormPanel label="Notes">
      <MarkdownInput fullWidth source="notes" Label="" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput fullWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput fullWidth source="feedback" />
    </AccordionFormPanel>
  </SimpleForm>
);

export const MailUsageShow = (props) => {
  const [hasDocuments, setHasDocuments] = React.useState(false);
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField source="user.userName" label={'userName'} />
          <TextField source="mailServerDevice.deviceName" label="deviceName" />
          <BooleanField source="isActiveSyncEnabled" />
          <BooleanField source="isActiveSyncInUse" />
          <BooleanField source="isCostAvoidanceCandidate" />
          <BooleanField source="isDataLossPreventionEnabled" />
          <BooleanField source="isEnterpriseFeaturesEnabled" />
          <BooleanField source="isInPlaceArchiveEnabled" />
          <BooleanField source="isInPlaceHoldEnabled" />
          <BooleanField source="isOWAEnabled" />
          <BooleanField source="isOWAInUse" />
          <BooleanField source="isPersonalRetentionPolicyEnabled" />
          <BooleanField source="isPersonalRetentionPolicyEnabled" />
          <BooleanField source="isUnifiedMessagingEnabled" />
          <NumberField source="owaDevices" />
          <NumberField source="activeSyncDevices" />
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>

        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        {sourceTab}
        {hasDocuments && (
          <Tab label="Linked Documents">
            <SimpleForm toolbar={<EmptyToolbar />}>
              <FileUpload source="documents" show />
            </SimpleForm>
          </Tab>
        )}
        <FunctionField
          style={{ diplay: 'none' }}
          label=""
          render={(record) => setHasDocuments(record?.documents?.length > 0)}
        />
      </TabbedShowLayout>
    </Show>
  );
};

export const MailUsageEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {inputs}
      </Edit>
    </CrudWrapper>
  );
};

export const MailUsageCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {inputs}
    </Create>
  </CrudWrapper>
);
