import ProductIcon from '@material-ui/icons/LocalActivity';
import ProductList from './ProductList';
import { ProductShow, ProductEdit, ProductCreate } from './ProductForm';

export default {
  list: ProductList,
  create: ProductCreate,
  show: ProductShow,
  edit: ProductEdit,
  icon: ProductIcon,
};
