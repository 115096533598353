import React, { useState } from 'react';
import {
  BooleanInput,
  Create,
  Edit,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceArrayField,
  Show,
  SimpleForm,
  TextField,
  BooleanField,
  useRefresh,
  Labeled,
  TopToolbar,
  CloneButton,
  EditButton,
  ArrayInput,
  SimpleFormIterator,
  ChipField,
  SingleFieldList,
} from 'react-admin';
import FilteringProducts from '../../../components/FilteringProducts';
import { NewEnumInput } from '../../../types/NewEnumInput';
import { NewEnumField } from '../../../types/NewEnumField';
import EditToolbar, { CreateToolbar } from '../../../components/EditToolbar';
import { Typography, Grid, Box } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productId : ''}</span>;
};

const fields = (
  <SimpleForm>
    <ReferenceField source="fromProductId" reference="products" label="From product">
      <FunctionField
        label="productName"
        render={(record) => record.productName + ' ' + record.version + ' ' + record.edition}
      />
    </ReferenceField>
    <ReferenceArrayField source="targetProductIds" reference="products" label="To products">
      <SingleFieldList>
        <FunctionField
          label="productName"
          render={(record) => (
            <Typography noWrap>
              {record.productName + ' ' + record.version + ' ' + record.edition}
            </Typography>
          )}
        />
      </SingleFieldList>
    </ReferenceArrayField>
    <NewEnumField source="productPathType" reference="productpathtypes" />
    <NewEnumField source="licenseMetric" reference="licensemetrics" />
    <NewEnumField source="installMetric" reference="licensemetrics" />
    <BooleanField source="isActiveMaintenanceRequired" />
  </SimpleForm>
);

const inputs = (
  <SimpleForm>
    <Typography variant="h6">From Product</Typography>
    <FilteringProducts source="fromProductId" />
    <Typography variant="h6">To Products</Typography>
    <FilteringProducts source="targetProductIds" array />
    <Typography variant="h6">Properties</Typography>
    <NewEnumInput source="productPathType" reference="productpathtypes" />
    <NewEnumInput source="licenseMetric" reference="licensemetrics" />
    <NewEnumInput source="installMetric" reference="licensemetrics" />
    <BooleanInput source="isActiveMaintenanceRequired" />
  </SimpleForm>
);

const ShowToolbar = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const ProductPathShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ShowToolbar />} {...props}>
      {fields}
    </Show>
  );
};

export const ProductPathEdit = (props) => {
  return (
    <Edit undoable={false} title={<PageTitle />} {...props}>
      {inputs}
    </Edit>
  );
};

export const ProductPathCreate = (props) => {
  return (
    <Create title={<PageTitle />} {...props}>
      {inputs}
    </Create>
  );
};
