import * as React from 'react';
import {
  Edit,
  NumberInput,
  Create,
  Show,
  BooleanField,
  BooleanInput,
  TabbedShowLayout,
  Tab,
  TextField,
  NumberField,
  ReferenceField,
} from 'react-admin';
import FilteringProducts from '../../../components/FilteringProducts';
import ActionBarShow from '../../../components/ActionBarShow';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { Annotations } from '../../../components/Annotations';
import { NewEnumInput } from '../../../types/NewEnumInput';
import { NewEnumField } from '../../../types/NewEnumField';
import { MarkdownInput } from '@react-admin/ra-markdown';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.composedProductFamily : ''}</span>;
};

const fields = (
  <AccordionForm redirect="show" toolbar={<EditToolbar message={'compliancy position'} />}>
    <AccordionFormPanel label="Properties" defaultExpanded>
      <FilteringProducts source="productId" />
      <NewEnumInput source="licenseMetric" reference="licensemetrics" />
      <NewEnumInput source="licenseModel" reference="licensemodels" />
      <BooleanInput source={'excludedFromReports'} />
    </AccordionFormPanel>
    <AccordionFormPanel label="Software Usage">
      <NumberInput source="licenseQuantity" />
      <NumberInput source="usageQuantity" />
      <NumberInput source="allocatedQuantity" />
      <NumberInput source="complianceQuantity" />
      <NumberInput source="availableQuantity" />
      <NumberInput source="incomplianceQuantity" />
      <NumberInput source="actualPurchasedQuantity" />
      <NumberInput source="actualSoldQuantity" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Financial Impact">
      <NumberInput source="estimatedPurchasePricePerUnit" />
      <NumberInput source="estimatedSellingPricePerUnit" />
      <NumberInput source="actualPurchasePricePerUnit" />
      <NumberInput source="actualSellingPricePerUnit" />
      <NumberInput source="actualCostAvoidance" />
      <NumberInput source="actualCostSavings" />
      <NumberInput source="actualShortfall" />
      <NumberInput source="actualOverspend" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Financial Impact">
      <NumberInput source="extendedAmountPerUnit" />
      <NumberInput source="estimatedShortfall" />
      <NumberInput source="estimatedOverspend" />
      <NumberInput source="potentialCostAvoidance" />
      <NumberInput source="potentialCostSavings" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput source="notes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="FeedBack">
      <MarkdownInput source="feedback" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const CompliancePositionShow = (props) => (
  <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
    <TabbedShowLayout>
      <Tab label="Basic">
        <ReferenceField source="productId" reference="products" link="show" label="productName">
          <TextField source="productName" label="productFamily" />
        </ReferenceField>
        <ReferenceField source="productId" reference="products" label="productFamily">
          <TextField source="productPool" label="productPool" />
        </ReferenceField>
        <ReferenceField source="productId" reference="products" link="false" label="manufacturer">
          <TextField source="manufacturer" />
        </ReferenceField>
        <ReferenceField source="productId" reference="products" label="productGroup">
          <TextField source="productGroup" />
        </ReferenceField>
        <TextField source="buildNumber" />
        <ReferenceField source="productId" reference="products" label="version">
          <TextField source="version" />
        </ReferenceField>
        <ReferenceField source="productId" reference="products" label="edition">
          <TextField source="edition" />
        </ReferenceField>
        <NewEnumField source="licenseMetric" reference="licensemetrics" />
        <NewEnumField source="licenseModel" reference="licensemodels" />
        <BooleanField source={'excludedFromReports'} />
      </Tab>
      <Tab label="Extended">
        <AccordionFormPanel label="Software Usage">
          <NumberField source="licenseQuantity" />
          <NumberField source="usageQuantity" />
          <NumberField source="allocatedQuantity" />
          <NumberField source="complianceQuantity" />
          <NumberField source="availableQuantity" />
          <NumberField source="incomplianceQuantity" />
        </AccordionFormPanel>
        <AccordionFormPanel label="Actual Impact">
          <NumberField source="actualPurchasedQuantity" />
          <NumberField source="actualSoldQuantity" />
          <NumberField source="actualPurchasePricePerUnit" />
          <NumberField source="actualSellingPricePerUnit" />
          <NumberField source="actualCostAvoidance" />
          <NumberField source="actualCostSavings" />
          <NumberField source="actualShortfall" />
          <NumberField source="actualOverspend" />
        </AccordionFormPanel>
        <AccordionFormPanel label="Estimated Impact">
          <NumberField source="extendedAmountPerUnit" />
          <NumberField source="estimatedShortfall" />
          <NumberField source="estimatedOverspend" />
          <NumberField source="estimatedPurchasePricePerUnit" />
          <NumberField source="estimatedSellingPricePerUnit" />
          <NumberField source="potentialCostAvoidance" />
          <NumberField source="potentialCostSavings" />
        </AccordionFormPanel>
      </Tab>
      <Tab label="Annotations">
        <Annotations />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const CompliancePositionEdit = (props) => (
  <Edit undoable={false} title={<PageTitle />} {...props}>
    {fields}
  </Edit>
);

export const CompliancePositionCreate = (props) => (
  <Create title="New" {...props}>
    {fields}
  </Create>
);
