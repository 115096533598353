import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List, useNotify } from 'react-admin';
import { useHistory } from 'react-router';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  Button,
  sanitizeListRestProps,
} from 'react-admin';
import { getScope } from '../../../utils/scopeQuery';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import { useFetch } from '../../../utils/useFetch';
import { numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  const checkScope = getScope().measurementId;
  const getData = useFetch(`/measurements/applyhostsoftware/${checkScope}`, 'POST');
  const notify = useNotify();

  const applyHostSoftware = async () => {
    await getData().then((response) =>
      notify(
        `Host software applied, removed liability from ${response?.removedLiability} software lines`,
      ),
    );
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button label="Apply host software" onClick={applyHostSoftware} />
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List
      {...props}
      title="Host Software"
      actions={<ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions />} />}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="deviceName" />
          <AgGridColumn field="productName" />
          <AgGridColumn field="version" />
          <AgGridColumn field="edition" />
        </AgGridColumn>
        <AgGridColumn headerName="Hardware">
          <AgGridColumn field="cluster" />
          <AgGridColumn field="datacenter" />
          <AgGridColumn field="isDRSEnabled" columnGroupShow="open" />
          <AgGridColumn field="drsDefaultVMBehavior" columnGroupShow="open" />
          <AgGridColumn
            field="logicalProcessors"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="physicalCores"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="physicalProcessors"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="totalCores"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="virtualMachines"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn field="poweredOnVirtualMachines" columnGroupShow="open" />
          <AgGridColumn field="guestHasDatacenterEdition" columnGroupShow="open" />
          <AgGridColumn field="highestWindowsServerVersion" columnGroupShow="open" />
          <AgGridColumn field="cpuModel" columnGroupShow="open" />
          <AgGridColumn field="make" />
          <AgGridColumn field="model" />
        </AgGridColumn>
        <AgGridColumn headerName="Properties">
          <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
          <AgGridColumn {...useAgGridEnumCol('licensemodels', 'licenseModel')} />
          <AgGridColumn field="licenseQuantity" />
          <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
          <AgGridColumn field="isLicensable" columnGroupShow="open" />
          <AgGridColumn field="hasLiability" columnGroupShow="open" />
          <AgGridColumn field="isMaintenanceRequired" columnGroupShow="open" />
          <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
        </AgGridColumn>
        <AgGridColumn headerName="Annotanions">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
