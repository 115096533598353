import * as React from 'react';
import {
  Edit,
  Show,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  NumberField,
  BooleanField,
  Create,
  BooleanInput,
} from 'react-admin';

import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { Annotations } from '../../../components/Annotations';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import { DeviceInput } from '../../../components/CustomInputs';
import { sourceTab } from '../../../components/SourceTab';
import CrudWrapper from '../../../components/CrudWrapper';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import ActionBarShow from '../../../components/ActionBarShow';
import EditToolbar from '../../../components/EditToolbar';
import { AuditLog } from '../../../components/AuditLog';
const PageTitle = ({ record }) => {
  return <span>{record ? record.deviceName : ''}</span>;
};

const inputs = (
  <AccordionForm redirect="show" autoClose toolbar={<EditToolbar message={'virtual'} />}>
    <AccordionFormPanel label="Claims">
      <DeviceInput />
    </AccordionFormPanel>
    <AccordionFormPanel label="Virtual Layer">
      <BooleanInput halfWidth source="isHAEnabled" label="isHAEnabled" />
      <BooleanInput halfWidth source="isMobilityEnabled" label="isMobilityEnabled" />
      <TextInput halfWidth source="mobilityBehavior" label="mobilityBehavior" />
      <TextInput halfWidth source="vmState" label="vmState" />
      <TextInput halfWidth source="vmConfigurationStatus" label="vmConfigurationStatus" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Classification">
      <NewEnumInput halfWidth source="deviceRole" reference="deviceroles" />
      <NewEnumInput halfWidth source="deviceType" reference="devicetypes" />
      <NewEnumInput halfWidth source="deviceClass" reference="deviceclasses" />
      <NewEnumInput halfWidth source="deviceState" reference="devicestates" />
      <NewEnumInput halfWidth source="deviceStatus" reference="devicestatuses" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput halfWidth source="notes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput halfWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput halfWidth source="feedback" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const VirtualShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField source="deviceName" label="deviceName" />
          <TextField source="deviceTag" label="deviceTag" />
          <TextField source="manfacturer" />
          <TextField halfWidth source="fullyQualifiedDomainName" />
          <TextField source="domain" />
          <TextField source="version" />
        </Tab>
        <Tab label="Extended">
          <AccordionFormPanel label="Properties">
            <TextField source="serialNumber" label="serialNumber" />
            <TextField source="operatingSystem" label="operatingSystem" />
            <NumberField source="virtualProcessors" label="virtualProcessors" />
            <NumberField source="virtualCores" label="virtualCores" />
            <TextField halfWidth source="vmState" label="vmState" />
            <TextField halfWidth source="vmConfigurationStatus" label="vmConfigurationStatus" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Virtual Layer">
            <TextField source="host" label="host" />
            <TextField source="hostOS" label="hostOS" />
            <TextField source="hostOSVersion" label="hostOSVersion" />
            <TextField source="cluster" label="cluster" />
            <TextField source="datacenter" label="datacenter" />
            <BooleanField halfWidth source="isHAEnabled" label="isHAEnabled" />
            <BooleanField halfWidth source="isMobilityEnabled" label="isMobilityEnabled" />
            <TextField halfWidth source="mobilityBehavior" label="mobilityBehavior" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Classification">
            <NewEnumField halfWidth source="deviceRole" reference="deviceroles" />
            <NewEnumField halfWidth source="deviceType" reference="devicetypes" />
            <NewEnumField halfWidth source="deviceClass" reference="deviceclasses" />
            <NewEnumField halfWidth source="deviceState" reference="devicestates" />
            <NewEnumField halfWidth source="deviceStatus" reference="devicestatuses" />
          </AccordionFormPanel>
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        {sourceTab}
      </TabbedShowLayout>
    </Show>
  );
};

export const VirtualEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {inputs}
      </Edit>
    </CrudWrapper>
  );
};
export const VirtualCreate = (props) => {
  return (
    <CrudWrapper>
      <Create title={<PageTitle />} {...props}>
        {inputs}
      </Create>
    </CrudWrapper>
  );
};
