import PurchaseLineIcon from '@material-ui/icons/ShoppingBasket';
import { PurchaseLinesCreate, PurchaseLinesEdit, PurchaseLinesShow } from './PurcaseLinesForm';
import PurchaseLinesList from './PurchaseLinesList';

export default {
  list: PurchaseLinesList,
  icon: PurchaseLineIcon,
  show: PurchaseLinesShow,
  create: PurchaseLinesCreate,
  edit: PurchaseLinesEdit,
};
