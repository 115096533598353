import * as React from 'react';
import { Annotations } from '../../../components/Annotations';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  BooleanField,
  BooleanInput,
  SimpleForm,
  ReferenceField,
  FunctionField,
  NumberField,
  DateField,
} from 'react-admin';

import { AccordionFormPanel } from '@react-admin/ra-form-layout';
import { NewEnumField } from '../../../types/NewEnumField';
import { MarkdownInput } from '@react-admin/ra-markdown';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import { DeviceInput } from '../../../components/CustomInputs';
import { sourceTab } from '../../../components/SourceTab';
import { DeviceSoftwareInput } from '../../../components/SoftwareCustomField';
import { AuditLog } from '../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import ActionBarShow from '../../../components/ActionBarShow';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

const inputs = (
  <SimpleForm redirect="show" autoClose toolbar={<EditToolbar message={'windows server'} />}>
    <DeviceInput />
    {DeviceSoftwareInput}
    <TextInput source="applicationRoles" />
    <BooleanInput source="isExternalAccessEnabled" />
    <BooleanInput source="isCostAvoidanceCandidate" />
    <BooleanInput source="isVirtualMobilityEnabled" />
    <AccordionFormPanel label="Notes">
      <MarkdownInput fullWidth source="notes" Label="" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput fullWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput fullWidth source="feedback" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Link Documents">
      <FileUpload source="documents" />
    </AccordionFormPanel>
  </SimpleForm>
);

export const WindowsServerShow = (props) => {
  const [hasDocuments, setHasDocuments] = React.useState(false);
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="deviceId" reference="devices">
            <TextField source="deviceName" />
          </ReferenceField>
          <TextField source="productName" />
          <TextField source="edition" />
          <TextField source="version" />
          <TextField source="applicationRoles" />
          <BooleanField source="isExternalAccessEnabled" />
          <BooleanField source="isCostAvoidanceCandidate" />
          <BooleanField source="isVirtualMobilityEnabled" />
        </Tab>
        <Tab label="Extended">
          <NewEnumField source="deviceRole" label="deviceRole" reference="deviceroles" />
          <NewEnumField source="deviceType" label="deviceType" reference="devicetypes" />
          <NewEnumField source="deviceClass" label="deviceClass" reference="deviceclasses" />
          <NewEnumField source="deviceState" label="deviceState" reference="devicestates" />
          <NewEnumField source="deviceStatus" label="deviceStatus" reference="devicestatuses" />
          <DateField source="lastActivityDate" />
          <NumberField source="daysSinceLastActivity" />
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        <Tab label="Hardware">
          <TextField source="cpuModel" />
          <NumberField source="physicalCores" />
          <NumberField source="physicalProcessors" />
          <TextField source="make" />
          <TextField source="model" />
        </Tab>
        <Tab label="Virtual">
          <NumberField source="virtualProcessors" />
          <NumberField source="virtualCores" />
          <BooleanField source="virtualCPUType" />
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        {sourceTab}
        {hasDocuments && (
          <Tab label="Linked Documents">
            <SimpleForm toolbar={<EmptyToolbar />}>
              <FileUpload source="documents" show />
            </SimpleForm>
          </Tab>
        )}
        <FunctionField
          style={{ diplay: 'none' }}
          label=""
          render={(record) => setHasDocuments(record?.documents?.length > 0)}
        />
      </TabbedShowLayout>
    </Show>
  );
};

export const WindowsServerEdit = (props) => {
  return (
    <Edit undoable={false} title={<PageTitle />} {...props}>
      {inputs}
    </Edit>
  );
};

export const WindowsServerCreate = (props) => (
  <Create title="New" {...props}>
    {inputs}
  </Create>
);
