import HostSoftwareIcon from '@material-ui/icons/Save';
import { HostSoftwareCreate, HostSoftwareEdit, HostSoftwareShow } from './HostSoftwareForm';
import HostSoftwareList from './HostSoftwareList';

export default {
  list: HostSoftwareList,
  icon: HostSoftwareIcon,
  show: HostSoftwareShow,
  edit: HostSoftwareEdit,
  create: HostSoftwareCreate,
};
