import CommunicationServerIcon from '@material-ui/icons/CallMerge';
import {
  CommunicationServerCreate,
  CommunicationServerEdit,
  CommunicationServerShow,
} from './CommunicationServerForm';
import CommunicationServerList from './CommunicationServerList';

export default {
  list: CommunicationServerList,
  icon: CommunicationServerIcon,
  show: CommunicationServerShow,
  edit: CommunicationServerEdit,
  create: CommunicationServerCreate,
};
