import * as React from 'react';
import {
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  NumberInput,
  required,
  SelectInput,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
} from 'react-admin';
import { get } from 'lodash';
import './GenericRule/style.css';
import log from 'eslint-plugin-react/lib/util/log';

export const DynamicTypeInputField = ({ fieldConfig, label, source, isRequired, operator }) => {
  let validate = [];
  if (isRequired) {
    validate = [required()];
  }
  const dropdownValueMap = fieldConfig?.values;
  const dropdownChoices = dropdownValueMap
    ? Object.entries(dropdownValueMap).map(([id, name]) => ({
        id,
        name,
      }))
    : undefined;

  if (dropdownChoices) {
    return (
      <SelectInput resettable label={label ?? 'Value'} source={source} choices={dropdownChoices} />
    );
  } else {
    switch (fieldConfig?.type) {
      case 'BusinessUnit':
        return (
          <ReferenceInput source={source} reference="businessunits" label="Assign Business Unit">
            <AutocompleteInput resettable optionText={(record) => `${record?.businessUnitName}`} />
          </ReferenceInput>
        );
      case 'CreatedBy':
        return (
          <ReferenceInput source={source} reference="sources" label="Created by">
            <AutocompleteInput
              resettable
              optionText={(record) => `${record?.sourceFile} - ${record?.sourceType}`}
            />
          </ReferenceInput>
        );
      case 'UpdatedBy':
        return (
          <ReferenceInput source={source} reference="sources" label="Updated by">
            <AutocompleteInput
              resettable
              optionText={(record) => `${record?.sourceFile} - ${record?.sourceType}`}
            />
          </ReferenceInput>
        );
      case 'DateTime':
        return <DateInput source={source} validate={validate} label={label ?? 'Date'} />;
      case 'Int32':
      case 'Int64':
        return (
          <NumberInput
            parse={(n) => n.toString()} // The API expects all values to be sent as strings
            source={source}
            validate={validate}
            label={label ?? 'Value'}
          />
        );
      case 'Boolean':
        return (
          <SelectInput
            resettable
            source={source}
            validate={validate}
            label={label ?? 'Value'}
            optionText="name"
            optionValue="id"
            choices={[
              { id: 'true', name: 'True' },
              { id: 'false', name: 'False' },
            ]}
          />
        );
      default:
        return (
          operator !== 'IsEmpty' && (
            <TextInput
              source={source}
              validate={validate}
              label={label ?? 'Value'}
              multiline={operator === 'LikeAny' || operator === 'NotLikeAny'}
            />
          )
        );
    }
  }
};

export const WhereFields = (props) => {
  const [currentSourceName, setCurrentSourceName] = React.useState(props.scopedFormData?.source);
  const [currentFieldName, setCurrentFieldName] = React.useState(props.scopedFormData?.field);

  const currentSource = props.models?.[currentSourceName];
  const currentField = currentSource?.[currentFieldName];
  const currentOperators = currentField?.operators;

  return (
    <>
      {props.first ? null : (
        <div>
          <AutocompleteInput
            halfwidth
            label="Join operator"
            validate={[required()]}
            source={props.getSource('joinOperator')}
            choices={[
              { id: 'AND', name: 'AND' },
              { id: 'OR', name: 'OR' },
            ]}
          />
        </div>
      )}
      <div style={{ display: 'flex', gap: 15, flexWrap: 'wrap' }}>
        <AutocompleteInput
          halfwidth
          label="Source"
          validate={[required()]}
          source={props.getSource('source')}
          optionText="key"
          optionValue="key"
          choices={props.models ? Object.keys(props.models).map((key) => ({ key })) : undefined}
          onChange={(e) => setCurrentSourceName(e)}
        />
        <AutocompleteInput
          halfwidth
          label="Field"
          source={props.getSource('field')}
          optionText="key"
          validate={[required()]}
          optionValue="key"
          choices={currentSource ? Object.keys(currentSource).map((key) => ({ key })) : undefined}
          onChange={(e) => setCurrentFieldName(e)}
        />
        <AutocompleteInput
          halfwidth
          validate={[required()]}
          label="Operator"
          source={props.getSource('operator')}
          optionText="key"
          optionValue="key"
          choices={currentOperators?.map((key) => ({ key }))}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <DynamicTypeInputField
              fieldConfig={currentField}
              source={props.getSource('value')}
              operator={get(formData, props.getSource('operator'))}
            />
          )}
        </FormDataConsumer>
      </div>
    </>
  );
};

export const SetFields = ({ config, isSystem }) => {
  return (
    <div className=".set-form" style={{ width: '70%' }}>
      <p>Set</p>
      <div style={{ display: 'flex', gap: 15, flexWrap: 'wrap' }}>
        {Object.entries(config)
          .filter(([fieldName, fieldConfig]) => {
            if (!isSystem) return true;
            if (fieldConfig?.type === 'BusinessUnit') {
              return false;
            }
            return true;
          })
          .map(([fieldName, fieldConfig]) => {
            return (
              <>
                <DynamicTypeInputField
                  fieldConfig={fieldConfig}
                  label={fieldName}
                  optionText="value"
                  optionValue="key"
                  source={`set.${fieldName}`}
                />
              </>
            );
          })}
      </div>
    </div>
  );
};

export const ObservationDropdown = ({ observations }) => {
  return (
    <div className=".set-form" style={{ width: '70%' }}>
      <p>Assign Observation</p>
      <ReferenceArrayInput source="observations" reference="baseobservation">
        <AutocompleteArrayInput
          optionText={(record) => `${record?.observationTag} - ${record?.observation}`}
        />
      </ReferenceArrayInput>
    </div>
  );
};
