import * as React from 'react';
import DataGrid from '../../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';
import { getScope } from '../../../../utils/scopeQuery';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../../types/NewEnumCell';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  const { permissions } = usePermissions();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {(permissions === 'admin' || permissions === 'planner') && (
        <CreateButton basePath={basePath} />
      )}
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();
  const organizationId = getScope().organizationId;

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List
      {...props}
      title="Organizations"
      actions={<ListActions />}
      filter={{ id: organizationId }}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="organizationName" />
          <AgGridColumn field="addressLine1" />
          <AgGridColumn field="addressLine2" />
          <AgGridColumn field="postalCode" />
          <AgGridColumn field="city" />
          <AgGridColumn field="continent" />
          <AgGridColumn field="state" />
          <AgGridColumn field="country" />
          <AgGridColumn field="region" />
          <AgGridColumn field="corporateDomain" />
          <AgGridColumn field="currencyCode" />
          <AgGridColumn field="crm" />
          <AgGridColumn field="languageTag" />
        </AgGridColumn>
        <AgGridColumn headerName="Properties">
          <AgGridColumn {...useAgGridEnumCol('organizationscopes', 'organizationScope')} />
          <AgGridColumn {...useAgGridEnumCol('organizationtypes', 'organizationType')} />
          <AgGridColumn {...useAgGridEnumCol('organizationclasses', 'organizationClass')} />
          <AgGridColumn {...useAgGridEnumCol('organizationstatuses', 'organizationStatus')} />
          <AgGridColumn {...useAgGridEnumCol('organizationsizes', 'organizationSize')} />
          <AgGridColumn {...useAgGridEnumCol('organizationrevenues', 'organizationRevenue')} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
