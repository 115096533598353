import ITSMUsageIcon from '@material-ui/icons/SystemUpdate';
import { ITSMUsageCreate, ITSMUsageEdit, ITSMUsageShow } from './ITSMUsageForm';
import ITSMUsageList from './ITSMUsageList';

export default {
  list: ITSMUsageList,
  icon: ITSMUsageIcon,
  show: ITSMUsageShow,
  edit: ITSMUsageEdit,
  create: ITSMUsageCreate,
};
