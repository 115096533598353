import UserAccessIcon from '@material-ui/icons/VerifiedUserSharp';
import { UserAccessCreate, UserAccessEdit, UserAccessShow } from './UserAccessForm';
import UserAccessList from './UserAccessList';

export default {
  list: UserAccessList,
  icon: UserAccessIcon,
  show: UserAccessShow,
  edit: UserAccessEdit,
  create: UserAccessCreate,
};
