export default {
  TENNANT: 'common',
  CLIENT_ID: '391b535b-30c8-4fad-8b61-10e25ccb00e8',
  REDIRECT_URI: 'https://dev-samos.aboutdream.io/officelogin',
  FILE_TYPES: [
    { id: 0, type: 'ADExport' },
    { id: 1, type: 'RVTools' },
    { id: 2, type: 'HPDM' },
    { id: 3, type: 'DWDM' },
    { id: 4, type: 'VLSC' },
  ],
  IMPORT_TYPES: [
    'ActiveDirectory - XML',
    'Deployment-Hardware',
    'RVTools',
    'TC-HPDM',
    'TC-DWDM',
    'TC-IGEL',
    'CMDB-Ultimo',
    'CMDB-Topdesk',
    'Deployment-Software',
    'MAP-ActiveDeviceUsage',
    'MAP-ExchangeServerUsage',
    'MAP-WindowsServerUsage',
    'MAP-VMReport',
    'MAP-SqlServerUsage',
    'MAP-SqlServerDatabaseDetails',
    'MAP-SqlServerAssesment',
    'MAP-SystemCenterConfigManager',
    'MAP-SharepointUsage',
    'MAP-ServerAndCloudEnrollment',
    'MAP-LyncUsage',
    'MAP-RemoteDesktopLicensing',
    'MAP-ForefrontEndpointProtection',
    'PS-ExchangeEnterpriseFeatures',
    'MSOL',
    'UEM',
    'Tokens',
    'L-RelationshipSummary',
    'L-LicenseSummary',
    'MSDNSubscriptions',
    'Pricing',
    'ActiveDirectory - CSV',
  ],
  PARSING_STATUS: ['Ready for import', 'Processing', 'Imported', 'Error'],
  IMPORT_TYPES_SELECT: [
    { name: 'ActiveDirectory - CSV', id: 30 },
    { name: 'ActiveDirectory - XML', id: 0 },
    { name: 'Deployment-Hardware', id: 1 },
    { name: 'RVTools', id: 2 },
    { name: 'TC-HPDM', id: 3 },
    { name: 'TC-DWDM', id: 4 },
    { name: 'TC-IGEL', id: 5 },
    { name: 'CMDB-Ultimo', id: 6 },
    { name: 'CMDB-Topdesk', id: 7 },
    { name: 'Deployment-Software', id: 8 },
    { name: 'MAP-ActiveDeviceUsage', id: 9 },
    { name: 'MAP-ExchangeServerUsage', id: 10 },
    { name: 'MAP-WindowsServerUsage', id: 11 },
    { name: 'MAP-VMReport', id: 12 },
    { name: 'MAP-SqlServerUsage', id: 13 },
    { name: 'MAP-SqlServerDatabaseDetails', id: 14 },
    { name: 'MAP-SqlServerAssesment', id: 15 },
    { name: 'MAP-SystemCenterConfigManager', id: 16 },
    { name: 'MAP-SharepointUsage', id: 17 },
    { name: 'MAP-ServerAndCloudEnrollment', id: 18 },
    { name: 'MAP-LyncUsage', id: 19 },
    { name: 'MAP-RemoteDesktopLicensing', id: 20 },
    { name: 'MAP-ForefrontEndpointProtection', id: 21 },
    { name: 'PS-ExchangeEnterpriseFeatures', id: 22 },
    { name: 'MSOL', id: 23 },
    { name: 'UEM', id: 24 },
    { name: 'Tokens', id: 25 },
    { name: 'L-RelationshipSummary', id: 26 },
    { name: 'L-LicenseSummary', id: 27 },
    { name: 'MSDNSubscriptions', id: 28 },
    { name: 'Pricing', id: 29 },
  ],
};
