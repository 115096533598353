import DomainIcon from '@material-ui/icons/Domain';
import { DomainsCreate, DomainsEdit, DomainsShow } from './DomainsForm';
import DomainsList from './DomainsList';

export default {
  list: DomainsList,
  icon: DomainIcon,
  show: DomainsShow,
  create: DomainsCreate,
  edit: DomainsEdit,
};
