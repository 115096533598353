import * as React from 'react';
import { Toolbar, SaveButton, DeleteButton, useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const EditToolbar = (props) => {
  const record = useRecordContext();
  console.log(record);
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton
        confirmTitle={`Delete ${props?.message}`}
        confirmContent={'Are you sure you want to delete this item?'}
        {...props}
      />
    </Toolbar>
  );
};
export default EditToolbar;

export const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton redirect="show" />
      <SaveButton
        style={{ marginLeft: '1.6rem' }}
        label="Save and clone"
        redirect="create"
        submitOnEnter={false}
      />
    </Toolbar>
  );
};
