import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { SelectedSoftwareDialog, SoftwareDialog } from '../../../components/SoftwareDialog';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  useRefresh,
  useNotify,
  sanitizeListRestProps,
} from 'react-admin';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import { useFetchData } from '../../../utils/useFetch';
import { numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <SoftwareDialog resource="usersoftwaremodal" urlResource={props.resource} />
      <SelectedSoftwareDialog resource={props.resource} callBulkApply={props.callBulkApply} />

      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();
  const [gridApi, setGridApi] = React.useState(null);
  const refresh = useRefresh();
  const notify = useNotify();
  const fetchApplyLicenses = useFetchData(`/${props.resource}/bulkapplylicenses`, 'POST', true);

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  const callBulkApply = async (licenseMetric, productId) => {
    const data = {
      licenseMetric: licenseMetric,
      productId: productId,
      softwareIds: gridApi.getSelectedRows().map((row) => row.id),
    };
    await fetchApplyLicenses(data);
    refresh();
    notify(`Bulk apply started`);
  };

  return (
    <List
      {...props}
      title="User Software"
      actions={
        <ComplexActionBarShow
          id={props.id}
          {...props}
          oldToolbar={<ListActions callBulkApply={callBulkApply} resource={props.resource} />}
        />
      }
    >
      <DataGrid {...props} enableSelection setGridApi={setGridApi} rowDoubleClicked={onRowClicked}>
        <AgGridColumn
          field=""
          checkboxSelection
          headerCheckboxSelection
          maxWidth={35}
          suppressMenu
          sortable={false}
          lockPosition
          resizable={false}
          checkbox
          pined
        />
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="userName" />
          <AgGridColumn field="userTag" />
          <AgGridColumn field="normalizedName" />
          <AgGridColumn field="softwareTag" />
          <AgGridColumn field="domain" />
        </AgGridColumn>

        <AgGridColumn headerName="Validations">
          <AgGridColumn field="hasLiability" />
          <AgGridColumn field="isMaintenanceRequired" />
          <AgGridColumn field="isCoveredByHostInstall" columnGroupShow="open" />
          <AgGridColumn field="isCostSavingsCandidate" columnGroupShow="open" />
          <AgGridColumn field="isCostAvoidanceCandidate" columnGroupShow="open" />
          <AgGridColumn field="isManual" columnGroupShow="open" />
          <AgGridColumn field="isArchived" columnGroupShow="open" />
          <AgGridColumn field="fingerprint_IsIgnorable" columnGroupShow="open" />
          <AgGridColumn field="fingerprint_isReconciled" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Classifications">
          <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
          <AgGridColumn {...useAgGridEnumCol('userscopes', 'userScope')} />
          <AgGridColumn {...useAgGridEnumCol('userroles', 'userRole')} />
          <AgGridColumn {...useAgGridEnumCol('usertypes', 'userType')} columnGroupShow="open" />
          <AgGridColumn {...useAgGridEnumCol('userclasses', 'userClass')} columnGroupShow="open" />
          <AgGridColumn {...useAgGridEnumCol('userstates', 'userState')} columnGroupShow="open" />
          <AgGridColumn
            {...useAgGridEnumCol('userstatuses', 'userStatus')}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Install product">
          <AgGridColumn field="manufacturer" />
          <AgGridColumn field="installProductName" />
          <AgGridColumn field="installProductPool" columnGroupShow="open" />
          <AgGridColumn field="installProductCategory" columnGroupShow="open" />
          <AgGridColumn field="installProductGroup" columnGroupShow="open" />
          <AgGridColumn field="installProductFamily" columnGroupShow="open" />
          <AgGridColumn field="installVersion" />
          <AgGridColumn field="installEdition" />
          <AgGridColumn
            field="installQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
        </AgGridColumn>
        <AgGridColumn headerName="Licensed product">
          <AgGridColumn field="licenseProductName" />
          <AgGridColumn field="licenseProductPool" columnGroupShow="open" />
          <AgGridColumn field="licenseProductCategory" columnGroupShow="open" />
          <AgGridColumn field="licenseProductGroup" columnGroupShow="open" />
          <AgGridColumn field="licenseProductFamily" columnGroupShow="open" />
          <AgGridColumn field="licenseVersion" />
          <AgGridColumn field="licenseEdition" />
          <AgGridColumn
            field="licenseQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'installMetric')} />
          <AgGridColumn {...useAgGridEnumCol('licensemodels', 'installModel')} />
        </AgGridColumn>
        <AgGridColumn headerName="Organization">
          <AgGridColumn field="businessUnitName" />
          <AgGridColumn field="city" columnGroupShow="open" />
          <AgGridColumn field="country" columnGroupShow="open" />
          <AgGridColumn field="region" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Annotation">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
