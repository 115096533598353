import React from 'react';
import { useState } from 'react';
import {
  useListContext,
  Loading,
  useTranslate,
  useLocale,
  Button,
  useRefresh,
  useNotify,
  usePermissions,
} from 'react-admin';
import DoneIcon from '@material-ui/icons/Done';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import 'ag-grid-enterprise';
import AG_GRID_LOCALE_NL from './locale.nl';
import { usePreferences } from '@react-admin/ra-preferences';
import dataGridProvider from '../providers/dataGridProvider';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import * as $ from 'jquery';
import { useIsLocked } from './ActionBarShow';
import { useFetch } from '../utils/useFetch';

const AGGridBody = (props) => {
  const translate = useTranslate();
  const locale = useLocale();
  const { data, loading } = useListContext();
  const [isRuleApplying, setIsRuleApplying] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const [gridApi, setGridApi] = useState(null);
  const [theme] = usePreferences('theme');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const dataGridTheme = theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham';
  const translations = locale === 'nl' ? AG_GRID_LOCALE_NL : null;
  const locked = useIsLocked();
  const fetchActivation = useFetch(
    `/measurements/apply${props.resource}/${props.measurementId}`,
    'POST',
  );
  const fetchFingerprintActivation = useFetch(`/fingerprints/applyrules`, 'POST');

  if (loading) return <Loading />;
  if (!data) return <div />;

  let rowData = Object.keys(data).map((key) => data[key]);

  if (!rowData.length) return <div style={{ padding: '15px 20px' }}>no records found</div>;

  const onRowClicked = (table) => {
    if (!table?.data?.id) return;
    if (!props.onRowClicked) return;
    props.onRowClicked(table.data.id);
  };

  let keys = [];
  if (props.columnDefs) {
    keys = props.columnDefs;
  } else {
    keys = Object.keys(rowData[0]).map((key) => {
      return {
        field: key,
        headerName: key,
      };
    });
  }

  const onRowDragEnd = async (event) => {
    let arrayNew = [];
    gridApi.forEachNode(function (node) {
      arrayNew.push(node.data);
    });
    await dataGridProvider.setReorder(arrayNew);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.columnApi.autoSizeAllColumns();
  };

  const columnDefs = keys.map((item) => {
    if (item.children && item.children.length) {
      item.children = item.children.map((child) => {
        return {
          ...child,
          headerName: translate(child.field),
          headerTooltip: translate(child.field),
        };
      });
    }
    return {
      ...item,
      hide: item.field === 'id' ? true : item.hide,
      headerName: item.headerName ? translate(item.headerName) : translate(item.field),
      localeText: { applyFilter: 'OK' },
    };
  });

  const onRuleActivate = async (resource) => {
    setIsRuleApplying(true);
    if (resource === 'globalfingerprintrules') {
      await fetchFingerprintActivation();
    } else {
      await fetchActivation();
    }
    setIsRuleApplying(false);
    notify('Rules applied!');
    refresh();
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      $('.list-page .MuiPaper-root').addClass('grid-fullscreen');
      $('body').addClass('overflow-hidden');
      setIsFullscreen(true);
    } else {
      $('.list-page .MuiPaper-root').removeClass('grid-fullscreen');
      $('body').removeClass('overflow-hidden');
      setIsFullscreen(false);
    }
  };

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === 'Escape' && isFullscreen) {
          toggleFullscreen();
        }
      }}
      style={{ boxShadow: 'none' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: '#FAFAFA',
          border: 'none',
        }}
      >
        <>
          {!locked &&
          !props.isSystem &&
          props.measurementId &&
          (permissions === 'admin' || permissions === 'analyst') ? (
            <Button
              onClick={() => onRuleActivate(props.resource)}
              label={isRuleApplying ? 'Applying...' : 'Apply rules'}
              disabled={isRuleApplying}
            >
              <DoneIcon />
            </Button>
          ) : null}
          {!locked &&
          props.resource === 'globalfingerprintrules' &&
          (permissions === 'admin' || permissions === 'analyst') ? (
            <Button
              onClick={() => onRuleActivate(props.resource)}
              label={isRuleApplying ? 'Applying...' : 'Apply rules'}
              disabled={isRuleApplying}
            >
              <DoneIcon />
            </Button>
          ) : null}
        </>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <button onClick={() => toggleFullscreen()} class="btn-fullscreen">
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </button>
        </div>
      </div>
      <div className={dataGridTheme} style={{ height: isFullscreen ? '92vh' : '71vh' }}>
        <AgGridReact
          defaultColDef={{
            minWidth: 50,
            width: 150,
            editable: true,
            resizable: true,
            enableRowGroup: true,
            floatingFilter: false,
            headerCheckboxSelectionFilteredOnly: true,
            filter: 'agMultiColumnFilter',
          }}
          autoGroupColumnDef={{ minWidth: 200 }}
          groupUseEntireRow={true}
          suppressDragLeaveHidesColumns={true}
          suppressMakeColumnVisibleAfterUnGroup={true}
          rowGroupPanelShow={'always'}
          rowDragManaged={true}
          animateRows={true}
          rowSelection={'single'}
          onRowDoubleClicked={onRowClicked}
          columnDefs={columnDefs}
          rowData={rowData}
          onRowDragEnd={onRowDragEnd}
          suppressRowDrag={false}
          components={{
            rowNodeIdRenderer: function (params) {
              return params.node.id + 1;
            },
          }}
          enableRangeSelection={true}
          localeText={translations}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
};

export default AGGridBody;
