
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

export const NewEnumField = ({ reference, source }) => {
  return (
    <ReferenceField reference={reference} source={source} link={false}>
      <TextField source="description" />
    </ReferenceField>
  );
};

NewEnumField.defaultProps = {
  addLabel: true,
  };
