import React, { useEffect, forwardRef } from 'react';
import {
  PreferencesBasedThemeProvider,
  LanguageSwitcher,
  ToggleThemeButton,
  SidebarOpenPreferenceSync,
} from '@react-admin/ra-preferences';

import {
  AppLocationContext,
  Breadcrumb,
  BreadcrumbItem,
  ResourceBreadcrumbItems,
  theme,
} from '@react-admin/ra-navigation';

import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import ProfileProvider from './providers/APIProviders/ProfileProvider';
import MyLoginPage from './providers/MyLoginPage';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import dutchMessages from './i18n/nl';
import englishMessages from './i18n/en';
import Menu from './layout/Menu';

import {
  Admin,
  Layout,
  Resource,
  AppBar,
  UserMenu,
  MenuItemLink,
  useSetLocale,
  linkToRecord,
} from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

// ADMINISTRATION
import PlatformUsers from './containers/Administration/PlatformUsers';

// ORGANIZATIONS
import Organizations from './containers/Organizations/OrganizationSet/Organization';
import BusinessUnits from './containers/Organizations/BusinessUnit';
import Users from './containers/Organizations/User';
import Devices from './containers/Organizations/Device';
import Domains from './containers/Organizations/Domains';
import PricingOptions from './containers/Organizations/PricingOptions';
import Agreements from './containers/Organizations/Agreements/Agreement';
import AgreementLines from './containers/Organizations/Agreements/AgreementLine';
import Purchases from './containers/Organizations/Purchases';
import PurchaseLines from './containers/Organizations/PurchaseLines';

// MEASUREMENTS
import Projects from './containers/Measurements/ProjectSet/Project';
import Measurements from './containers/Measurements/Measurement';
import ADGroups from './containers/Measurements/ADGroup';
import Sources from './containers/Measurements/Source';
import OrganizationalUnits from './containers/Measurements/OrganizationalUnit';

// DEPLOYMENTS
import Hardware from './containers/Deployments/Hardware';
import Virtual from './containers/Deployments/Virtual';
import UserSoftware from './containers/Deployments/UserSoftware';
import DeviceSoftware from './containers/Deployments/DeviceSoftware';

// COMPLIANCY
import Entitlements from './containers/Compliancy/Entitlement';
import LicensePositions from './containers/Compliancy/LicensePosition';
import CompliancePositions from './containers/Compliancy/CompliancePosition';

// OBSERVATIONS
import Observations from './containers/Observations/Observation';
import MeasurementObservation from './containers/Observations/MeasurementObservation';

// RULES
import GenericRule from './containers/Rules/GenericRule';

// REFERENCES
import Products from './containers/References/Product';
import ProductComponents from './containers/References/ProductComponent';
import ProductPaths from './containers/References/ProductPath';
import Fingerprints from './containers/References/Fingerprint';
import Manufacturers from './containers/References/Manufacturer';
import Suppliers from './containers/References/Supplier';
import PriceList from './containers/References/PriceList';

// SERVER APPLICATIONS
import MailServers from './containers/ServerApps/MailServers';

// USER APPLICATIONS
import MailUsage from './containers/UserApps/MailUsage';

// LOOKUP ENTITIES
import OrganizationClasses from './containers/Organizations/OrganizationSet/Lookup/OrganizationClass';
import OrganizationScopes from './containers/Organizations/OrganizationSet/Lookup/OrganizationScope';
import OrganizationTypes from './containers/Organizations/OrganizationSet/Lookup/OrganizationType';
import OrganizationStatuses from './containers/Organizations/OrganizationSet/Lookup/OrganizationStatus';
import OrganizationSizes from './containers/Organizations/OrganizationSet/Lookup/OrganizationSize';
import OrganizationRevenues from './containers/Organizations/OrganizationSet/Lookup/OrganizationRevenue';
import ProjectScopes from './containers/Measurements/ProjectSet/Lookup/ProjectScope';
import ProjectTypes from './containers/Measurements/ProjectSet/Lookup/ProjectType';
import ProjectClasses from './containers/Measurements/ProjectSet/Lookup/ProjectClass';
import ProjectStatuses from './containers/Measurements/ProjectSet/Lookup/ProjectStatus';
import AgreementClasses from './containers/Organizations/Agreements/Lookup/AgreementClass';
import LicenseMetrics from './containers/References/LicenseMetric';
import routes from './routes';
import { createBrowserHistory as createHistory } from 'history';
import { preserveQuery } from './utils/scopeQuery';
import ScopeDialog from './components/ScopeDialog';
import MyHomepage from './components/HomePage';
import HostSoftware from './containers/Deployments/HostSoftware';
import WindowsServer from './containers/ServerApps/WindowsServer';
import DatabaseServer from './containers/ServerApps/DatabaseServer';
import ITSMServer from './containers/ServerApps/ITSMServer';
import UserAccess from './containers/UserApps/UserAccess';
import DeviceAccess from './containers/UserApps/DeviceAccess';
import UEMSession from './containers/UserApps/UEMSession';
import ITSMUsage from './containers/UserApps/ITSMUsage';
import EPPUsage from './containers/UserApps/EPPUsage';
import CollaborationUsage from './containers/UserApps/CollaborationUsage';
import CommunicationUsage from './containers/UserApps/CommunicationUsage';
import MSDNUsage from './containers/UserApps/MSDNUsage';
import CommunicationServer from './containers/ServerApps/CommunicationServer';
import CollaborationServer from './containers/ServerApps/CollaborationServer';
import MessagebrokerServer from './containers/ServerApps/MessagebrokerServer';
import Articles from './containers/References/Articles';
import { useFetch } from './utils/useFetch';
import SmartSassClients from './containers/Administration/SmartSassClients';
import { makeStyles } from '@material-ui/core/styles';

const history = preserveQuery(createHistory(), ['organizationId', 'projectId', 'measurementId']);
const useStagingStyles = makeStyles({
  appBar: {
    background:
      'repeating-linear-gradient(45deg, #B8B800, #B8B800 15px, #000000 15px, #000000 30px)',
  },
});

const MyAppBar = (props) => {
  const stagingClasses = useStagingStyles();
  return (
    <AppBar
      {...props}
      userMenu={<MyUserMenu />}
      className={process.env.REACT_APP_ENVIRONMENT === 'staging' ? stagingClasses.appBar : null}
    >
      <ScopeDialog />
      <LanguageSwitcher
        languages={[
          { locale: 'en_US', name: 'English' },
          { locale: 'nl_NL', name: 'Nederlands' },
        ]}
      />
      <ToggleThemeButton />
    </AppBar>
  );
};

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
  <>
    <MenuItemLink
      ref={ref}
      to="/my-profile"
      primaryText="Your Profile"
      leftIcon={<SettingsIcon />}
      onClick={onClick}
    />
  </>
));

const MyUserMenu = (props) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
);

const MyLayout = ({ children, ...props }) => {
  const setLocale = useSetLocale();
  const fetchLanguage = useFetch(`/platformusers/currentlanguage`, 'GET');

  useEffect(() => {
    const getActiveLanguage = async () => {
      const response = await fetchLanguage();
      if (response && response.language) {
        setLocale(response.language);
      }
    };
    getActiveLanguage();
  }, []);

  const routes = [
    { name: 'projects', label: 'Projects', to: '/projects', title: 'projectName' },
    { name: 'measurements', label: 'Measurements', to: '/measurements', title: 'measurementName' },
    { name: 'adgroups', label: 'AD groups', to: '/adgroups', title: 'adGroupName' },
    {
      name: 'organizationalunits',
      label: 'Organizational Units',
      to: '/organizationalunits',
      title: 'distinguishedName',
    },
    { name: 'sources', label: 'Sources', to: '/sources', title: 'sourceTag' },

    {
      name: 'organizations',
      label: 'Organizations',
      to: '/organizations',
      title: 'organizationName',
    },
    {
      name: 'businessunits',
      label: 'Business units',
      to: '/businessunits',
      title: 'businessUnitName',
    },
    {
      name: 'agreementlines',
      label: 'Agreements',
      refLabel: 'agreementNumber',
      refTo: 'agreementId',
      to: '/agreements',
      title: 'sku',
    },
    { name: 'agreements', label: 'Agreements', to: '/agreements', title: 'agreementNumber' },
    { name: 'users', label: 'Users', to: '/users', title: 'userName' },
    { name: 'devices', label: 'Devices', to: '/devices', title: 'deviceName' },
    { name: 'domains', label: 'Domains', to: '/domains', title: 'domainName' },

    { name: 'entitlements', label: 'Entitlements', to: '/entitlements', title: 'entitlementName' },
    {
      name: 'licensepositions',
      label: 'License positions',
      to: '/licensepositions',
      title: 'licensepositionName',
    },
    {
      name: 'compliancepositions',
      label: 'Compliance positions',
      to: '/compliancepositions',
      title: 'compliancepositionName',
    },

    { name: 'hardware', label: 'Hardware', to: '/hardware', title: 'deviceName' },
    { name: 'virtual', label: 'Virtual', to: '/virtual', title: 'deviceName' },
    { name: 'usersoftware', label: 'User software', to: '/usersoftware', title: 'deviceName' },
    {
      name: 'devicesoftware',
      label: 'Device software',
      to: '/devicesoftware',
      title: 'deviceName',
    },
    { name: 'hostsoftware', label: 'Host software', to: '/hostsoftware', title: 'deviceName' },

    { name: 'mailservers', label: 'Mail servers', to: '/mailservers', title: 'applicationRoles' },
    {
      name: 'windowsservers',
      label: 'Windows servers',
      to: '/windowsservers',
      title: 'applicationRoles',
    },
    {
      name: 'communicationservers',
      label: 'Communication servers',
      to: '/communicationservers',
      title: 'applicationRoles',
    },
    {
      name: 'collaborationservers',
      label: 'Collaboration servers',
      to: '/collaborationservers',
      title: 'applicationRoles',
    },
    {
      name: 'messagebrokerservers',
      label: 'Messagebroker servers',
      to: '/messagebrokerservers',
      title: 'applicationRoles',
    },
    {
      name: 'databaseservers',
      label: 'Database servers',
      to: '/databaseservers',
      title: 'applicationRoles',
    },
    { name: 'itsmservers', label: 'ITSM servers', to: '/itsmservers', title: 'applicationRoles' },

    { name: 'mailusage', label: 'Mail usage', to: '/mailusage', title: 'userName' },
    { name: 'useraccess', label: 'User access', to: '/useraccess', title: 'userId' }, // ToDo: TITLE
    { name: 'deviceaccess', label: 'Device access', to: '/deviceaccess', title: 'deviceId' }, // ToDo: TITLE
    { name: 'uemsession', label: 'UEM session', to: '/uemsession', title: 'deviceAccessId' }, // ToDo: TITLE
    { name: 'itsmusage', label: 'ITSM usage', to: '/itsmusage', title: '' }, // ToDo: TITLE
    { name: 'eppusage', label: 'EPP usage', to: '/eppusage', title: '' }, // ToDo: TITLE
    { name: 'msdnusage', label: 'MSDN usage', to: '/msdnusage', title: '' }, // ToDo: TITLE
    {
      name: 'communicationusage',
      label: 'Communication usage',
      to: '/communicationusage',
      title: '',
    }, // ToDo: TITLE
    {
      name: 'collaborationusage',
      label: 'Collaboration usage',
      to: '/collaborationusage',
      title: '',
    }, // ToDo: TITLE

    {
      name: 'measurementobservations',
      label: 'Observations',
      to: '/measurementobservations',
      title: '',
    }, // ToDo: TITLE

    {
      name: 'globaluserclassificationrules',
      label: 'User classification rules',
      to: '/globaluserclassificationrules',
      title: 'ruleTag',
    },
    {
      name: 'globaluserqualificationrules',
      label: 'User qualification rules',
      to: '/globaluserqualificationrules',
      title: 'ruleTag',
    },
    {
      name: 'globaldeviceclassificationrules',
      label: 'Device classification rules',
      to: '/globaldeviceclassificationrules',
      title: 'ruleTag',
    },
    {
      name: 'globaldevicequalificationrules',
      label: 'Device qualification rules',
      to: '/globaldevicequalificationrules',
      title: 'ruleTag',
    },
    {
      name: 'globalsoftwarerules',
      label: 'Software rules',
      to: '/globalsoftwarerules',
      title: 'ruleTag',
    },
    {
      name: 'globalfingerprintrules',
      label: 'Fingerprint rules',
      to: '/globalfingerprintrules',
      title: 'ruleTag',
    },

    {
      name: 'userclassificationrules',
      label: 'User classification rules',
      to: '/userclassificationrules',
      title: 'ruleTag',
    },
    {
      name: 'userqualificationrules',
      label: 'User qualification rules',
      to: '/userqualificationrules',
      title: 'ruleTag',
    },
    {
      name: 'deviceclassificationrules',
      label: 'Device classification rules',
      to: '/deviceclassificationrules',
      title: 'ruleTag',
    },
    {
      name: 'devicequalificationrules',
      label: 'Device qualification rules',
      to: '/devicequalificationrules',
      title: 'ruleTag',
    },
    {
      name: 'softwarerules',
      label: 'Software rules',
      to: '/softwarerules',
      title: 'ruleTag',
    },

    { name: 'products', label: 'Products', to: '/products', title: 'composedProductName' },
    {
      name: 'productcomponents',
      label: 'Product components',
      to: '/productcomponents',
      title: 'composedProductName',
    }, // ToDo: TITLE
    {
      name: 'productpaths',
      label: 'Product paths',
      to: '/productpaths',
      title: 'composedProductNameFrom',
    },
    { name: 'fingerprints', label: 'Fingerprints', to: '/fingerprints', title: 'rawProductName' },
    {
      name: 'manufacturers',
      label: 'Manufacturers',
      to: '/manufacturers',
      title: 'manufacturerKey',
    },
    { name: 'suppliers', label: 'Suppliers', to: '/suppliers', title: 'legalEntity' },

    { name: 'platformUsers', label: 'Platform Users', to: '/platformUsers', title: 'email' },
    {
      name: 'baseobservation',
      label: 'Observation',
      to: '/baseobservation',
      title: 'observationTag',
    },
    {
      name: 'articles',
      label: 'Articles',
      to: '/articles',
      title: 'articles',
    },
    {
      name: 'pricelists',
      label: 'Pricelists',
      to: '/pricelists',
      title: 'productName',
    },
    {
      name: 'purchaselines',
      label: 'Purchase lines',
      to: '/purchaselines',
      title: 'productName',
    },
    {
      name: 'purchases',
      label: 'Purchases',
      to: '/purchases',
      title: 'purchaseNumber',
    },
    {
      name: 'pricingoptions',
      label: 'Pricing options',
      to: '/pricingoptions',
      title: 'pricingOptions',
    },
    {
      name: 'smartsassclients',
      label: 'SmartSass Clients',
      to: '/smartsassclients',
      title: 'smartsassclients',
    },
  ];

  return (
    <AppLocationContext hasDashboard={!!props.dashboard}>
      <Layout {...props} appBar={MyAppBar} menu={Menu}>
        {/* <div style={{ position: 'sticky', top: '1rem' }}> */}
        <Breadcrumb {...props} dashboard={false}>
          <ResourceBreadcrumbItems resources={['otherResources']} />
          {routes.map((element) => {
            return element.refTo ? (
              <BreadcrumbItem name={element.name} label={element.label} to={element.to}>
                <BreadcrumbItem
                  name="list"
                  label={({ record }) => record && record[element.refLabel]}
                />
                <BreadcrumbItem
                  name="edit"
                  label={({ record }) => record && record[element.title]}
                  to={({ record }) => record && `${linkToRecord(element.to, record.id)}/edit`}
                />
                <BreadcrumbItem
                  name="show"
                  label={({ record }) => record && record[element.title]}
                  to={({ record }) => record && `${linkToRecord(element.to, record.id)}/show`}
                />
                <BreadcrumbItem name="create" label="Create New" />
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem name={element.name} label={element.label} to={element.to}>
                <BreadcrumbItem
                  name="edit"
                  label={({ record }) => record && record[element.title]}
                  to={({ record }) => record && `${linkToRecord(element.to, record.id)}/edit`}
                />
                <BreadcrumbItem
                  name="show"
                  label={({ record }) => record && record[element.title]}
                  to={({ record }) => record && `${linkToRecord(element.to, record.id)}/show`}
                />
                <BreadcrumbItem name="create" label="Create New" />
              </BreadcrumbItem>
            );
          })}
        </Breadcrumb>
        {children}
        {/* </div> */}
      </Layout>
      <SidebarOpenPreferenceSync />
    </AppLocationContext>
  );
};

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'en' || locale === 'en_US') {
    ProfileProvider.setActiveLanguage({ language: 'en_US' });
    return englishMessages;
  }
  if (locale === 'nl' || locale === 'nl_NL') {
    ProfileProvider.setActiveLanguage({ language: 'nl_NL' });
    return dutchMessages;
  }
}, 'en');

const App = () => {
  return (
    <PreferencesBasedThemeProvider>
      <Admin
        history={history}
        dataProvider={dataProvider}
        authProvider={authProvider}
        title="SAMOS"
        dashboard={MyHomepage}
        i18nProvider={i18nProvider}
        loginPage={MyLoginPage}
        layout={MyLayout}
        /* Apply the theme provided by ra-navigation */
        theme={theme}
        customRoutes={routes}
      >
        <Resource name="organizations" {...Organizations} />
        <Resource name="organizationalunits" {...OrganizationalUnits} />
        <Resource name="adgroups" {...ADGroups} />
        <Resource name="businessunits" {...BusinessUnits} />
        <Resource name="projects" {...Projects} />
        <Resource name="measurements" {...Measurements} />
        <Resource name="sources" {...Sources} />
        <Resource name="hostsoftware" {...HostSoftware} />
        <Resource name="windowsservers" {...WindowsServer} />
        <Resource name="communicationservers" {...CommunicationServer} />
        <Resource name="collaborationservers" {...CollaborationServer} />
        <Resource name="messagebrokerservers" {...MessagebrokerServer} />
        <Resource name="databaseservers" {...DatabaseServer} />
        <Resource name="itsmservers" {...ITSMServer} />
        <Resource name="useraccess" {...UserAccess} />
        <Resource name="deviceaccess" {...DeviceAccess} />
        <Resource name="uemsession" {...UEMSession} />
        <Resource name="itsmusage" {...ITSMUsage} />
        <Resource name="eppusage" {...EPPUsage} />
        <Resource name="collaborationusage" {...CollaborationUsage} />
        <Resource name="communicationusage" {...CommunicationUsage} />
        <Resource name="msdnusage" {...MSDNUsage} />

        <Resource name="document" />
        <Resource
          name="userqualificationrules"
          {...GenericRule}
          options={{
            isSystem: false,
            ruleConfigurationKey: 'UserQualification',
            title: 'User Qualification Rules',
          }}
        />
        <Resource
          name="globaluserqualificationrules"
          {...GenericRule}
          options={{
            isSystem: true,
            ruleConfigurationKey: 'UserQualification',
            title: 'Global User Qualification Rules',
          }}
        />
        <Resource
          name="userclassificationrules"
          {...GenericRule}
          options={{
            isSystem: false,
            ruleConfigurationKey: 'UserClassification',
            title: 'User Classification Rules',
          }}
        />
        <Resource
          name="globaluserclassificationrules"
          {...GenericRule}
          options={{
            isSystem: true,
            ruleConfigurationKey: 'UserClassification',
            title: 'Global User Classification Rules',
          }}
        />
        <Resource
          name="softwarerules"
          {...GenericRule}
          options={{ isSystem: false, ruleConfigurationKey: 'Software', title: 'Software Rules' }}
        />
        <Resource
          name="globalsoftwarerules"
          {...GenericRule}
          options={{
            isSystem: true,
            ruleConfigurationKey: 'Software',
            title: 'Global Software Rules',
          }}
        />
        <Resource
          name="globalfingerprintrules"
          {...GenericRule}
          options={{
            isSystem: true,
            ruleConfigurationKey: 'FingerprintClassification',
            title: 'Global Fingerprint Rules',
          }}
        />
        <Resource
          name="deviceclassificationrules"
          {...GenericRule}
          options={{
            isSystem: false,
            ruleConfigurationKey: 'DeviceClassification',
            title: 'Device Classification Rules',
          }}
        />
        <Resource
          name="globaldeviceclassificationrules"
          {...GenericRule}
          options={{
            isSystem: true,
            ruleConfigurationKey: 'DeviceClassification',
            title: 'Global Device Classification Rules',
          }}
        />
        <Resource
          name="devicequalificationrules"
          {...GenericRule}
          options={{
            isSystem: false,
            ruleConfigurationKey: 'DeviceQualification',
            title: 'Device Qualification Rules',
          }}
        />
        <Resource
          name="globaldevicequalificationrules"
          {...GenericRule}
          options={{
            isSystem: true,
            ruleConfigurationKey: 'DeviceQualification',
            title: 'Global Device Qualification Rules',
          }}
        />
        <Resource name="domains" {...Domains} />
        <Resource name="pricingoptions" {...PricingOptions} />
        <Resource name="purchases" {...Purchases} />
        <Resource name="purchaselines" {...PurchaseLines} />
        <Resource name="users" {...Users} />
        <Resource name="products/productgroups" />
        <Resource name="productcategories" />
        <Resource name="productgroups" />
        <Resource name="producteditions" />
        <Resource name="devices" {...Devices} />
        <Resource name="hardware" {...Hardware} />
        <Resource name="virtual" {...Virtual} />
        <Resource name="usersoftware" {...UserSoftware} />
        <Resource name="devicesoftware" {...DeviceSoftware} />
        <Resource name="products" {...Products} />
        <Resource name="productcomponents" {...ProductComponents} />
        <Resource name="productpaths" {...ProductPaths} />
        <Resource name="fingerprints" {...Fingerprints} />
        <Resource name="manufacturers" {...Manufacturers} />
        <Resource name="pricelists" {...PriceList} />
        <Resource name="articles" {...Articles} />
        <Resource name="suppliers" {...Suppliers} />
        <Resource name="agreements" {...Agreements} />
        <Resource name="agreementlines" {...AgreementLines} />
        <Resource name="mailservers" {...MailServers} />
        <Resource name="mailusage" {...MailUsage} />

        <Resource name="entitlements" {...Entitlements} />
        <Resource name="licensepositions" {...LicensePositions} />
        <Resource name="compliancepositions" {...CompliancePositions} />

        <Resource name="baseobservation" {...Observations} />
        <Resource name="measurementobservations" {...MeasurementObservation} />

        <Resource name="organizationscopes" {...OrganizationScopes} />
        <Resource name="organizationtypes" {...OrganizationTypes} />
        <Resource name="organizationclasses" {...OrganizationClasses} />
        <Resource name="organizationstatuses" {...OrganizationStatuses} />
        <Resource name="organizationsizes" {...OrganizationSizes} />
        <Resource name="organizationrevenues" {...OrganizationRevenues} />

        <Resource name="agreementclasses" {...AgreementClasses} />

        <Resource name="projectscopes" {...ProjectScopes} />
        <Resource name="projecttypes" {...ProjectTypes} />
        <Resource name="projectclasses" {...ProjectClasses} />
        <Resource name="projectstatuses" {...ProjectStatuses} />
        <Resource name="licensemetrics" {...LicenseMetrics} />
        <Resource name="platformusers" {...PlatformUsers} />
        <Resource name="smartsaasclients" {...SmartSassClients} />

        {/* *******    ENUM RESOURCES     ******* */}

        <Resource name="licensemetrics" />
        <Resource name="agreementtypes" />
        <Resource name="agreementscopes" />
        <Resource name="agreementstatuses" />
        <Resource name="agreementclasses" />
        <Resource name="installtypes" />
        <Resource name="licensemetrics" />
        <Resource name="installsources" />
        <Resource name="licensemetrics" />
        <Resource name="licensemodels" />
        <Resource name="licensetypes" />
        <Resource name="devicescopes" />
        <Resource name="deviceroles" />
        <Resource name="devicetypes" />
        <Resource name="deviceclasses" />
        <Resource name="devicestates" />
        <Resource name="devicestatuses" />
        <Resource name="userscopes" />
        <Resource name="userroles" />
        <Resource name="usertypes" />
        <Resource name="userclasses" />
        <Resource name="userstates" />
        <Resource name="userstatuses" />
        <Resource name="measurementstatuses" />
        <Resource name="projecttypes" />
        <Resource name="projectclasses" />
        <Resource name="projectstatuses" />
        <Resource name="projectscopes" />
        <Resource name="observationtypes" />
        <Resource name="agreementlineusagetypes" />
        <Resource name="agreementlinemeasureunits" />
        <Resource name="agreementstatuses" />
        <Resource name="agreementlinetypes" />
        <Resource name="organizationscopes" />
        <Resource name="organizationtypes" />
        <Resource name="organizationclasses" />
        <Resource name="organizationstatuses" />
        <Resource name="organizationsizes" />
        <Resource name="organizationrevenues" />

        <Resource name="defaultlicensemetrics" />
        <Resource name="productpathtypes" />
      </Admin>
    </PreferencesBasedThemeProvider>
  );
};

export default App;
