import React from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';

export const NewEnumInput = ({ reference, source }) => {
    return (
        <ReferenceInput fullwidth  reference={reference} source={source}>
            <SelectInput style={{minWidth: "255px"}} optionText="description" />
        </ReferenceInput>
    );
};
