import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { dateFormatter } from '../../../utils/formatters';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';
import { dateColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, filterValues, basePath, total } = useListContext();
  const { permissions } = usePermissions();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {(permissions === 'admin' || permissions === 'planner' || permissions === 'analyst') && (
        <>
          <CreateButton basePath={basePath} />
        </>
      )}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Measurements" actions={<ListActions />}>
      <DataGrid
        onRowDataChanged={(params) => {
          return params.api?.getFilterInstance('isArchived');
          //filterComponent.selectNothing();
          //filterComponent.selectValue('false');
          //filterComponent.refreshFilterValues();
        }}
        {...props}
        rowDoubleClicked={onRowClicked}
      >
        <AgGridColumn field="measurementName" />
        <AgGridColumn field="projectName" />
        <AgGridColumn
          field="plannedStartDate"
          valueFormatter={dateFormatter}
          filter="agMultiColumnFilter"
          filterParams={dateColumnFilter}
        />
        <AgGridColumn
          field="actualStartDate"
          valueFormatter={dateFormatter}
          filter="agMultiColumnFilter"
          filterParams={dateColumnFilter}
        />
        <AgGridColumn
          field="plannedEndDate"
          valueFormatter={dateFormatter}
          filter="agMultiColumnFilter"
          filterParams={dateColumnFilter}
        />
        <AgGridColumn
          field="actualEndDate"
          valueFormatter={dateFormatter}
          filter="agMultiColumnFilter"
          filterParams={dateColumnFilter}
        />
        <AgGridColumn
          field="collectionDate"
          valueFormatter={dateFormatter}
          filter="agMultiColumnFilter"
          filterParams={dateColumnFilter}
        />
        <AgGridColumn {...useAgGridEnumCol('measurementstatuses', 'status')} />
        <AgGridColumn field="isArchived" filter={true} sortable={true} />
      </DataGrid>
    </List>
  );
};
