import MSDNUsageIcon from '@material-ui/icons/DeveloperBoard';
import { MSDNUsageCreate, MSDNUsageEdit, MSDNUsageShow } from './MSDNUsageForm';
import MSDNUsageList from './MSDNUsageList';

export default {
  list: MSDNUsageList,
  icon: MSDNUsageIcon,
  show: MSDNUsageShow,
  edit: MSDNUsageEdit,
  create: MSDNUsageCreate,
};
