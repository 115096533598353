import { get, last, toPath } from 'lodash';

const licensemetric = [
  '',
  'TBD',
  'Per Device',
  'Per User',
  'Per Processor',
  'Per Core',
  'Per User CAL',
  'Per Device CAL',
  'Per Unit',
  'Per TB',
  'Per CAL',
  'Per Asset',
];

const installtype = ['', 'TBD', 'Component', 'Product', 'Suite', 'Bundle', 'SBC Install'];

const installmetric = [
  '',
  'TBD',
  'Per Core',
  'Per Device',
  'Per Processor',
  'Per User',
  'Per User CAL',
  'Per Device Cal',
];

const licensemodel = [
  '',
  'TBD',
  'Perpetual',
  'Perpetual + Active Maintenance',
  'Active Maintenance',
  'Upgrade Plus Active Maintenance',
  'Subscription',
  'Upgrade + Expired Maintenance',
  'Perpetual + Expired Maintenance',
  'Expired Maintenance',
  'Elevated',
  'Elevated Active Maintenance',
  'Elevated Subscription',
  'Subscription Supplement',
  'Work At Home',
  'Extended Support',
];
const installsource = [
  '',
  'TBD',
  'Added Manually ',
  'Microsoft Map Toolkit',
  'Office 365 Admin Portal ',
  'Vlsc Subscriptions ',
  'Igel Ums',
  'Dell Wyse Device Manager',
  'Hp Device Manager',
  'Vmware Vsphere',
];

const enums = {
  users: {
    userscope: ['', 'TBD', 'Production', 'Development', 'Test', 'Acceptance'],
    userrole: [
      '',
      'TBD',
      'Named',
      'Functional',
      'Group',
      'Admin',
      'Resource',
      'System',
      'Service',
      'Test',
      'Training',
      'Share',
    ],
    usertype: ['', 'TBD', 'Internal', 'External', 'Duplicate'],
    userclass: ['', 'TBD', 'Regular', 'Light', 'Heavy', 'Developer', 'Power'],
    userstate: ['', 'TBD', 'Inactive', 'Active', 'Idle', 'Disabled', 'Dismissable'],
    userstatus: ['', 'TBD', 'Qualified', 'Disqualified', 'OutScope'],
  },
  measurements: {
    status: ['', 'TBD', 'Pending', 'Active', 'Archived'],
  },
  projects: {
    projectclass: [
      '',
      'TBD',
      'License Check',
      'Internal Audit',
      'External Audit',
      'SAMOS Services',
    ],
    projectscope: ['', 'TBD', 'Local', 'Global'],
    projectstatus: ['', 'TBD', 'Pending', 'Active', 'Closed'],
    projecttype: ['', 'TBD', 'Internal', 'External'],
  },
  agreements: {
    agreementclass: ['', 'TBD', 'Master', 'Agreement', 'Sub Agreement'],
    agreementscope: ['', 'Organization Level', 'Business Unit Level'],
    agreementstatus: ['', 'Active', 'Pending', 'Expired', 'Terminated', 'Returned', 'Reject'],
    agreementtype: [
      '',
      'TBD',
      'Master Business Service Agreement',
      'Enterprise Agreement',
      'Enterprise Subscription Agreement',
      'Microsoft Product & Service Agreement',
      'Microsoft Customer Agreement for Cloud Solution Provider',
      'Service Provider License Agreement',
      'Standard Enrollment',
      'Affiliate Registration Form',
      'License Confirmation Number',
      'Independed Software Vendor',
      'Recycled Software',
      'Open',
      'Open Value',
      'Select (Plus)',
      'License',
      'Agreement',
    ],
  },
  devices: {
    licensemodel: licensemodel,
    installsource: installsource,
    installtype: installtype,
    licensemetric: licensemetric,
    installmetric: installmetric,
    agreementtype: [
      '',
      'TBD',
      'Master Business Service Agreement',
      'Enterprise Agreement',
      'Enterprise Subscription Agreement',
      'Microsoft Product & Service Agreement',
      'Microsoft Customer Agreement for Cloud Solution Provider',
      'Service Provider License Agreement',
      'Standard Enrollment',
      'Affiliate Registration Form',
      'License Confirmation Number',
      'Independed Software Vendor',
      'Recycled Software',
      'Open',
      'Open Value',
      'Select (Plus)',
      'License',
      'Agreement',
    ],
    devicescope: [
      'TBD',
      'Physical Client',
      'Virtual Client',
      'Physical Server',
      'Virtual Server',
      'DTA',
      'Development',
      'Test',
      'Acceptance',
      'Work At Home',
      'Cloud IaaS',
      'Source',
    ],
    devicestatus: ['TBD', 'Qualified', 'Disqualified', 'InScope', 'OutScope'],
    devicestate: [
      'TBD',
      'Inactive',
      'Active',
      'Idle',
      'Disabled',
      'Powered Off',
      'Non Applicable',
      'Source',
    ],
    deviceclass: [
      'TBD',
      'Datacenter',
      'Cluster',
      'Server',
      'Desktop',
      'Laptop',
      'Thin Client',
      'Tablet',
      'Mobile',
      'Industrial Device',
      'Source',
    ],
    devicetype: ['TBD', 'Physical', 'Virtual', 'Datacenter', 'Template', 'Source'],
    devicerole: ['TBD', 'Client', 'Server', 'Host', 'Source'],
  },
  organizations: {
    organizationscope: ['', 'TBD', 'Single Entity', 'Multiple Entities'],
    organizationtype: ['', 'TBD', 'Customer'],
    organizationclass: ['', 'TBD', 'Healthcare', 'Logistics', 'Financial', 'Government'],
    organizationstatus: ['', 'TBD', 'New', 'Active', 'Retired'],
    organizationsize: ['', 'TBD', 'Small', 'Medium', 'Large', 'XLarge'],
    organizationrevenue: ['', 'TBD', '0 - 1 Mio', '1 - 10 Mio', '10 - 100 Mio', '> 100 Mio'],
  },
  productpaths: {
    licensemetric: licensemetric,
    installmetric: installmetric,
    productpathtype: ['', 'TBD', 'Match', 'Downgrade', 'Downedition', 'Upgrade'],
  },
  products: {
    defaultlicensemetric: licensemetric
  },
  agreementlines: {
    licensemetric: licensemetric,
    licensemodel: licensemodel,
    statuscode: ['TBD', 'Active', 'Expired'],
    typecode: [
      'TBD',
      'Enterprise',
      'Additional Products',
      'Enterprise Online Services',
      'Enterprise Online Products',
      'Server And Cloud Enrollment',
      'Healthcare',
    ],
    usagetypecode: ['TBD', 'Adjustable'],
    measureunitcode: ['TBD', 'Per Year', 'Per Month'],
  },
  hostsoftware: {
    licensemetric: licensemetric,
    licensemodel: licensemodel,
    installtype: installtype,
    installsource: installsource,
  },
  useraccess: {
    licensemetric: licensemetric,
  },
  deviceaccess: {
    licensemetric: licensemetric,
  },
  usersoftware: {
    licensemetric: licensemetric,
    licensemodel: licensemodel,
    installmetric: installmetric,
    installtype: installtype,
    installsource: installsource,
  },
  observations: {
    observationtype: [
      'assumption',
      'attention',
      'clarification',
      'recommendation',
      'validation',
      'warning',
    ],
  },
  purchaselines: {
    licensemetric: licensemetric,
    licensemodel: licensemodel,
  },
  pricelists: {
    licensemetric: licensemetric,
    licensemodel: licensemodel,
  },
  pricingoptions: {
    agreementtype: [
      '',
      'TBD',
      'Master Business Service Agreement',
      'Enterprise Agreement',
      'Enterprise Subscription Agreement',
      'Microsoft Product & Service Agreement',
      'Microsoft Customer Agreement for Cloud Solution Provider',
      'Service Provider License Agreement',
      'Standard Enrollment',
      'Affiliate Registration Form',
      'License Confirmation Number',
      'Independed Software Vendor',
      'Recycled Software',
      'Open',
      'Open Value',
      'Select (Plus)',
      'License',
      'Agreement',
    ],
    agreementlinetype: [
      'TBD',
      'Enterprise',
      'Additional Products',
      'Enterprise Online Services',
      'Enterprise Online Products',
      'Server And Cloud Enrollment',
      'Healthcare',
    ],
  },
  virtual: {
    licensemodel: licensemodel,
    installtype: installtype,
    licensemetric: licensemetric,
    installmetric: installmetric,
    devicescope: [
      'TBD',
      'Physical Client',
      'Virtual Client',
      'Physical Server',
      'Virtual Server',
      'DTA',
      'Development',
      'Test',
      'Acceptance',
      'Work At Home',
      'Cloud IaaS',
      'Source',
    ],
    devicestatus: ['TBD', 'Qualified', 'Disqualified', 'InScope', 'OutScope'],
    devicestate: [
      'TBD',
      'Inactive',
      'Active',
      'Idle',
      'Disabled',
      'Powered Off',
      'Non Applicable',
      'Source',
    ],
    deviceclass: [
      'TBD',
      'Datacenter',
      'Cluster',
      'Server',
      'Desktop',
      'Laptop',
      'Thin Client',
      'Tablet',
      'Mobile',
      'Industrial Device',
      'Source',
    ],
    devicetype: ['TBD', 'Physical', 'Virtual', 'Datacenter', 'Template', 'Source'],
    devicerole: ['TBD', 'Client', 'Server', 'Host', 'Source'],
  },
  hardware: {
    licensemodel: licensemodel,
    installtype: installtype,
    licensemetric: licensemetric,
    installmetric: installmetric,
    devicescope: [
      'TBD',
      'Physical Client',
      'Virtual Client',
      'Physical Server',
      'Virtual Server',
      'DTA',
      'Development',
      'Test',
      'Acceptance',
      'Work At Home',
      'Cloud IaaS',
      'Source',
    ],
    devicestatus: ['TBD', 'Qualified', 'Disqualified', 'InScope', 'OutScope'],
    devicestate: [
      'TBD',
      'Inactive',
      'Active',
      'Idle',
      'Disabled',
      'Powered Off',
      'Non Applicable',
      'Source',
    ],
    deviceclass: [
      'TBD',
      'Datacenter',
      'Cluster',
      'Server',
      'Desktop',
      'Laptop',
      'Thin Client',
      'Tablet',
      'Mobile',
      'Industrial Device',
      'Source',
    ],
    devicetype: ['TBD', 'Physical', 'Virtual', 'Datacenter', 'Template', 'Source'],
    devicerole: ['TBD', 'Client', 'Server', 'Host', 'Source'],
  },
  usersoftwaremodal: {
    licensemetric: ['User', 'User CAL'],
  },
  devicesoftware: {
    licensemetric: licensemetric,
    licensemodel: licensemodel,
    installmetric: installmetric,
    installtype: installtype,
    installsource: installsource,
  },
  devicesoftwaremodal: {
    licensemetric: ['Device', 'Device CAL'],
  },
  articles: {
    licensemetric: licensemetric,
    licensemodel: licensemodel,
  },
  softwaregenerationratio: {
    ratio: ['Actual', 'Qualified', 'All'],
  },
  entitlements: {
    licensemetric: licensemetric,
  },
  licensepositions: {
    licensemetric: licensemetric,
  },
  compliancepositions: {
    licensemetric: licensemetric,
  },
  productcomponents: {
    installtype: installtype,
    installsource: installsource,
  },
  collaborationservers: {
    licensemetric: licensemetric,
  },
  communicationservers: {
    licensemetric: licensemetric,
  },
  databaseservers: {
    licensemetric: licensemetric,
  },
  itsmservers: {
    licensemetric: licensemetric,
  },
  mailservers: {
    licensemetric: licensemetric,
  },
  messagebrokerservers: {
    licensemetric: licensemetric,
  },
  windowsservers: {
    licensemetric: licensemetric,
  },
};

export function getEnumTransformForType(resource) {
  return (propertyName, value) => {
    return enums?.[resource]?.[propertyName.toLowerCase()]?.[value] ?? value;
  };
}

export function getColumnDefForType(resource) {
  const enumTransform = getEnumTransformForType(resource);
  return (field) => {
    return {
      field: field,
      valueGetter: (params) => enumTransform(last(toPath(field)), get(params.data, field)),
    };
  };
}

export function getSelectChoices(resource, property) {
  return enums[resource][property.toLowerCase()]
    .map((element, index) => ({
      id: index,
      name: element,
    }))
    .filter((element) => element.name);
}
