import UserIcon from '@material-ui/icons/People';
import { SmartSassClientsCreate, SmartSassClientsShow } from './SmartSassClientsForm';
import SmartSassClientsList from './SmartSassClientsList';

export default {
  list: SmartSassClientsList,
  show: SmartSassClientsShow,
  create: SmartSassClientsCreate,
  icon: UserIcon,
};
