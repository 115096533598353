import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextField,
  TabbedShowLayout,
  Tab,
  NumberField,
  BooleanField,
  BooleanInput,
  NumberInput,
} from 'react-admin';

import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import FilteringProducts from '../../../components/FilteringProducts';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { Annotations } from '../../../components/Annotations';
import { sourceTab } from '../../../components/SourceTab';
import { UserInput } from '../../../components/CustomInputs';
import { AuditLog } from '../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import ActionBarShow from '../../../components/ActionBarShow';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

const inputs = (
  <AccordionForm redirect="show" autoClose toolbar={<EditToolbar message={'user software'} />}>
    <AccordionFormPanel label="Basic">
      <UserInput />
    </AccordionFormPanel>
    <AccordionFormPanel label="Installed product">
      <FilteringProducts source="installProductId" specialProductGroup="installProductGroup" />
      <NewEnumInput source="installMetric" reference="licensemetrics" />
      <NewEnumInput source="installType" reference="installtypes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="License">
      <FilteringProducts source="licenseProductId" specialProductGroup="licenseProductGroup" />
      <NewEnumInput source="licenseMetric" reference="licensemetrics" />
      <NewEnumInput source="licenseModel" reference="licensemodels" />

      <NumberInput source="licenseQuantity" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Properties">
      <BooleanInput source="isCoveredByHostInstall" />
      <BooleanInput source="isCostAvoidanceCandidate" />
      <BooleanInput source="isCostSavingsCandidate" />
      <BooleanInput source="hasLiability" />
      <BooleanInput source="isMaintenanceRequired" />
      <BooleanInput source="isManual" />
      <BooleanInput source="isArchived" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput fullWidth source="notes" Label="" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput fullWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput fullWidth source="feedback" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const UserSoftwareShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField source="userName" />
          <TextField source="softwareTag" />
          <TextField source="installProductName" />
          <TextField source="installVersion" />
          <TextField source="installEdition" />
          <NumberField source="installQuantity" />
          <NewEnumField source="installMetric" reference="licensemetrics" />
          <NewEnumField source="installType" reference="installtypes" />
          <TextField source="licenseProductName" />
          <TextField source="licenseVersion" />
          <TextField source="licenseEdition" />
          <NumberField source="licenseQuantity" />
          <NewEnumField source="licenseMetric" reference="licensemetrics" />
          <NewEnumField source="licenseModel" reference="licensemodels" />
        </Tab>
        <Tab label="Liability">
          <BooleanField source="hasLiability" />
          <BooleanField source="isMaintenanceRequired" />
          <BooleanField source="isCoveredByHostInstall" />
          <BooleanField source="isCostAvoidanceCandidate" />
          <BooleanField source="isCostSavingsCandidate" />
          <BooleanField source="isManual" />
          <BooleanField source="isArchived" />
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        {sourceTab}{' '}
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const UserSoftwareEdit = (props) => {
  return (
    <Edit undoable={false} title={<PageTitle />} {...props}>
      {inputs}
    </Edit>
  );
};

export const UserSoftwareCreate = (props) => (
  <Create title="New" {...props}>
    {inputs}
  </Create>
);
