import * as React from 'react';
import DataGrid from '../../../components/DataGrid';

import { List } from 'react-admin';
import { useHistory } from 'react-router';
import {
  useListContext,
  TopToolbar,
  useRefresh,
  useNotify,
  CreateButton,
  sanitizeListRestProps,
} from 'react-admin';
import { SelectedSoftwareDialog } from '../../../components/SoftwareDialog';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import { dateFormatter } from '../../../utils/formatters';
import { useFetchData } from '../../../utils/useFetch';
import { dateColumnFilter, numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <SelectedSoftwareDialog resource={props.resource} callBulkApply={props.callBulkApply} />
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();
  const [gridApi, setGridApi] = React.useState(null);
  const refresh = useRefresh();
  const notify = useNotify();
  const fetchApplyLicenses = useFetchData(`/devicesoftware/bulkapplylicenses`, 'POST', true);

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  const callBulkApply = async (licenseMetric, productId) => {
    const data = {
      licenseMetric: licenseMetric,
      productId: productId,
      softwareIds: gridApi.getSelectedRows().map((row) => row?.softwareId),
    };
    await fetchApplyLicenses(data);
    refresh();
    notify(`Bulk apply started`);
  };

  return (
    <List
      {...props}
      title="Windows Server"
      actions={
        <ComplexActionBarShow
          id={props.id}
          {...props}
          oldToolbar={<ListActions callBulkApply={callBulkApply} resource={props.resource} />}
        />
      }
    >
      <DataGrid {...props} enableSelection setGridApi={setGridApi} rowDoubleClicked={onRowClicked}>
        <AgGridColumn
          field=""
          checkboxSelection
          headerCheckboxSelection
          maxWidth={35}
          suppressMenu
          sortable={false}
          lockPosition
          resizable={false}
          checkbox
          pined
        />
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="deviceName" />
          <AgGridColumn field="productName" />
          <AgGridColumn field="version" />
          <AgGridColumn field="edition" />
          <AgGridColumn field="fullyQualifiedDomainName" columnGroupShow="open" />
        </AgGridColumn>

        <AgGridColumn headerName="Properties">
          <AgGridColumn field="applicationRoles" />
          <AgGridColumn field="isExternalAccessEnabled" />
          <AgGridColumn field="isVirtualMobilityEnabled" columnGroupShow="open" />
          <AgGridColumn field="isCostAvoidanceCandidate" columnGroupShow="open" />
          <AgGridColumn
            field="daysSinceLastActivity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
            columnGroupShow="open"
          />
          <AgGridColumn
            field="lastActivityDate"
            valueFormatter={dateFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={dateColumnFilter}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Hardware">
          <AgGridColumn field="cpuModel" />
          <AgGridColumn
            field="physicalCores"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="physicalProcessors"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn field="make" columnGroupShow="open" />
          <AgGridColumn field="model" />
          <AgGridColumn
            field="virtualProcessors"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="virtualCores"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn field="virtualCPUType" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Classifications">
          <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
          <AgGridColumn {...useAgGridEnumCol('deviceroles', 'deviceRole')} />
          <AgGridColumn {...useAgGridEnumCol('devicetypes', 'deviceType')} columnGroupShow="open" />
          <AgGridColumn
            {...useAgGridEnumCol('deviceclasses', 'deviceClass')}
            columnGroupShow="open"
          />
          <AgGridColumn
            {...useAgGridEnumCol('devicestates', 'deviceState')}
            columnGroupShow="open"
          />
          <AgGridColumn
            {...useAgGridEnumCol('devicestatuses', 'deviceStatus')}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Organization">
          <AgGridColumn field="businessUnitName" />
          <AgGridColumn field="city" columnGroupShow="open" />
          <AgGridColumn field="country" columnGroupShow="open" />
          <AgGridColumn field="region" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Annotation">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
