import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextField,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  ReferenceField,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import FilteringProducts from '../../../components/FilteringProducts';
import { DeviceInput } from '../../../components/CustomInputs';
import { sourceTab } from '../../../components/SourceTab';
import CrudWrapper from '../../../components/CrudWrapper';
import { AuditLog } from '../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

export const DeviceAccessShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="deviceId" reference="devices">
            <TextField source="deviceName" />
          </ReferenceField>
          <ReferenceField source="productId" reference="products">
            <TextField source="productName" />
          </ReferenceField>
          <ReferenceField source="productId" reference="products">
            <TextField source="version" />
          </ReferenceField>
          <ReferenceField source="productId" reference="products">
            <TextField source="edition" />
          </ReferenceField>
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        {sourceTab}
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const DeviceAccessEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit title={<PageTitle />} undoable={false} {...props}>
        <SimpleForm redirect="show" autoClose toolbar={<EditToolbar message={'device acces'} />}>
          <DeviceInput />
          <FilteringProducts source="productId" />
        </SimpleForm>
      </Edit>
    </CrudWrapper>
  );
};

export const DeviceAccessCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      <SimpleForm redirect="show" autoClose>
        <DeviceInput />
        <FilteringProducts source="productId" />
      </SimpleForm>
    </Create>
  </CrudWrapper>
);
