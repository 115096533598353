import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'ra-core';
import { ReferenceField, useDataProvider } from 'react-admin';
import DatagridAPI from '../providers/dataGridProvider';

const CellRenderer = ({ source, ...props }) => {
  const record = useRecordContext(props);
  return get(record, source);
};

export const NewEnumCell = ({ reference, source }) => {
  return (
    <ReferenceField reference={reference} source={source} link={false}>
      <CellRenderer source="description" />
    </ReferenceField>
  );
};

export const useAgGridEnumCol = (resource, field) => {
  const dataProvider = useDataProvider();
  const [enums, setEnums] = useState(new Map());
  useEffect(() => {
    dataProvider
      .getList(resource, { filter: {} })
      .then((data) => setEnums(new Map(data.data.map((e) => [String(e.id), e.description]))));
  }, [resource]);

  const enumValueFormatter = (params) => {
    // params.value is converted to a string in set value formatter but remains the original type in cell value formatter
    return enums.get(String(params.value)) ?? params.value;
  };

  return {
    field,
    valueFormatter: enumValueFormatter,
    filterParams: {
      filters: [
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: enumValueFormatter,
            excelMode: 'windows',
          },
        },
      ],
    },
  };
};

//Stays new until we change all grids
export const useNewAgGridEnumCol = (resource, field, reference) => {
  const dataProvider = useDataProvider();
  const [enums, setEnums] = useState(new Map());
  useEffect(() => {
    dataProvider
      .getList(resource, { filter: {} })
      .then((data) => setEnums(new Map(data.data.map((e) => [String(e.id), e.description]))));
  }, [resource]);

  const enumValueFormatter = (params) => {
    // params.value is converted to a string in set value formatter but remains the original type in cell value formatter
    return enums.get(String(params.value)) ?? params.value;
  };

  return {
    field,
    valueFormatter: enumValueFormatter,
    filterParams: {
      filters: [
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: enumValueFormatter,
            excelMode: 'windows',
            values: async (params) => {
              const responseValue = await DatagridAPI.getFilterValues(
                reference,
                field,
                params.api.getFilterModel(),
              );
              params.success(responseValue);
            },
            refreshValuesOnOpen: true,
          },
        },
      ],
    },
  };
};

NewEnumCell.defaultProps = {
  addLabel: true,
};

export const observationValueGetter = (params) => {
  return params.data.observations?.map((observation) => observation?.observationTag).join(', ');
};

export const enumColDef = (reference, field) => {
  return {
    field: field,
    cellRendererFramework: (params) => {
      return (
        <ReferenceField reference={reference} record={params.data} source={field} link={false}>
          <CellRenderer source="description" />
        </ReferenceField>
      );
    },
    filter: 'agSetColumnFilter',
    filterParams: {
      cellRendererFramework: (params) => {
        if (params.value === '(Select All)') {
          return params.value;
        } else {
          return (
            <ReferenceField
              reference={reference}
              record={{ [field]: params.value }}
              source={field}
              link={false}
            >
              <CellRenderer source="description" />
            </ReferenceField>
          );
        }
      },
    },
  };
};
