import * as React from 'react';
import {
  Edit,
  TextInput,
  Create,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  required,
  SimpleForm,
  SelectInput,
  FunctionField,
  TopToolbar,
  EditButton,
  usePermissions,
  TranslatableFields,
  TranslatableInputs,
} from 'react-admin';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.projectName : ''}</span>;
};

export const observationTypeMap = new Map([
  [0, 'assumption'],
  [1, 'attention'],
  [2, 'clarification'],
  [3, 'recommendation'],
  [4, 'validation'],
  [5, 'warning'],
]);

const choices = Array.from(observationTypeMap.entries()).map(([id, name]) => ({
  id,
  name,
}));

const fields = (
  <SimpleForm redirect="show" toolbar={<EditToolbar message={'Observation'} />}>
    <SelectInput
      choices={choices}
      source="observationType"
      label="Observation Type"
      validate={required()}
    />

    <TranslatableInputs locales={['en_US', 'nl_NL']}>
      <TextInput fullWidth source="observationTranslations" label="Observation" />
      <TextInput fullWidth source="actionTranslations" label="Action" />
    </TranslatableInputs>
  </SimpleForm>
);

export const ObservationShow = (props) => {
  const { permissions } = usePermissions();

  const fields = (
    <TabbedShowLayout>
      <Tab label="Details">
        <FunctionField
          render={(record) => observationTypeMap.get(record.observationType)}
          label="Observation Type"
        />
        <TranslatableFields locales={['en_US', 'nl_NL']}>
          <TextField source="observationTranslations" label="Observation" />
          <TextField source="actionTranslations" label="Action" />
        </TranslatableFields>
      </Tab>
    </TabbedShowLayout>
  );

  const PostShowActions = ({ basePath, data }) => (
    <TopToolbar>
      {(permissions === 'admin' || permissions === 'analyst') && (
        <EditButton basePath={basePath} record={data} />
      )}
    </TopToolbar>
  );

  return (
    <Show actions={<PostShowActions />} title={<PageTitle />} {...props}>
      {fields}
    </Show>
  );
};

export const ObservationEdit = (props) => (
  <Edit undoable={false} title={<PageTitle />} {...props}>
    {fields}
  </Edit>
);

export const ObservationCreate = (props) => (
  <Create title="New" {...props}>
    {fields}
  </Create>
);
