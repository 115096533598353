import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List, usePermissions, useRefresh } from 'react-admin';
import { useHistory } from 'react-router';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  Button,
  sanitizeListRestProps,
  useNotify,
} from 'react-admin';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { AgGridColumn } from 'ag-grid-react';
import { useFetchData } from '../../../utils/useFetch';
import fingerprintProvider from '../../../providers/fingerprintProvider';
import { SelectedSoftwareDialog } from '../../../components/SoftwareDialog';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

const ListActions = (props) => {
  const IgnoreSelectedFingerprints = () => {
    props.ignoreSelectedFingerprints();
  };

  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  const { permissions } = usePermissions();
  const notify = useNotify();
  const refresh = useRefresh();

  const reassignFingerprints = async () => {
    await fingerprintProvider.reassignFingerprints();
    notify('Reassigned all singerprints');
    refresh();
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <SelectedSoftwareDialog
        resource={props.resource}
        callBulkApply={props.callBulkApply}
        label="Bulk apply products"
      />
      <Button onClick={IgnoreSelectedFingerprints} label="Ignore selected fingerprints">
        <HighlightOffIcon />
      </Button>
      {permissions === 'admin' && (
        <Button onClick={reassignFingerprints} label="Reassign all fingerprints">
          <FingerprintIcon />
        </Button>
      )}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();
  const [gridApi, setGridApi] = React.useState(null);
  const refresh = useRefresh();
  const notify = useNotify();
  const fetchApplyLicenses = useFetchData(`/fingerprints/applyproducts`, 'POST', true);

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  const callBulkApply = async (licenseMetric, productId) => {
    const data = {
      productId: productId,
      fingerprintIds: gridApi.getSelectedRows().map((row) => row.id),
    };
    await fetchApplyLicenses(data);
    refresh();
    notify(`Bulk apply done!`);
  };

  const ignoreSelectedFingerprints = async () => {
    if (gridApi.getSelectedRows().length < 1) return;
    fingerprintProvider.ignoreMultiple(gridApi.getSelectedRows());
    refresh();
    notify('Fingerprints successfully updated');
  };

  return (
    <List
      {...props}
      title="Fingerprints"
      actions={
        <ListActions
          callBulkApply={callBulkApply}
          resource={props.resource}
          ignoreSelectedFingerprints={ignoreSelectedFingerprints}
        />
      }
    >
      <DataGrid
        {...props}
        setGridApi={setGridApi}
        rowDoubleClicked={onRowClicked}
        /*         onRowSelection={onRowSelection}
         */ source="fingerprints"
      >
        <AgGridColumn
          checkboxSelection={true}
          checkbox={true}
          headerCheckboxSelection={true}
          maxWidth={50}
        />
        <AgGridColumn field="source" />
        <AgGridColumn field="rawManufacturer" />
        <AgGridColumn field="rawProductName" />
        <AgGridColumn field="rawVersion" />
        <AgGridColumn field="rawMetric" />
        <AgGridColumn field="productName" />
        <AgGridColumn field="productVersion" />
        <AgGridColumn field="productEdition" />
        <AgGridColumn field="productManufacturer" />
        <AgGridColumn field="isIgnorable" />
        <AgGridColumn field="isReconciled" />
      </DataGrid>
    </List>
  );
};
