import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { SelectInput } from 'ra-ui-materialui';
import FilteringProducts from './FilteringProducts';
import { getScope } from '../utils/scopeQuery';
import { useNotify, useRefresh } from 'ra-core';
import { Button, NumberInput } from 'react-admin';
import { useFetchData } from '../utils/useFetch';
import { NewEnumInput } from '../types/NewEnumInput';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '20px',
  },
});

export const SoftwareDialog = ({ resource, urlResource }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const checkScope = getScope().measurementId;
  const refresh = useRefresh();
  const notify = useNotify();
  const fetchSoftwareLines = useFetchData(
    `/measurements/generate${urlResource}/${checkScope}`,
    'POST',
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    const response = await fetchSoftwareLines(e);
    handleClose();
    refresh();
    notify(`Generated ${response?.added} new software lines.`);
  };

  return (
    <div>
      <Button
        style={{ padding: '3px' }}
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        label="Generate software lines"
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Generate software lines</DialogTitle>
        <DialogContent>
          {checkScope ? (
            <Form
              onSubmit={(e) => handleSubmit(e)}
              render={({ handleSubmit }) => (
                <FormSpy>
                  {(props) => (
                    <div className={classes.container}>
                      <FilteringProducts source="productId" />
                      <SelectInput
                        choices={[
                          { id: 3, name: 'Per User' },
                          { id: 6, name: 'Per User CAL' },
                          { id: 2, name: 'Per Device' },
                          { id: 7, name: 'Per Device CAL' },
                        ]}
                        source="licenseMetric"
                      />
                      <NewEnumInput source="licenseMetric" reference="licensemetrics" />
                      <Button
                        style={{ padding: '5px' }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        label="Generate"
                      />
                    </div>
                  )}
                </FormSpy>
              )}
            />
          ) : (
            <div style={{ display: 'flex', textAlign: 'center' }}>
              <h3>Please select measurement to continue.</h3>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export const SelectedSoftwareDialog = ({ callBulkApply, resource, label }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const checkScope = getScope().measurementId;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    callBulkApply(e.licenseMetric, e.productId, e.licenseQuantity);
    handleClose();
  };

  return (
    <div>
      <Button
        style={{ padding: '3px', marginLeft: '12px' }}
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        label={label ?? 'Bulk apply licenses'}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{label ?? 'Bulk apply products'}</DialogTitle>
        <DialogContent>
          {checkScope ? (
            <Form
              onSubmit={(e) => handleSubmit(e)}
              render={({ handleSubmit }) => (
                <FormSpy>
                  {(props) => (
                    <div className={classes.container}>
                      <FilteringProducts source="productId" />
                      {resource === 'fingerprints' ? null : (
                        <>
                          <NumberInput
                            source="licenseQuantity"
                            allowNull={true}
                            defaultValue={null}
                            resettable
                          />
                          <NewEnumInput source="licenseMetric" reference="licensemetrics" />{' '}
                        </>
                      )}
                      <Button
                        style={{ padding: '5px' }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        label="Generate"
                      />
                    </div>
                  )}
                </FormSpy>
              )}
            />
          ) : (
            <div style={{ display: 'flex', textAlign: 'center' }}>
              <h3>Please select measurement to continue.</h3>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export const AddSoftwareLinesDialog = ({ addSoftwareLines, resource }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const checkScope = getScope().measurementId;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    addSoftwareLines(e.licenseMetric, e.productId, e.licenseQuantity);
    handleClose();
  };

  return (
    <div>
      <Button
        style={{ padding: '3px', marginLeft: '12px' }}
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        label="Add software lines"
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Bulk apply licenses</DialogTitle>
        <DialogContent>
          {checkScope ? (
            <Form
              onSubmit={(e) => handleSubmit(e)}
              render={({ handleSubmit }) => (
                <FormSpy>
                  {(props) => (
                    <div className={classes.container}>
                      <FilteringProducts source="productId" specialFilter={true} />
                      <NumberInput
                        source="licenseQuantity"
                        allowNull={true}
                        defaultValue={null}
                        resettable
                      />
                      <NewEnumInput source="licenseMetric" reference="licensemetrics" />
                      <Button
                        style={{ padding: '5px' }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        label="Generate"
                      />
                    </div>
                  )}
                </FormSpy>
              )}
            />
          ) : (
            <div style={{ display: 'flex', textAlign: 'center' }}>
              <h3>Please select measurement to continue.</h3>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
