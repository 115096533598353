import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  DateField,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import { NullableDateInput } from '../../../components/NullableDateInput';
import { DeviceInput, UserInput } from '../../../components/CustomInputs';
import { sourceTab } from '../../../components/SourceTab';
import { AuditLog } from '../../../components/AuditLog';
import CrudWrapper from '../../../components/CrudWrapper';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

export const UEMSessionShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="deviceId" reference="devices">
            <TextField source="deviceName" />
          </ReferenceField>
          <ReferenceField source="deviceAccessId" reference="deviceaccess">
            <TextField source="deviceName" />
          </ReferenceField>
          <ReferenceField source="userId" reference="users">
            <TextField source="userName" />
          </ReferenceField>
          <TextField source="application" />
          <DateField source="startDate" />
          <DateField source="endDate" />
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        {sourceTab}
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const UEMSessionEdit = (props) => {
  const [deviceFilter, setDeviceFilter] = React.useState('');
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        <SimpleForm redirect="show" toolbar={<EditToolbar message={'UEM usage'} />}>
          <DeviceInput setDeviceFilter={setDeviceFilter} />
          <ReferenceInput
            source="deviceAccessId"
            reference="deviceaccess"
            filter={{ deviceId: deviceFilter }}
          >
            <SelectInput optionText={(record) => record.deviceName + ' - ' + record.productName} />
          </ReferenceInput>
          <UserInput />
          <TextInput source="application" />
          <NullableDateInput source="startDate" />
          <NullableDateInput source="endDate" />
        </SimpleForm>
      </Edit>
    </CrudWrapper>
  );
};

export const UEMSessionCreate = (props) => {
  const [deviceFilter, setDeviceFilter] = React.useState('');
  return (
    <CrudWrapper>
      <Create title="New" {...props}>
        <SimpleForm redirect="show" autoClose>
          <DeviceInput setDeviceFilter={setDeviceFilter} />
          <ReferenceInput
            source="deviceAccessId"
            reference="deviceaccess"
            filter={{ deviceId: deviceFilter }}
          >
            <SelectInput optionText={(record) => record.deviceName + ' - ' + record.productName} />
          </ReferenceInput>
          <UserInput />
          <TextInput source="application" />
          <NullableDateInput source="startDate" />
          <NullableDateInput source="endDate" />
        </SimpleForm>
      </Create>
    </CrudWrapper>
  );
};
