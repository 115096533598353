import IconSupplier from '@material-ui/icons/ContactPhone';
import ListSupplier from './SupplierList';
import { SupplierShow, SupplierEdit, SupplierCreate } from './SupplierForm';

export default {
  list: ListSupplier,

  icon: IconSupplier,
  show: SupplierShow,
  edit: SupplierEdit,
  create: SupplierCreate,
};
