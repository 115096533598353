export default {
  // Set Filter
  selectAll: 'Alles selecteren',
  selectAllSearchResults: '(Selecteer alle zoek resultaten)',
  searchOoo: 'Zoek...',
  blanks: '(Leeg)',
  noMatches: 'Geen resultaat',

  // Number Filter & Text Filter
  filterOoo: 'Filter...',
  equals: 'is gelijk aan ',
  notEqual: 'is niet gelijk aan',
  empty: 'Kies',

  // Number Filter
  lessThan: 'kleiner dan',
  greaterThan: 'groter dan',
  lessThanOrEqual: 'kleiner of gelijk aan',
  greaterThanOrEqual: 'groter of helijk aan',
  inRange: 'Binnen reeks',
  inRangeStart: 'van',
  inRangeEnd: 'tot',

  // Text Filter
  contains: 'bevat',
  notContains: 'bevat niet',
  startsWith: 'start met',
  endsWith: 'eindigt op',

  // Date Filter
  dateFormatOoo: 'dd-mm-yyyy',

  // Filter Conditions
  andCondition: 'EN',
  orCondition: 'OF',

  // Filter Buttons
  applyFilter: 'OK',
  resetFilter: 'Herstel',
  clearFilter: 'Maak leeg',
  cancelFilter: 'Annuleer',

  // Filter Titles
  textFilter: 'Tekst Filter',
  numberFilter: 'Numeriek Filter',
  dateFilter: 'Datum Filter',
  setFilter: 'Excel Filter',

  // Side Bar
  columns: 'Kolommen',
  filters: 'Filters',

  // columns tool panel
  pivotMode: 'Draaitabel Modus',
  groups: 'Groepering per rij',
  rowGroupColumnsEmptyMessage: 'Sleep hierheen om per rij te groeperen',
  values: 'Waarden',
  valueColumnsEmptyMessage: 'Sleep hierheen om te bundelen',
  pivots: 'Draaitabellen',
  pivotColumnsEmptyMessage: 'Sleep hierheen om draaitabelkolommen in te stellen',

  // Header of the Default Group Column
  group: 'Groep',

  // Other
  loadingOoo: 'laden...',
  noRowsToShow: 'Geen rijen ',
  enabled: 'Ingeschakeld',

  // Menu
  pinColumn: 'Pin Kolom',
  pinLeft: 'Pin Links',
  pinRight: 'Pin Rechts',
  noPin: 'Verwijder Pin',
  valueAggregation: 'Waarde bundeling',
  autosizeThiscolumn: 'Stel automatische breedte voor deze kolom in',
  autosizeAllColumns: 'Stel automatische breedte voor alle kolommen in',
  groupBy: 'Groepeer per',
  ungroupBy: 'De-groepeer per',
  resetColumns: 'Herstel kolommen',
  expandAll: 'Klap alles uit',
  collapseAll: 'Sluit alles',
  copy: 'Kopïeer',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Kopïeer met koppen',
  paste: 'Plak',
  ctrlV: 'Ctrl+V',
  export: 'Exporteer',
  csvExport: 'Exporteer als CSV bestand',
  excelExport: 'Exporteer als Excel bestand (.xlsx)',
  excelXmlExport: 'Exporteer als Excel bestand (.xml)',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Totaal',
  min: 'Min',
  max: 'Max',
  none: 'Geen',
  count: 'Aamtal',
  avg: 'Gemiddeld',
  filteredRows: 'Gefilterd',
  selectedRows: 'Geselecteerd',
  totalRows: 'Aantal rijen',
  totalAndFilteredRows: 'Rijen',
  more: 'Meer',
  to: 'van',
  of: 'tot',
  page: 'Pagina',
  nextPage: 'Volgende pagina',
  lastPage: 'Laatste pagina',
  firstPage: 'Eerste pagina',
  previousPage: 'Vorige pagina',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Draaitabel grafiek & Daraaitabel modus',
  pivotChart: 'NLPivot Chart',
  chartRange: 'NLChart Range',

  columnChart: 'NLColumn',
  groupedColumn: 'NLGrouped',
  stackedColumn: 'NLStacked',
  normalizedColumn: 'NL100% Stacked',

  barChart: 'NLBar',
  groupedBar: 'NLGrouped',
  stackedBar: 'NLStacked',
  normalizedBar: 'NL100% Stacked',

  pieChart: 'Taart',
  pie: 'Taart',
  doughnut: 'Donot',

  line: 'Lijngrafiek',

  xyChart: 'NLX Y (Scatter)',
  scatter: 'NLScatter',
  bubble: 'NLBubble',

  areaChart: 'NLArea',
  area: 'NLArea',
  stackedArea: 'NLStacked',
  normalizedArea: 'NL100% Stacked',

  histogramChart: 'NLHistogram',

  // Charts
  pivotChartTitle: 'NLPivot Chart',
  rangeChartTitle: 'NLRange Chart',
  settings: 'NLSettings',
  data: 'NLData',
  format: 'NLFormat',
  categories: 'NLCategories',
  defaultCategory: 'NL(None)',
  series: 'NLSeries',
  xyValues: 'NLX Y Values',
  paired: 'NLPaired Mode',
  axis: 'NLAxis',
  navigator: 'NLNavigator',
  color: 'NLColor',
  thickness: 'NLThickness',
  xType: 'NLX Type',
  automatic: 'NLAutomatic',
  category: 'NLCategory',
  number: 'NLNumber',
  time: 'NLTime',
  xRotation: 'NLX Rotation',
  yRotation: 'NLY Rotation',
  ticks: 'NLTicks',
  width: 'NLWidth',
  height: 'NLHeight',
  length: 'NLLength',
  padding: 'NLPadding',
  spacing: 'NLSpacing',
  chart: 'NLChart',
  title: 'NLTitle',
  titlePlaceholder: 'NLChart title - double click to edit',
  background: 'NLBackground',
  font: 'NLFont',
  top: 'NLTop',
  right: 'NLRight',
  bottom: 'NLBottom',
  left: 'NLLeft',
  labels: 'NLLabels',
  size: 'NLSize',
  minSize: 'NLMinimum Size',
  maxSize: 'NLMaximum Size',
  legend: 'NLLegend',
  position: 'NLPosition',
  markerSize: 'NLMarker Size',
  markerStroke: 'NLMarker Stroke',
  markerPadding: 'NLMarker Padding',
  itemSpacing: 'NLItem Spacing',
  itemPaddingX: 'NLItem Padding X',
  itemPaddingY: 'NLItem Padding Y',
  layoutHorizontalSpacing: 'NLHorizontal Spacing',
  layoutVerticalSpacing: 'NLVertical Spacing',
  strokeWidth: 'NLStroke Width',
  offset: 'NLOffset',
  offsets: 'NLOffsets',
  tooltips: 'NLTooltips',
  callout: 'NLCallout',
  markers: 'NLMarkers',
  shadow: 'NLShadow',
  blur: 'NLBlur',
  xOffset: 'NLX Offset',
  yOffset: 'NLY Offset',
  lineWidth: 'NLLine Width',
  normal: 'NLNormal',
  bold: 'NLBold',
  italic: 'NLItalic',
  boldItalic: 'NLBold Italic',
  predefined: 'NLPredefined',
  fillOpacity: 'NLFill Opacity',
  strokeOpacity: 'NLLine Opacity',
  histogramBinCount: 'NLBin count',
  columnGroup: 'NLColumn',
  barGroup: 'NLBar',
  pieGroup: 'NLPie',
  lineGroup: 'NLLine',
  scatterGroup: 'NLX Y (Scatter)',
  areaGroup: 'NLArea',
  histogramGroup: 'NLHistogram',
  groupedColumnTooltip: 'NLGrouped',
  stackedColumnTooltip: 'NLStacked',
  normalizedColumnTooltip: 'NL100% Stacked',
  groupedBarTooltip: 'NLGrouped',
  stackedBarTooltip: 'NLStacked',
  normalizedBarTooltip: 'NL100% Stacked',
  pieTooltip: 'NLPie',
  doughnutTooltip: 'NLDoughnut',
  lineTooltip: 'NLLine',
  groupedAreaTooltip: 'NLArea',
  stackedAreaTooltip: 'NLStacked',
  normalizedAreaTooltip: 'NL100% Stacked',
  scatterTooltip: 'NLScatter',
  bubbleTooltip: 'NLBubble',
  histogramTooltip: 'NLHistogram',
  noDataToChart: 'NLNo data available to be charted.',
  pivotChartRequiresPivotMode: 'NLPivot Chart requires Pivot Mode enabled.',
};
