import React from 'react';
import { useState } from 'react';
import { useLogin, Notification, useAuthProvider } from 'react-admin';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import HttpsIcon from '@material-ui/icons/Https';
import './login.css';
import { TextField, Button } from '@material-ui/core';

const MyLoginPage = ({ theme }) => {
  const login = useLogin();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const authProvider = useAuthProvider();
  const officeUrl = `https://login.microsoftonline.com/${process.env.REACT_APP_API_TENNANT}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_API_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_API_REDIRECT_URI}&scope=offline_access%20openid%20profile%20user.read`;
  let history = useHistory();

  const submit = async (e) => {
    e.preventDefault();
    await login({ username, password });
    await authProvider.checkAuth();
    history.push('/');
  };

  const handleRedirect = () => {
    history.push('/reset-password');
  };

  return (
    <div className="root">
      <div className="mainContainer">
        <div className="iconWrapper">
          <div className="iconContainer">
            <HttpsIcon className="iconStyle" />
          </div>
        </div>

        <form className="formStyle" onSubmit={submit}>
          <div className="inputWrapper">
            <TextField
              name="username"
              margin="normal"
              /* helperText="Required *" */
              /* type="username" */
              label="Email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              name="password"
              type="password"
              margin="normal"
              /* helperText="Required *" */
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button variant="contained" className="bottomInput" type="submit" color="primary">
            Sign in
          </Button>
        </form>
        <div className="officeBtnWrapper">
          <Button
            variant="contained"
            //onClick={openOfficeLogin}
            color="secondary"
            className="officeBtn"
            href={officeUrl}
          >
            Sign in with Office 365
          </Button>
        </div>
        <Notification />
        <div className="forgotContainer">
          <Link component="button" variant="body2" onClick={handleRedirect}>
            <p className="passReset">Did you forgot your password?</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyLoginPage;
