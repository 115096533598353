import React, { useState } from 'react';
import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout/lib/src/forms';

import { useNotify } from 'react-admin';
import { useHistory } from 'react-router';
import WizardDraggable from './WizardDraggable';
import './wizard.css';
import { Button } from '@material-ui/core';
import WizardDropzone from './DropzoneComponent';
import WizardList from './WizardList';
import { useFetchData } from '../../../utils/useFetch';

//   REORDERING FUNCTION
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const WizardInventory = (props) => {
  const [files, setFiles] = useState([]);
  const isWizard = true;
  const [typedFileList, setTypedFileList] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const notify = useNotify();
  let history = useHistory();

  const apiUrl = process.env.REACT_APP_API_URL;
  const urlSplit = window.location.href.split('/');
  const measurement_id = urlSplit[urlSplit.length - 1];
  const fetchUpload = useFetchData(`/measurements/file/upload/${measurement_id}`, 'POST');

  const nextEnabled = () => {
    const result = files.filter((file) => file.fileType === null);
    if (result.length === 0) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  const onDrop = (uploadedFile) => {
    postUploadFiles(uploadedFile);
    setDisabledBtn(false);
  };

  const changedType = async (newFiles) => {
    files.forEach((file) => {
      if (file.id === newFiles.id) {
        file.fileType = newFiles.fileType;
      }
    });

    setTypedFileList([newFiles]);
  };

  const postUploadFiles = async (data) => {
    setIsFileUploading(true);

    var formData = new FormData();

    data.forEach((file) => {
      formData.append('file', file);
    });

    let response = await fetchUpload(formData);

    setIsFileUploading(false);
    setFiles((files) => [...files, response]);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    setTypedFileList(reorder(typedFileList, result.source.index, result.destination.index));

    const request = new Request(apiUrl + `/measurements/files/${measurement_id}`, {
      method: 'PUT',
      headers: new Headers({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }),

      body: JSON.stringify(typedFileList),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          notify('Something went wrong, please try again');
        }
        return response.json();
      })
      .catch(() => {
        notify('Something went wrong, please try again');
      });
  };

  const handleDelete = (name) => {
    const removeItem = files.filter((file) => {
      deleteAction(name.id);
      return file !== name;
    });
    setFiles(removeItem);
  };

  const parseRequest = () => {
    const request = new Request(apiUrl + `/measurements/parse/${measurement_id}`, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
    return fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        notify('Something went wrong, please try again');
      }
      history.push(`/measurements/${measurement_id}/show/4`);
      notify('Import has started!');
      return response.json();
    });
  };

  const MyToolbar = ({ hasPreviousStep, hasNextStep, onPreviousClick, onNextClick, invalid }) => {
    const save = () => {
      parseRequest();
    };

    return (
      <ul className="toolbarList">
        {hasPreviousStep ? (
          <li className="toolbarItem">
            <Button
              variant="contained"
              color="primary"
              onClick={onPreviousClick}
              style={{ margin: 0 }}
            >
              Previous
            </Button>
          </li>
        ) : null}
        {hasNextStep ? (
          <li>
            <Button
              variant="contained"
              color="primary"
              disabled={disabledBtn}
              onClick={(e) => {
                onNextClick(e);
              }}
            >
              Next
            </Button>
          </li>
        ) : (
          <li>
            <Button variant="contained" color="primary" disabled={invalid} onClick={save}>
              Import
            </Button>
          </li>
        )}
      </ul>
    );
  };

  //    LISTING AFTER UPLOAD

  return (
    <div className="wizardForm">
      <WizardForm toolbar={MyToolbar} className="wizardForm">
        <WizardFormStep label="Upload files">
          <WizardDropzone
            files={files}
            onDrop={onDrop}
            handleDelete={handleDelete}
            isFileUploading={isFileUploading}
          />
        </WizardFormStep>
        <WizardFormStep label="View files">
          <WizardList
            typedFileList={typedFileList}
            files={files}
            id={measurement_id}
            nextEnabled={nextEnabled}
            changedType={changedType}
          />
        </WizardFormStep>
        <WizardFormStep style={{ width: '100%' }} label="Choose file type">
          <WizardDraggable
            isWizard={isWizard}
            fileList={files}
            onDragEnd={onDragEnd}
            handleDelete={handleDelete}
          />
        </WizardFormStep>
      </WizardForm>
    </div>
  );
};

export default WizardInventory;

const deleteAction = async (id) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const deleteRequest = new Request(apiUrl + `/measurements/file/delete/${id}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
  });
  let response = await fetch(deleteRequest);
  response = await response.json();
  if (response.status < 200 || response.status >= 300) {
    console.log(response.status);
  }
  return;
};
