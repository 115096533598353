import * as React from 'react';
import { Annotations } from '../../../components/Annotations';
import {
  Edit,
  Show,
  Create,
  TextField,
  TabbedShowLayout,
  Tab,
  BooleanField,
  BooleanInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  FunctionField,
  ReferenceField,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import { AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { DeviceInput } from '../../../components/CustomInputs';
import { sourceTab } from '../../../components/SourceTab';
import { NewEnumField } from '../../../types/NewEnumField';
import { AuditLog } from '../../../components/AuditLog';
import CrudWrapper from '../../../components/CrudWrapper';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

export const ITSMUsageShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="deviceId" reference="devices">
            <TextField source="deviceName" />
          </ReferenceField>
          <ReferenceField source="deviceAccessId" reference="deviceaccess">
            <FunctionField
              label="deviceAccess"
              render={(record) => `${record.deviceName} - ${record.productName}`}
            />
          </ReferenceField>
          <ReferenceField source="itsmServerId" reference="itsmservers">
            <TextField source="productName" />
          </ReferenceField>
          <ReferenceField source="fingerprintId" reference="fingerprints">
            <TextField source="rawProductName" />
          </ReferenceField>
          <TextField source="manufacturer" />
          <TextField source="productName" />
          <TextField source="version" />
          <BooleanField source="isCostAvoidanceCandidate" />
        </Tab>
        <Tab label="Classification">
          <NewEnumField source="deviceScope" label="deviceScope" reference="devicescopes" />
          <NewEnumField source="deviceRole" label="deviceRole" reference="deviceroles" />
          <NewEnumField source="deviceType" label="deviceType" reference="devicetypes" />
          <NewEnumField source="deviceClass" label="deviceClass" reference="deviceclasses" />
          <NewEnumField source="deviceState" label="deviceState" reference="devicestates" />
          <NewEnumField source="deviceStatus" label="deviceStatus" reference="devicestatuses" />
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        {sourceTab}
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const ITSMUsageEdit = (props) => {
  const [deviceFilter, setDeviceFilter] = React.useState('');
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        <SimpleForm redirect="show" autoClose toolbar={<EditToolbar message={'ITMS usage'} />}>
          <DeviceInput setDeviceFilter={setDeviceFilter} />
          <ReferenceInput
            source="deviceAccessId"
            reference="deviceaccess"
            filter={{ deviceId: deviceFilter }}
          >
            <SelectInput optionText={(record) => record.deviceName + ' - ' + record.productName} />
          </ReferenceInput>
          <ReferenceInput source="itsmServerId" reference="itsmservers">
            <SelectInput optionText={(record) => record.productName + ' - ' + record.version} />
          </ReferenceInput>
          <BooleanInput source="isCostAvoidanceCandidate" />
          <AccordionFormPanel label="Notes">
            <MarkdownInput fullWidth source="notes" Label="" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Interpretations">
            <MarkdownInput fullWidth source="interpretations" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Feedback">
            <MarkdownInput fullWidth source="feedback" />
          </AccordionFormPanel>
        </SimpleForm>
      </Edit>
    </CrudWrapper>
  );
};

export const ITSMUsageCreate = (props) => {
  const [deviceFilter, setDeviceFilter] = React.useState('');
  return (
    <CrudWrapper>
      <Create title="New" {...props}>
        <SimpleForm redirect="show" autoClose>
          <DeviceInput setDeviceFilter={setDeviceFilter} />
          <ReferenceInput
            source="deviceAccessId"
            reference="deviceaccess"
            filter={{ deviceId: deviceFilter }}
          >
            <SelectInput
              optionText={(record) => record.deviceName + ' - ' + record.productName}
              disabled={!deviceFilter.length}
            />
          </ReferenceInput>
          <ReferenceInput source="itsmServerId" reference="itsmservers">
            <SelectInput optionText={(record) => record.productName + ' - ' + record.version} />
          </ReferenceInput>
          <BooleanInput source="isCostAvoidanceCandidate" />
          <AccordionFormPanel label="Notes">
            <MarkdownInput fullWidth source="notes" Label="" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Interpretations">
            <MarkdownInput fullWidth source="interpretations" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Feedback">
            <MarkdownInput fullWidth source="feedback" />
          </AccordionFormPanel>
        </SimpleForm>
      </Create>
    </CrudWrapper>
  );
};
