import React from 'react';
import baseStyle from './baseStyle';
import Dropzone from 'react-dropzone';
import { useTranslate } from 'ra-core';
import { LinearProgress } from 'react-admin';

function WizardDropzone({ onDrop, isFileUploading, fileUploadProgress = null }) {
  const translate = useTranslate();

  return (
    <Dropzone multiple={true} onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <section className="container">
          <div style={baseStyle} {...getRootProps({ baseStyle })}>
            <input {...getInputProps()} />
            <p>
              {translate("Drag 'n' drop Inventory tracker files here, or click to select files")}
            </p>
          </div>
          <aside>
            {isFileUploading ? (
              <span>
                <LinearProgress />
                {translate('Upload in progress')}: {fileUploadProgress}%
              </span>
            ) : null}
          </aside>
        </section>
      )}
    </Dropzone>
  );
}

export default WizardDropzone;
