import * as React from 'react';
import { useHistory } from 'react-router';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  NumberField,
  DateField,
  BooleanField,
  BooleanInput,
  SimpleForm,
  CloneButton,
  TopToolbar,
  EditButton,
  FunctionField,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ReferenceField,
} from 'react-admin';
import { NullableDateInput } from '../../../components/NullableDateInput';
import { NewEnumInput } from '../../../types/NewEnumInput';
import { NewEnumField } from '../../../types/NewEnumField';

import DataGrid from '../../../components/DataGrid';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';

import { MarkdownInput } from '@react-admin/ra-markdown';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import { AgGridColumn } from 'ag-grid-react';
import {
  observationValueGetter,
  useAgGridEnumCol,
  useNewAgGridEnumCol,
} from '../../../types/NewEnumCell';
import { Annotations } from '../../../components/Annotations';
import EditToolbar from '../../../components/EditToolbar';
import { numberColumnFilter } from '../../../utils/filterParams';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import DatagridAPI from '../../../providers/dataGridProvider';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

export const ProductShow = (props) => {
  const history = useHistory();
  const softwareRuleIndicator = 0;
  const [hasDocuments, setHasDocuments] = React.useState(false);

  const ShowToolbar = ({ basePath, data }) => (
    <TopToolbar>
      <CloneButton basePath={basePath} record={data} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );

  const onFingerprintRowClicked = (id) => {
    const url = `/fingerprints/${id}/show`;
    history.push(url);
  };

  const onProductComponentRowClicked = (id) => {
    const url = `/productcomponents/${id}/show`;
    history.push(url);
  };

  const onProductPathRowClicked = (id) => {
    const url = `/productpaths/${id}/show`;
    history.push(url);
  };

  const ruleValueGetter = (params) => {
    return params.data?.rules
      ?.filter((rule) => rule.ruleIndicator === softwareRuleIndicator)
      .map((rule) => rule.ruleTag)
      .join(', ');
  };

  const ruleFilterParams = (ruleTag, columnName = 'rules') => {
    return {
      filters: [
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            excelMode: 'windows',
            values: async (params) => {
              const response = await DatagridAPI.getFilterValues(props.resource, columnName);
              const ruleCategory = response.filter((rule) => rule.substring(0, 2) === ruleTag);
              params.success(ruleCategory);
            },
            refreshValuesOnOpen: true,
          },
        },
      ],
    };
  };

  const fields = (
    <TabbedShowLayout>
      <Tab label="Basic">
        <TextField halfWidth source="productName" label="productName" />
        <ReferenceField reference="manufacturers" source="manufacturerId">
          <TextField halfWidth source="manufacturerKey" />
        </ReferenceField>
        <TextField halfWidth source="edition" label="edition" />
        <TextField halfWidth source="version" label="version" />
        <TextField halfWidth source="productPool" label="productPool" />
        <TextField halfWidth source="productCategory" label="productCategory" />
        <TextField halfWidth source="productGroup" label="productGroup" />
        <TextField halfWidth source="productFamily" label="productFamily" />
        <DateField halfWidth source="releaseDate" label="releaseDate" />
      </Tab>
      <Tab label="Extended">
        <AccordionFormPanel label="Claims">
          <TextField halfWidth source="productName" label="productName" />
          <TextField halfWidth source="version" label="version" />
          <TextField halfWidth source="buildNumber" label="buildNumber" />
        </AccordionFormPanel>

        <AccordionFormPanel label="Properties">
          <BooleanField halfWidth source="isLicensable" label="isLicensable" />
          <BooleanField halfWidth source="trackUsage" label="trackUsage" />
          <BooleanField halfWidth source="isOperatingSystem" label="isOperatingSystem" />
          <BooleanField halfWidth source="isServerRelated" label="isServerRelated" />
          <BooleanField halfWidth source="hasDowngradePaths" label="hasDowngradePaths" />
          <BooleanField halfWidth source="hasUpgradePaths" label="hasUpgradePaths" />
          <BooleanField halfWidth source="excludeFromReports" label="excludeFromReports" />
          <NewEnumField halfWidth source="defaultLicenseMetric" reference="licensemetrics" />
          <NumberField
            halfWidth
            source="minimumRequiredLicenseQuantity"
            label="minimumRequiredLicenseQuantity"
          />
        </AccordionFormPanel>
        <AccordionFormPanel label="Timestamps">
          <DateField halfWidth source="releaseDate" label="releaseDate" />
          <DateField halfWidth source="mainstreamSupportEndDate" label="mainstreamSupportEndDate" />
          <DateField halfWidth source="extendedSupportEndDate" label="extendedSupportEndDate" />
        </AccordionFormPanel>
      </Tab>
      <Tab label="Fingerprints">
        <ReferenceManyField reference="fingerprints" target="productId" addLabel={false}>
          <DataGrid source="fingerprints" rowDoubleClicked={onFingerprintRowClicked}>
            <AgGridColumn field="source" />
            <AgGridColumn field="rawManufacturer" />
            <AgGridColumn field="rawProductName" />
            <AgGridColumn field="rawVersion" />
            <AgGridColumn field="productName" />
            <AgGridColumn field="productVersion" />
            <AgGridColumn field="productEdition" />
            <AgGridColumn field="productManufacturer" />
            <AgGridColumn field="isIgnorable" />
            <AgGridColumn field="isReconciled" />
          </DataGrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Components">
        <ReferenceManyField reference="productcomponents" target="productId" addLabel={false}>
          <DataGrid source="productcomponents" rowDoubleClicked={onProductComponentRowClicked}>
            <AgGridColumn headerName="Properties">
              <AgGridColumn field="sequenceNumber" />
              <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
              <AgGridColumn field="hasLiability" />
              <AgGridColumn field="isRequired" />
              <AgGridColumn field="suiteName" />
              <AgGridColumn field="suiteGroup" />
            </AgGridColumn>
          </DataGrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Paths">
        <ReferenceManyField reference="productpaths" target="productId" addLabel={false}>
          <DataGrid source="productpaths" rowDoubleClicked={onProductPathRowClicked}>
            <AgGridColumn field="composedProductNameFrom" headerName="From Product" />
            <AgGridColumn field="composedProductNameTo" headerName="To Product" />
            <AgGridColumn field="manufacturerName" />
            <AgGridColumn field="buildNumber" />
            <AgGridColumn
              field="productGroup"
              headerName="Group of product, containing changed products"
            />
            <AgGridColumn {...useAgGridEnumCol('productpathtypes', 'productPathType')} />
            <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
            <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'installMetric')} />
          </DataGrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Deployments">
        <ReferenceManyField reference="devicesoftware" target="installProductId" addLabel={false}>
          <DataGrid source="devicesoftware">
            <AgGridColumn headerName="Claims" pivot={true} enablePivot={true}>
              <AgGridColumn field="device_DeviceTag" headerName="Device Tag" />
              <AgGridColumn field="device_DeviceName" headerName="Device Name" />
              <AgGridColumn field="softwareTag" />
              <AgGridColumn
                field="device_FullyQualifiedDomainName"
                headerName="Fully Qualified Domain Name"
              />
            </AgGridColumn>

            <AgGridColumn headerName="Validations">
              <AgGridColumn field="installProduct_IsLicensable" headerName={'Is Licensable'} />
              <AgGridColumn field="hasLiability" />
              <AgGridColumn field="isMaintenanceRequired" />
              <AgGridColumn field="isCoveredByHostInstall" />
              <AgGridColumn field="isCostSavingsCandidate" />
              <AgGridColumn field="isCostAvoidanceCandidate" />
              <AgGridColumn field="fingerprint_IsIgnorable" columnGroupShow="open" />
              <AgGridColumn field="fingerprint_isReconciled" columnGroupShow="open" />
              <AgGridColumn
                field="installProduct_TrackUsage"
                headerName={'Track Usage'}
                columnGroupShow="open"
              />
              <AgGridColumn
                field="processors"
                columnGroupShow="open"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn
                field="coresPerProcessor"
                columnGroupShow="open"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn
                field="totalCores"
                columnGroupShow="open"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
            </AgGridColumn>
            <AgGridColumn headerName="Classifications">
              <AgGridColumn
                field="rules"
                valueGetter={(e) => ruleValueGetter(e)}
                filterParams={() => ruleFilterParams('SR')}
              />
              <AgGridColumn
                field="baseObservations"
                valueGetter={(e) => observationValueGetter(e)}
                filterParams={() => ruleFilterParams('OB', 'baseObservations')}
              />
              <AgGridColumn
                {...useNewAgGridEnumCol('devicescopes', 'device_DeviceScope', 'deviceSoftware')}
                headerName="Device scope"
              />
              <AgGridColumn
                {...useNewAgGridEnumCol('deviceroles', 'device_DeviceRole', 'deviceSoftware')}
                headerName="Device role"
              />
              <AgGridColumn
                {...useNewAgGridEnumCol('devicetypes', 'device_DeviceType', 'deviceSoftware')}
                headerName="Device types"
              />
              <AgGridColumn
                {...useNewAgGridEnumCol('deviceclasses', 'device_DeviceClass', 'deviceSoftware')}
                headerName="Device class"
              />
              <AgGridColumn
                {...useNewAgGridEnumCol('devicestates', 'device_DeviceState', 'deviceSoftware')}
                headerName="Device state"
              />
              <AgGridColumn
                {...useNewAgGridEnumCol('devicestatuses', 'device_DeviceStatus', 'deviceSoftware')}
                headerName="Device status"
              />
            </AgGridColumn>
            <AgGridColumn headerName="Install product">
              <AgGridColumn
                field="installProduct_Manufacturer_ManufacturerKey"
                headerName={'Manufacturer'}
              />
              <AgGridColumn field="installProduct_ProductName" headerName={'Product Name'} />
              <AgGridColumn
                field="installProduct_ProductPool"
                columnGroupShow="open"
                headerName={'Product Pool'}
              />
              <AgGridColumn
                field="installProduct_ProductCategory"
                columnGroupShow="open"
                headerName={'Product Category'}
              />
              <AgGridColumn
                field="installProduct_ProductGroup"
                columnGroupShow="open"
                headerName={'Product Group'}
              />
              <AgGridColumn
                field="installProduct_ProductFamily"
                columnGroupShow="open"
                headerName={'Product Family'}
              />
              <AgGridColumn field="installProduct_Version" headerName={'Version'} />
              <AgGridColumn field="installProduct_Edition" headerName={'Edition'} />
              <AgGridColumn
                field="installQuantity"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
            </AgGridColumn>
            <AgGridColumn headerName="Licensed product">
              <AgGridColumn field="licenseProduct_ProductName" headerName={'Product Name'} />
              <AgGridColumn
                field="licenseProduct_ProductPool"
                columnGroupShow="open"
                headerName={'Product Pool'}
              />
              <AgGridColumn
                field="licenseProduct_ProductCategory"
                columnGroupShow="open"
                headerName={'Product Category'}
              />
              <AgGridColumn
                field="licenseProduct_ProductGroup"
                columnGroupShow="open"
                headerName={'Product Group'}
              />
              <AgGridColumn
                field="licenseProduct_ProductFamily"
                columnGroupShow="open"
                headerName={'Product Family'}
              />
              <AgGridColumn field="licenseProduct_Version" headerName={'Version'} />
              <AgGridColumn field="licenseProduct_Edition" headerName={'Edition'} />
              <AgGridColumn
                field="licenseQuantity"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'installMetric')} />
              <AgGridColumn {...useAgGridEnumCol('licensemodels', 'installModel')} />
            </AgGridColumn>
            <AgGridColumn headerName="Organization">
              <AgGridColumn
                field="device_BusinessUnit_BusinessUnitName"
                headerName={'Business Unit Name'}
              />
              <AgGridColumn
                field="device_BusinessUnit_City"
                columnGroupShow="open"
                headerName={'City'}
              />
              <AgGridColumn
                field="device_BusinessUnit_Country"
                columnGroupShow="open"
                headerName={'Country'}
              />
              <AgGridColumn
                field="device_BusinessUnit_Region"
                columnGroupShow="open"
                headerName={'Region'}
              />
              <AgGridColumn
                field="device_BusinessUnit_Organization_OrganizationName"
                columnGroupShow="open"
                headerName={'Organization Name'}
              />
            </AgGridColumn>
            <AgGridColumn headerName="Annotation">
              <AgGridColumn field="notes" />
              <AgGridColumn field="interpretations" columnGroupShow="open" />
              <AgGridColumn field="feedback" columnGroupShow="open" />
            </AgGridColumn>
            <AgGridColumn headerName="Sources">
              <AgGridColumn
                headerName="Created by"
                field="sources"
                valueGetter={(e) => createdBy(e)}
              />
              <AgGridColumn
                field="sources"
                headerName="Updated by"
                valueGetter={(e) => updatedBy(e)}
              />
            </AgGridColumn>
          </DataGrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Annotations">
        <Annotations noFeedback={true} />
      </Tab>
      {hasDocuments && (
        <Tab label="Linked Documents">
          <SimpleForm toolbar={<EmptyToolbar />}>
            <FileUpload source="documents" show />
          </SimpleForm>
        </Tab>
      )}
      <FunctionField
        style={{ diplay: 'none' }}
        label=""
        render={(record) => setHasDocuments(record?.documents?.length > 0)}
      />
    </TabbedShowLayout>
  );
  return (
    <Show actions={<ShowToolbar {...props} />} title={<PageTitle />} {...props}>
      {fields}
    </Show>
  );
};

const inputs = (
  <AccordionForm autoClose redirect="show" toolbar={<EditToolbar message={'product'} />}>
    <AccordionFormPanel label="Claims">
      <TextInput halfWidth source="productName" />
      <ReferenceInput source="manufacturerId" reference="manufacturers">
        <SelectInput optionText="manufacturerKey" />
      </ReferenceInput>
      <TextInput halfWidth source="productPool" />
      <TextInput halfWidth source="productCategory" />
      <TextInput halfWidth source="productGroup" />
      <TextInput halfWidth source="productFamily" />
      <TextInput halfWidth source="version" />
      <TextInput halfWidth source="edition" />
      <TextInput halfWidth source="buildNumber" />

      <NewEnumInput halfwidth source="defaultLicenseMetric" reference="licensemetrics" />
      <NumberInput halfWidth source="minimumRequiredLicenseQuantity" />
    </AccordionFormPanel>

    <AccordionFormPanel label="Properties">
      <BooleanInput halfWidth source="isLicensable" />
      <BooleanInput halfWidth source="trackUsage" />
      <BooleanInput halfWidth source="isOperatingSystem" />
      <BooleanInput halfWidth source="isServerRelated" />
      <BooleanInput halfWidth source="hasDowngradePaths" />
      <BooleanInput halfWidth source="hasUpgradePaths" />
      <BooleanInput halfWidth source="excludeFromReports" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Timestamps">
      <NullableDateInput halfWidth source="releaseDate" />
      <NullableDateInput halfWidth source="mainstreamSupportEndDate" />
      <NullableDateInput halfWidth source="extendedSupportEndDate" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Annotations">
      <AccordionFormPanel label="Notes">
        <MarkdownInput halfWidth source="notes" />
      </AccordionFormPanel>
      <AccordionFormPanel label="Interpretations">
        <MarkdownInput halfWidth source="interpretations" />
      </AccordionFormPanel>
    </AccordionFormPanel>
    <AccordionFormPanel label="Link Documents">
      <FileUpload source="documents" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const ProductEdit = (props) => {
  return (
    <Edit undoable={false} title={<PageTitle />} {...props}>
      {inputs}
    </Edit>
  );
};

export const ProductCreate = (props) => (
  <Create title="New" {...props}>
    {inputs}
  </Create>
);
