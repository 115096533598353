import * as React from 'react';
import DataGrid from '../../../components/RuleDataGrid';
import { dateTimeFormatter } from '../../../utils/formatters';
import {
  Button,
  CreateButton,
  List,
  ReferenceField,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  usePermissions,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useHistory } from 'react-router';
import { useRecordContext } from 'ra-core';
import { get } from 'lodash';
import { getScope } from '../../../utils/scopeQuery';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { useFetch } from '../../../utils/useFetch';

const CellRenderer = ({ source, ...props }) => {
  const record = useRecordContext(props);
  return get(record, source);
};

export default (props) => {
  const [columnDefs, setColumnDefs] = React.useState([]);
  const ruleConfigurationKey = props.options.ruleConfigurationKey;
  const getData = useFetch('/rules/configuration', 'GET');

  React.useEffect(() => {
    const getConfig = async () => {
      const response = await getData();
      const setConfig = response?.configuration?.[ruleConfigurationKey]?.set;
      const modelConfig = response?.configuration?.[ruleConfigurationKey]?.models;
      const dynamicColumnDefs = Object.entries(setConfig ?? [])?.map(([key, value]) => ({
        headerName: key,
        field: `set.${key}`,
        cellRendererFramework: (params) => {
          const columnValue = params.data.set?.[key];
          const fieldConfig = setConfig[key];
          if (fieldConfig.values) {
            // Try to translate the value using the enum values map if it exists, otherwise just display the raw value.
            return setConfig[key].values?.[columnValue] ?? columnValue ?? null;
          }

          if (fieldConfig.type === 'BusinessUnit') {
            return (
              <ReferenceField
                reference="businessunits"
                record={params.data}
                source={`set.${key}`}
                link={false}
              >
                <CellRenderer source="businessUnitName" />
              </ReferenceField>
            );
          }

          return columnValue ?? null;
        },
      }));
      const columnDefs = props.options.isSystem
        ? [
            {
              headerName: 'Tag',
              field: 'ruleTag',
              rowDrag: true,
            },
            {
              headerName: 'Enabled',
              field: 'enabled',
            },

            {
              headerName: 'where',
              valueGetter: function (params) {
                return params.data.where
                  .map(
                    (item) =>
                      `  ${item.joinOperator ? item.joinOperator : ''} 
            ${item.source}  ${item.field}  ${item.operator}  ${
                        modelConfig[item.source]?.[item.field]?.values?.[item.value] ??
                        item.value ??
                        null
                      } `,
                  )
                  .join(' ');
              },
            },

            ...dynamicColumnDefs,
          ]
        : [
            {
              headerName: 'Tag',
              field: 'ruleTag',
              rowDrag: true,
            },
            {
              headerName: 'Enabled',
              field: 'enabled',
            },

            {
              headerName: 'Global',
              field: 'global',
            },
            {
              headerName: 'where',
              valueGetter: function (params) {
                return params.data.where
                  .map(
                    (item) =>
                      `  ${item.joinOperator ? item.joinOperator : ''} 
                    ${item.source}  ${item.field}  ${item.operator}  ${
                        modelConfig[item.source]?.[item.field]?.values?.[item.value] ??
                        item.value ??
                        null
                      } `,
                  )
                  .join(' ');
              },
            },
            {
              headerName: 'Rows Affected',
              field: 'rowsAffected',
            },
            {
              headerName: 'Execution Date',
              field: 'executionDate',
              valueFormatter: dateTimeFormatter,
            },
            {
              headerName: 'Observations',
              field: 'observationTags',
            },
            ...dynamicColumnDefs,
          ];

      setColumnDefs(columnDefs);
    };
    getConfig();
  }, []);

  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  const ListActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const id = getScope().measurementId;
    const getData = useFetch(`/measurements/resetrules/${id}`, 'POST', null, true);
    const { basePath } = useListContext();
    const { permissions } = usePermissions();
    const refresh = useRefresh();
    const notify = useNotify();

    const ruleReset = async () => {
      await getData().then((e) => {
        notify('Rules reset!');
        refresh();
      });
    };

    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {(permissions === 'admin' || (permissions === 'analyst' && !props.options.isSystem)) && (
          <>
            <CreateButton basePath={basePath} />
            <Button
              disabled={!id}
              children={<RotateLeftIcon />}
              onClick={ruleReset}
              label="reset all rules"
            />
          </>
        )}
      </TopToolbar>
    );
  };
  const search = props.location.search;
  const measurementId = new URLSearchParams(search).get('measurementId');
  return (
    <List
      {...props}
      title={props.options.title}
      pagination={false}
      actions={
        props.options.isSystem ? (
          <ListActions {...props} />
        ) : (
          <ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions {...props} />} />
        )
      }
    >
      <DataGrid
        onRowClicked={onRowClicked}
        measurementId={measurementId}
        isSystem={props.options.isSystem}
        columnDefs={columnDefs}
      />
    </List>
  );
};
