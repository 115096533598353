import UEMSessionIcon from '@material-ui/icons/PeopleOutline';
import { UEMSessionCreate, UEMSessionEdit, UEMSessionShow } from './UEMSessionForm';
import UEMSessionList from './UEMSessionList';

export default {
  list: UEMSessionList,
  icon: UEMSessionIcon,
  show: UEMSessionShow,
  edit: UEMSessionEdit,
  create: UEMSessionCreate,
};
