import * as React from 'react';
import { useHistory } from 'react-router';
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  Create,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  ReferenceManyField,
  ReferenceInput,
  TextField,
  BooleanField,
  required,
  FunctionField,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import DataGrid from '../../../components/DataGrid';
import CrudWrapper from '../../../components/CrudWrapper';
import { sourceTab } from '../../../components/SourceTab';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import { AuditLog } from '../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import { AgGridColumn } from 'ag-grid-react';
import { dateFormatter } from '../../../utils/formatters';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import EditToolbar from '../../../components/EditToolbar';
import { dateColumnFilter, numberColumnFilter } from '../../../utils/filterParams';

const PageTitle = ({ record }) => {
  return <span>{record ? record.businessUnitName : ''}</span>;
};

const fields = (
  <SimpleForm redirect="show">
    <ReferenceInput source="parentBusinessUnitId" reference="businessunits">
      <SelectInput optionText="businessUnitName" />
    </ReferenceInput>
    <TextInput source="businessUnitName" validate={required()} label="businessUnitName" />
    <TextInput source="city" validate={required()} label="city" />
    <TextInput source="country" validate={required()} label="country" />
    <TextInput source="continent" validate={required()} label="continent" />
    <TextInput source="region" validate={required()} label="region" />
    <TextInput halfWidth source="languageTag" label="languageTag" />
    <BooleanInput halfWidth source="isDefaultBusinessUnit" label="isDefaultBusinessUnit" />
    <FileUpload source="documents" />
  </SimpleForm>
);

export const BusinessUnitShow = (props) => {
  const history = useHistory();
  const classificationRuleIndicator = 0;
  const qualificationRuleIndicator = 1;

  const onUserRowClicked = (id) => {
    const url = `/users/${id}/show`;
    history.push(url);
  };

  const onDeviceRowClicked = (id) => {
    const url = `/devices/${id}/show`;
    history.push(url);
  };

  const classificationRuleValueGetter = (params) => {
    return params.data.rules
      .filter((rule) => rule.ruleIndicator === classificationRuleIndicator)
      .map((rule) => rule.ruleTag)
      .join(', ');
  };
  const qualificationRuleValueGetter = (params) => {
    return params.data.rules
      .filter((rule) => rule.ruleIndicator === qualificationRuleIndicator)
      .map((rule) => rule.ruleTag)
      .join(', ');
  };

  const [hasDocuments, setHasDocuments] = React.useState(false);

  const fields = (
    <TabbedShowLayout>
      <Tab label="Basic">
        <ReferenceField source="parentBusinessUnitId" reference="businessunits">
          <TextField source="businessUnitName" />
        </ReferenceField>
        <ReferenceField source="organizationId" reference="organizations">
          <TextField source="organizationName" />
        </ReferenceField>
        <TextField source="businessUnitTag" label="businessUnitTag" />
        <TextField source="businessUnitName" label="businessUnitName" />
        <TextField source="city" label="city" />
        <TextField source="country" label="country" />
        <TextField source="continent" label="continent" />
        <TextField source="region" label="region" />
        <TextField halfWidth source="languageTag" label="languageTag" />
        <BooleanField halfWidth source="isDefaultBusinessUnit" label="isDefaultBusinessUnit" />
      </Tab>
      <Tab label="Users">
        <ReferenceManyField reference="users" target="businessunit" addLabel={false}>
          <DataGrid source="users" rowDoubleClicked={onUserRowClicked}>
            <AgGridColumn headerName="Claims">
              <AgGridColumn field="userTag" />
              <AgGridColumn field="userName" />
              <AgGridColumn field="principalName" />
              <AgGridColumn field="displayName" />
              <AgGridColumn field="distinguishedName" />
              <AgGridColumn field="normalizedName" />
              <AgGridColumn field="emailAddress" />
              <AgGridColumn field="isDuplicate" />
            </AgGridColumn>
            <AgGridColumn headerName="Properties">
              <AgGridColumn field="domain" />
              <AgGridColumn field="ouPath" />
              <AgGridColumn field="firstName" />
              <AgGridColumn field="lastName" />
              <AgGridColumn field="maidenName" />
            </AgGridColumn>
            <AgGridColumn headerName="Timestamps">
              <AgGridColumn
                field="lastActivityDate"
                valueFormatter={dateFormatter}
                filter={'agMultiColumnFilter'}
                filterParams={dateColumnFilter}
              />
              <AgGridColumn
                field="daysSinceLastActivity"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn
                field="whenCreatedDate"
                valueFormatter={dateFormatter}
                filter={'agMultiColumnFilter'}
                filterParams={dateColumnFilter}
              />
              <AgGridColumn field="daysSinceCreated" />
              <AgGridColumn
                field="collectionDate"
                valueFormatter={dateFormatter}
                filter={'agMultiColumnFilter'}
                filterParams={dateColumnFilter}
              />
            </AgGridColumn>
            <AgGridColumn headerName="Classifications">
              <AgGridColumn
                field="softwareRule"
                headerName={'Classification rules'}
                valueGetter={(e) => classificationRuleValueGetter(e)}
              />
              <AgGridColumn
                field="softwareRule"
                headerName={'Qualification rules'}
                valueGetter={(e) => qualificationRuleValueGetter(e)}
              />
              <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
              <AgGridColumn {...useAgGridEnumCol('userscopes', 'userScope')} />
              <AgGridColumn {...useAgGridEnumCol('userroles', 'userRole')} />
              <AgGridColumn {...useAgGridEnumCol('usertypes', 'userType')} />
              <AgGridColumn {...useAgGridEnumCol('userclasses', 'userClass')} />
              <AgGridColumn {...useAgGridEnumCol('userstates', 'userState')} />
              <AgGridColumn {...useAgGridEnumCol('userstatuses', 'userStatus')} />
            </AgGridColumn>
            <AgGridColumn headerName="Organization">
              <AgGridColumn field="businessUnitName" />
              <AgGridColumn field="city" />
              <AgGridColumn field="country" />
              <AgGridColumn field="region" />
            </AgGridColumn>
            <AgGridColumn headerName="Annotanions">
              <AgGridColumn field="notes" />
              <AgGridColumn field="interpretations" />
              <AgGridColumn field="feedback" />
            </AgGridColumn>
            <AgGridColumn headerName="Sources">
              <AgGridColumn
                headerName="Created by"
                field="sources"
                valueGetter={(e) => createdBy(e)}
              />
              <AgGridColumn
                field="sources"
                headerName="Updated by"
                valueGetter={(e) => updatedBy(e)}
              />
            </AgGridColumn>
          </DataGrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Devices">
        <ReferenceManyField reference="devices" target="businessunit" addLabel={false}>
          <DataGrid source="devices" rowDoubleClicked={onDeviceRowClicked}>
            <AgGridColumn headerName="Claims">
              <AgGridColumn field="deviceName" />
              <AgGridColumn field="deviceTag" />
              <AgGridColumn field="distinguishedName" />
              <AgGridColumn field="fullyQualifiedDomainName" />
            </AgGridColumn>
            <AgGridColumn headerName="Properties">
              <AgGridColumn field="domain" />
              <AgGridColumn field="ouPath" />
              <AgGridColumn field="manufacturer" />
              <AgGridColumn field="operatingSystem" />
              <AgGridColumn field="version" />
              <AgGridColumn
                field="physicalCores"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn
                field="physicalProcessors"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn
                field="totalCores"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
            </AgGridColumn>
            <AgGridColumn headerName="Timestamps">
              <AgGridColumn
                field="lastActivityDate"
                valueFormatter={dateFormatter}
                filter={'agMultiColumnFilter'}
                filterParams={dateColumnFilter}
              />
              <AgGridColumn
                field="daysSinceLastActivity"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn
                field="whenCreatedDate"
                valueFormatter={dateFormatter}
                filter={'agMultiColumnFilter'}
                filterParams={dateColumnFilter}
              />
              <AgGridColumn field="daysSinceCreated" />
              <AgGridColumn
                field="collectionDate"
                valueFormatter={dateFormatter}
                filter={'agMultiColumnFilter'}
                filterParams={dateColumnFilter}
              />
            </AgGridColumn>
            <AgGridColumn headerName="Classifications">
              <AgGridColumn
                field="softwareRule"
                headerName={'Classification rules'}
                valueGetter={(e) => classificationRuleValueGetter(e)}
              />
              <AgGridColumn
                field="softwareRule"
                headerName={'Qualification rules'}
                valueGetter={(e) => qualificationRuleValueGetter(e)}
              />
              <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
              <AgGridColumn {...useAgGridEnumCol('devicescopes', 'deviceScope')} />
              <AgGridColumn {...useAgGridEnumCol('deviceroles', 'deviceRole')} />
              <AgGridColumn {...useAgGridEnumCol('devicetypes', 'deviceType')} />
              <AgGridColumn {...useAgGridEnumCol('deviceclasses', 'deviceClass')} />
              <AgGridColumn {...useAgGridEnumCol('devicestates', 'deviceState')} />
              <AgGridColumn {...useAgGridEnumCol('devicestatuses', 'deviceStatus')} />
            </AgGridColumn>
            <AgGridColumn headerName="Organization">
              <AgGridColumn field="businessUnitName" />
              <AgGridColumn field="city" />
              <AgGridColumn field="country" />
              <AgGridColumn field="region" />
            </AgGridColumn>
            <AgGridColumn headerName="Annotanions">
              <AgGridColumn field="notes" />
              <AgGridColumn field="interpretations" />
              <AgGridColumn field="feedback" />
            </AgGridColumn>
            <AgGridColumn headerName="Sources">
              <AgGridColumn
                headerName="Created by"
                field="sources"
                valueGetter={(e) => createdBy(e)}
              />
              <AgGridColumn
                field="sources"
                headerName="Updated by"
                valueGetter={(e) => updatedBy(e)}
              />
            </AgGridColumn>
          </DataGrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Observations">
        <RuleDisplayObservation />
      </Tab>
      <Tab label="Audit">
        <AuditLog resource={props.resource} id={props.id} />
      </Tab>
      {sourceTab}
      {hasDocuments && (
        <Tab label="Linked Documents">
          <SimpleForm toolbar={<EmptyToolbar />}>
            <FileUpload source="documents" show />
          </SimpleForm>
        </Tab>
      )}
      <FunctionField
        style={{ diplay: 'none' }}
        label=""
        render={(record) => setHasDocuments(record?.documents?.length > 0)}
      />
    </TabbedShowLayout>
  );
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      {fields}
    </Show>
  );
};

export const BusinessUnitEdit = (props) => (
  <CrudWrapper>
    <Edit undoable={false} title={<PageTitle />} {...props}>
      <SimpleForm redirect="show" toolbar={<EditToolbar message={'business unit'} />}>
        <ReferenceInput source="parentBusinessUnitId" reference="businessunits">
          <SelectInput optionText="businessUnitName" />
        </ReferenceInput>
        <TextInput source="businessUnitName" validate={required()} label="businessUnitName" />
        <TextInput source="city" validate={required()} label="city" />
        <TextInput source="country" validate={required()} label="country" />
        <TextInput source="continent" validate={required()} label="continent" />
        <TextInput source="region" validate={required()} label="region" />
        <TextInput halfWidth source="languageTag" label="languageTag" />
        <BooleanInput halfWidth source="isDefaultBusinessUnit" label="isDefaultBusinessUnit" />
        <FileUpload source="documents" />
      </SimpleForm>
    </Edit>
  </CrudWrapper>
);

export const BusinessUnitCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {fields}
    </Create>
  </CrudWrapper>
);
