import EPPUsageIcon from '@material-ui/icons/Security';
import { EPPUsageCreate, EPPUsageEdit, EPPUsageShow } from './EPPUsageForm';
import EPPUsageList from './EPPUsageList';

export default {
  list: EPPUsageList,
  icon: EPPUsageIcon,
  show: EPPUsageShow,
  edit: EPPUsageEdit,
  create: EPPUsageCreate,
};
