import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';

import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';
import { dateFormatter } from '../../../utils/formatters';
import { dateColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Products" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="composedProductName" />
          <AgGridColumn field="manufacturerName" />
          <AgGridColumn field="productName" />
          <AgGridColumn field="version" />
          <AgGridColumn field="edition" />
          <AgGridColumn field="productType" />
          <AgGridColumn field="buildNumber" columnGroupShow="open" />
          <AgGridColumn field="productPool" columnGroupShow="open" />
          <AgGridColumn field="productCategory" columnGroupShow="open" />
          <AgGridColumn field="productGroup" />
          <AgGridColumn field="productFamily" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Properties">
          <AgGridColumn field="isLicensable" />
          <AgGridColumn field="trackUsage" />
          <AgGridColumn field="isOperatingSystem" />
          <AgGridColumn field="isServerRelated" columnGroupShow="open" />
          <AgGridColumn field="hasUpgradePaths" columnGroupShow="open" />
          <AgGridColumn field="hasDowngradePaths" columnGroupShow="open" />
          <AgGridColumn field="hasComponents" columnGroupShow="open" />
          <AgGridColumn field="excludeFromReports" columnGroupShow="open" />
          <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'defaultLicenseMetric')} />
          <AgGridColumn field="minimumRequiredLicenseQuantity" />
          <AgGridColumn field="hasComponents" />
        </AgGridColumn>
        <AgGridColumn headerName="Timestamps">
          <AgGridColumn
            field="releaseDate"
            valueFormatter={dateFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={dateColumnFilter}
          />
          <AgGridColumn
            field="mainstreamSupportEndDate"
            valueFormatter={dateFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={dateColumnFilter}
            columnGroupShow="open"
          />
          <AgGridColumn
            field="extendedSupportEndDate"
            valueFormatter={dateFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={dateColumnFilter}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Annotanions">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
