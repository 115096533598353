import * as React from 'react';
import {
  Edit,
  TextInput,
  Create,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  required,
  NumberInput,
  NumberField,
  NullableBooleanInput,
  BooleanField,
  DateField,
  FunctionField,
} from 'react-admin';
import FilteringProducts from '../../../components/FilteringProducts';
import { Annotations } from '../../../components/Annotations';
import { NullableDateInput } from '../../../components/NullableDateInput';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import { formatPrice } from '../../../utils/formatters';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.legalEntity : ''}</span>;
};

const fields = (
  <AccordionForm redirect="show" toolbar={<EditToolbar message={'pricelist'} />}>
    <AccordionFormPanel label="Basic">
      <TextInput source="pricelistSource" label="Pricelist Source" validate={required()} />
      <TextInput source="pricelistType" label="pricelist Type" validate={required()} />
      <TextInput source="offering" label="offering" validate={required()} />
      <TextInput source="sku" label="sku" validate={required()} />
      <TextInput source="license" label="license" />
      <TextInput source="purchasePeriod" label="purchasePeriod" validate={required()} />
    </AccordionFormPanel>
    <AccordionFormPanel label="Product">
      <FilteringProducts source="productId" />
      <TextInput source="manufacturerModel" label="Manufacturer model" validate={required()} />
    </AccordionFormPanel>
    <AccordionFormPanel label="Metrics">
      <NewEnumInput source="licenseMetric" reference="licensemetrics" />
      <NewEnumInput source="licenseModel" reference="licensemodels" />
      <NumberInput source="packageQuantity" label="Package Quantity" />
      <NumberInput source="measureUnit" label="Measure unit" />
      <NullableBooleanInput source="isPlatform" label="Is platform" />
      <NullableBooleanInput source="isFromSA" label="is From SA" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Prices">
      <TextInput source="netPriceLevelA" />
      <TextInput source="netPriceLevelB" />
      <TextInput source="netPriceLevelC" />
      <TextInput source="netPriceLevelD" />
      <TextInput source="retailPriceLevelA" />
      <TextInput source="retailPriceLevelB" />
      <TextInput source="retailPriceLevelC" />
      <TextInput source="retailPriceLevelD" />
      <NullableDateInput source="priceValidFrom" label="Price Valid From" />
      <NullableDateInput source="priceValidUntil" label="Price Valid Until" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput halfWidth source="notes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput halfWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput halfWidth source="feedback" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const PriceListShow = (props) => (
  <Show title={<PageTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="pricelistSource" label="Pricelist Source" />
        <TextField source="pricelistType" label="pricelist Type" />
        <TextField source="offering" label="offering" />
        <TextField source="sku" label="sku" />
        <TextField source="license" label="license" />
        <TextField source="purchasePeriod" label="purchasePeriod" />
      </Tab>
      <Tab label="Product">
        <TextField source="manufacturerModel" label="Manufacturer model" />
        <TextField source="manufacturer" label="Manufacturer Name" />
        <TextField source="productName" label="Product Name" />
        <TextField source="edition" label="Edition" />
        <TextField source="version" label="Version" />
      </Tab>
      <Tab label="Metrics">
        <NewEnumField source="licenseMetric" reference="licensemetrics" />
        <NewEnumField source="licenseModel" reference="licensemodels" />
        <NumberField source="packageQuantity" label="Package Quantity" />
        <NumberField source="measureUnit" label="Measure unit" />
        <BooleanField source="isPlatform" label="Is platform" />
        <BooleanField source="isFromSA" label="is From SA" />
      </Tab>
      <Tab label="Prices">
        {getPriceField('netPriceLevelA', 'Net Price Level A')}
        {getPriceField('netPriceLevelB', 'Net Price Level B')}
        {getPriceField('netPriceLevelC', 'Net Price Level C')}
        {getPriceField('netPriceLevelD', 'Net Price Level D')}
        {getPriceField('retailPriceLevelA', 'Retail Price Level A')}
        {getPriceField('retailPriceLevelB', 'Retail Price Level B')}
        {getPriceField('retailPriceLevelC', 'Retail Price Level C')}
        {getPriceField('retailPriceLevelD', 'Retail Price Level D')}
        <DateField source="priceValidFrom" label="Price Valid From" />
        <DateField source="priceValidUntil" label="Price Valid Until" />
      </Tab>
      <Tab label="Annotations">
        <Annotations />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const getPriceField = (source, label) => {
  return <FunctionField label={label} render={(value) => formatPrice(value[source])} />;
};

export const PriceListEdit = (props) => (
  <Edit undoable={false} title={<PageTitle />} {...props}>
    {fields}
  </Edit>
);

export const PriceListCreate = (props) => (
  <Create title="New" {...props}>
    {fields}
  </Create>
);
