import OrganizationalUnitIcon from '@material-ui/icons/ControlCamera';
import OrganizationalUnitList from './OrganizationalUnitList';
import { OrganizationalUnitShow, OrganizationalUnitEdit, OrganizationalUnitCreate } from "./OrganizationalUnitForm";

export default {
  list: OrganizationalUnitList,
  icon: OrganizationalUnitIcon,
  show: OrganizationalUnitShow,
  edit: OrganizationalUnitEdit,
  create: OrganizationalUnitCreate,
  
};
