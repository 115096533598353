const DateTimeFormatter = ({ date }) => {
  const thisDate = new Date(date);
  const dd = thisDate.getDate();
  const mm = thisDate.getMonth();
  const yyyy = thisDate.getFullYear();

  const hr = thisDate.getHours();
  const min = thisDate.getMinutes();

  const pad = (value) => {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  };

  return `${pad(dd)}.${pad(mm + 1)}.${yyyy}. ${pad(hr)}:${pad(min)}`;
};

export default DateTimeFormatter;
