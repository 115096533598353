import CompliancePositionIcon from '@material-ui/icons/Flare';
import CompliancePositionList from './CompliancePositionList';
import {
  CompliancePositionShow,
  CompliancePositionEdit,
  CompliancePositionCreate,
} from './CompliancePositionForm';

export default {
  list: CompliancePositionList,
  show: CompliancePositionShow,
  edit: CompliancePositionEdit,
  create: CompliancePositionCreate,
  icon: CompliancePositionIcon,
};
