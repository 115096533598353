import React from 'react';
import {useState} from 'react';
import SubMenu from './SubMenu';
import {makeStyles} from '@material-ui/core/styles';

import MeasurementIcon from '@material-ui/icons/TrendingUpTwoTone';
import OrganizationIcon from '@material-ui/icons/ApartmentTwoTone';
import DeploymentsIcon from '@material-ui/icons/DevicesOtherTwoTone';
import LicensesIcon from '@material-ui/icons/StarsTwoTone';
import ObservationsIcon from '@material-ui/icons/LinkedCameraTwoTone';
import ReferencesIcon from '@material-ui/icons/SearchTwoTone';
import RulesIcon from '@material-ui/icons/MenuBookTwoTone';
import ServerAppsIcon from '@material-ui/icons/DnsTwoTone';
import UserAppsIcon from '@material-ui/icons/ContactsTwoTone';
import SettingsIcon from '@material-ui/icons/Settings';

// ORGANIZATIONS
import Organizations from './../containers/Organizations/OrganizationSet/Organization';
import BusinessUnits from './../containers/Organizations/BusinessUnit';
import Agreements from '../containers/Organizations/Agreements/Agreement';
import Users from './../containers/Organizations/User';
import PricingOptions from './../containers/Organizations/PricingOptions';
import Devices from './../containers/Organizations/Device';
import Purchases from './../containers/Organizations/Purchases';
import PurchaseLines from './../containers/Organizations/PurchaseLines';

// MEASUREMENTS
import Projects from './../containers/Measurements/ProjectSet/Project';
import Measurements from './../containers/Measurements/Measurement';
import ADGroups from './../containers/Measurements/ADGroup';
import Sources from './../containers/Measurements/Source';
import OrganizationalUnits from './../containers/Measurements/OrganizationalUnit';

// DEPLOYMENTS
import Hardware from './../containers/Deployments/Hardware';
import Virtual from './../containers/Deployments/Virtual';
import UserSoftware from './../containers/Deployments/UserSoftware';
import DeviceSoftware from './../containers/Deployments/DeviceSoftware';

// COMPLIANCY
import Entitlements from './../containers/Compliancy/Entitlement';
import LicensePosition from '../containers/Compliancy/LicensePosition';
import CompliancePosition from '../containers/Compliancy/CompliancePosition';

// OBSERVATIONS
import Observations from './../containers/Observations/Observation';
import MeasurementObservations from '../containers/Observations/MeasurementObservation';

// RULES
import UserClassificationRuleIcon from '@material-ui/icons/Assignment';
import UserQualificationRuleIcon from '@material-ui/icons/PlaylistAddCheck';
import SoftwareRuleIcon from '@material-ui/icons/Tune';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import DeviceClassificationRuleIcon from '@material-ui/icons/Devices';

// REFERENCES
import Products from './../containers/References/Product';
import ProductComponents from './../containers/References/ProductComponent';
import ProductPaths from './../containers/References/ProductPath';
import Fingerprints from './../containers/References/Fingerprint';
import Manufacturers from './../containers/References/Manufacturer';
import Suppliers from './../containers/References/Supplier';

// SERVER APPLICATIONS
import MailServers from './../containers/ServerApps/MailServers';

// USER APPLICATIONS
import MailUsage from './../containers/UserApps/MailUsage';
import UserAccess from './../containers/UserApps/UserAccess';

import {useSelector} from 'react-redux';
import {useTranslate, MenuItemLink, usePermissions} from 'react-admin';

import {Box} from '@material-ui/core';
import Domains from '../containers/Organizations/Domains';
import HostSoftware from '../containers/Deployments/HostSoftware';
import WindowsServer from '../containers/ServerApps/WindowsServer';
import CommunicationServer from '../containers/ServerApps/CommunicationServer';
import DatabaseServer from '../containers/ServerApps/DatabaseServer';
import ITSMServer from '../containers/ServerApps/ITSMServer';
import DeviceAccess from '../containers/UserApps/DeviceAccess';
import UEMSession from '../containers/UserApps/UEMSession';
import ITSMUsage from '../containers/UserApps/ITSMUsage';
import CollaborationServer from '../containers/ServerApps/CollaborationServer';
import MessagebrokerServer from '../containers/ServerApps/MessagebrokerServer';
import MSDNUsage from '../containers/UserApps/MSDNUsage';
import CommunicationUsage from '../containers/UserApps/CommunicationUsage';
import CollaborationUsage from '../containers/UserApps/CollaborationUsage';
import EPPUsage from '../containers/UserApps/EPPUsage';
import PriceList from '../containers/References/PriceList';
import Articles from '../containers/References/Articles';

const Menu = ({onMenuClick, logout, dense = false}) => {
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const translate = useTranslate();
    const {permissions} = usePermissions();

    const [state, setState] = useState({
        menuMeasurements: true,
        menuOrganisations: false,
        menuLicenses: false,
        menuDeployments: false,
        menuServerApplications: false,
        menuUserApplications: false,
        menuProjectRules: false,
        menuSystemRules: false,
        menuRules: false,
        menuReferences: false,
        menuAdmin: false,
    });

    const handleToggle = (menu) => {
        setState((state) => ({...state, [menu]: !state[menu]}));
    };

    const useStyles = makeStyles({
        active: {
            color: '#f51257',
            fontWeight: 'bold',
        },
    });

    const classes = useStyles();

    return (
        <Box mt={1}>
            {' '}
            <SubMenu
                name="Measurements"
                to="/"
                handleToggle={() => handleToggle('menuMeasurements')}
                isOpen={state.menuMeasurements}
                sidebarIsOpen={open}
                icon={<MeasurementIcon/>}
            >
                <MenuItemLink
                    to={'/projects'}
                    leftIcon={<Projects.icon/>}
                    primaryText={translate(`Projects`)}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/measurements'}
                    leftIcon={<Measurements.icon/>}
                    primaryText={translate(`Measurements`)}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/adgroups'}
                    leftIcon={<ADGroups.icon/>}
                    primaryText={translate('AD Groups')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/organizationalunits'}
                    leftIcon={<OrganizationalUnits.icon/>}
                    primaryText={translate('Organizational Units')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />

                <MenuItemLink
                    to={'/sources'}
                    leftIcon={<Sources.icon/>}
                    primaryText={translate('Sources')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
            </SubMenu>
            <SubMenu
                name="Organizations"
                to={'/organizations'}
                handleToggle={() => handleToggle('menuOrganisations')}
                isOpen={state.menuOrganisations}
                sidebarIsOpen={open}
                icon={<OrganizationIcon/>}
            >
                <MenuItemLink
                    to={'/organizations'}
                    leftIcon={<Organizations.icon/>}
                    primaryText={translate('Organizations')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                {permissions === 'admin' && (
                    <MenuItemLink
                        to={'/smartSaasClients'}
                        leftIcon={<Users.icon/>}
                        primaryText={translate('SmartSaaS Clients')}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                        classes={classes}
                    />)}
                <MenuItemLink
                    to={'/businessunits'}
                    leftIcon={<BusinessUnits.icon/>}
                    primaryText={translate('Business Units')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/agreements'}
                    leftIcon={<Agreements.icon/>}
                    primaryText="Agreements"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />

                <MenuItemLink
                    to={'/users'}
                    leftIcon={<Users.icon/>}
                    primaryText="Users"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/devices'}
                    leftIcon={<Devices.icon/>}
                    primaryText="Devices"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/domains'}
                    leftIcon={<Domains.icon/>}
                    primaryText="Domains"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/pricingoptions'}
                    leftIcon={<PricingOptions.icon/>}
                    primaryText="Pricing Options"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/purchases'}
                    leftIcon={<Purchases.icon/>}
                    primaryText="Purchases"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/purchaselines'}
                    leftIcon={<PurchaseLines.icon/>}
                    primaryText="Purchase Lines"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                name="Compliancy"
                to="/licenses"
                handleToggle={() => handleToggle('menuLicenses')}
                isOpen={state.menuLicenses}
                sidebarIsOpen={open}
                icon={<LicensesIcon/>}
            >
                <MenuItemLink
                    to={'/entitlements'}
                    leftIcon={<Entitlements.icon/>}
                    primaryText="Entitlements"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />

                <MenuItemLink
                    to={'/licensepositions'}
                    leftIcon={<LicensePosition.icon/>}
                    primaryText={translate('License Positions')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/compliancepositions'}
                    leftIcon={<CompliancePosition.icon/>}
                    primaryText={translate('Compliance Positions')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
            </SubMenu>
            <SubMenu
                name="Deployments"
                to="/"
                handleToggle={() => handleToggle('menuDeployments')}
                isOpen={state.menuDeployments}
                sidebarIsOpen={open}
                icon={<DeploymentsIcon/>}
            >
                <MenuItemLink
                    to={'/hardware'}
                    leftIcon={<Hardware.icon/>}
                    primaryText="Hardware"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/virtual'}
                    leftIcon={<Virtual.icon/>}
                    primaryText="Virtual"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/usersoftware'}
                    leftIcon={<UserSoftware.icon/>}
                    primaryText="User Software"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/devicesoftware'}
                    leftIcon={<DeviceSoftware.icon/>}
                    primaryText="Device Software"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/hostsoftware'}
                    leftIcon={<HostSoftware.icon/>}
                    primaryText="Host Software"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
            </SubMenu>
            <SubMenu
                name="Server Applications"
                to="/"
                handleToggle={() => handleToggle('menuServerApplications')}
                isOpen={state.menuServerApplications}
                sidebarIsOpen={open}
                icon={<ServerAppsIcon/>}
            >
                <MenuItemLink
                    to={'/mailservers'}
                    leftIcon={<MailServers.icon/>}
                    primaryText="Mail Servers"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/windowsservers'}
                    leftIcon={<WindowsServer.icon/>}
                    primaryText="Windows Servers"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/communicationservers'}
                    leftIcon={<CommunicationServer.icon/>}
                    primaryText="Communication Servers"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/collaborationservers'}
                    leftIcon={<CollaborationServer.icon/>}
                    primaryText="Collaboration Servers"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/messagebrokerservers'}
                    leftIcon={<MessagebrokerServer.icon/>}
                    primaryText="Messagebroker Servers"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/databaseservers'}
                    leftIcon={<DatabaseServer.icon/>}
                    primaryText="Database Servers"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/itsmservers'}
                    leftIcon={<ITSMServer.icon/>}
                    primaryText="ITSM Servers"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
            </SubMenu>
            <SubMenu
                name="User Applications"
                to="/"
                handleToggle={() => handleToggle('menuUserApplications')}
                isOpen={state.menuUserApplications}
                sidebarIsOpen={open}
                icon={<UserAppsIcon/>}
            >
                <MenuItemLink
                    to={'/mailusage'}
                    leftIcon={<MailUsage.icon/>}
                    primaryText="Mail Usage"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/useraccess'}
                    leftIcon={<UserAccess.icon/>}
                    primaryText="User Access"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/deviceaccess'}
                    leftIcon={<DeviceAccess.icon/>}
                    primaryText="Device Access"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/uemsession'}
                    leftIcon={<UEMSession.icon/>}
                    primaryText="UEM Session"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/itsmusage'}
                    leftIcon={<ITSMUsage.icon/>}
                    primaryText="ITSM Usage"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/eppusage'}
                    leftIcon={<EPPUsage.icon/>}
                    primaryText="EPP Usage"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/msdnusage'}
                    leftIcon={<MSDNUsage.icon/>}
                    primaryText="MSDN Usage"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/communicationusage'}
                    leftIcon={<CommunicationUsage.icon/>}
                    primaryText="Communication Usage"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/collaborationusage'}
                    leftIcon={<CollaborationUsage.icon/>}
                    primaryText="Collaboration Usage"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
            </SubMenu>
            <SubMenu
                name="Observations"
                to="/"
                handleToggle={() => handleToggle('menuObservations')}
                isOpen={state.menuObservations}
                sidebarIsOpen={open}
                icon={<ObservationsIcon/>}
            >
                <MenuItemLink
                    to={'/measurementobservations'}
                    leftIcon={<MeasurementObservations.icon/>}
                    primaryText={translate('Observations')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
            </SubMenu>
            <SubMenu
                name="Global Business Rules"
                to="/globalrules"
                handleToggle={() => handleToggle('menuSystemRules')}
                isOpen={state.menuSystemRules}
                sidebarIsOpen={open}
                icon={<RulesIcon/>}
            >
                <MenuItemLink
                    to={'/globaluserclassificationrules'}
                    leftIcon={<UserClassificationRuleIcon/>}
                    primaryText="User Classification"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/globaluserqualificationrules'}
                    leftIcon={<UserQualificationRuleIcon/>}
                    primaryText="User Qualification"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/globaldeviceclassificationrules'}
                    leftIcon={<DeviceClassificationRuleIcon/>}
                    primaryText="Device Classification"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/globaldevicequalificationrules'}
                    leftIcon={<DeviceClassificationRuleIcon/>}
                    primaryText="Device Qualification"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/globalsoftwarerules'}
                    leftIcon={<SoftwareRuleIcon/>}
                    primaryText="Software Rules"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/globalfingerprintrules'}
                    leftIcon={<FingerprintIcon/>}
                    primaryText="Fingerprint Rules"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
            </SubMenu>
            <SubMenu
                name="Custom Business Rules"
                to="/rules"
                handleToggle={() => handleToggle('menuRules')}
                isOpen={state.menuRules}
                sidebarIsOpen={open}
                icon={<RulesIcon/>}
            >
                <MenuItemLink
                    to={'/userclassificationrules'}
                    leftIcon={<UserClassificationRuleIcon/>}
                    primaryText="User Classification"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/userqualificationrules'}
                    leftIcon={<UserQualificationRuleIcon/>}
                    primaryText="User Qualification"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/deviceclassificationrules'}
                    leftIcon={<DeviceClassificationRuleIcon/>}
                    primaryText="Device Classification"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/devicequalificationrules'}
                    leftIcon={<DeviceClassificationRuleIcon/>}
                    primaryText="Device Qualification"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/softwarerules'}
                    leftIcon={<SoftwareRuleIcon/>}
                    primaryText="Software Rules"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
            </SubMenu>
            <SubMenu
                name="References"
                to="/references"
                handleToggle={() => handleToggle('menuReferences')}
                isOpen={state.menuReferences}
                sidebarIsOpen={open}
                icon={<ReferencesIcon/>}
            >
                <MenuItemLink
                    to={'/products'}
                    leftIcon={<Products.icon/>}
                    primaryText={translate('Products')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/productcomponents'}
                    leftIcon={<ProductComponents.icon/>}
                    primaryText={translate('Product Components')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />{' '}
                <MenuItemLink
                    to={'/productpaths'}
                    leftIcon={<ProductPaths.icon/>}
                    primaryText={translate('Product Paths')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />{' '}
                <MenuItemLink
                    to={'/fingerprints'}
                    leftIcon={<Fingerprints.icon/>}
                    primaryText={translate('Fingerprints')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />{' '}
                <MenuItemLink
                    to={'/manufacturers'}
                    leftIcon={<Manufacturers.icon/>}
                    primaryText={translate('Manufacturers')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />{' '}
                <MenuItemLink
                    to={'/suppliers'}
                    leftIcon={<Suppliers.icon/>}
                    primaryText={translate('Suppliers')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/pricelists'}
                    leftIcon={<PriceList.icon/>}
                    primaryText={translate('Price List')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
                <MenuItemLink
                    to={'/articles'}
                    leftIcon={<Articles.icon/>}
                    primaryText={translate('articles')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    classes={classes}
                />
            </SubMenu>
            {permissions === 'admin' && (
                <SubMenu
                    name="Administration"
                    to="/administration"
                    handleToggle={() => handleToggle('menuAdmin')}
                    isOpen={state.menuAdmin}
                    sidebarIsOpen={open}
                    icon={<SettingsIcon/>}
                >
                    <MenuItemLink
                        to={'/platformUsers'}
                        leftIcon={<Users.icon/>}
                        primaryText={translate('Platform Users')}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={'/baseobservation'}
                        leftIcon={<Observations.icon/>}
                        primaryText={translate('Observations')}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>
            )}
        </Box>
    );
};

export default Menu;
