import englishMessages from 'ra-language-english';

const customEnglishMessages = {
  ...englishMessages,

  // ORGANIZATIONS

  // Organization
  Organization: 'Organization',
  organizationName: 'Organization',
  databaseName: 'Database',
  addressLine1: 'Address line',
  addressLine2: 'Address line 2',
  postalCode: 'Postal Code',
  city: 'City',
  state: 'State',
  country: 'Country',
  continent: 'Continent',
  region: 'Region',
  corporateDomain: 'Corporate Domain',
  organizationScope: 'Scope',
  organizationType: 'Type',
  organizationClass: 'Class',
  organizationStatus: 'Status',
  organizationSize: 'Size',
  organizationAnnualRevenue: 'Annual Revenue',
  languageTag: 'Language',
  organizationRevenue: 'Organization revenue',
  projectScope: 'Project scope',
  projectType: 'Project type',
  projectClass: 'Project class',
  crm: 'CRM',
  status: 'Status',
  'organization.organizationName': 'Organization name',

  // Business Unit
  businessUnit: 'Business unit',
  businessUnitTag: 'Tag',
  businessUnitName: 'Business unit name',
  businessUnitId: 'Business unit ID',
  isDefaultBusinessUnit: 'Default business unit?',
  'parentBusinessUnit.businessUnitName': 'Parent business unit',

  // User
  userName: 'User Name',
  Timestamps: 'Timestamps',
  userTag: 'Tag',
  principalName: 'Principal Name',
  displayName: 'Display Name',
  normalizedName: 'Normalized Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  maidenName: 'Maiden Name',
  emailAddress: 'Email Address',
  userScope: 'Scope',
  userRole: 'Role',
  userType: 'Type',
  userClass: 'Class',
  userState: 'State',
  userStatus: 'Status',
  isDuplicate: 'Potential duplicate',
  undefined: 'undefined',
  lastActivityDate: 'Last Activity Date',
  daysSinceLastActivity: 'Days Since Last Activity',
  whenCreatedDate: 'Creation Date',
  ageOfRecord: 'Days Since Created',
  collectionDate: 'Collection Date',
  Classification: 'Classification',
  qualificationRules: 'Qualification rules',
  classificationRules: 'Classification rules',
  daysSinceCreated: 'Days since created',
  WhenCreatedDate: 'When created date',

  // Device
  'Device Name': 'Device Name',
  deviceTag: 'Tag',
  fullyQualifiedDomainName: 'Fully Qualified Domain Name',
  distinguishedName: 'Distinguished Name',
  context: 'Context',
  operatingSystem: 'Operating System',
  osManufacturer: 'OS manufacturer',
  operatingSystem_Manufacturer_ManufacturerKey: 'Manufacturer',
  osVersion: 'OS version',
  version: 'Version',
  deviceRole: 'Role',
  deviceType: 'Type',
  deviceClass: 'Class',
  deviceState: 'State',
  deviceStatus: 'Status',
  interpretations: 'Interpretations',
  notes: 'Notes',
  observations: 'Observations',
  flags: 'Observation Flags',
  deviceScope: 'Scope',
  totalCores: 'Total cores',

  //license

  licenseEdition: 'License edition',
  licenseProductCategory: 'License product category',
  licenseProductGroup: 'License product group',
  licenseProductId: 'License product ID',
  licenseProductName: 'License product name',
  licenseProductPool: 'License product pool',
  licenseVersion: 'License version',
  licenseType: 'License type',
  licenseProgram: 'License program',
  licenseFamily: 'License family',

  // Agreements
  agreementNumber: 'Agreement Number',
  agreementScope: 'Agreement Scope',
  agreementStatus: 'Agreement Status',
  agreementType: 'Agreement Type',
  signedByContractorContact: 'Signed By (Contractor)',
  signedByContractorJobTitle: 'Job Title (Contractor)',
  signedAtContractor: 'Signed At (Contractor)',
  signedByOrganizationContact: 'Signed By (Organization)',
  signedByOrganizationJobTitle: 'Job Title (Organization)',
  signedAtOrganization: 'Signed At (Organization)',
  parentAgreementNumber: 'Parent Agreement',
  previousAgreementNumber: 'Previous Agreement',
  anniversaryDate: 'Anniversary Date',
  isAutomaticRenewal: 'Automatic Renewal?',
  alertInDaysBeforeAnniversary: 'Anniversary Alert',
  alertInDaysBeforeRenewal: 'Renewal Alert',
  parentAgreement: 'Parent agreement',
  agreementClass: 'Agreement class',
  costCenter: 'Cost center',
  'Agreement Specific': 'Agreement specific',
  agreement: 'Agreement',
  agreementId: 'Agreement id',
  feedback: 'Feedback',
  futureUnitPriceD: 'Future unit price D',
  futureUnitPriceC: 'Future unit price C',
  futureUnitPriceB: 'Future unit price B',
  futureUnitPriceA: 'Future unit price A',
  measureUnitQuantity: 'Measure unit quantity',
  measureUnitCode: 'Measure unit code',
  usageTypeCode: 'Usage type code',
  usageStartDate: 'Usage start date',
  countryOfUsage: 'Country of usage',
  extendedAmount: 'Extended amount',
  netUnitPrice: 'Net unit price',
  packageQuantity: 'Package quantity',
  licenseQuantity: 'License quantity',
  currencyCode: 'Currency code',
  licenseModel: 'License model',
  licenseMetric: 'License metric',
  sku: 'SKU',
  nextAnniversaryDate: 'Next Anniversary Date',

  // MEASUREMENTS

  isArchived: 'Is archived',
  createdBy: 'Created by',
  updatedBy: 'Updated by',
  'Ready for import': 'Ready for import',
  'Click here to upload files': 'Click here to upload files',
  'No inventory trackers': 'No inventory trackers',
  'Export questionnaire': 'Export questionnaire',
  "Drag 'n' drop Inventory tracker files here, or click to select files":
    "Drag 'n' drop Inventory tracker files here, or click to select files",
  'File Name': 'File Name',
  'File Type': 'File Type',
  Date: 'Date',
  Progress: 'Progress',
  Status: 'Status',
  Reset: 'Reset',
  Import: 'Import',
  Edit: 'Edit',

  // Project
  startDate: 'Start Date',
  endDate: 'End Date',
  projectName: 'Project',
  reuseProjectRules: 'Reuse Customer/Project Business Rules from previous measurement',
  projectStatus: 'Status',
  responsible: 'Responsible',
  accountable: 'Acccountable',
  consulted: 'Consulted',
  informed: 'Informed',
  Projects: 'Projects',

  // Measurement
  Measurements: 'Measurements',
  measurementName: 'Measurement',
  plannedStartDate: 'Planned Start Date',
  plannedEndDate: 'Planned End Date',
  actualStartDate: 'Actual Start Date',
  actualendDate: 'Actal End Date',
  measurementGuid: 'Measurement id',
  actualEndDate: 'Actual end date',
  Measurement: 'Measurement',

  // Organizational Unit

  adObjectGuid: 'Object Guid',
  ouPath: 'OU Path',
  domain: 'Domain',
  measurementId: 'Measurement Id',

  // AD Group

  adGroupTag: 'Tag',
  adGroupName: 'Group Name',
  parentGroup: 'Parent Group',
  application: 'Application',
  organizationalUnit: 'OU Path',
  Claims: 'Claims',
  Annotations: 'Annotations',
  'user access lines!': 'user access lines!',
  Added: 'Added',

  //SOURCES
  Sources: 'Sources',
  sourceTag: 'Source tag',
  sourceFile: 'Source file',
  sourceCount: 'Source count',
  SourceType: 'Source type',
  sourceName: 'Source name',

  // DEPLOYMENTS

  // Hardware
  make: 'Make',
  Hardware: 'Hardware',
  model: 'Model',
  serialNumber: 'Serial Number',
  ipAddress: 'IP Address',
  macAddress: 'MAC Address',
  MACAddress: 'MAC Address',
  Specifications: 'Specifications',
  logicalCores: 'Logical Cores',
  Claim: 'Claim',
  physicalCores: 'Physical Cores',
  physicalProcessors: 'Physical Processors',
  logicalProcessors: 'Logical Processors',
  threads: 'Threads',
  'License Metric': 'License Metric',
  'License Model': 'License Model',
  'Install Type': 'Install Type',

  // Software
  softwareFileTag: 'Tag',
  installComposedProductName: 'Composed Installed Product',
  licenseComposedProductName: 'Composed Licensed Product',
  installProductName: 'Installed Product',
  licensedProductName: 'Licensed Product',
  installVersion: 'Installed Version',
  licensedVersion: 'Licesende Version',
  installEdition: 'Installed Edition',
  licensedEdition: 'Licensed Edition',
  installProductPool: 'Installed Product Pool',
  licensedProductPool: 'Licensed Product Pool',
  installProductCategory: 'Installed Product Category',
  licensedProductCategory: 'Licensed Product Category',
  installProductGroup: 'Installed Product Group',
  licensedProductGroup: 'Licensed Product Group',
  installProductFamily: 'Installed Product Family',
  licenseProductFamily: 'Licensed Product Family',
  installMetric: 'Installed Metric',
  licensedMetric: 'Licensed Metric',
  softwareTag: 'Software tag',
  installQuantity: 'Install quantity',
  installType: 'Install type',
  installSource: 'Install source',
  hasLiability: 'Has liability?',
  isMaintenanceRequired: 'Is maintenance required?',
  isCoveredByHostInstall: 'Is covered by host install?',
  isManual: 'Is manual?',
  isCostSavingsCandidate: 'Is cost savings candidate?',

  //FUNCTIONALITY
  'ra-form-layout.action.previous': 'Previous',
  'ra-form-layout.action.next': 'Next',
  'ra.navigation.skip_nav': 'navigation',

  // Product
  manufacturerName: 'Manufacturer',
  productName: 'Product',
  edition: 'Edition',
  productType: 'Product Type',
  buildNumber: 'Build Number',
  isLicensable: 'Licensable?',
  trackUsage: 'Track Usage?',
  isOperatingSystem: 'Operating System?',
  isServerRelated: 'Server related?',
  hasComponents: 'Has components?',
  excludeFromReports: 'Exluded from Reporting?',
  product: 'Product',
  productVersion: 'Product version',
  productEdition: 'Product edition',
  productManufacturer: 'Product manufacturer',
  defaultLicenseMetric: 'Default license metric',
  minimumRequiredLicenseQuantity: 'Minimum required license quantity',
  hasDowngradePaths: 'Has downgrade paths',
  hasUpgradePaths: 'Has upgrade paths',
  Edition: 'Edition',
  Version: 'Version',
  releaseDate: 'Release Date',
  mainstreamSupportEndDate: 'Mainstream Support Date',
  extendedSupportEndDate: 'Extended Support Date',

  productPool: 'Product Pool',
  productCategory: 'Product Category',
  productGroup: 'Product Group',
  productFamily: 'Product Family',
  suiteName: 'Suite name',

  // ProductComponent
  componentProduct: 'Component product',
  composedProductName: 'Composed Product',
  composedComponentName: 'Composed Component',
  componentName: 'Component',
  componentVersion: 'Component Version',
  componentType: 'Component Type',
  isRequired: 'Required?',
  sequenceNumber: 'Sequence number',
  suiteGroup: 'Suite group',

  // PRODUCT PATHS
  composedProductNameFrom: 'Composed Product From',
  composedProductNameTo: 'Composed Product To',
  productNameFrom: 'Product From',
  versionFrom: 'Version From',
  editionFrom: 'Edition From',
  sourceType: 'Source type',
  import: 'Import',
  toEdition: 'To edition',
  toVersion: 'To version',
  toProductName: 'To product name',
  'To Product': 'To product',
  'From Product': 'From Product',
  fromEdition: 'From edition',
  fromVersion: 'From version',
  fromProductName: 'From Product name',
  relativeVersion: 'Relative version',
  productPathType: 'Product path type',
  Source: 'Source',
  'Source Manufacturer': 'Source Manufacturer',
  'Source Product': 'Source Product',
  'Source Version': 'Source Version',
  'Composed Product': 'Composed Product',
  tag: 'tag',

  // Manufacturer
  manufacturerKey: 'Manufacturer',
  legalEntity: 'Legal entity',
  address: 'Address',

  // Supplier
  supplierKey: 'Supplier',

  // OBSERVATIONS

  // Observations
  observation: 'Observation',
  observationTag: 'Observation tag',
  organizationalUnitTag: 'Tag',
  observationName: 'Observation',
  observationType: 'Observation Type',
  observationAction: 'Observation Action',
  observationProof: 'Observation Proof',
  observationSource: 'Observation Source',
  observationTarget: 'Observation Target',
  Observation: 'Observation',
  'Observation Type': 'Observation Type',
  Action: 'Action',
  action: 'Action',

  // AGREEMENT LINES

  statusCode: 'Status code',
  typeCode: 'Type code',

  // Classification Rules

  classificationTag: 'Tag',
  classificationScope: 'Scope',
  classificationSource: 'Source',
  classificationType: 'Type',
  whereType: 'Object Type',
  whereOperator: 'Operator',
  whereObject: 'Object',
  whereCondition: 'Condition',
  isDisabled: 'Disabled ?',
  executionSequence: 'Execution Sequence',
  setTarget: 'Target',

  setEnvironment: 'Environment',
  setRole: 'Role',
  setType: 'Type',
  setClass: 'Class',
  setState: 'State',
  setStatus: 'Status',
  setBusinessUnit: 'Business Unit',
  setCity: 'City',
  setCountry: 'Country',
  setContinent: 'Continent',
  setRegion: 'Region',

  // Qualification Rules
  qualificationTag: 'Tag',
  qualificationScope: 'Scope',
  qualificationSource: 'Source',
  qualificationType: 'Type',

  setObservationType: 'Observation Type',
  setOobservation: 'Observation',
  setAction: 'Action',

  // Software Rules

  ruleTag: 'Tag',
  ruleScope: 'Scope',
  ruleSource: 'Source',
  ruleWhereType: 'Object Type',
  ruleWhereOperator: 'Operator',
  ruleWhereObject: 'Object',
  ruleWhereCondition: 'Condition',

  ruleSetHasLiability: 'Set Liability',
  ruleSetActiveMaintenanceRequired: 'Set Maintenance Required',
  ruleSetPrefix: 'Set Prefix',
  ruleSetSuffix: 'Set Suffix',
  ruleSetRole: 'Set Role',
  ruleSetInstallType: 'Set Install Type',
  ruleSetObservationType: 'Observation Type',
  ruleSetObservation: 'Observation',
  ruleSetAction: 'Action',

  estimatedOverspend: 'Estimated overspend',

  //MENU

  Organizations: 'Organizations',
  'AD Groups': 'AD Groups',
  'Organizational Units': 'Organizational Units',
  'Business Units': 'Business Units',
  'License Positions': 'License Positions',
  'Compliance Positions': 'Compliance Positions',
  Observations: 'Observations',
  Products: 'Products',
  'Product Components': 'Product Components',
  'Product Paths': 'Product Paths',
  Fingerprints: 'Fingerprints',
  Manufacturers: 'Manufacturers',
  Suppliers: 'Suppliers',
  Compliancy: 'Compliancy',
  Deployments: 'Deployments',
  'Server Applications': 'Server Applications',
  'User Applications': 'User Applications',
  'Global Business Rules': 'Global Business Rules',
  'Custom Business Rules': 'Custom Business Rules',
  References: 'References',
  'Platform Users': 'Platform Users',
  Administration: 'Administration',

  //DOMAINS
  forest: 'Forest',
  mode: 'Mode',
  win2KDomain: 'Win 2K domain',
  domainName: 'Domain name',
  primaryDomainController: 'Primary domain controller',
  PrimaryDomainController: 'Primary domain controller',
  DistinguishedName: 'Distinguished name',
  domainTag: 'Domain tag',
  domainTagSequence: 'Domain tag sequence',

  //ENTITLEMENTS
  Quantities: 'Quantities',
  availableQuantity: 'Available quantity',
  manualAllocatedQuantity: 'Manual allocated quantity',
  automaticAllocatedQuantity: 'Automatic allocated quantity',
  activeMaintenanceQuantity: 'Active maintenance quantity',
  initialMaintenanceQuantity: 'Initial maintenance quantity',
  upgradeWithMainenanceQuantity: 'Upgrade with mainenance quantity',
  upgradeQuantity: 'Upgrade quantity',
  manufacturer: 'Manufacturer',
  ID: 'id',
  maintenanceExpirationDate: 'Maintenance Expiration Date',
  isContract: 'Is contract?',

  //LICENSE POSITIONS
  Delta: 'Delta',
  positionAfterCorrectionQuantity: 'Position after correction quantity',
  correctionQuantity: 'Correction quantity',
  positionBeforeCorrectionQuantity: 'Position before correction quantity',
  activeMaintenanceDeploymentQuantity: 'Active maintenance deployment quantity',
  totalDeploymentQuantity: 'Total deployment quantity',
  manualDeploymentQuantity: 'Manual deployment quantity',
  userDeploymentQuantity: 'User deployment quantity',
  hostDeploymentQuantity: 'Host deployment quantity',
  virtualDeploymentQuantity: 'Virtual deployment quantity',
  physicalDeploymentQuantity: 'Physical deployment quantity',
  Licenses: 'Licenses',
  activeMaintenanceLicenseQuantity: 'Active maintenance license quantity',
  totalLicenseQuantity: 'Total license quantity',
  oemLicenseQuantity: 'OEM license quantity',
  retailicenseQuantity: 'Retai license quantity',
  recycledLicenseQuantity: 'Recycled license quantity',
  metric: 'Metric',
  volumeLicenseQuantity: 'Volume license quantity',
  Financials: 'Financials',
  actualOverspend: 'Actual overspend',
  actualShortfall: 'Actual shortfall',
  actualCostSavings: 'Actual cost savings',
  actualCostAvoidance: 'Actual cost avoidance',
  potentialCostSavings: 'Potential cost savings',
  estimatedShortfall: 'Estimated shortfall',
  extendedAmountPerUnit: 'Extended amount per unit',
  'Software Usage': 'Software Usage',
  incomplianceQuantity: 'Incompliance quantity',
  complianceQuantity: 'Compliance quantity',
  allocatedQuantity: 'Allocated quantity',
  potentialCostAvoidance: 'Potential cost avoidance',
  retailLicenseQuantity: 'Retail license quantity',
  upgradeLicenseQuantity: 'Upgrade license quantity',
  upgradeWithMaintenanceLicenseQuantity: 'Upgrade with maintenance license quantity',
  usageQuantity: 'Usage quantity',
  actualPurchaseQuantity: 'Actual purchase quantity',
  actualSoldQuantity: 'Actual sold quantity',
  estimatedPurchasePricePerUnit: 'Estimated purchase price per unit',
  actualPurchasePricePerUnit: 'Actual purchase price per unit',
  actualSellingPricePerUnit: 'Actual selling price per unit',

  //HARDWARE

  deviceTagSequence: 'Device tag sequence',
  ram: 'Ram',
  cpuSpeed: 'CPU speed',
  hasInstalls: 'Has installs?',

  //VIRTUAL

  Virtual: 'Virtual',
  vmConfigurationStatus: 'VM configuration status',
  vmState: 'VM state',
  host: 'Host',
  hostOS: 'Host OS',
  hostOSVersion: 'Host OS version',
  isHAEnabled: 'Is HAE enabled?',
  isMobilityEnabled: 'Is mobility enabled?',
  mobilityBehavior: 'Mobility behavior',
  virtualLayer: 'Virtual layer',

  // DEVICE SOFTWARE
  softwareRule: 'Software rule',
  'Install product': 'Install product',
  'License product': 'License product',
  Allocation: 'Allocation',
  installations: 'Installations',
  License: 'License',
  Quantity: 'Quantity',

  //HOST SOFTWARE

  virtualMachines: 'Virtual machines',
  poweredOnVirtualMachines: 'Powered on virtual machines',
  guestHasDatacenterEdition: 'Guest has Datacenter edition',
  highestWindowsServerVersion: 'Highest Windows version',
  sharePointServers: 'Share Point Servers',

  //RULES
  Tag: 'Tag',
  Global: 'Global',
  Enabled: 'Enabled',
  where: 'Where',
  Rules: 'Rules',
  UserScope: 'Scope',
  UserRole: 'Role',
  UserType: 'Type',
  UserClass: 'Class',
  UserState: 'State',
  BusinessUnitId: 'Business unit id',
  'Rule order set': 'Rule order set',
  UserStatus: 'Status',
  DeviceScope: 'Scope',
  DeviceRole: 'Role',
  DeviceType: 'Type',
  DeviceClass: 'Class',
  DeviceStatus: 'Status',
  DeviceState: 'State',
  IsCostSavingsCandidate: 'Is cost savings candidate',
  IsCostAvoidanceCandidate: 'Is cost avoidance candidate',
  IsCoveredByHostInstall: 'Is covered by host install',
  IsMaintenanceRequired: 'Is maintenance required',
  HasLiability: 'Has liability',
  LicenseModel: 'License model',
  LicenseMetric: 'License metric',
  InstallType: 'Install type',
  InstallMetric: 'Install metric',
  'Applied Rules': 'Applied Rules',
  'Operating System': 'Operating System',
  'Rows Affected': 'Rows Affected',
  'Execution Date': 'Execution Date',

  //FINGERPRINT

  isReconciled: 'Is reconciled',
  isIgnorable: 'Is ignorable',
  rawMetric: 'Raw metric',
  rawVersion: 'Raw version',
  rawProductName: 'Raw product name',
  rawManufacturer: 'Raw manufacturer',
  source: 'Source',

  //PLATFORM USERS

  email: 'Email',
  role: 'Role',
  'Price List': 'Price List',

  //MAIL SERVERS

  isExternalAccessRequired: 'Is external access required',
  isDRSEnabled: 'Is DRS enabled',
  drsDefaultVMBehavior: 'DRS default VM behavior',
  datacenter: 'Data center',

  //WINDOWS SERVERS

  isVirtualMobilityEnabled: 'Is virtual mobility enabled',

  //ITSM SERVERS
  'Software Tag': 'Software tag',
  applicationRoles: 'Application roles',
  isExternalAccessEnabled: 'Is external access enabled',
  isVirtualMachineMobilityEnabled: 'Is virtual machine mobility enabled',
  isCostAvoidanceCandidate: 'Is cost avoidance candidate',

  //MESSAGEBROKER SERVERS
  components: 'Components',
  instances: 'Instances',
  messagebrokers: 'Message brokers',
  dbCluster: 'DB cluster',
  dbState: 'DB state',
  isDbClustered: 'Is DB clustered',
  isActivePassiveModeEnabled: 'Is active passive mode enabled',

  //DATABASE SERVERS
  databases: 'Databases',
  dbClusterName: 'DB cluster name',
  cluster: 'Cluster',
  isDBClustered: 'Is DB clustered',
  isPassive: 'Is passive',

  //MAIL USAGE
  Properties: 'Properties',
  activeSyncDevices: 'Active sync device',
  owaDevices: 'OWA device',
  IsActiveSyncInUse: 'Is active sync in use?',
  IsOWAInUse: 'Is OWA in use?',
  isActiveSyncEnabled: 'Is active sync enabled?',
  isOWAEnabled: 'Is OWA enabled?',
  isPersonalRetentionPolicyEnabled: 'Is persona retention policy enabled?',
  isDataLossPreventionEnabled: 'Is data loss prevention enabled?',
  isInPlaceHoldEnabled: 'Is in place hold enabled?',
  isInPlaceArchiveEnabled: 'Is in place archive enabled?',
  isUnifiedMessagingEnabled: 'Is unified messaging enabled?',
  isEnterpriseFeaturesEnabled: 'Is enterprise features enabled?',
  isOWAInUse: 'Is OWA in use?',
  isActiveSyncInUse: 'Is active sync in use?',

  //USER ACCESS
  'Product Name': 'Product name',
  Username: 'Username',
  'product.productName': 'Product name',
  'device.deviceTag': 'Device tag',
  'device.deviceName': 'Device name',

  //DEVICE ACCESS
  deviceAccessId: 'Device access ID',
  User: 'User',

  //MSDN USAGE
  'software.softwareTag': 'Software tag',
  requiredProductId: 'Required product',
  servers: 'Servers',
  desktops: 'Desktops',
  environments: 'Environments',
  userLogons: 'User logons',
  coveragePeriod: 'Coverage period',
  'user.userName': 'Username',
  'assignedProduct.productName': 'Assigned product',
  'requiredProduct.productName': 'Required product',

  //ITSM USAGE
  'deviceAccess.device.deviceName': 'Device name',
  serverName: 'Server name',

  //EPP USAGE
  isManaged: 'Is managed',

  //COMMUNICATION USAGE

  communicationServers: 'Communication servers',
  dialPlan: 'Dial plan',
  lineServer: 'Line server',
  voicePolicy: 'Voice policy',
  conferencingPolicy: 'Conferencing policy',
  externalAccessPolicy: 'External access policy',
  registerPool: 'Register pool',
  standardFeatureEnabled: 'Standard feature enabled',
  plusFeatureEnabled: 'Plus feature enabled',
  unifiedMessagingEnabled: 'Unified messaging enabled',
  enterpriseVoiceEnabled: 'Enterprise voice enabled',
  audioVideoEnabled: 'Audio video enabled',
  enabledForRichPresence: 'Enabled for rich presence',

  //COLLABORATION USAGE

  enterpriseFeatureEnabled: 'Enterprise feature enabled',
  collaborationServer: 'Collaboration server',

  //PRICING OPTIONS

  'agreement.agreementNumber': 'Agreement number',
  productCategories: 'Product categories',
  productGroups: 'Product Groups',
  editions: 'Editions',
  isPlatform: 'Is Platform?',
  priceLevelApplicationPool: 'Price level application pool',
  priceLevelServerPool: 'Price level server pool',
  priceLevelSystemPool: 'Price level system pool',
  discount: 'Discount',
  vat: 'Vat',

  //PURCHASES

  purchaseDate: 'Purchase Date',
  purchaseNumber: 'Purchase Number',
  purchaseDescription: 'Purchase Description',

  // PURCHASE LINES

  maintenanceQuantity: 'Maintenance Quantity',
  maintenanceStart: 'Maintenance Start',
  maintenanceEnd: 'Maintenance End',

  // SERVERS

  cpuModel: 'CPU model',
  virtualProcessors: 'Virtual Processors',
  virtualCores: 'Virtual Cores',
  virtualCPUType: 'Virtual CPU Type',

  //PRICE LIST

  pricelistSource: 'Price list source',
  pricelistType: 'Price list type',
  offering: 'Offering',
  license: 'License',
  purchasePeriod: 'Purchase period',
  'product.manufacturer.manufacturerKey': 'Manufacturer key',
  'product.edition': 'Edition',
  'product.version': 'Version',
  manufacturerModel: 'Manufacturer model',
  isFromSA: 'Is from SA',
  netPriceLevelA: 'Net Price Level A',
  netPriceLevelB: 'Net Price Level B',
  netPriceLevelC: 'Net Price Level C',
  netPriceLevelD: 'Net Price Level D',
  retailPriceLevelA: 'Retail Price Level A',
  retailPriceLevelB: 'Retail Price Level B',
  retailPriceLevelC: 'Retail Price Level C',
  retailPriceLevelD: 'Retail Price Level D',
  priceValidFrom: 'Price Valid From',
  priceValidUntil: 'Price Valid To',
  measureUnit: 'Measure unit',

  //ARTICLES
  articles: 'Articles',
  'manufacturer.manufacturerKey': 'Manufacturer key',
  deviceName: 'Device Name',
  'Mail Server Device Device Name': 'Device Name',
  hasActivePassiveRight: 'Has active passive right?',
  hasExternalAccessRight: 'Has external access right?',
  hasWorkAtHomeRight: 'Has work at home right?',
  hasVirtualMachineMobilityRight: 'Has virtual machine mobility right?',
  hasLimitOnNumberOfCores: 'Has limit on number of cores?',
  hasPerpetualUseRight: 'Has perpetual use right?',
  hasUnlimitedInstancesRight: 'Has unlimited instances right?',
  hasUnlimitedVirtualMachinesRight: 'Has unlimited virtual machines right?',
  hasUpgradeRight: 'Has upgrade right?',

  // NOTIFICATIONS

  'Profile updated!': 'Profile updated!',
  'Error occurred!': 'Error occurred!',
  'Rules applied!': 'Rules applied!',
  'Counts updated!': 'Counts updated!',
  'User successfully created!': 'User successfully created!',
  'Something went wrong!': 'Something went wrong!',
  'Invalid email or password': 'Invalid email or password',
  'Fingerprints successfully updated': 'Fingerprints successfully updated',
  'Updated successfully!': 'Updated successfully!',
  'Measurement created!': 'Measurement created!',
  'Bulk apply started': 'Bulk apply started',
  'Files successfully added to import queue!': 'Files successfully added to import queue!',
  'Import has started!': 'Import has started!',
  'Reset Order to Default': 'Reset Order to Default',
  'Delete contents': 'Delete contents',
  'Content deleted': 'Content deleted',
};

export default customEnglishMessages;
