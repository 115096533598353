import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';
import { dateFormatter, priceFormatter } from '../../../utils/formatters';
import { dateColumnFilter, numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Price List" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="pricelistSource" />
          <AgGridColumn field="pricelistType" />
          <AgGridColumn field="offering" />
          <AgGridColumn field="sku" />
          <AgGridColumn field="license" />
          <AgGridColumn field="purchasePeriod" />
        </AgGridColumn>
        <AgGridColumn headerName="product">
          <AgGridColumn field="manufacturer" />
          <AgGridColumn field="productName" />
          <AgGridColumn field="edition" />
          <AgGridColumn field="version" />
          <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
          <AgGridColumn {...useAgGridEnumCol('licensemodels', 'licenseModel')} />
          <AgGridColumn field="manufacturerModel" />
          <AgGridColumn field="packageQuantity" columnGroupShow="open" />
          <AgGridColumn field="measureUnit" columnGroupShow="open" />
          <AgGridColumn field="isPlatform" columnGroupShow="open" />
          <AgGridColumn field="isFromSA" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Prices">
          <AgGridColumn
            field="netPriceLevelA"
            type="numericColumn"
            valueFormatter={priceFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="netPriceLevelB"
            type="numericColumn"
            valueFormatter={priceFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="netPriceLevelC"
            type="numericColumn"
            valueFormatter={priceFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
            columnGroupShow="open"
          />
          <AgGridColumn
            field="netPriceLevelD"
            type="numericColumn"
            valueFormatter={priceFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
            columnGroupShow="open"
          />
          <AgGridColumn
            field="retailPriceLevelA"
            type="numericColumn"
            valueFormatter={priceFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="retailPriceLevelB"
            type="numericColumn"
            valueFormatter={priceFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="retailPriceLevelC"
            type="numericColumn"
            valueFormatter={priceFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
            columnGroupShow="open"
          />
          <AgGridColumn
            field="retailPriceLevelD"
            type="numericColumn"
            valueFormatter={priceFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
            columnGroupShow="open"
          />
          <AgGridColumn
            field="priceValidFrom"
            type="numericColumn"
            valueFormatter={priceFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
            columnGroupShow="open"
          />
          <AgGridColumn
            field="priceValidUntil"
            type="numericColumn"
            valueFormatter={dateFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={dateColumnFilter}
            columnGroupShow="open"
          />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
