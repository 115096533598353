import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  BooleanField,
  BooleanInput,
  SimpleForm,
  ReferenceField,
  DateField,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import { NullableDateInput } from '../../../components/NullableDateInput';
import { Annotations } from '../../../components/Annotations';
import { AccordionFormPanel } from '@react-admin/ra-form-layout/lib/src/forms';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { sourceTab } from '../../../components/SourceTab';
import { UserInput } from '../../../components/CustomInputs';
import { UserSoftwareField, UserSoftwareInput } from '../../../components/SoftwareCustomField';
import { AuditLog } from '../../../components/AuditLog';
import { NewEnumField } from '../../../types/NewEnumField';
import CrudWrapper from '../../../components/CrudWrapper';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

const inputs = (
  <SimpleForm redirect="show" autoClose toolbar={<EditToolbar message={'MSDN usage'} />}>
    <UserInput />
    {UserSoftwareInput}
    <NullableDateInput source="coveragePeriod" />
    <TextInput source="userLogons" />
    <TextInput source="environments" />
    <TextInput source="desktops" />
    <TextInput source="servers" />
    <BooleanInput source="isCostAvoidanceCandidate" />
    <AccordionFormPanel label="Notes">
      <MarkdownInput source="notes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="FeedBack">
      <MarkdownInput source="feedback" />
    </AccordionFormPanel>
  </SimpleForm>
);

export const MSDNUsageShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="userId" reference="users">
            <TextField source="userName" />
          </ReferenceField>
          {UserSoftwareField}
          <ReferenceField source="assignedProductId" reference="products">
            <TextField source="productName" />
          </ReferenceField>
          <DateField source="emailAddress" />
          <DateField source="coveragePeriod" />
          <TextField source="userLogons" />
          <TextField source="environments" />
          <TextField source="desktops" />
          <TextField source="servers" />
          <ReferenceField source="requiredProductId" reference="products">
            <TextField source="productName" />
          </ReferenceField>
          <BooleanField source="isCostAvoidanceCandidate" />
        </Tab>
        <Tab label="Classifications">
          <NewEnumField source="userScope" label="userScope" reference="userscopes" />
          <NewEnumField source="userRole" label="userRole" reference="userroles" />
          <NewEnumField source="userType" label="userType" reference="usertypes" />
          <NewEnumField source="userClass" label="userClass" reference="userclasses" />
          <NewEnumField source="userState" label="userState" reference="userstates" />
          <NewEnumField source="userStatus" label="userStatus" reference="userstatuses" />
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        {sourceTab}
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const MSDNUsageEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {inputs}
      </Edit>
    </CrudWrapper>
  );
};

export const MSDNUsageCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {inputs}
    </Create>
  </CrudWrapper>
);
