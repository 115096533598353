import { stringify } from 'query-string';
import { getScope } from '../utils/scopeQuery';

const apiUrl = process.env.REACT_APP_API_URL;

const getRequestOptions = () => {
  return {
    headers: new Headers({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
  };
};

const getRequestOptionsJson = () => {
  return {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
  };
};

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.title);
  }
  return response;
};

export default {
  setFiltersModel: (path, data) => {
    const request = new Request(apiUrl + '/configurations/' + path, {
      method: 'POST',
      body: data,
      ...getRequestOptionsJson(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
  setGroupState: (path, data) => {
    const request = new Request(apiUrl + '/configurations/' + path, {
      method: 'POST',
      body: data,
      ...getRequestOptionsJson(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
  setPageSizeModel: (path, data) => {
    const request = new Request(apiUrl + '/configurations/' + path, {
      method: 'POST',
      body: data,
      ...getRequestOptionsJson(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },

  getConfiguration: (path) => {
    const request = new Request(apiUrl + '/configurations/' + path, {
      method: 'GET',
      ...getRequestOptions(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },

  setColumnState: (path, data) => {
    const request = new Request(apiUrl + '/configurations/' + path, {
      method: 'POST',
      body: data,
      ...getRequestOptionsJson(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
  getColumnState: (path) => {
    const request = new Request(apiUrl + '/configurations/' + path, {
      method: 'GET',
      ...getRequestOptions(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },

  setReorder: (data) => {
    const request = new Request(apiUrl + '/rules/setorder', {
      method: 'PUT',
      ...getRequestOptionsJson(),
      body: JSON.stringify(data),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
  getFilterValues: (resource, columnName, filterModel) => {
    const request = new Request(
      apiUrl + '/' + resource + '/values?columnName=' + columnName + '&' + stringify(getScope()),
      {
        method: 'post',
        body: filterModel ? JSON.stringify(filterModel) : JSON.stringify({}),
        ...getRequestOptionsJson(),
      },
    );

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
  exportDataFromTable: async (resource, filters) => {
    const request = new Request(apiUrl + '/' + resource + '/export?' + stringify(getScope()), {
      method: 'POST',
      body: JSON.stringify(filters),
      ...getRequestOptionsJson(),
    });

    try {
      const response = await fetch(request);
      handleErrors(response);
      return response.blob();
    } catch (error) {
      console.log('Error:', error);
      return error;
    }
  },
};
