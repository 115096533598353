import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';

import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';
import { numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Product Paths" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn headerName="From Product">
          <AgGridColumn field="manufacturerName" headerName="Manufacturer" />
          <AgGridColumn field="fromProductGroup" headerName="Group" />
          <AgGridColumn field="fromProductName" headerName="Name" />
          <AgGridColumn field="fromProductVersion" headerName="Version" />
        </AgGridColumn>
        <AgGridColumn headerName="To Products">
          <AgGridColumn field="toProducts" headerName="Name & Version" />
        </AgGridColumn>
        <AgGridColumn {...useAgGridEnumCol('productpathtypes', 'productPathType')} />
        <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
        <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'installMetric')} />
        <AgGridColumn field="ConversionRate" headerName="Conversion Rate" />
        <AgGridColumn
          field="IsActiveMaintenanceRequired"
          headerName="Is Active Maintenance Required"
        />
      </DataGrid>
    </List>
  );
};
