import AgreementLineIcon from '@material-ui/icons/FormatListNumbered';
import AgreementLineList from './AgreementLineList';
import { AgreementLineCreate, AgreementLineEdit, AgreementLineShow } from './AgreementLineForm';

export default {
  list: AgreementLineList,
  icon: AgreementLineIcon,
  edit: AgreementLineEdit,
  show: AgreementLineShow,
  create: AgreementLineCreate,
};
