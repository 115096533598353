import React, { useEffect } from 'react';
import { AutocompleteArrayInput, AutocompleteInput, ReferenceInput } from 'ra-ui-materialui';
import { Form, useForm } from 'react-final-form';
import { useInput, useRecordContext } from 'react-admin';



const NoProgressBarSelectInput = (props) => {
    return (
        <AutocompleteInput
            resettable
            style={{ flex: 1, margin: '2px', background: 'white' }}
            {...props}
            label={!props.choices.length ? 'No options' : props.label}
            loading={false}
            disabled={props.loading || props.disabled || !props.choices.length}
        />
    );
};
const NoProgressBarSelectArrayInput = (props) => {
    return (
        <AutocompleteArrayInput
            resettable
            style={{ flex: 1, margin: '2px', background: 'white' }}
            {...props}
            label={!props.choices.length ? 'No options' : props.label}
            loading={false}
            disabled={props.loading || props.disabled || !props.choices.length}
        />
    );
};
const Fields = (props) => {
    const form = useForm();
    const productSourceName = props.nestedInput.input.name;
    const productIds = props.nestedInput.input.value
    const record = useRecordContext()

    useEffect(() => {
        if (record) {
            form.change('manufacturerId', record.manufacturerId);
            form.change('productGroup', record.productGroup);
            form.change(productSourceName, productIds)
        }

    }, [record])



    const { manufacturerId, productGroup } = form.getState().values;
    return (
        <>
            <ReferenceInput
                source="manufacturerId"
                reference="manufacturers"
                onChange={() => {
                    form.change('productGroup', undefined);
                    form.change(productSourceName, null);
                    props.nestedInput.input.onChange(null);
                }}
            >
                <NoProgressBarSelectInput resettable optionText="manufacturerKey" />
            </ReferenceInput>
            <ReferenceInput
                source="productGroup"
                reference="products/productgroups"
                onChange={() => {
                    form.change(productSourceName, null);
                    props.nestedInput.input.onChange(null);
                }}
                disabled={!manufacturerId}
                filter={{ manufacturerId }}
            >
                <NoProgressBarSelectInput optionText="id" />
            </ReferenceInput>
            <ReferenceInput
                reference="products"
                filter={{ productGroup }}
                disabled={!manufacturerId || !productGroup}
                {...props.nestedInput.input}
            >
                    <NoProgressBarSelectArrayInput
                        optionText={(record) => record ? record.productName + ' ' + record.version : ''}
                    />
            </ReferenceInput>
        </>
    );
};
const FilteringArrayOfProducts = (props) => {
    const nestedInput = useInput(props);
    return (
        <>
            <Form
                onSubmit={() => { }}
                render={() => (
                    <Fields nestedInput={nestedInput} />
                )}
            />
        </>
    );
};
export default FilteringArrayOfProducts;
