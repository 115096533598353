function preserveQueryParameters(history, queryParameterNames, location) {
  const currentQuery = new URLSearchParams(history.location.search);
  if (currentQuery) {
    const newQuery = new URLSearchParams(location.search);
    for (let [key, value] of newQuery.entries()) {
      if (value === 'undefined') {
        currentQuery.delete(key);
      } else {
        currentQuery.set(key, newQuery.get(key));
      }
    }
    location.search = currentQuery.toString();
  }
  return location;
}

function createLocationDescriptorObject(location, state) {
  return typeof location === 'string' ? { pathname: location, state } : location;
}

/**
 * Extend the history object to preserve the given query parameters
 * when changing locations.
 */
export function preserveQuery(history, queryParameterNames) {
  const originalPush = history.push,
    originalReplace = history.replace;

  history.push = (path, state) =>
    originalPush.apply(history, [
      preserveQueryParameters(
        history,
        queryParameterNames,
        createLocationDescriptorObject(path, state),
      ),
    ]);
  history.replace = (path, state) =>
    originalReplace.apply(history, [
      preserveQueryParameters(
        history,
        queryParameterNames,
        createLocationDescriptorObject(path, state),
      ),
    ]);
  return history;
}

export function getScope() {
  const params = new URLSearchParams(document.location.search);
  const scope = {};
  for (let [key, value] of params.entries()) {
    scope[key] = value;
  }
  return scope;
}
