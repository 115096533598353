import MessagebrokerServerIcon from '@material-ui/icons/Message';
import {
  MessagebrokerServerCreate,
  MessagebrokerServerEdit,
  MessagebrokerServerShow,
} from './MessagebrokerServerForm';
import MessagebrokerServerList from './MessagebrokerServerList';

export default {
  list: MessagebrokerServerList,
  icon: MessagebrokerServerIcon,
  show: MessagebrokerServerShow,
  edit: MessagebrokerServerEdit,
  create: MessagebrokerServerCreate,
};
