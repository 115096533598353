import ADGroupIcon from '@material-ui/icons/Grain';
import ADGroupList from './ADGroupList';
import { ADGroupShow, ADGroupEdit, ADGroupCreate } from "./ADGroupForm";

export default {
  list: ADGroupList,
  icon: ADGroupIcon,
  show: ADGroupShow,
  edit: ADGroupEdit,
  create: ADGroupCreate,
};
