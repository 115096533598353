import * as React from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { useHistory } from 'react-router';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useNewAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import { dateFormatter } from '../../../utils/formatters';
import { AddSoftwareLinesDialog } from '../../../components/SoftwareDialog';
import { useState } from 'react';
import { useFetchData } from '../../../utils/useFetch';
import {
  dateColumnFilter,
  numberColumnFilter,
  serverSideDateColumnFilter,
  serverSideNumberColumnFilter,
} from '../../../utils/filterParams';
import ServerSideDataGrid from '../../../components/ServerSideDataGrid';
import SSRWrapper from '../../../components/SSRWrapper';
import DatagridAPI from '../../../providers/dataGridProvider';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  const { permissions } = usePermissions();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {(permissions === 'admin' || permissions === 'analyst') && (
        <>
          <AddSoftwareLinesDialog
            resource="devicesoftwaremodal"
            addSoftwareLines={props.addSoftwareLines}
          />
          <CreateButton basePath={basePath} />
        </>
      )}
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();
  const classificationRuleIndicator = 0;
  const qualificationRuleIndicator = 1;
  const [gridApi, setGridApi] = useState(null);
  const refresh = useRefresh();
  const notify = useNotify();
  const fetchAddSoftwareLines = useFetchData(`/devicesoftware/addsoftwarelines`, 'POST', true);

  const onRowClicked = (id) => {
    console.log(id);
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  const classificationRuleValueGetter = (params) => {
    return params.data.rules
      .filter((rule) => rule.ruleIndicator === classificationRuleIndicator)
      .map((rule) => rule.ruleTag)
      .join(', ');
  };
  const qualificationRuleValueGetter = (params) => {
    return params.data.rules
      .filter((rule) => rule.ruleIndicator === qualificationRuleIndicator)
      .map((rule) => rule.ruleTag)
      .join(', ');
  };

  const ruleFilterParams = (ruleTag, columnName = 'rules') => {
    return {
      filters: [
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            excelMode: 'windows',
            values: async (params) => {
              const response = await DatagridAPI.getFilterValues(props.resource, columnName);
              const ruleCategory = response.filter((rule) => rule.substring(0, 2) === ruleTag);
              params.success(ruleCategory);
            },
            refreshValuesOnOpen: true,
          },
        },
      ],
    };
  };

  const addSoftwareLines = async (licenseMetric, productId, licenseQuantity) => {
    const data = {
      licenseMetric: licenseMetric,
      productId: productId,
      licenseQuantity: licenseQuantity,
      entityIds: gridApi.getSelectedRows().map((row) => row.id),
    };
    await fetchAddSoftwareLines(data);
    refresh();
    notify(`Software lines added!`);
  };

  return (
    <SSRWrapper>
      <ComplexActionBarShow
        id={props.id}
        {...props}
        oldToolbar={<ListActions resource={props.resource} addSoftwareLines={addSoftwareLines} />}
      />

      <ServerSideDataGrid
        resource="devices"
        {...props}
        setGridApi={setGridApi}
        rowDoubleClicked={onRowClicked}
      >
        <AgGridColumn
          field=""
          checkboxSelection
          headerCheckboxSelection
          maxWidth={35}
          suppressMenu
          sortable={false}
          lockPosition
          resizable={false}
          checkbox
          pined
        />
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="deviceName" />
          <AgGridColumn field="deviceTag" />
          <AgGridColumn field="distinguishedName" />
          <AgGridColumn field="fullyQualifiedDomainName" />
        </AgGridColumn>
        <AgGridColumn headerName="Properties">
          <AgGridColumn field="domain" />
          <AgGridColumn field="ouPath" columnGroupShow="open" />
          <AgGridColumn
            field="operatingSystem_Manufacturer_ManufacturerKey"
            headerName="Manufacturer"
          />
          <AgGridColumn
            field="operatingSystem_ProductName"
            headerName="Product Name"
            columnGroupShow="open"
          />
          <AgGridColumn
            field="operatingSystem_Version"
            columnGroupShow="open"
            headerName="Version"
          />
          <AgGridColumn
            field="hardware_physicalCores"
            headerName="Physical cores"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={serverSideNumberColumnFilter(props.resource)}
          />
          <AgGridColumn
            field="hardware_physicalProcessors"
            columnGroupShow="open"
            headerName="Physical processors"
            filter={'agMultiColumnFilter'}
            filterParams={serverSideNumberColumnFilter(props.resource)}
          />
          <AgGridColumn
            field="totalCores"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={serverSideNumberColumnFilter(props.resource)}
          />
        </AgGridColumn>
        <AgGridColumn headerName="Timestamps">
          <AgGridColumn
            field="lastActivityDate"
            valueFormatter={dateFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={serverSideDateColumnFilter(props.resource)}
          />
          <AgGridColumn
            field="daysSinceLastActivity"
            filter={'agMultiColumnFilter'}
            filterParams={serverSideNumberColumnFilter(props.resource)}
            columnGroupShow="open"
          />
          <AgGridColumn
            field="whenCreatedDate"
            columnGroupShow="open"
            valueFormatter={dateFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={serverSideDateColumnFilter(props.resource)}
          />
          <AgGridColumn field="daysSinceCreated" columnGroupShow="open" />
          <AgGridColumn
            field="collectionDate"
            columnGroupShow="open"
            valueFormatter={dateFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={serverSideDateColumnFilter(props.resource)}
          />
        </AgGridColumn>
        <AgGridColumn headerName="Classifications">
          <AgGridColumn
            field="rules"
            headerName={'Classification rules'}
            valueGetter={(e) => classificationRuleValueGetter(e)}
            filterParams={() => ruleFilterParams('DC')}
          />
          <AgGridColumn
            field="rules"
            headerName={'Qualification rules'}
            valueGetter={(e) => qualificationRuleValueGetter(e)}
            filterParams={() => ruleFilterParams('DQ')}
          />
          <AgGridColumn
            field="baseObservations"
            valueGetter={(e) => observationValueGetter(e)}
            filterParams={() => ruleFilterParams('OB', 'baseObservations')}
          />
          <AgGridColumn {...useNewAgGridEnumCol('devicescopes', 'deviceScope', 'devices')} />
          <AgGridColumn
            {...useNewAgGridEnumCol('deviceroles', 'deviceRole', 'devices')}
            columnGroupShow="open"
          />
          <AgGridColumn
            {...useNewAgGridEnumCol('devicetypes', 'deviceType', 'devices')}
            columnGroupShow="open"
            type={'devicetypes'}
          />
          <AgGridColumn
            {...useNewAgGridEnumCol('deviceclasses', 'deviceClass', 'devices')}
            columnGroupShow="open"
            type={'deviceclasses'}
          />
          <AgGridColumn
            {...useNewAgGridEnumCol('devicestates', 'deviceState', 'devices')}
            columnGroupShow="open"
            type={'devicestates'}
          />
          <AgGridColumn
            {...useNewAgGridEnumCol('devicestatuses', 'deviceStatus', 'devices')}
            columnGroupShow="open"
            type={'devicestatuses'}
          />
        </AgGridColumn>
        <AgGridColumn headerName="Organization">
          <AgGridColumn field="businessUnit_BusinessUnitName" headerName="Business Unit Name" />
          <AgGridColumn field="businessUnit_City" headerName="City" columnGroupShow="open" />
          <AgGridColumn field="businessUnit_Country" headerName="Country" columnGroupShow="open" />
          <AgGridColumn field="businessUnit_Region" headerName="Region" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Annotanions">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </ServerSideDataGrid>
    </SSRWrapper>
  );
};
