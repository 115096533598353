import ITSMServerIcon from '@material-ui/icons/SettingsSystemDaydream';
import { ITSMServerCreate, ITSMServerEdit, ITSMServerShow } from './ITSMServerForm';
import ITSMServerList from './ITSMServerList';

export default {
  list: ITSMServerList,
  icon: ITSMServerIcon,
  show: ITSMServerShow,
  edit: ITSMServerEdit,
  create: ITSMServerCreate,
};
