import HardwareIcon from '@material-ui/icons/DesktopWindows';
import HardwareList from './HardwareList';
import { HardwareCreate, HardwareEdit, HardwareShow } from './HardwareForm';
export default {
  list: HardwareList,
  show: HardwareShow,
  edit: HardwareEdit,
  icon: HardwareIcon,
  create: HardwareCreate,
};
