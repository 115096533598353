import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List, useNotify } from 'react-admin';
import { useHistory } from 'react-router';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  useTranslate,
  Button,
  sanitizeListRestProps,
} from 'react-admin';
import { getScope } from '../../../utils/scopeQuery';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import { useFetch } from '../../../utils/useFetch';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  const notify = useNotify();
  const translate = useTranslate();
  const checkScope = getScope().measurementId;
  const getData = useFetch(`/measurements/generateadgroupsoftware/${checkScope}`, 'POST');

  const generateadGroupSoftware = async () => {
    await getData().then((e) =>
      notify(`${translate('Added')} ${e?.added} ${translate('user access lines!')}`),
    );
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button onClick={generateadGroupSoftware} label="Generate AD group software" />
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List
      {...props}
      title="AD Groups"
      actions={<ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions />} />}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn field="adGroupTag" />
        <AgGridColumn field="adGroupName" />
        <AgGridColumn field="distinguishedName" />
        <AgGridColumn field="organizationalUnit" columnGroupShow="open" />
        <AgGridColumn field="parentGroup" />
        <AgGridColumn
          field="observations"
          valueGetter={(e) => observationValueGetter(e)}
          columnGroupShow="open"
        />
        <AgGridColumn headerName="Annotation">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
