import PurchaseIcon from '@material-ui/icons/ShoppingCart';
import { PurchasesCreate, PurchasesEdit, PurchasesShow } from './PurchasesForm';
import PurchasesList from './PurchasesList';

export default {
  list: PurchasesList,
  icon: PurchaseIcon,
  show: PurchasesShow,
  create: PurchasesCreate,
  edit: PurchasesEdit,
};
