import React from 'react';
import { getScope } from '../utils/scopeQuery';
import ErrorPage from './ErrorPage';

const CrudWrapper = (props) => {
  const checkScope = getScope().measurementId;
  return <>{!checkScope ? <ErrorPage action={'Measurement'} item={'rules'} /> : props.children}</>;
};

export default CrudWrapper;

export const CrudWrapperOrg = (props) => {
  const checkScope = getScope().organizationId;
  return <>{!checkScope ? <ErrorPage action={'Organization'} item={'rules'} /> : props.children}</>;
};

export const CrudWrapperProject = (props) => {
  const checkScope = getScope().projectId;
  return <>{!checkScope ? <ErrorPage action={'Project'} item={'rules'} /> : props.children}</>;
};
