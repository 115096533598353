import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import { observationValueGetter } from '../../../types/NewEnumCell';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Organizational Units" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="organizationalUnitTag" />
          <AgGridColumn field="adObjectGuid" />
          <AgGridColumn field="distinguishedName" />
          <AgGridColumn field="domain" />
          <AgGridColumn field="ouPath" />
          <AgGridColumn
            field="observations"
            valueGetter={(e) => observationValueGetter(e)}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Annotation">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
