import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';

import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List
      {...props}
      title="Mail Usage"
      actions={<ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions />} />}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked} source="mailusage">
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="user.userTag" headerName="User Tag" />
          <AgGridColumn field="user.userName" headerName="Username" />
          <AgGridColumn field="mailServerDevice.deviceName" headerName="Device Name" />
        </AgGridColumn>

        <AgGridColumn headerName="Properties">
          <AgGridColumn field="isEnterpriseFeaturesEnabled" />
          <AgGridColumn field="isUnifiedMessagingEnabled" />
          <AgGridColumn field="isInPlaceArchiveEnabled" />
          <AgGridColumn field="isInPlaceHoldEnabled" />
          <AgGridColumn field="isDataLossPreventionEnabled" columnGroupShow="open" />
          <AgGridColumn field="isPersonalRetentionPolicyEnabled" columnGroupShow="open" />
          <AgGridColumn field="isOWAEnabled" columnGroupShow="open" />
          <AgGridColumn field="isActiveSyncEnabled" columnGroupShow="open" />
          <AgGridColumn field="isOWAInUse" columnGroupShow="open" />
          <AgGridColumn field="isActiveSyncInUse" columnGroupShow="open" />
          <AgGridColumn field="owaDevices" columnGroupShow="open" />
          <AgGridColumn field="activeSyncDevices" columnGroupShow="open" />
          <AgGridColumn field="isCostAvoidanceCandidate" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
        <AgGridColumn headerName="Annotation">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
