import ProductComponentIcon from '@material-ui/icons/Category';
import ProductComponentList from './ProductComponentList';
import {
  ProductComponentShow,
  ProductComponentEdit,
  ProductComponentCreate,
} from './ProductComponentForm';

export default {
  list: ProductComponentList,
  icon: ProductComponentIcon,
  create: ProductComponentCreate,
  show: ProductComponentShow,
  edit: ProductComponentEdit,
};
