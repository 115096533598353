import EntitlementIcon from '@material-ui/icons/Flare';
import EntitlementList from './EntitlementList';

import { EntitlementShow, EntitlementEdit, EntitlementCreate } from './EntitlementForm';

export default {
  list: EntitlementList,
  show: EntitlementShow,
  edit: EntitlementEdit,
  create: EntitlementCreate,
  icon: EntitlementIcon,
};
