import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNotify, Loading } from 'react-admin';
import { useHistory } from 'react-router-dom';
import './officeLogin.css';

const OfficeLogin = ({ theme }) => {
  const [success, setSuccess] = useState(true);
  const notify = useNotify();
  let history = useHistory();

  const apiUrl = process.env.REACT_APP_API_CONNECT;
  let url = new URLSearchParams(window.location.search);
  const code = url.get('code');

  const oAuthParams = {
    grant_type: 'microsoft',
    code,
    client_id: 'spa',
  };

  const body = Object.keys(oAuthParams)
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(oAuthParams[key]);
    })
    .join('&');

  const request = new Request(apiUrl + '/connect/token', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
      Host: apiUrl,
    }),
    body,
  });
  useEffect(() => {
    const fetchOfficeToken = async () => {
      let response = await fetch(request);
      response = await response.json();
      if (response.status < 200 || response.status >= 300) {
        notify('Error occured!');
        setSuccess(false);
      }
      localStorage.setItem('token', response.access_token);
      history.push('/organizations');
    };
    fetchOfficeToken();
  }, []);

  return (
    <div className="rootEl">
      <div className="mainField">
        <div cassName="iconwrapper">
          {!success ? (
            <div className="errorrMessage">
              <h3 className="errHeader">Something went wrong</h3>
              <p className="errParagraph">Please try again</p>
            </div>
          ) : (
            <Loading
              className="MuiCircularProgress-circle"
              loadingPrimary="Office login in progress"
              loadingSecondary="Please wait"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OfficeLogin;
