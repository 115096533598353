import dutchMessages from 'ra-language-dutch';

const customDutchMessages = {
  ...dutchMessages,
  'Project Management': 'Project Beheer',
  Rules: 'Regels',
  Projects: 'Projecten',
  Measurements: 'Meetmomenten',
  Inventory: 'Inventaris',
  Deployment: 'Inzet',
  Deployments: 'Inzet',
  Virtual: 'Virtueel',

  Licenses: 'Licenties',
  Entitlements: 'Licentierechten',
  References: 'Referentie',
  Devices: 'Apparaten',
  Users: 'Gebruikers',
  Products: 'Producten',
  Fingerprints: 'Vingerafdrukken',
  'Product Components': 'Product Componenten',
  'Product Paths': 'Product Paden',
  Manufacturers: 'Fabrikanten',
  Suppliers: 'Leveranciers',

  Sources: 'Bronnen',
  Observations: 'Waarnemingen',

  organizationalunits: 'Organisatorische Eenheden',
  'Organizational Units': 'Organisatorische Eenheden',

  Selected: 'Geselecteerd',
  Cancel: 'Annuleer',

  'Classification Rules': 'Classificatie Regels',
  'Qualification Rules': 'Kwalificatie Regels',
  Basic: 'Basis',
  Extended: 'Uitgebreid',
  Address: 'Adres',

  manufacturer: 'Fabrikant',
  domain: 'Domain',
  Version: 'Versie',
  Role: 'Rol',
  Class: 'Klasse',
  State: 'Staat',

  Organizations: 'Organisaties',
  Organization: 'Organisatie',
  organizations: 'Organisaties',
  Businessunits: 'Bedrijfseenheden',
  Businesunit: 'Bedrijfseenheid',
  'Business Units': 'Bedrijfseenheden',
  Project: 'Project',

  // ORGANIZATIONS

  // Organization

  organizationName: 'Organisatie',
  databaseName: 'Database',
  addressLine1: 'Adresregel 1',
  addressLine2: 'Adresregel 2',
  postalCode: 'Postcode',
  city: 'Staat',
  state: 'Provincie',
  country: 'Land',
  continent: 'Continent',
  region: 'Regio',
  corporateDomain: 'Bedrijfsdomein',
  organizationType: 'Type',
  organizationStatus: 'Status',
  organizationSize: 'Grootte',
  organizationAnnualRevenue: 'Omzet',
  languageTag: 'Taal',

  //Measurement
  Measurement: 'Meetmoment',
  Properties: 'Eigenschappen',
  plannedStartDate: 'Verwachte Start Datum',
  plannedEndDate: 'Verwachte Eind Datum',
  actualStartDate: 'Werkelijke Start Datum',
  actualendDate: 'Werkelijke Eind Datum',

  //Organizatinal Unit
  organizationalUnitTag: 'Label',
  adObjectGuid: 'Object Guid',
  ouPath: 'OU Pad',
  measurementId: 'Measurement Id',

  projectGUID: 'Project ID',
  measurement: 'Meetmoment',
  measurementName: 'Meetmoment',
  //Project
  projectName: 'Project',
  startDate: 'Start Datum',
  endDate: 'Eind Datum',
  projectStatus: 'Status',
  responsible: 'Eindverantwoordelijke',
  accountable: 'Verantwoordelijke',
  consulted: 'Geraadpleegd',
  informed: 'Geïnformeerd',

  //Business Unit
  businessUnit: 'Label',

  businessUnitTag: 'Label',
  businessUnitName: 'Bedrijfseenheid',
  businessUnitId: 'Bedrijfseenheid Id',

  classificationId: 'ID',
  classificationScope: 'Scope',
  classificationType: 'Categorie',

  Specifications: 'Specificaties',
  Annotations: 'Annotaties',
  Classification: 'Categorisatie',
  Timestamps: 'Tijdstempels',

  Where: 'Waar',
  Set: 'Stel',

  //Users
  userName: 'Gebruikers Naam',
  userTag: 'Label',
  principalName: 'Login Naam',
  displayName: 'Scherm Naam',
  normalizedName: 'Genormaliseerde Naam',
  firstName: 'Voornaam',
  lastName: 'Achternaam',
  maidenName: 'Meisjesnaam',

  emailAddress: 'Email Adres',
  userRole: 'Rol',
  userType: 'Type',
  userClass: 'Klasse',
  userState: 'Stand',
  userStatus: 'Status',

  lastActivityDate: 'Datum recenste aktiviteit',
  daysSinceLastActivity: '# Dagen sinds laatste aktiviteit',
  whenCreatedDate: 'Aanmaak Datum',
  ageOfRecord: 'Ancieniteit in Dagen',
  collectionDate: 'Verzamel Datum',

  'Import all objects from AD': 'Importeer alle objecten uit AD',
  'Import user objects from AD': 'Importeer gebruiker objecten uit AD',
  'Import ou objects from AD': 'Importeer OU objecten uit AD',
  'Import group objects from AD': 'Importeer groep objecten uit AD',
  'Classify/Qualify users': 'Klassificeer/Kwalificeer gebruikers',

  //Device
  deviceName: 'Apparaat Naam',
  deviceTag: 'Label',
  fullyQualifiedDomainName: 'Volledig Gekwalificeerde Domeinnaam',
  distinguishedName: 'Specifieke Naam',

  context: 'Context',
  operatingSystem: 'Besturingsysteem',
  version: 'Versie',

  // Hardware
  make: 'Merk',
  model: 'Model',
  serialNumber: 'Serienummer',
  ipAddress: 'IP Adres',
  MACAddress: 'MAC Adres',
  macAddress: 'MAC Adres',
  logicalCores: 'Logische Cores',
  physicalCores: 'Fysieke Cores',
  physicalProcessors: 'Fysieke Processoren',
  threads: 'Draden',
  'Import hardware from Hardware Data': 'Importeer hardware uit Hardware Data',
  'Classify/Qualify hardware': 'Classificeer/Kwalificeer hardware',

  // Software
  Installation: 'Installatie',
  License: 'Licentie',
  softwareTag: 'Label',
  installProductPool: 'Geïnstalleerde Pool',
  installProductCategory: 'Geïnstalleerde Categorie',
  installProductGroup: 'Geïnstalleerde Groep',
  installProductFamily: 'Geïnstalleerde Familie',
  installProduct: 'Geïnstalleerd Product',
  installVersion: 'Geïnstalleerde Versie',
  installEdition: 'Geïnstalleerde Editie',
  installMetric: 'Geïnstalleerde Meeteenheid',
  installQuantity: 'Geïnstalleerd Aantal',
  installSource: 'Installatie Bron',
  installType: 'Installatie Type',
  installStatus: 'Installatie Status',
  licenseProductPool: 'Gelicenseerde Pool',
  licenseProductCategory: 'Gelicenseerd Categorie',
  licenseProductGroup: 'Gelicenseerde Groep',
  licenseProductFamily: 'Gelicenseerde Familie',
  licenseProduct: 'Gelicenseerd Product',
  licenseVersion: 'Gelicenseerde Versie',
  ilicenseEdition: 'Gelicenseerde Editie',
  licenseMetric: 'Gelicenseerde Meeteenheid',
  licenseQuantity: 'Gelicenseerd Aantal',
  licenseModel: 'Licentie Model',
  product: 'Product',
  hasLiability: 'Licentie Vereist?',
  isMaintenanceRequired: 'Onderhoud Vereist?',
  'Import software from MSI/ESI Data': 'Importeer software uit MSI/ESI Data',
  'Classify/Qualify software': 'Classificeer/Kwalificeer software',

  // Server Application,
  isExternalAccessRequired: 'Externe Toegang Vereist?',
  applicationRoles: 'Applicatie Rol(len)',

  // Product
  manufacturerName: 'Fabrikant',
  productName: 'Product',
  edition: 'Editie',
  buildNumber: 'Build nummer',
  productType: 'Build nummer',
  isLicensable: 'Licenseerbaar?',
  trackUsage: 'Volg gebruik?',
  isOperatingSystem: 'Besturingsysteem?',
  isServerRelated: 'Server gelelateerd?',
  hasUpgradePaths: 'Upgradepad beschikbaar?',
  hasDowndgradePaths: 'Donwngradepad beschikbaar?',
  hasComponents: 'Heeft componenten?',
  excludeFromReports: 'Sluit uit voor rapportage',
  releaseDate: 'Datum algemene beschikbaarheid',
  mainstreamSupportEndDate: 'Standaard Einddatum Ondersteuning',
  extendedSupportEndDate: 'Verlengde Einddatum Ondersteuning',

  productPool: 'Product Pool',
  productCategory: 'Product Categorie',
  productGroup: 'Product Groep',
  productFamily: 'Product Familie',

  interpretations: 'Interpretaties',
  notes: 'Notities',
  observations: 'Observaties',
  feedback: 'Feedback',
  deviceRole: 'Rol',
  deviceType: 'Type',
  deviceClass: 'Klasse',
  deviceState: 'Staat',
  deviceStatus: 'Status',

  rawManufacturer: 'Fabrikant (bron)',
  rawProductName: 'Product (bron)',
  rawVersion: 'Versie (bron)',
  rawMetric: 'Meeteenhied (bron)',
  isIgnorable: 'Negeerbaar?',
  isReconciled: 'Geassocieerd?',
  sourceType: 'SourceType',
  import: 'Importeren',

  // Licentie
  Quantities: 'Aantallen',
  activeMaintenanceQuantity: 'Actief Onderhoud',
  estimatedOverspend: 'Estimated overspend',
};

export default customDutchMessages;
