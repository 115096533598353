import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import {
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';
import { numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {' '}
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List
      {...props}
      title='Compliance Positions'
      actions={<ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions />} />}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked} source='compliancepositions'>
        <AgGridColumn headerName='Product'>
          <AgGridColumn field='productName' />
          <AgGridColumn field='productCategory' columnGroupShow='open' />
          <AgGridColumn field='productGroup' columnGroupShow='open' />
          <AgGridColumn field='productFamily' columnGroupShow='open' />
          <AgGridColumn field='productPool' columnGroupShow='open' />
          <AgGridColumn field='manufacturer' />
          <AgGridColumn field='edition' />
          <AgGridColumn field='version' />
          <AgGridColumn field='buildNumber' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='excludedFromReports' />
        </AgGridColumn>
        <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
        <AgGridColumn {...useAgGridEnumCol('licensemodels', 'licenseModel')} />
        <AgGridColumn headerName='Software Usage'>
          <AgGridColumn field='licenseQuantity' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='usageQuantity' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='allocatedQuantity' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='availableQuantity' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='complianceQuantity' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='incomplianceQuantity' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
        </AgGridColumn>
        <AgGridColumn headerName='Financials'>
          <AgGridColumn field='actualPurchaseQuantity' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='actualSoldQuantity' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='extendedAmountPerUnit' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='estimatedPurchasePricePerUnit' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='actualPurchasePricePerUnit' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='actualSellingPricePerUnit' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='estimatedShortfall' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='estimatedOverspend' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='potentialCostAvoidance' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='potentialCostSavings' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='actualCostAvoidance' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='actualCostSavings' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='actualShortfall' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
          <AgGridColumn field='actualOverspend' columnGroupShow='open' filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter} />
        </AgGridColumn>
        <AgGridColumn headerName='Organization'>
          <AgGridColumn field='businessUnitName' />
          <AgGridColumn field='city' columnGroupShow='open' />
          <AgGridColumn field='country' columnGroupShow='open' />
          <AgGridColumn field='region' columnGroupShow='open' />
        </AgGridColumn>
        <AgGridColumn headerName='Annotation'>
          <AgGridColumn field='notes' />
          <AgGridColumn field='interpretations' columnGroupShow='open' />
          <AgGridColumn field='feedback' columnGroupShow='open' />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
