import * as React from 'react';
import {
  Edit,
  NumberInput,
  Create,
  Show,
  TabbedShowLayout,
  Tab,
  BooleanInput,
  BooleanField,
  TextField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
} from 'react-admin';
import { useHistory } from 'react-router';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { Annotations } from '../../../components/Annotations';
import { NewEnumInput } from '../../../types/NewEnumInput';
import { NewEnumField } from '../../../types/NewEnumField';
import ActionBarShow from '../../../components/ActionBarShow';
import FilteringProducts from '../../../components/FilteringProducts';
import { MarkdownInput } from '@react-admin/ra-markdown';
import DataGrid from '../../../components/DataGrid';
import { useEffect, useState } from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import { useFetch } from '../../../utils/useFetch';
import EditToolbar from '../../../components/EditToolbar';
import { numberColumnFilter } from '../../../utils/filterParams';

const PageTitle = ({ record }) => {
  return <span>{record ? record.composedProductFamily : ''}</span>;
};

const fields = (
  <AccordionForm redirect="show" toolbar={<EditToolbar message={'license position'} />}>
    <AccordionFormPanel label="Properties">
      <FilteringProducts source="productId" />
      <NewEnumInput source="licenseMetric" reference="licensemetrics" />
      <BooleanInput source={'excludedFromReports'} />
    </AccordionFormPanel>
    <AccordionFormPanel label="Licenses">
      <NumberInput source="volumeLicenseQuantity" />
      <NumberInput source="recycledLicenseQuantity" />
      <NumberInput source="retailLicenseQuantity" />
      <NumberInput source="oemLicenseQuantity" />
      <NumberInput source="totalLicenseQuantity" />
      <NumberInput source="activeMaintenanceLicenseQuantity" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Deployments">
      <NumberInput source="physicalDeploymentQuantity" />
      <NumberInput source="virtualDeploymentQuantity" />
      <NumberInput source="hostDeploymentQuantity" />
      <NumberInput source="userDeploymentQuantity" />
      <NumberInput source="manualDeploymentQuantity" />
      <NumberInput source="totalDeploymentQuantity" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Delta">
      <NumberInput source="positionBeforeCorrectionQuantity" />
      <NumberInput source="correctionQuantity" />
      <NumberInput source="positionAfterCorrectionQuantity" />
      <NumberInput source="upgradeLicenseQuantity" />
      <NumberInput source="upgradeWithMaintenanceLicenseQuantity" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput source="notes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="FeedBack">
      <MarkdownInput source="feedback" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const LicensePositionShow = (props) => {
  const [data, setData] = useState();
  const history = useHistory();
  const softwareRuleIndicator = 0;
  const fetchData = useFetch(`/licensepositions/${props.id}`, 'GET');

  const ruleValueGetter = (params) => {
    return params.data?.rules
      ?.filter((rule) => rule.ruleIndicator === softwareRuleIndicator)
      .map((rule) => rule.ruleTag)
      .join(', ');
  };

  useEffect(() => {
    const getData = async () => {
      const response = await fetchData();
      setData({ licenseMetric: response.licenseMetric, licenseProductId: response.productId });
    };
    getData();
  }, []);

  const onRowClicked = (id, resource) => {
    const url = `/${resource}/${id}/show`;
    history.push(url);
  };

  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="productId" reference="products" label="manufacturerName">
            <TextField source="manufacturerName" />
          </ReferenceField>
          <ReferenceField source="productId" reference="products" label="productGroup">
            <TextField source="productGroup" />
          </ReferenceField>
          <ReferenceField source="productId" reference="products" label="productName">
            <TextField source="productName" />
          </ReferenceField>
          <ReferenceField source="productId" reference="products" label="productPool">
            <TextField source="productPool" />
          </ReferenceField>
          <TextField source="buildNumber" />
          <ReferenceField source="productId" reference="products" label="version">
            <TextField source="version" />
          </ReferenceField>
          <ReferenceField source="productId" reference="products" label="edition">
            <TextField source="edition" />
          </ReferenceField>
          <BooleanField source={'excludedFromReports'} />
        </Tab>
        <Tab label="Extended">
          <AccordionFormPanel label="Licenses">
            <NewEnumField source="licenseMetric" reference="licensemetrics" />
            <NumberField source="totalLicenseQuantity" />
            <NumberField source="totalDeploymentQuantity" />
            <NumberField source="volumeLicenseQuantity" />
            <NumberField source="recycledLicenseQuantity" />
            <NumberField source="retailLicenseQuantity" />
            <NumberField source="oemLicenseQuantity" />
            <NumberField source="totalLicenseQuantity" />
            <NumberField source="activeMaintenanceLicenseQuantity" />
            <NumberField source="upgradeWithMaintenanceLicenseQuantity" />
            <NumberField source="upgradeLicenseQuantity" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Deployments">
            <NumberField source="physicalDeploymentQuantity" />
            <NumberField source="virtualDeploymentQuantity" />
            <NumberField source="hostDeploymentQuantity" />
            <NumberField source="userDeploymentQuantity" />
            <NumberField source="manualDeploymentQuantity" />
            <NumberField source="totalDeploymentQuantity" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Delta">
            <NumberField source="positionBeforeCorrectionQuantity" />
            <NumberField source="correctionQuantity" />
            <NumberField source="positionAfterCorrectionQuantity" />
          </AccordionFormPanel>
        </Tab>

        <Tab label="Device Software">
          <ReferenceManyField
            reference="devicesoftware"
            filter={{
              licenseMetric: data?.licenseMetric,
              licenseProductId: data?.licenseProductId,
            }}
            target="filter"
            addLabel={false}
          >
            <DataGrid
              source="devicesoftware"
              rowDoubleClicked={(e) => onRowClicked(e, 'devicesoftware')}
            >
              <AgGridColumn headerName="Claims">
                <AgGridColumn field="deviceTag" />
                <AgGridColumn field="deviceName" />
                <AgGridColumn field="softwareTag" />
                <AgGridColumn field="fullyQualifiedDomainName" />
              </AgGridColumn>

              <AgGridColumn headerName="Validations">
                <AgGridColumn field="isLicensable" />
                <AgGridColumn field="hasLiability" />
                <AgGridColumn field="isMaintenanceRequired" />
                <AgGridColumn field="isCoveredByHostInstall" />
                <AgGridColumn field="isCostSavingsCandidate" />
                <AgGridColumn field="isCostAvoidanceCandidate" />
                <AgGridColumn field="trackUsage" />
                <AgGridColumn
                  field="processors"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="coresPerProcessor"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="totalCores"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
              </AgGridColumn>
              <AgGridColumn headerName="Classifications">
                <AgGridColumn field="softwareRule" valueGetter={(e) => ruleValueGetter(e)} />
                <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
                <AgGridColumn {...useAgGridEnumCol('devicescopes', 'deviceScope')} />
                <AgGridColumn {...useAgGridEnumCol('deviceroles', 'deviceRole')} />
                <AgGridColumn {...useAgGridEnumCol('devicetypes', 'deviceType')} />
                <AgGridColumn {...useAgGridEnumCol('deviceclasses', 'deviceClass')} />
                <AgGridColumn {...useAgGridEnumCol('devicestates', 'deviceState')} />
                <AgGridColumn {...useAgGridEnumCol('devicestatuses', 'deviceStatus')} />
              </AgGridColumn>
              <AgGridColumn headerName="Install product">
                <AgGridColumn field="installProductName" />
                <AgGridColumn field="installProductPool" />
                <AgGridColumn field="installProductCategory" />
                <AgGridColumn field="installProductGroup" />
                <AgGridColumn field="installProductFamily" />
                <AgGridColumn field="installVersion" />
                <AgGridColumn field="installEdition" />
                <AgGridColumn
                  field="installQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
              </AgGridColumn>
              <AgGridColumn headerName="Licensed product">
                <AgGridColumn field="licenseProductName" />
                <AgGridColumn field="licenseProductPool" />
                <AgGridColumn field="licenseProductCategory" />
                <AgGridColumn field="licenseProductGroup" />
                <AgGridColumn field="licenseProductFamily" />
                <AgGridColumn field="licenseVersion" />
                <AgGridColumn field="licenseEdition" />
                <AgGridColumn
                  field="licenseQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'installMetric')} />
                <AgGridColumn {...useAgGridEnumCol('licensemodels', 'installModel')} />
              </AgGridColumn>
              <AgGridColumn headerName="Organization">
                <AgGridColumn field="businessUnitName" />
                <AgGridColumn field="city" />
                <AgGridColumn field="country" />
                <AgGridColumn field="region" />
              </AgGridColumn>
              <AgGridColumn headerName="Annotation">
                <AgGridColumn field="notes" />
                <AgGridColumn field="interpretations" />
                <AgGridColumn field="feedback" />
              </AgGridColumn>
              <AgGridColumn headerName="Sources">
                <AgGridColumn
                  headerName="Created by"
                  field="sources"
                  valueGetter={(e) => createdBy(e)}
                />
                <AgGridColumn
                  field="sources"
                  headerName="Updated by"
                  valueGetter={(e) => updatedBy(e)}
                />
              </AgGridColumn>
            </DataGrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="User Software">
          <ReferenceManyField
            reference="usersoftware"
            filter={{
              licenseMetric: data?.licenseMetric,
              licenseProductId: data?.licenseProductId,
            }}
            target="filter"
            addLabel={false}
          >
            <DataGrid
              source="devicesoftware"
              rowDoubleClicked={(e) => onRowClicked(e, 'usersoftware')}
            >
              <AgGridColumn headerName="Claims">
                <AgGridColumn field="userName" />
                <AgGridColumn field="normalizedName" />
                <AgGridColumn field="softwareTag" />
                <AgGridColumn field="domain" />
              </AgGridColumn>

              <AgGridColumn headerName="Validations">
                <AgGridColumn field="hasLiability" />
                <AgGridColumn field="isMaintenanceRequired" />
                <AgGridColumn field="isCoveredByHostInstall" />
                <AgGridColumn field="isCostSavingsCandidate" />
                <AgGridColumn field="isCostAvoidanceCandidate" />
                <AgGridColumn field="isManual" />
                <AgGridColumn field="isArchived" />
              </AgGridColumn>
              <AgGridColumn headerName="Classifications">
                <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
                <AgGridColumn {...useAgGridEnumCol('userscopes', 'userScope')} />
                <AgGridColumn {...useAgGridEnumCol('userroles', 'userRole')} />
                <AgGridColumn {...useAgGridEnumCol('usertypes', 'userType')} />
                <AgGridColumn {...useAgGridEnumCol('userclasses', 'userClass')} />
                <AgGridColumn {...useAgGridEnumCol('userstates', 'userState')} />
                <AgGridColumn {...useAgGridEnumCol('userstatuses', 'userStatus')} />
              </AgGridColumn>
              <AgGridColumn headerName="Install product">
                <AgGridColumn field="installProductName" />
                <AgGridColumn field="installProductPool" />
                <AgGridColumn field="installProductCategory" />
                <AgGridColumn field="installProductGroup" />
                <AgGridColumn field="installProductFamily" />
                <AgGridColumn field="installVersion" />
                <AgGridColumn field="installEdition" />
                <AgGridColumn field="installQuantity" />
                <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
              </AgGridColumn>
              <AgGridColumn headerName="Licensed product">
                <AgGridColumn field="licenseProductName" />
                <AgGridColumn field="licenseProductPool" />
                <AgGridColumn field="licenseProductCategory" />
                <AgGridColumn field="licenseProductGroup" />
                <AgGridColumn field="licenseProductFamily" />
                <AgGridColumn field="licenseVersion" />
                <AgGridColumn field="licenseEdition" />
                <AgGridColumn field="licenseQuantity" />
                <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'installMetric')} />
                <AgGridColumn {...useAgGridEnumCol('licensemodels', 'installModel')} />
              </AgGridColumn>
              <AgGridColumn headerName="Organization">
                <AgGridColumn field="businessUnitName" />
                <AgGridColumn field="city" />
                <AgGridColumn field="country" />
                <AgGridColumn field="region" />
              </AgGridColumn>
              <AgGridColumn headerName="Annotation">
                <AgGridColumn field="notes" />
                <AgGridColumn field="interpretations" />
                <AgGridColumn field="feedback" />
              </AgGridColumn>
              <AgGridColumn headerName="Sources">
                <AgGridColumn
                  headerName="Created by"
                  field="sources"
                  valueGetter={(e) => createdBy(e)}
                />
                <AgGridColumn
                  field="sources"
                  headerName="Updated by"
                  valueGetter={(e) => updatedBy(e)}
                />
              </AgGridColumn>
            </DataGrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const LicensePositionEdit = (props) => (
  <Edit undoable={false} title={<PageTitle />} {...props}>
    {fields}
  </Edit>
);

export const LicensePositionCreate = (props) => (
  <Create title="New" {...props}>
    {fields}
  </Create>
);
