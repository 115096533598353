import CommunicationUsageIcon from '@material-ui/icons/VerifiedUserSharp';
import {
  CommunicationUsageCreate,
  CommunicationUsageEdit,
  CommunicationUsageShow,
} from './CommunicationUsageForm';
import CommunicationUsageList from './CommunicationUsageList';

export default {
  list: CommunicationUsageList,
  icon: CommunicationUsageIcon,
  show: CommunicationUsageShow,
  edit: CommunicationUsageEdit,
  create: CommunicationUsageCreate,
};
