import SourceIcon from '@material-ui/icons/InsertDriveFile';
import SourceList from './SourceList';

export default {
  list: SourceList,
  icon: SourceIcon /* 
  show: SourceShow,
  edit: SourceEdit,
  create: SourceCreate, */,
};
