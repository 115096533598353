import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout/lib/src/forms';
import { MarkdownInput } from '@react-admin/ra-markdown';
import * as React from 'react';
import {
  Edit,
  TextInput,
  BooleanInput,
  NumberInput,
  Create,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  TextField,
  NumberField,
  DateField,
  BooleanField,
} from 'react-admin';
import { Annotations } from '../../../components/Annotations';
import { NullableDateInput } from '../../../components/NullableDateInput';
import FilteringProducts from '../../../components/FilteringProducts';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import EditToolbar from '../../../components/EditToolbar';
import { dateFormatter } from '../../../utils/formatters';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productFamily : ''}</span>;
};

const fields = (
  <AccordionForm redirect="show" toolbar={<EditToolbar message={'entitlement'} />}>
    <AccordionFormPanel defaultExpanded label="basic">
      <FilteringProducts source="productId" />
      <NewEnumInput source="licenseMetric" reference="licensemetrics" />
      <NewEnumInput source="agreementType" reference="agreementtypes" />
      <NumberInput source="licenseQuantity" />
      <NumberInput source="activeMaintenanceQuantity" />
      <NumberInput source="initialMaintenanceQuantity" />
      <NullableDateInput source="maintenanceExpirationDate" valueFormatter={dateFormatter} />
    </AccordionFormPanel>
    <AccordionFormPanel label="Properties">
      <NumberInput source="automaticAllocatedQuantity" />
      <NumberInput source="manualAllocatedQuantity" />
      <NumberInput source="availableQuantity" />
      <NumberInput source="upgradeQuantity" />
      <NumberInput source="upgradeWithMaintenanceQuantity" />
      <TextInput source="licenseType" />
      <BooleanInput source="isContract" />
      <BooleanInput source="excludeFromReports" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput source="notes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="FeedBack">
      <MarkdownInput source="feedback" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const EntitlementShow = (props) => (
  <Show title={<PageTitle />} actions={null} {...props}>
    <TabbedShowLayout>
      <Tab label="Basic">
        <ReferenceField source="productId" reference="products" label="manufacturerName">
          <TextField source="manufacturerName" />
        </ReferenceField>
        <ReferenceField source="productId" reference="products" label="productGroup">
          <TextField source="productGroup" />
        </ReferenceField>
        <ReferenceField source="productId" reference="products" label="productName">
          <TextField source="productName" />
        </ReferenceField>
        <ReferenceField source="productId" reference="products" label="version">
          <TextField source="version" />
        </ReferenceField>
        <ReferenceField source="productId" reference="products" label="edition">
          <TextField source="edition" />
        </ReferenceField>
      </Tab>
      <Tab label="Extended">
        <NewEnumField source="licenseMetric" reference="licensemetrics" />
        <NewEnumField source="agreementType" reference="agreementtypes" />
        <NumberField source="licenseQuantity" />
        <NumberField source="activeMaintenanceQuantity" />
        <NumberField source="initialMaintenanceQuantity" />
        <DateField source="maintenanceExpirationDate" />
        <NumberField source="automaticAllocatedQuantity" />
        <NumberField source="manualAllocatedQuantity" />
        <NumberField source="availableQuantity" />
        <NumberField source="upgradeQuantity" />
        <NumberField source="upgradeWithMaintenanceQuantity" />
        <TextField source="licenseType" />
        <BooleanField source="isContract" />
        <BooleanField source="excludeFromReports" />
      </Tab>
      <Tab label="Business Unit">
        <TextField source="businessUnitName" />
        <TextField source="city" />
        <TextField source="country" />
        <TextField source="continent" />
        <TextField source="region" />
        <TextField source="organizationName" />
      </Tab>
      <Tab label="Annotations">
        <Annotations />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const EntitlementEdit = (props) => (
  <Edit undoable={false} title={<PageTitle />} {...props}>
    {fields}
  </Edit>
);

export const EntitlementCreate = (props) => (
  <Create title="New" {...props}>
    {fields}
  </Create>
);
