import CollaborationServerIcon from '@material-ui/icons/MeetingRoom';
import {
  CollaborationServerCreate,
  CollaborationServerEdit,
  CollaborationServerShow,
} from './CollaborationServerForm';
import CollaborationServerList from './CollaborationServerList';

export default {
  list: CollaborationServerList,
  icon: CollaborationServerIcon,
  show: CollaborationServerShow,
  edit: CollaborationServerEdit,
  create: CollaborationServerCreate,
};
