import DataGrid from '../../../../components/DataGrid';
import { useHistory } from 'react-router';

import * as React from 'react';
import {
  Edit,
  TextInput,
  Create,
  DateInput,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ReferenceManyField,
  required,
} from 'react-admin';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout/lib/src/forms';
import AssignUsers, { AssignUsersShow } from '../../../../components/AssignUsers';
import { NewEnumField } from '../../../../types/NewEnumField';
import { NewEnumInput } from '../../../../types/NewEnumInput';
import { NullableDateInput } from '../../../../components/NullableDateInput';
import { CrudWrapperOrg } from '../../../../components/CrudWrapper';
import { useRefresh } from 'ra-core';
import { AgGridColumn } from 'ag-grid-react';
import { dateFormatter } from '../../../../utils/formatters';
import { useAgGridEnumCol } from '../../../../types/NewEnumCell';
import EditToolbar from '../../../../components/EditToolbar';
import { dateColumnFilter } from '../../../../utils/filterParams';

const PageTitle = ({ record }) => {
  return <span>{record ? record.projectName : ''}</span>;
};

const fields = (
  <AccordionForm autoClose redirect="show" toolbar={<EditToolbar message={'project'} />}>
    <AccordionFormPanel label="Basic">
      <TextInput source="projectName" label="Project Name" validate={required()} />
      <DateInput source="startDate" label="startDate" validate={required()} />
      <NullableDateInput source="endDate" label="endDate" />
      <NewEnumInput source="projectScope" reference="projectscopes" />
      <NewEnumInput source="projectType" reference="projecttypes" />
      <NewEnumInput source="projectClass" reference="projectclasses" />
      <NewEnumInput source="projectStatus" reference="projectstatuses" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Add Members">
      <AssignUsers />
    </AccordionFormPanel>
  </AccordionForm>
);

export const ProjectShow = (props) => {
  const history = useHistory();

  const onMeasurementRowClicked = (id) => {
    const url = `/measurements/${id}/show`;
    history.push(url);
  };

  const fields = (
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="projectName" label="projectName" />
        <DateField source="startDate" label="startDate" />
        <DateField source="endDate" label="endDate" />
        <NewEnumField source="projectType" reference="projecttypes" />
        <NewEnumField source="projectClass" reference="projectclasses" />
        <NewEnumField source="projectStatus" reference="projectstatuses" />
        <NewEnumField source="projectScope" reference="projectscopes" />
      </Tab>
      <Tab label="Measurements">
        <ReferenceManyField
          addLabel={false}
          target="projectId"
          /* source={'id'} */ reference="measurements"
        >
          <DataGrid rowDoubleClicked={onMeasurementRowClicked}>
            <AgGridColumn field="measurementName" />
            <AgGridColumn field="projectName" />
            <AgGridColumn
              field="plannedStartDate"
              valueFormatter={dateFormatter}
              filter={'agMultiColumnFilter'}
              filterParams={dateColumnFilter}
            />
            <AgGridColumn
              field="actualStartDate"
              valueFormatter={dateFormatter}
              filter={'agMultiColumnFilter'}
              filterParams={dateColumnFilter}
            />
            <AgGridColumn
              field="plannedEndDate"
              valueFormatter={dateFormatter}
              filter={'agMultiColumnFilter'}
              filterParams={dateColumnFilter}
            />
            <AgGridColumn
              field="actualEndDate"
              valueFormatter={dateFormatter}
              filter={'agMultiColumnFilter'}
              filterParams={dateColumnFilter}
            />
            <AgGridColumn {...useAgGridEnumCol('measurementstatuses', 'status')} />
            <AgGridColumn field="isArchived" filter={true} sortable={true} />
          </DataGrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Members">
        <AssignUsersShow />
      </Tab>
    </TabbedShowLayout>
  );

  return (
    <Show title={<PageTitle />} {...props}>
      {fields}
    </Show>
  );
};

export const ProjectEdit = (props) => {
  return (
    <CrudWrapperOrg>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {fields}
      </Edit>
    </CrudWrapperOrg>
  );
};

export const ProjectCreate = (props) => {
  const refresh = useRefresh();
  const history = useHistory();
  return (
    <CrudWrapperOrg>
      <Create
        onSuccess={(value) => {
          history.push(`${value.data.id}/show`);
          refresh();
        }}
        title="New"
        {...props}
      >
        {fields}
      </Create>
    </CrudWrapperOrg>
  );
};
