import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  NumberField,
  BooleanField,
  BooleanInput,
  SimpleForm,
  ReferenceField,
  NumberInput,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import { AccordionFormPanel } from '@react-admin/ra-form-layout';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import FilteringProducts from '../../../components/FilteringProducts';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { Annotations } from '../../../components/Annotations';
import { DeviceInput } from '../../../components/CustomInputs';
import { sourceTab } from '../../../components/SourceTab';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import { AuditLog } from '../../../components/AuditLog';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

const inputs = (
  <SimpleForm redirect="show" autoClose toolbar={<EditToolbar message={'host software'} />}>
    <DeviceInput />
    <FilteringProducts source="productId" />
    <NewEnumInput source="licenseMetric" reference="licensemetrics" />
    <NewEnumInput source="licenseModel" reference="licensemodels" />
    <NumberInput source="licenseQuantity" />
    <NewEnumInput source="installType" reference="installtypes" />
    <BooleanInput source="isLicensable" />
    <BooleanInput source="hasLiability" />
    <BooleanInput source="isDRSEnabled" />
    <TextInput source="drsDefaultVMBehavior" />
    <BooleanInput source="isMaintenanceRequired" />
    <AccordionFormPanel label="Notes">
      <MarkdownInput fullWidth source="notes" Label="" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput fullWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput fullWidth source="feedback" />
    </AccordionFormPanel>
  </SimpleForm>
);

export const HostSoftwareShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField source="deviceId" reference="devices">
            <TextField source="deviceName" />
          </ReferenceField>
          <TextField source="productName" />
          <TextField source="version" />
          <TextField source="edition" />
          <NewEnumField source="licenseMetric" reference="licensemetrics" />
          <NewEnumField source="licenseModel" reference="licensemodels" />
          <NumberField source="licenseQuantity" />
          <NewEnumField source="installType" reference="installtypes" />
          {/*
      <NewEnumField source="installSource" /> */}
          <BooleanField source="isLicensable" />
          <BooleanField source="hasLiability" />

          <BooleanField source="isDRSEnabled" />
          <TextField source="drsDefaultVMBehavior" />
          <BooleanField source="isMaintenanceRequired" />
          <Tab label="Properties">
            <TextField source="cluster" />
            <TextField source="datacenter" />
            <NumberField source="logicalProcessors" />
            <NumberField source="physicalCores" />
            <NumberField source="physicalProcessors" />
            <TextField source="cpuMake" />
            <TextField source="make" />
            <TextField source="model" />
          </Tab>
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        {sourceTab}
      </TabbedShowLayout>
    </Show>
  );
};

export const HostSoftwareEdit = (props) => {
  return (
    <Edit undoable={false} title={<PageTitle />} {...props}>
      {inputs}
    </Edit>
  );
};

export const HostSoftwareCreate = (props) => (
  <Create title="New" {...props}>
    {inputs}
  </Create>
);
