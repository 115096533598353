const apiUrl = process.env.REACT_APP_API_URL;

const getRequestOptions = () => {
  return {
    headers: new Headers({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
  };
};

const getRequestOptionsJson = () => {
  return {
    headers: new Headers({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }),
  };
};

const handleErrors = (response) => {
  if (!response.ok) {
    return Error(response.title);
  }
  return response;
};

export default {
  uploadFiles: (id, data) => {
    const request = new Request(apiUrl + `/measurements/file/upload/${id}`, {
      method: 'POST',
      body: data,
      ...getRequestOptions(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
  updateSingleFile: (id, data) => {
    const request = new Request(apiUrl + `/measurements/files/${id}`, {
      method: 'PUT',
      ...getRequestOptionsJson(),
      body: JSON.stringify(data),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },

  delete: (id) => {
    const request = new Request(apiUrl + `/measurements/file/delete/${id}`, {
      method: 'POST',
      ...getRequestOptions(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },

  reassignFingerprints: (id) => {
    const request = new Request(apiUrl + `/measurements/${id}/reassignfingerprints`, {
      method: 'POST',
      ...getRequestOptions(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
  anonymize: (id) => {
    const request = new Request(apiUrl + `/measurements/${id}/anonymize`, {
      method: 'POST',
      ...getRequestOptions(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
};
