import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'ra-ui-materialui';

export const DeviceInput = (props) => (
  <ReferenceInput
    filter={{ deviceName: props.record?.device?.deviceName }}
    filterToQuery={(searchText) => ({ deviceName: searchText })}
    source="deviceId"
    reference="devices"
    onChange={(e) => props.setDeviceFilter && props?.setDeviceFilter(e)}
  >
    <AutocompleteInput suggestionLimit={20} optionText="deviceName" />
  </ReferenceInput>
);

export const UserInput = (props) => (
  <ReferenceInput
    filter={{ userName: props.record?.user?.userName }}
    filterToQuery={(searchText) => ({ userName: searchText })}
    source="userId"
    reference="users"
    onChange={(e) => props.setUserFilter && props?.setUserFilter(e)}
  >
    <AutocompleteInput suggestionLimit={20} optionText="userName" />
  </ReferenceInput>
);
