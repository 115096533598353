import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Show,
  TabbedShowLayout,
  TextField,
  required,
  BooleanInput,
  BooleanField,
  FunctionField,
  ReferenceField,
  Tab,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import FilteringProducts from '../../../components/FilteringProducts';
import { NewEnumInput } from '../../../types/NewEnumInput';
import { NewEnumField } from '../../../types/NewEnumField';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.legalEntity : ''}</span>;
};

const fields = (
  <SimpleForm redirect="show" toolbar={<EditToolbar message={'product component'} />}>
    <TextInput source="suiteGroup" label="suiteGroup" />
    <TextInput source="suiteName" validate={required()} />
    <FilteringProducts source="productId" />
    <FilteringProducts source="componentProductId" />
    <NewEnumInput source="installType" reference="installtypes" />
    <BooleanInput source="hasLiability" />
    <BooleanInput source="isRequired" />
  </SimpleForm>
);

export const ProductComponentShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField source="suiteGroup" label="suiteGroup" />
          <TextField source="suiteName" />
          <ReferenceField source="productId" reference="products">
            <FunctionField
              label="Composed Product"
              render={(record) => `${record.productName} ${record.version}`}
            />
          </ReferenceField>
          <ReferenceField source="componentProductId" reference="products">
            <FunctionField
              label="Composed component Product"
              render={(record) => `${record.productName} ${record.version}`}
            />
          </ReferenceField>

          <NewEnumField source="installType" reference="installtypes" />
          <BooleanField source="hasLiability" />
          <BooleanField source="isRequired" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const ProductComponentEdit = (props) => (
  <Edit undoable={false} title={<PageTitle />} {...props}>
    {fields}
  </Edit>
);

export const ProductComponentCreate = (props) => (
  <Create title="New" {...props}>
    {fields}
  </Create>
);
