import UserIcon from '@material-ui/icons/People';
import UserList from './UserList';
import { UserShow, UserEdit, UserCreate } from './UserForm';

export default {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
  icon: UserIcon,
};
