import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';

import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import { numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List
      {...props}
      title="Virtual"
      actions={<ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions />} />}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked} source="virtual">
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="deviceName" />
          <AgGridColumn field="deviceTagSequence" />
          <AgGridColumn
            field="serialNumber"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn field="fullyQualifiedDomainName" />
          <AgGridColumn field="ipAddress" columnGroupShow="open" />
          <AgGridColumn field="macAddress" columnGroupShow="open" />
          <AgGridColumn field="manufacturer" />
          <AgGridColumn field="operatingSystem" columnGroupShow="open" />
          <AgGridColumn field="version" />
        </AgGridColumn>
        <AgGridColumn headerName="Virtual">
          <AgGridColumn
            field="virtualProcessors"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="virtualCores"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn field="vmConfigurationStatus" columnGroupShow="open" />
          <AgGridColumn field="vmState" columnGroupShow="open" />
          <AgGridColumn field="host" />
          <AgGridColumn field="hostOS" />
          <AgGridColumn field="hostOSVersion" columnGroupShow="open" />
          <AgGridColumn field="cluster" columnGroupShow="open" />
          <AgGridColumn field="datacenter" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Validators">
          <AgGridColumn field="isHAEnabled" />
          <AgGridColumn field="isMobilityEnabled" />
          <AgGridColumn field="mobilityBehavior" />
        </AgGridColumn>
        <AgGridColumn headerName="Classifications">
          <AgGridColumn {...useAgGridEnumCol('devicescopes', 'deviceScope')} />
          <AgGridColumn {...useAgGridEnumCol('deviceroles', 'deviceRole')} />
          <AgGridColumn {...useAgGridEnumCol('devicetypes', 'deviceType')} columnGroupShow="open" />
          <AgGridColumn
            {...useAgGridEnumCol('deviceclasses', 'deviceClass')}
            columnGroupShow="open"
          />
          <AgGridColumn
            {...useAgGridEnumCol('devicestates', 'deviceState')}
            columnGroupShow="open"
          />
          <AgGridColumn
            {...useAgGridEnumCol('devicestatuses', 'deviceStatus')}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
        <AgGridColumn headerName="Organization">
          <AgGridColumn field="businessUnitName" />
          <AgGridColumn field="city" columnGroupShow="open" />
          <AgGridColumn field="country" columnGroupShow="open" />
          <AgGridColumn field="region" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Annotanions">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
