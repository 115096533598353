import DeviceSoftwareIcon from '@material-ui/icons/Save';
import DeviceSoftwareList from './DeviceSoftwareList';

import { DeviceSoftwareCreate, DeviceSoftwareEdit, DeviceSoftwareShow } from './DeviceSoftwareForm';

export default {
  list: DeviceSoftwareList,
  show: DeviceSoftwareShow,
  icon: DeviceSoftwareIcon,
  edit: DeviceSoftwareEdit,
  create: DeviceSoftwareCreate,
};
