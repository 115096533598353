import * as React from 'react';
import {
  Create,
  DateField,
  DateInput,
  Edit,
  Labeled,
  NumberField,
  NumberInput,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  required,
  SimpleForm,
  DateTimeInput,
  FunctionField,
} from 'react-admin';
import DataGrid from '../../../components/DataGrid';
import ActionBarShow from '../../../components/ActionBarShow';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownField, MarkdownInput } from '@react-admin/ra-markdown';
import { RuleDisplay, RuleDisplayObservation } from '../../../components/RuleDisplay';
import { AuditLog } from '../../../components/AuditLog';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import CrudWrapper from '../../../components/CrudWrapper';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import { Annotations } from '../../../components/Annotations';
import { sourceTab } from '../../../components/SourceTab';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import EditToolbar from '../../../components/EditToolbar';
import { numberColumnFilter } from '../../../utils/filterParams';

const PageTitle = ({ record }) => {
  return <span>{record ? record.userName : ''}</span>;
};

export const UserShow = (props) => {
  const [hasDocuments, setHasDocuments] = React.useState(false);

  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout style={{ paddingBottom: 40, paddingRight: 40 }}>
        <Tab label="Basic">
          <TextField halfWidth source="userName" label="userName" />
          <TextField halfWidth source="normalizedName" label="normalizedName" />

          <TextField halfWidth source="domain" label="domain" />
          <TextField halfWidth source="ouPath" label="ouPath" />
          <NumberField halfWidth source="daysSinceLastActivity" label="daysSinceLastActivity" />
          <MarkdownField fullWidth source="notes" label="notes" />
        </Tab>
        <Tab label="Extended">
          <AccordionFormPanel label="Claims">
            <TextField fullwidth label="Tag" source="userTag" />
            <TextField halfWidth source="userName" label="userName" />
            <TextField halfWidth source="principalName" label="principalName" />
            <TextField halfWidth source="distinguishedName" label="distinguishedName" />
            <TextField halfWidth source="displayName" label="displayName" />
            <TextField halfWidth source="normalizedName" label="normalizedName" />
            <TextField halfWidth source="emailAddress" label="emailAddress" />
          </AccordionFormPanel>

          <AccordionFormPanel label="Properties">
            <TextField halfWidth source="domain" label="domain" />
            <TextField halfWidth source="ouPath" label="ouPath" />
            <TextField halfWidth source="firstName" label="firstName" />
            <TextField halfWidth source="lastName" label="lastName" />
            <TextField halfWidth source="maidenName" label="maidenName" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Timestamps">
            <DateField halfWidth source="lastActivityDate" label="lastActivityDate" />
            <NumberField halfWidth source="daysSinceLastActivity" label="daysSinceLastActivity" />
            <DateField halfWidth source="whenCreatedDate" label="WhenCreatedDate" />
            <NumberField halfWidth source="daysSinceCreated" label="daysSinceCreated" />
            <DateField halfWidth source="collectionDate" label="collectionDate" />
          </AccordionFormPanel>

          <AccordionFormPanel label="Classification">
            <NewEnumField source="userScope" reference="userscopes" />
            <NewEnumField source="userRole" reference="userroles" />
            <NewEnumField source="userType" reference="usertypes" />
            <NewEnumField source="userClass" reference="userclasses" />
            <NewEnumField source="userState" reference="userstates" />
            <NewEnumField source="userStatus" reference="userstatuses" />
          </AccordionFormPanel>
          <Annotations />
        </Tab>
        <Tab label="Rules">
          <Labeled label="Classification rules">
            <RuleDisplay
              whereReference="userclassificationrules"
              ruleConfigurationKey="UserClassification"
              ruleIndicator={0}
            />
          </Labeled>
          <Labeled label="Qualification rules">
            <RuleDisplay
              whereReference="userqualificationrules"
              ruleConfigurationKey="UserQualification"
              ruleIndicator={1}
            />
          </Labeled>
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>

        <Tab label="Software">
          <ReferenceManyField reference="usersoftware" target="userId" addLabel={false}>
            <DataGrid source="usersoftware">
              <AgGridColumn headerName="Claims">
                <AgGridColumn field="userName" />
                <AgGridColumn field="normalizedName" />
                <AgGridColumn field="softwareTag" />
                <AgGridColumn field="domain" />
              </AgGridColumn>

              <AgGridColumn headerName="Validations">
                <AgGridColumn field="hasLiability" />
                <AgGridColumn field="isMaintenanceRequired" />
                <AgGridColumn field="isCoveredByHostInstall" />
                <AgGridColumn field="isCostSavingsCandidate" />
                <AgGridColumn field="isCostAvoidanceCandidate" />
                <AgGridColumn field="isManual" />
                <AgGridColumn field="isArchived" />
              </AgGridColumn>
              <AgGridColumn headerName="Classifications">
                <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
                <AgGridColumn {...useAgGridEnumCol('userscopes', 'userScope')} />
                <AgGridColumn {...useAgGridEnumCol('userroles', 'userRole')} />
                <AgGridColumn {...useAgGridEnumCol('usertypes', 'userType')} />
                <AgGridColumn {...useAgGridEnumCol('userclasses', 'userClass')} />
                <AgGridColumn {...useAgGridEnumCol('userstates', 'userState')} />
                <AgGridColumn {...useAgGridEnumCol('userstatuses', 'userStatus')} />
              </AgGridColumn>
              <AgGridColumn headerName="Install product">
                <AgGridColumn field="installProductName" />
                <AgGridColumn field="installProductPool" />
                <AgGridColumn field="installProductCategory" />
                <AgGridColumn field="installProductGroup" />
                <AgGridColumn field="installProductFamily" />
                <AgGridColumn field="installVersion" />
                <AgGridColumn field="installEdition" />
                <AgGridColumn
                  field="installQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
              </AgGridColumn>
              <AgGridColumn headerName="Licensed product">
                <AgGridColumn field="licenseProductName" />
                <AgGridColumn field="licenseProductPool" />
                <AgGridColumn field="licenseProductCategory" />
                <AgGridColumn field="licenseProductGroup" />
                <AgGridColumn field="licenseProductFamily" />
                <AgGridColumn field="licenseVersion" />
                <AgGridColumn field="licenseEdition" />
                <AgGridColumn
                  field="licenseQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'installMetric')} />
                <AgGridColumn {...useAgGridEnumCol('licensemodels', 'installModel')} />
              </AgGridColumn>
              <AgGridColumn headerName="Organization">
                <AgGridColumn field="businessUnitName" />
                <AgGridColumn field="city" />
                <AgGridColumn field="country" />
                <AgGridColumn field="region" />
              </AgGridColumn>
              <AgGridColumn headerName="Annotation">
                <AgGridColumn field="notes" />
                <AgGridColumn field="interpretations" />
                <AgGridColumn field="feedback" />
              </AgGridColumn>
              <AgGridColumn headerName="Sources">
                <AgGridColumn
                  headerName="Created by"
                  field="sources"
                  valueGetter={(e) => createdBy(e)}
                />
                <AgGridColumn
                  field="sources"
                  headerName="Updated by"
                  valueGetter={(e) => updatedBy(e)}
                />
              </AgGridColumn>
            </DataGrid>
          </ReferenceManyField>
        </Tab>
        {sourceTab}
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        {hasDocuments && (
          <Tab label="Linked Documents">
            <SimpleForm toolbar={<EmptyToolbar />}>
              <FileUpload source="documents" show />
            </SimpleForm>
          </Tab>
        )}
        <FunctionField
          style={{ diplay: 'none' }}
          label=""
          render={(record) => setHasDocuments(record?.documents?.length > 0)}
        />
      </TabbedShowLayout>
    </Show>
  );
};

const inputs = (
  <AccordionForm autoClose redirect="show" toolbar={<EditToolbar message={'user'} />}>
    <AccordionFormPanel label="Claims">
      <TextInput fullwidth label="Tag" source="userTag" />
      <TextInput halfWidth source="userName" />
      <TextInput halfWidth source="principalName" />
      <TextInput halfWidth source="distinguishedName" />
      <TextInput halfWidth source="displayName" />
      <TextInput halfWidth source="normalizedName" />
      <TextInput halfWidth source="emailAddress" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Properties">
      <TextInput halfWidth source="context" />
      <TextInput halfWidth source="domain" />
      <TextInput halfWidth source="ouPath" />
      <TextInput halfWidth source="firstName" />
      <TextInput halfWidth source="lastName" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Timestamps">
      <DateTimeInput halfWidth source="lastActivityDate" validate={required()} />
      <NumberInput halfWidth source="daysSinceLastActivity" />
      <DateInput halfWidth source="whenCreatedDate" validate={required()} />
      <NumberInput halfWidth source="daysSinceCreated" />
      <DateTimeInput halfWidth source="collectionDate" validate={required()} />
    </AccordionFormPanel>
    <AccordionFormPanel label="Classification">
      <NewEnumInput source="userScope" reference="userscopes" />
      <NewEnumInput source="userRole" reference="userroles" />
      <NewEnumInput source="userType" reference="usertypes" />
      <NewEnumInput source="userClass" reference="userclasses" />
      <NewEnumInput source="userState" reference="userstates" />
      <NewEnumInput source="userStatus" reference="userstatuses" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput halfWidth source="notes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput halfWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput halfWidth source="feedback" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Organization">
      <ReferenceInput source="businessUnitId" reference="businessunits">
        <SelectInput optionText="businessUnitName"></SelectInput>
      </ReferenceInput>
    </AccordionFormPanel>
    <AccordionFormPanel label="Link Documents">
      <FileUpload source="documents" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const UserEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} {...props}>
        {inputs}
      </Edit>
    </CrudWrapper>
  );
};

export const UserCreate = (props) => {
  return (
    <CrudWrapper>
      <Create title={<PageTitle />} {...props}>
        {inputs}
      </Create>
    </CrudWrapper>
  );
};
