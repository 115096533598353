import IconMailUsage from '@material-ui/icons/ContactMail';
import ListMailUsage from './ListMailUsage';
import { MailUsageShow, MailUsageCreate, MailUsageEdit } from './MailUsageForm';

export default {
  list: ListMailUsage,
  create: MailUsageCreate,
  show: MailUsageShow,
  edit: MailUsageEdit,
  icon: IconMailUsage,
};
