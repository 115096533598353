import PricingOptionsIcon from '@material-ui/icons/Money';
import PricingOptionsList from './PricingOptionsList';
import { PricingOptionsCreate, PricingOptionsEdit, PricingOptionsShow } from './PricingOptionsForm';

export default {
  list: PricingOptionsList,
  icon: PricingOptionsIcon,
  edit: PricingOptionsEdit,
  show: PricingOptionsShow,
  create: PricingOptionsCreate,
};
