import DeviceAccessIcon from '@material-ui/icons/Devices';
import { DeviceAccessCreate, DeviceAccessEdit, DeviceAccessShow } from './DeviceAccessForm';
import DeviceAccessList from './DeviceAccessList';

export default {
  list: DeviceAccessList,
  icon: DeviceAccessIcon,
  show: DeviceAccessShow,
  edit: DeviceAccessEdit,
  create: DeviceAccessCreate,
};
