import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  required,
} from 'react-admin';
import CrudWrapper from '../../../components/CrudWrapper';
import FileUpload from '../../../components/FileUpload';
import { sourceTab } from '../../../components/SourceTab';
import { AuditLog } from '../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import ActionBarShow from '../../../components/ActionBarShow';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.rawProductName : ''}</span>;
};

const basicinputs = (
  <SimpleForm redirect="show" toolbar={<EditToolbar message={'domain'} />}>
    <TextInput source="domainName" label="Domain name" validate={required()} />
    <TextInput source="win2KDomain" validate={required()} />
    <TextInput source="distinguishedName" label="DistinguishedName" validate={required()} />
    <TextInput source="mode" label="Mode" />
    <TextInput source="forest" label="Forest" />
    <TextInput source="primaryDomainController" label="PrimaryDomainController" />
    <ReferenceInput source="businessUnitId" reference="businessunits" validate={required()}>
      <SelectInput optionText="businessUnitName" />
    </ReferenceInput>
    <FileUpload source="documents" />
  </SimpleForm>
);

export const DomainsShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField source="domainName" label="Domain name" />
          <TextField source="win2KDomain" />
          <TextField source="distinguishedName" label="DistinguishedName" />
          <TextField source="domainTagSequence" />
          <TextField source="mode" label="Mode" />
          <TextField source="forest" label="Forest" />
          <TextField source="primaryDomainController" label="PrimaryDomainController" />
          <ReferenceField source="businessUnitId" reference="businessunits">
            <TextField source="businessUnitName" />
          </ReferenceField>
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        {sourceTab}
      </TabbedShowLayout>
    </Show>
  );
};

export const DomainsEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {basicinputs}
      </Edit>
    </CrudWrapper>
  );
};

export const DomainsCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {basicinputs}
    </Create>
  </CrudWrapper>
);
