import OrganizationIcon from '@material-ui/icons/Business';
import OrganizationList from './OrganizationList';
import { OrganizationShow, OrganizationEdit, OrganizationCreate } from './OrganizationForm';

export default {
  list: OrganizationList,
  icon: OrganizationIcon,
  show: OrganizationShow,
  edit: OrganizationEdit,
  create: OrganizationCreate,
};
