import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button } from '@material-ui/core';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  closeBtn: {
    position: 'absolute',
    top: 5,
    right: 6,
    color: '#000',
    cursor: 'pointer',
  },
  modalHeader: {
    color: 'black',
    marginBottom: '3rem',
  },
}));

const MeasurementInventoryModal = ({ error, title, errorType = 'error', btnText }) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <CancelPresentationIcon onClick={handleClose} className={classes.closeBtn} />
      <h2 className={classes.modalHeader} id="simple-modal-title">
        {title}
      </h2>
      <div>
        {error?.map((err) => (
          <p style={errorType === 'warning' ? { color: 'orange' } : { color: 'red' }}>
            {err.message}
          </p>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      <Button
        style={errorType === 'warning' ? { color: 'orange' } : { color: 'red' }}
        type="button"
        onClick={handleOpen}
      >
        {btnText}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default MeasurementInventoryModal;
