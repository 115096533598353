const apiUrl = process.env.REACT_APP_API_URL;

const getRequestOptionsJson = () => {
  return {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
  };
};

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.title);
  }
  return response;
};

export default {
  ignoreMultiple: (data) => {
    let sendData = [];

    data.forEach((element) => {
      sendData.push(element.id);
    });

    const request = new Request(apiUrl + `/fingerprints/ignoreMultiple`, {
      method: 'POST',
      body: JSON.stringify(sendData),
      ...getRequestOptionsJson(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
  reassignFingerprints: () => {
    const request = new Request(apiUrl + `/fingerprints/reassignfingerprints`, {
      method: 'POST',
      ...getRequestOptionsJson(),
    });

    return fetch(request)
      .then(handleErrors)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  },
};
