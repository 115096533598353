import * as React from 'react';
import DataGrid from '../../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { dateFormatter } from '../../../../utils/formatters';
import { useAgGridEnumCol } from '../../../../types/NewEnumCell';
import { dateColumnFilter } from '../../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  const { permissions } = usePermissions();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {(permissions === 'admin' || permissions === 'planner' || permissions === 'analyst') && (
        <CreateButton basePath={basePath} />
      )}
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Projects" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn field="projectName" />
        <AgGridColumn field="organizationName" />
        <AgGridColumn
          field="startDate"
          valueFormatter={dateFormatter}
          filter="agMultiColumnFilter"
          filterParams={dateColumnFilter}
        />
        <AgGridColumn
          field="endDate"
          valueFormatter={dateFormatter}
          filter={'agMultiColumnFilter'}
          filterParams={dateColumnFilter}
        />
        <AgGridColumn {...useAgGridEnumCol('projectscopes', 'projectScope')} />
        <AgGridColumn {...useAgGridEnumCol('projecttypes', 'projectType')} />
        <AgGridColumn {...useAgGridEnumCol('projectclasses', 'projectClass')} />
        <AgGridColumn {...useAgGridEnumCol('projectstatuses', 'projectStatus')} />
      </DataGrid>
    </List>
  );
};
