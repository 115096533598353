import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import {
  Button,
  List,
  sanitizeListRestProps,
  TopToolbar,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useHistory } from 'react-router';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { getScope } from '../../../utils/scopeQuery';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';
import { useFetch } from '../../../utils/useFetch';
import { numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const refresh = useRefresh();
  const notify = useNotify();
  const updateCountsFetch = useFetch(
    `/measurements/updatecounts/${getScope().measurementId}`,
    'POST',
    null,
    true,
  );

  const updateCounts = async () => {
    await updateCountsFetch().then((response) => notify('Counts updated!'));
    refresh();
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button onClick={updateCounts} label={'Update counts'}>
        <AutorenewIcon />
      </Button>{' '}
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List
      {...props}
      title="License Positions"
      actions={<ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions />} />}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked} source="licensepositions">
        <AgGridColumn headerName="Product">
          <AgGridColumn field="productName" />
          <AgGridColumn field="productCategory" />
          <AgGridColumn field="productGroup" columnGroupShow="open" />
          <AgGridColumn field="productFamily" columnGroupShow="open" />
          <AgGridColumn field="productPool" columnGroupShow="open" />
          <AgGridColumn field="manufacturer" />
          <AgGridColumn field="edition" />
          <AgGridColumn field="version" />
          <AgGridColumn
            field="buildNumber"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn field="excludedFromReports" />
        </AgGridColumn>
        <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
        <AgGridColumn headerName="Licenses">
          <AgGridColumn
            field="volumeLicenseQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="recycledLicenseQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="retailLicenseQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="oemLicenseQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="totalLicenseQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="activeMaintenanceLicenseQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
        </AgGridColumn>
        <AgGridColumn headerName="Deployments">
          <AgGridColumn
            field="physicalDeploymentQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="virtualDeploymentQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="hostDeploymentQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="userDeploymentQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="manualDeploymentQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="totalDeploymentQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
        </AgGridColumn>
        <AgGridColumn headerName="Delta">
          <AgGridColumn
            field="positionBeforeCorrectionQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="correctionQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="positionAfterCorrectionQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="upgradeLicenseQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="upgradeWithMaintenanceLicenseQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
        </AgGridColumn>
        <AgGridColumn headerName="Organization">
          <AgGridColumn field="businessUnitName" />
          <AgGridColumn field="city" columnGroupShow="open" />
          <AgGridColumn field="country" columnGroupShow="open" />
          <AgGridColumn field="region" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Annotation">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
