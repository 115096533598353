import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { TopToolbar, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {/* <CreateButton basePath={basePath} /> */}
    </TopToolbar>
  );
};

export default (props) => {
  return (
    <List {...props} title="Available Observations" actions={<ListActions />}>
      <DataGrid {...props}>
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="objectType" headerName="sourceType" />
          <AgGridColumn field="objectTag" headerName="sourceTag" />
          <AgGridColumn field="objectName" headerName="sourceName" />
          <AgGridColumn field="baseObservation.observationType" headerName="observationType" />
          <AgGridColumn field="baseObservation.observationTag" headerName="observationTag" />
          <AgGridColumn field="observation" />
          <AgGridColumn field="action" />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
