import React, { useEffect, useState } from 'react';
import { TextInput, SimpleForm, Toolbar, Button } from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import { useNotify } from 'ra-core';
import { Typography } from '@material-ui/core';
import { useFetch } from '../../utils/useFetch';

const labelStyle = {
  color: 'rgba(0, 0, 0, 0.54)',
  marginTop: 40,
};
const textStyle = {
  color: 'rgba(0, 0, 0)',
  lineHeight: 2.5,
  letterSpacing: '0.00938em',
  marginBottom: 20,
};

export const ProfileEdit = (props) => {
  const [data, setData] = useState();
  const notify = useNotify();
  const profileId = localStorage.getItem('profile-id');
  const fetchProfile = useFetch(`/platformusers/${profileId}`, 'GET');
  const fetchProfileChange = useFetch(`/platformusers/update/${profileId}`, 'PUT', data);

  useEffect(() => {
    const getProfileData = async () => {
      const response = await fetchProfile();
      setData(response);
    };
    getProfileData();
  }, []);

  const changeFirstName = (e) => {
    setData({ ...data, firstName: e.target.value });
  };

  const changeLastName = (e) => {
    setData({ ...data, lastName: e.target.value });
  };

  const saveProfileChanges = async () => {
    await fetchProfileChange().then((e) => notify('Profile updated!'));
  };

  const ProfileToolbar = (props) => (
    <Toolbar {...props}>
      <Button
        variant="contained"
        children={<SaveIcon />}
        label="save"
        onClick={() => saveProfileChanges()}
      />
    </Toolbar>
  );

  return (
    <div style={{ padding: '2rem', backgroundColor: 'white' }}>
      <h2>Profile page</h2>
      <SimpleForm toolbar={<ProfileToolbar />}>
        <TextInput
          source="firstName"
          label="First name"
          onChange={(e) => changeFirstName(e)}
          initialValue={data?.firstName}
        />
        <TextInput
          onChange={(e) => changeLastName(e)}
          type="text"
          source="lastName"
          label="Last name"
          initialValue={data?.lastName}
        />

        <Typography style={labelStyle} variant="caption">
          Role
        </Typography>
        <Typography style={textStyle} variant="p">
          {data?.role}
        </Typography>
        <Typography style={labelStyle} variant="caption">
          Email
        </Typography>
        <Typography style={textStyle} variant="p">
          {data?.email}
        </Typography>
      </SimpleForm>
    </div>
  );
};
