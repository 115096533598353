import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextField,
  TabbedShowLayout,
  Tab,
  NumberField,
  BooleanField,
  BooleanInput,
  Labeled,
  ReferenceField,
  NumberInput,
} from 'react-admin';

import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import ActionBarShow from '../../../components/ActionBarShow';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { RuleDisplay, RuleDisplayObservation } from '../../../components/RuleDisplay';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import { Annotations } from '../../../components/Annotations';
import { DeviceInput } from '../../../components/CustomInputs';
import FilteringProducts from '../../../components/FilteringProducts';
import { sourceTab } from '../../../components/SourceTab';
import CrudWrapper from '../../../components/CrudWrapper';
import { AuditLog } from '../../../components/AuditLog';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

const inputs = (
  <AccordionForm redirect="show" autoClose toolbar={<EditToolbar message={'device software'} />}>
    <AccordionFormPanel label="Basic">
      <DeviceInput />
    </AccordionFormPanel>
    <AccordionFormPanel label="Installed product">
      <FilteringProducts source="installProductId" />
      <NewEnumInput source="installMetric" reference="licensemetrics" />
      <NewEnumInput source="installType" reference="installtypes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="License">
      <FilteringProducts source="licenseProductId" />
      <NewEnumInput source="licenseMetric" reference="licensemetrics" />
      <NewEnumInput source="licenseModel" reference="licensemodels" />
      <NumberInput source="licenseQuantity" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Properties">
      <BooleanInput source="isCostAvoidanceCandidate" />
      <BooleanInput source="isCostSavingsCandidate" />
      <BooleanInput source="isCoveredByHostInstall" />
      <BooleanInput source="hasLiability" label="hasLiability" />
      <BooleanInput source="isMaintenanceRequired" label="isMaintenanceRequired" />
      <BooleanInput source="isManual" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput fullWidth source="notes" Label="" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput fullWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput fullWidth source="feedback" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const DeviceSoftwareShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField reference="devices" source="deviceId">
            <TextField source="deviceName" />
          </ReferenceField>
          <ReferenceField reference="products" source="installProductId">
            <TextField source="productName" />
          </ReferenceField>
          <ReferenceField reference="products" source="licenseProductId">
            <TextField source="productName" />
          </ReferenceField>
        </Tab>
        <Tab label="Extended">
          <AccordionFormPanel label="Claims">
            <TextField source="softwareTag" label="softwareTag" />
            <BooleanField source="isCostAvoidanceCandidate" />
            <BooleanField source="isCostSavingsCandidate" />
            <BooleanField source="isCoveredByHostInstall" />
            <BooleanField source="isCoveredByHostInstall" />
            <BooleanField source="hasLiability" label="hasLiability" />
            <BooleanField source="isMaintenanceRequired" label="isMaintenanceRequired" />
            <BooleanField source="isManual" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Installation">
            <ReferenceField reference="products" source="installProductId" label="manufacturer">
              <TextField source="manufacturerName" />
            </ReferenceField>
            <ReferenceField reference="products" source="installProductId" label="productCategory">
              <TextField source="productCategory" />
            </ReferenceField>
            <ReferenceField reference="products" source="installProductId" label="productGroup">
              <TextField source="productGroup" />
            </ReferenceField>
            <ReferenceField reference="products" source="installProductId" label="productFamily">
              <TextField source="productFamily" />
            </ReferenceField>
            <ReferenceField reference="products" source="installProductId" label="edition">
              <TextField source="edition" />
            </ReferenceField>
            <ReferenceField reference="products" source="installProductId" label="version">
              <TextField source="version" />
            </ReferenceField>
            <NewEnumField source="installMetric" reference="licensemetrics" />
            <NewEnumField source="installType" reference="installtypes" />
          </AccordionFormPanel>
          <AccordionFormPanel label="License">
            <ReferenceField reference="products" source="licenseProductId" label="manufacturer">
              <TextField source="manufacturerName" />
            </ReferenceField>
            <ReferenceField reference="products" source="licenseProductId" label="productCategory">
              <TextField source="productCategory" />
            </ReferenceField>
            <ReferenceField reference="products" source="licenseProductId" label="productGroup">
              <TextField source="productGroup" />
            </ReferenceField>
            <ReferenceField reference="products" source="licenseProductId" label="productFamily">
              <TextField source="productFamily" />
            </ReferenceField>
            <ReferenceField reference="products" source="licenseProductId" label="edition">
              <TextField source="edition" />
            </ReferenceField>
            <ReferenceField reference="products" source="licenseProductId" label="version">
              <TextField source="version" />
            </ReferenceField>
            <NumberField source="licenseQuantity" />
            <NewEnumField source="licenseMetric" reference="licensemetrics" />
            <NewEnumField source="licenseModel" reference="licensemodels" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Properties">
            <TextField source="fullyQualifiedDomainName" />
            <NumberField source="processors" />
            <NumberField source="coresPerProcessor" />
            <NumberField source="totalCores" />
            <NumberField source="logicalCores" />
          </AccordionFormPanel>
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        <Tab label="Rules">
          <Labeled label="Classification rules">
            <RuleDisplay
              whereReference="softwarerules"
              ruleConfigurationKey="Software"
              ruleIndicator={0}
            />
          </Labeled>
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>

        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        {sourceTab}
      </TabbedShowLayout>
    </Show>
  );
};

export const DeviceSoftwareEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {inputs}
      </Edit>
    </CrudWrapper>
  );
};

export const DeviceSoftwareCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {inputs}
    </Create>
  </CrudWrapper>
);
