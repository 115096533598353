import ObservationIcon from '@material-ui/icons/LinkedCamera';
import ObservationList from './ObservationList';
import { ObservationShow, ObservationEdit, ObservationCreate } from './ObservationForm';



export default {
  list: ObservationList,
  icon: ObservationIcon,
  show: ObservationShow,
  edit: ObservationEdit,
  create: ObservationCreate,


};
