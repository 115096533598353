import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  ReferenceInput,
  ReferenceField,
  required,
  NumberField,
  DateField,
  NumberInput,
  AutocompleteInput,
  DateInput,
} from 'react-admin';
import CrudWrapper from '../../../components/CrudWrapper';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import FilteringProducts from '../../../components/FilteringProducts';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.rawProductName : ''}</span>;
};

const fields = (
  <TabbedShowLayout>
    <Tab label="Basic">
      <ReferenceField source="purchaseId" reference="purchases">
        <TextField source="purchaseNumber" />
      </ReferenceField>
      <NumberField source="licenseQuantity" label="licenseQuantity" />
      <NumberField source="sku" label="sku" />
      <NumberField source="maintenanceQuantity" label="maintenanceQuantity" />
      <NumberField source="upgradeQuantity" label="upgradeQuantity" />
      <DateField source="maintenanceStart" label="maintenanceStart" />
      <DateField source="maintenanceEnd" label="maintenanceEnd" />
      <TextField source="sku" label="sku" />
      <NewEnumField source="licenseMetric" reference="licensemetrics" />
      <NewEnumField source="licenseModel" reference="licensemodels" />
      <TextField source="purchaseDescription" label="purchaseDescription" />
      <TextField source="productName" label="productName" />
    </Tab>
  </TabbedShowLayout>
);

const basicinputs = (
  <SimpleForm redirect="show" toolbar={<EditToolbar message={'purchase line'} />}>
    <ReferenceInput source="purchaseId" reference="purchases">
      <AutocompleteInput optionText="purchaseNumber" validate={required()} />
    </ReferenceInput>
    <FilteringProducts source="productId" />
    <NumberInput source="licenseQuantity" label="licenseQuantity" validate={required()} />
    <NumberInput source="maintenanceQuantity" label="maintenanceQuantity" validate={required()} />
    <NumberInput source="upgradeQuantity" label="upgradeQuantity" validate={required()} />
    <DateInput source="maintenanceStart" label="maintenanceStart" validate={required()} />
    <DateInput source="maintenanceEnd" label="maintenanceEnd" validate={required()} />
    <NewEnumInput source="licenseMetric" reference="licensemetrics" />
    <NewEnumInput source="licenseModel" reference="licensemodels" />
    <TextInput source="sku" label="sku" />
  </SimpleForm>
);

export const PurchaseLinesShow = (props) => {
  return (
    <Show title={<PageTitle />} {...props}>
      {fields}
    </Show>
  );
};

export const PurchaseLinesEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {basicinputs}
      </Edit>
    </CrudWrapper>
  );
};

export const PurchaseLinesCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {basicinputs}
    </Create>
  </CrudWrapper>
);
