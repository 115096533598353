import ManufacturerIcon from '@material-ui/icons/AssignmentInd';
import ManufacturerList from './ManufacturerList';
import { ManufacturerShow, ManufacturerEdit, ManufacturerCreate } from './ManufacturerForm';

export default {
  list: ManufacturerList,
  icon: ManufacturerIcon,
  show: ManufacturerShow,
  edit: ManufacturerEdit,
  create: ManufacturerCreate,
};
