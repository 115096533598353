import React from 'react';

import {
  FunctionField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from 'ra-ui-materialui';

export const DeviceSoftwareField = (
  <ReferenceField source="softwareId" reference="devicesoftware">
    <FunctionField
      render={(record) =>
        `${record?.deviceName} / ${record?.installProductName} ${record?.installVersion}`
      }
      label=""
    />
  </ReferenceField>
);

export const UserSoftwareField = (
  <ReferenceField source="softwareId" reference="usersoftware">
    <FunctionField
      render={(record) =>
        `${record?.userName} / ${record?.installProductName} ${record?.installVersion}`
      }
      label=""
    />
  </ReferenceField>
);

export const DeviceSoftwareInput = (
  <ReferenceInput source="softwareId" reference="devicesoftware">
    <SelectInput
      optionText={(record) =>
        `${record?.deviceName} / ${record?.installProductName} ${record?.installVersion}`
      }
    />
  </ReferenceInput>
);

export const UserSoftwareInput = (
  <ReferenceInput source="softwareId" reference="usersoftware">
    <SelectInput
      optionText={(record) =>
        `${record?.userName} / ${record?.installProductName} ${record?.installVersion}`
      }
    />
  </ReferenceInput>
);

export const DeviceSoftwareColDef = ({ software }) =>
  `${software?.device?.deviceName} / ${software?.installProduct?.productName} ${software?.installProduct?.version}`;

export const UserSoftwareColDef = ({ software }) =>
  `${software?.user?.userName} / ${software?.installProduct?.productName} ${software?.installProduct?.version}`;
