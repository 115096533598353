import * as React from 'react';
import {
  Edit,
  TextInput,
  Create,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  required,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  ReferenceField,
  FunctionField,
} from 'react-admin';

import ActionBarShow from '../../../components/ActionBarShow';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import CrudWrapper from '../../../components/CrudWrapper';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import FilteringProducts from '../../../components/FilteringProducts';
import { Annotations } from '../../../components/Annotations';
import { sourceTab } from '../../../components/SourceTab';
import { AuditLog } from '../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.businessUnitName : ''}</span>;
};

const inputs = (
  <AccordionForm redirect="show" toolbar={<EditToolbar message={'AD group'} />}>
    <AccordionFormPanel label="Details" defaultExpanded>
      <TextInput source="adGroupName" validate={required()} />
      <ReferenceInput source="parentGroup" reference="adgroups">
        <SelectInput optionText="adGroupName" />
      </ReferenceInput>
      <TextInput source="distinguishedName" />
      <ReferenceInput source="organizationalUnit" reference="organizationalunits">
        <SelectInput optionText="organizationalUnitTag" />
      </ReferenceInput>
      <FilteringProducts source="productId" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput fullWidth source="notes" Label="" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput fullWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Link Documents">
      <FileUpload source="documents" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const ADGroupShow = (props) => {
  const [hasDocuments, setHasDocuments] = React.useState(false);

  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab selected label="Details">
          <TextField source="adGroupTag" label="adGroupTag" />
          <TextField source="adGroupName" label="adGroupName" />
          <TextField source="parentGroup" />
          <TextField source="distinguishedName" label="distinguishedName" />
          <TextField source="organizationalUnit" />
          <ReferenceField source="manufacturerId" reference="manufacturers">
            <TextField source="manufacturerKey" />
          </ReferenceField>
          <TextField source="productGroup" />
          <ReferenceField source="productId" reference="products">
            <FunctionField
              label="product"
              render={(record) => record.productName + ' ' + record.version}
            />
          </ReferenceField>
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Annotations">
          <Annotations noFeedback />
        </Tab>
        {sourceTab}
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        <FunctionField
          style={{ diplay: 'none' }}
          label=""
          render={(record) => setHasDocuments(record?.documents?.length > 0)}
        />
        {hasDocuments && (
          <Tab label="Linked Documents">
            <SimpleForm toolbar={<EmptyToolbar />}>
              <FileUpload source="documents" show />
            </SimpleForm>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

export const ADGroupEdit = (props) => (
  <CrudWrapper>
    <Edit undoable={false} title={<PageTitle />} {...props}>
      {inputs}
    </Edit>
  </CrudWrapper>
);

export const ADGroupCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {inputs}
    </Create>
  </CrudWrapper>
);
