import ProjectIcon from '@material-ui/icons/FolderShared';
import ProjectList from './ProjectList';
import { ProjectShow, ProjectEdit, ProjectCreate } from './ProjectForm';

export default {
  list: ProjectList,
  icon: ProjectIcon,
  show: ProjectShow,
  edit: ProjectEdit,
  create: ProjectCreate,
};
