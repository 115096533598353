import * as React from 'react';
import { Route } from 'react-router-dom';
import UserImport from './containers/Organizations/User/UserImport';
import ResetPassword from './providers/ResetPassword';
import SuccessReset from './providers/SuccessReset';
import ConfirmEmail from './providers/ConfirmEmail';
import ConfirmResetPassword from './providers/ConfirmResetPassword';
import WizardInventory from './containers/Measurements/wizardForm/WizardInventory';
import OfficeLogin from './providers/OfficeLogin';
import { ProfileEdit } from './containers/Profile/ProfileForm';

export default [
  <Route exact path="/import/ad//success-resetusers" render={() => <UserImport />} />,
  <Route noLayout exact path="/reset-password" render={() => <ResetPassword />} />,
  <Route noLayout exact path="/success-reset" render={() => <SuccessReset />} />,
  <Route noLayout exact path="/confirmemail" render={() => <ConfirmEmail />} />,
  <Route noLayout exact path="/resetpassword" render={() => <ConfirmResetPassword />} />,
  <Route noLayout exact path="/officelogin" render={() => <OfficeLogin />} />,
  <Route exact path="/wizardinventory/:id" render={() => <WizardInventory />} />,
  <Route exact path="/my-profile" render={() => <ProfileEdit />} />,
];
