import * as React from 'react';
import DataGrid from '../../../components/DataGrid';

import { List } from 'react-admin';
import { useHistory } from 'react-router';
import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List
      {...props}
      title="EPP Usage"
      actions={<ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions />} />}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="deviceName" />
          <AgGridColumn field="productName" />
          <AgGridColumn field="manufacturer" />
          <AgGridColumn field="version" />
          <AgGridColumn field="isManaged" columnGroupShow="open" />
          <AgGridColumn field="isCostAvoidanceCandidate" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Classifications">
          <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
          <AgGridColumn {...useAgGridEnumCol('deviceroles', 'deviceRole')} />
          <AgGridColumn {...useAgGridEnumCol('devicetypes', 'deviceType')} columnGroupShow="open" />
          <AgGridColumn
            {...useAgGridEnumCol('deviceclasses', 'deviceClass')}
            columnGroupShow="open"
          />
          <AgGridColumn
            {...useAgGridEnumCol('devicestates', 'deviceState')}
            columnGroupShow="open"
          />
          <AgGridColumn
            {...useAgGridEnumCol('devicestatuses', 'deviceStatus')}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Annotation">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
