import React from 'react';
import {
  Edit,
  Show,
  Create,
  Tab,
  TextInput,
  TextField,
  TabbedShowLayout,
  SimpleForm,
  SelectInput,
  BooleanField,
  BooleanInput,
  FunctionField,
  ReferenceField,
  Datagrid,
  ReferenceManyField,
} from 'react-admin';

import FilteringProducts from '../../../components/FilteringProducts';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import EditToolbar from '../../../components/EditToolbar';
import DateTimeFormatter from '../../../utils/dateTimeFormatter';

const PageTitle = ({ record }) => {
  return <span>{record ? record.rawProductName : ''}</span>;
};

export const FingerprintShow = (props) => {
  const [hasDocuments, setHasDocuments] = React.useState(false);

  return (
    <Show title={<PageTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField halfWidth source="rawManufacturer" />
          <TextField fullWidth source="rawProductName" />
          <TextField halfWidth source="rawVersion" />
          <TextField halfWidth source="rawMetric" />
          <TextField fullWidth source="productName" />
          <TextField halfWidth source="productVersion" />
          <TextField halfWidth source="productEdition" />
          <TextField halfWidth source="productManufacturer" />
          <BooleanField halfWidth source="isIgnorable" />
        </Tab>
        <Tab label="Sources">
          <ReferenceManyField reference="sources" target="fingerprintId" label="Sources">
            <Datagrid>
              <TextField source="sourceFile" label="Source File" />
              <TextField source="sourceType" label="Source Type" />
              <FunctionField
                label="Import Date"
                render={(record) => <DateTimeFormatter date={record.importDate} />}
              />
              <ReferenceField reference="measurements" source="measurementId">
                <TextField source="measurementName" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        {hasDocuments && (
          <Tab label="Linked Documents">
            <SimpleForm toolbar={<EmptyToolbar />}>
              <FileUpload source="documents" show />
            </SimpleForm>
          </Tab>
        )}
        <FunctionField
          style={{ diplay: 'none' }}
          label=""
          render={(record) => setHasDocuments(record?.documents?.length > 0)}
        />
      </TabbedShowLayout>
    </Show>
  );
};

const choices = [
  { id: 'Licenses' },
  { id: 'MSI Query' },
  { id: 'Microsoft MAP Toolkit' },
  { id: 'AD' },
  { id: 'Dell Wyse Device Manager' },
  { id: 'HP Device Manager' },
  { id: 'Igel UMS' },
  { id: 'VMware vSphere' },
  { id: 'MS Online' },
];

const basicinputs = (
  <SimpleForm redirect="show" toolbar={<EditToolbar message={'fingerprint'} />}>
    <TextInput halfWidth source="rawManufacturer" />
    <TextInput halfWidth source="rawProductName" />
    <TextInput halfWidth source="rawVersion" />
    <TextInput halfWidth source="rawMetric" />
    <FilteringProducts source="productId" />
    <SelectInput source="source" choices={choices} optionText="id" />
    <BooleanInput fullWidth source="isIgnorable" />
    <FileUpload source="documents" />
  </SimpleForm>
);

export const FingerprintEdit = (props) => {
  return (
    <Edit undoable={false} title={<PageTitle />} {...props}>
      {basicinputs}
    </Edit>
  );
};

export const FingerprintCreate = (props) => (
  <Create title="New" {...props}>
    {basicinputs}
  </Create>
);
