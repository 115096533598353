import React from 'react';
import {
  Show,
  TextInput,
  TextField,
  Create,
  SimpleForm,
  useNotify,
  useRedirect,
  SimpleShowLayout,
  Button,
  FunctionField,
  TopToolbar,
  useDelete,
} from 'react-admin';
import { CrudWrapperOrg } from '../../../components/CrudWrapper';
import DeleteIcon from '@material-ui/icons/Delete';

const PageTitle = ({ record }) => {
  return <span>{record ? record.userName : ''}</span>;
};

const DeleteShow = (props) => {
  const [deleteOne] = useDelete(props.resource, props?.id, props.data);
  const notify = useNotify();
  const redirect = useRedirect();

  const deleteItem = () => {
    deleteOne();
    redirect(props.resource);
    notify('User deleted');
  };

  console.log(props);
  return (
    <TopToolbar>
      <Button
        label="Delete"
        color="secondary"
        {...props}
        onClick={deleteItem}
        children={<DeleteIcon />}
      />
    </TopToolbar>
  );
};

export const SmartSassClientsShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<DeleteShow {...props} />} {...props}>
      <SimpleShowLayout>
        <TextField halfWidth source="email" label="Email" />
        <FunctionField
          label="password"
          render={(record) =>
            props.location.state?.password ? props.location.state?.password : '- - -'
          }
        />
        <FunctionField
          label=" "
          render={(record) =>
            props.location.state?.password ? (
              <p style={{ fontSize: '0.8rem', color: 'red' }}>
                This password will not show again. Be sure to copy and save it.{' '}
              </p>
            ) : (
              ''
            )
          }
        />
      </SimpleShowLayout>
    </Show>
  );
};

export const SmartSassClientsCreate = (props) => {
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    redirect('show', '/smartsaasclients', data.id, {}, { password: data.password });
  };

  return (
    <CrudWrapperOrg>
      <Create {...props} title={<PageTitle />} onSuccess={onSuccess}>
        <SimpleForm submitOnEnter={false}>
          <TextInput type="email" source="email" label="email" />
          <TextInput
            type="password"
            source="password"
            label="Password is randomly generated"
            disabled
          />
        </SimpleForm>
      </Create>
    </CrudWrapperOrg>
  );
};
