import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { ListItemIcon, IconButton, ListItem, ListItemText } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  LinearProgress,
  ReferenceArrayInput,
  useInput,
  FunctionField,
  TextField,
  Datagrid,
  Toolbar,
} from 'react-admin';
import UploadProvider from '../providers/APIProviders/UploadProvider';

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const FileUpload = (props) => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [fileIds, setFileIds] = useState([]);

  const {
    input: { onChange, value },
  } = useInput(props);

  const onDrop = (uploadedFile) => {
    postUploadFiles(uploadedFile);
  };

  const handleDelete = async (clickedId) => {
    await UploadProvider.deleteDocument(clickedId);
    const removeItem = value.filter((id) => {
      return id !== clickedId;
    });
    onChange([...removeItem]);
  };

  const postUploadFiles = async (data) => {
    setIsFileUploading(true);

    var formData = new FormData();

    data.forEach((file) => {
      formData.append('file', file);
    });

    let response = await UploadProvider.uploadDocument(formData);
    setIsFileUploading(false);
    const newFileIds = [...fileIds, response.id];
    setFileIds((id) => newFileIds);
    onChange([...value, response.id]);
  };

  const FileList = ({ record }) => (
    <ListItem key={record.id}>
      {isFileUploading ? (
        <LinearProgress />
      ) : (
        <>
          <ListItemIcon>
            <IconButton id={record.fileName} onClick={() => handleDelete(record.id)}>
              <RemoveCircleIcon className="deleteIconOnDrop" />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary={record.fileName} secondary={formatBytes(record.fileSize)} />
        </>
      )}
    </ListItem>
  );
  return !props.show ? (
    <Dropzone multiple={false} onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <section className="container">
          <div style={dropdownStyle} {...getRootProps({ dropdownStyle })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop documents here, or click to select files</p>
          </div>
          <aside>
            <ReferenceArrayInput
              source="documents"
              reference="document"
              filter={{ id: value }}
              /*               defaultValue={[]}
               */
            >
              <Datagrid>
                <FunctionField
                  render={(record) => {
                    return <FileList record={record} />;
                  }}
                />
              </Datagrid>
            </ReferenceArrayInput>
          </aside>
        </section>
      )}
    </Dropzone>
  ) : (
    <ReferenceArrayInput source="documents" reference="document" filter={{ id: value }}>
      <Datagrid>
        <TextField source="fileName" />
        <FunctionField render={(record) => formatBytes(record.fileSize)} />
        <FunctionField
          render={(record) => (
            <ListItemIcon>
              <IconButton href={record.downloadUrl}>
                <CloudDownloadIcon />
              </IconButton>
            </ListItemIcon>
          )}
        />
      </Datagrid>
    </ReferenceArrayInput>
  );
};

export default FileUpload;

const dropdownStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

export const EmptyToolbar = (props) => {
  return (
    <Toolbar style={{ display: 'none' }} {...props}>
      <hr />
    </Toolbar>
  );
};
