import PriceListIcon from '@material-ui/icons/Payment';
import PriceListList from './PriceListList';
import { PriceListShow, PriceListEdit, PriceListCreate } from './PriceListForm';

export default {
  list: PriceListList,
  icon: PriceListIcon,
  show: PriceListShow,
  edit: PriceListEdit,
  create: PriceListCreate,
};
