import { AccordionFormPanel } from '@react-admin/ra-form-layout/lib/src/forms';
import { MarkdownField } from '@react-admin/ra-markdown';
import React from 'react';
import { useRecordContext } from 'react-admin';

export const Annotations = ({ noFeedback }) => {
  const record = useRecordContext();
  return (
    <>
      <AccordionFormPanel label="Notes" defaultExpanded={record?.notes}>
        <MarkdownField fullWidth source="notes" record={record} />
      </AccordionFormPanel>
      <AccordionFormPanel label="Interpretations" defaultExpanded={record?.interpretations}>
        <MarkdownField fullWidth source="interpretations" record={record} />
      </AccordionFormPanel>
      {!noFeedback && (
        <AccordionFormPanel label="Feedback" defaultExpanded={record?.feedback}>
          <MarkdownField fullWidth source="feedback" record={record} />
        </AccordionFormPanel>
      )}
    </>
  );
};
