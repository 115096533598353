import React, { useState } from 'react';
import { Button as RAButton, useTranslate, useDataProvider, useGetList } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  Select,
  InputLabel,
  MenuItem,
  LinearProgress,
} from '@material-ui/core';
export default (props) => {
  let refInput = null;

  const translate = useTranslate();
  const [result, setResult] = useState('');
  const sourceTypes = ['csvde', 'LdScript', 'SamosCloud'];
  const targets = ['all', 'groups', 'users', 'computers', 'organizationalunits'];
  const [target, setTarget] = useState(targets[0]);
  const dataProvider = useDataProvider();
  const [formData, setFormData] = useState({
    formFile: null,
    fileName: null,
    BusinessUnitName: '',
    SourceType: sourceTypes[0],
  });

  const { data, loading } = useGetList('businessunits');

  const updateFormData = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const onFileAdded = (e) => {
    const file = e.target.files && e.target.files[0];

    setFormData({
      ...formData,
      fileName: file.name,
      formFile: file,
    });
  };

  const reset = () => {
    setFormData({
      ...formData,
      fileName: null,
      formFile: null,
    });
  };

  const clickImportButton = () => {
    refInput.value = '';
    refInput.click();
    reset();
  };

  const uploadFile = (event) => {
    let postFormData = new FormData();
    postFormData.append('formFile', formData.formFile);
    postFormData.append('fileName', formData.fileName);
    postFormData.append('BusinessUnitName', formData.BusinessUnitName);
    postFormData.append('SourceType', formData.SourceType);

    let targetUrl = `/${target}`;
    if (target === 'all') {
      targetUrl = '';
    }

    dataProvider
      .import(`import/ad${targetUrl}/43f163dd-fa7f-4448-cad1-08d885c0fce0`, postFormData)
      .then((result) => {
        setResult(`${result.data.length} resultaten geimporteerd`);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const businessUnits = () => {
    if (loading) return <LinearProgress />;
    if (data)
      return Object.keys(data).map((key) => (
        <MenuItem value={data[key].id} key={data[key].id}>
          {data[key].businessUnitName}
        </MenuItem>
      ));
    return null;
  };

  return (
    <>
      <FormControl>
        <RAButton
          color="primary"
          component="span"
          label="Select a file"
          onClick={clickImportButton}
        >
          <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: '20' }} />
        </RAButton>
        <input
          ref={(ref) => (refInput = ref)}
          type="file"
          style={{ display: 'none' }}
          onChange={onFileAdded}
          accept=".csv,.tsv"
        />
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">{translate('sourceType')}</FormLabel>
        <RadioGroup
          aria-label="sourceType"
          name="sourceType"
          value={formData.SourceType}
          onChange={(event) => updateFormData('SourceType', event.target.value)}
        >
          {sourceTypes.map((sourceType) => (
            <FormControlLabel
              value={sourceType}
              control={<Radio />}
              label={sourceType}
              key={sourceType}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">Target</FormLabel>
        <RadioGroup
          aria-label="target"
          name="target"
          value={target}
          onChange={(event) => setTarget(event.target.value)}
        >
          {targets.map((target) => (
            <FormControlLabel value={target} control={<Radio />} label={target} key={target} />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl>
        <InputLabel id="business-unit-label">{translate('businessUnitName')}</InputLabel>
        <Select
          labelId="business-unit-label"
          id="business-unit"
          value={formData.BusinessUnitName}
          onChange={(event) => updateFormData('BusinessUnitName', event.target.value)}
        >
          {businessUnits()}
        </Select>
      </FormControl>
      <p>{result}</p>
      <RAButton
        variant="contained"
        color="primary"
        label={translate('import')}
        onClick={uploadFile}
      />
    </>
  );
};
