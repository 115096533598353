import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  ReferenceInput,
  ReferenceField,
  required,
  DateField,
  ReferenceManyField,
  DateInput,
  AutocompleteInput,
} from 'react-admin';
import CrudWrapper from '../../../components/CrudWrapper';
import DataGrid from '../../../components/DataGrid';
import { AgGridColumn } from 'ag-grid-react';
import { useHistory } from 'react-router';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';
import { dateFormatter } from '../../../utils/formatters';
import EditToolbar from '../../../components/EditToolbar';
import { dateColumnFilter, numberColumnFilter } from '../../../utils/filterParams';

const PageTitle = ({ record }) => {
  return <span>{record ? record.rawProductName : ''}</span>;
};

const basicinputs = (
  <SimpleForm redirect="show" toolbar={<EditToolbar message={'purchase'} />}>
    <ReferenceInput
      filter={{ showOnlySubAgreements: true }}
      source="agreementId"
      reference="agreements"
    >
      <AutocompleteInput optionText="agreementNumber" validate={required()} />
    </ReferenceInput>
    <TextInput source="purchaseNumber" validate={required()} />
    <DateInput source="purchaseDate" validate={required()} />
    <TextInput multiline source="purchaseDescription" />
  </SimpleForm>
);

export const PurchasesShow = (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    console.log(props);
    const url = `/purchaselines/${id}/show`;
    history.push(url);
  };

  return (
    <Show title={<PageTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <ReferenceField
            filter={{ showOnlySubAgreements: true }}
            source="agreementId"
            reference="agreements"
          >
            <TextField source="agreementNumber" />
          </ReferenceField>
          <TextField source="purchaseNumber" label="Purchase Number" />
          <DateField source="purchaseDate" label="Purchase Date" />
          <TextField source="purchaseDescription" label="Purchase Description" />
        </Tab>
        <Tab label="Purchase Lines">
          <ReferenceManyField reference="purchaselines" target="purchaseId" addLabel={false}>
            <DataGrid source="purchaselines" rowDoubleClicked={onRowClicked}>
              <AgGridColumn field="productName" />
              <AgGridColumn field="productEdition" />
              <AgGridColumn field="productVersion" />
              <AgGridColumn
                field="licenseQuantity"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn
                field="maintenanceQuantity"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn
                field="upgradeQuantity"
                filter={'agMultiColumnFilter'}
                filterParams={numberColumnFilter}
              />
              <AgGridColumn field="purchaseDescription" />
              <AgGridColumn field="agreementNumber" />
              <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
              <AgGridColumn {...useAgGridEnumCol('licensemodels', 'licenseModel')} />
              <AgGridColumn
                field="maintenanceStart"
                valueFormatter={dateFormatter}
                filter={'agMultiColumnFilter'}
                filterParams={dateColumnFilter}
              />
              <AgGridColumn
                field="maintenanceEnd"
                valueFormatter={dateFormatter}
                filter={'agMultiColumnFilter'}
                filterParams={dateColumnFilter}
              />
            </DataGrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const PurchasesEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {basicinputs}
      </Edit>
    </CrudWrapper>
  );
};

export const PurchasesCreate = (props) => (
  <CrudWrapper>
    <Create title="New" {...props}>
      {basicinputs}
    </Create>
  </CrudWrapper>
);
