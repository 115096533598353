import VirtualIcon from '@material-ui/icons/AccountTree';
import VirtualList from './VirtualList';
import { VirtualCreate, VirtualEdit, VirtualShow } from './VirtualForm';

export default {
  list: VirtualList,
  show: VirtualShow,
  icon: VirtualIcon,
  edit: VirtualEdit,
  create: VirtualCreate,
};
