import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextField,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  ReferenceField,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import FilteringProducts from '../../../components/FilteringProducts';
import { sourceTab } from '../../../components/SourceTab';
import { UserInput } from '../../../components/CustomInputs';
import CrudWrapper from '../../../components/CrudWrapper';
import { AuditLog } from '../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

const inputs = (
  <SimpleForm redirect="show" autoClose toolbar={<EditToolbar message={'user access'} />}>
    <UserInput />
    <FilteringProducts source="productId" />
  </SimpleForm>
);

export const UserAccessShow = (props) => {
  return (
    <CrudWrapper>
      <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
        <TabbedShowLayout>
          <Tab label="Basic">
            <ReferenceField source="userId" reference="users">
              <TextField source="userName" />
            </ReferenceField>
            <ReferenceField source="productId" reference="products">
              <TextField source="productName" />
            </ReferenceField>
            <ReferenceField source="productId" reference="products">
              <TextField source="version" />
            </ReferenceField>
            <ReferenceField source="productId" reference="products">
              <TextField source="edition" />
            </ReferenceField>
          </Tab>
          <Tab label="Observations">
            <RuleDisplayObservation />
          </Tab>
          <Tab label="Audit">
            <AuditLog resource={props.resource} id={props.id} />
          </Tab>
          {sourceTab}
        </TabbedShowLayout>
      </Show>
    </CrudWrapper>
  );
};

export const UserAccessEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {inputs}
      </Edit>
    </CrudWrapper>
  );
};

export const UserAccessCreate = (props) => (
  <Create title="New" {...props}>
    {inputs}
  </Create>
);
