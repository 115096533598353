import LicensePositionIcon from '@material-ui/icons/Flare';
import LicensePositionList from './LicensePositionList';
import {
  LicensePositionShow,
  LicensePositionEdit,
  LicensePositionCreate,
} from './LicensePositionForm';

export default {
  list: LicensePositionList,
  show: LicensePositionShow,
  edit: LicensePositionEdit,
  create: LicensePositionCreate,
  icon: LicensePositionIcon,
};
