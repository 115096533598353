import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';

import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import { numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List
      {...props}
      title="Hardware"
      actions={<ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions />} />}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="deviceName" />
          <AgGridColumn field="deviceTagSequence" />
          <AgGridColumn
            field="serialNumber"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn field="fullyQualifiedDomainName" />
          <AgGridColumn field="ipAddress" columnGroupShow="open" />
          <AgGridColumn field="macAddress" columnGroupShow="open" />
          <AgGridColumn field="manufacturer" />
          <AgGridColumn field="operatingSystem" columnGroupShow="open" />
          <AgGridColumn field="version" />
        </AgGridColumn>
        <AgGridColumn headerName="Properties">
          <AgGridColumn field="make" />
          <AgGridColumn field="model" />
          <AgGridColumn
            field="totalCores"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="physicalProcessors"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="physicalCores"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="threads"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="logicalProcessors"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="ram"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn field="cpuModel" columnGroupShow="open" />
          <AgGridColumn field="cpuSpeed" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Validators">
          <AgGridColumn field="hasInstalls" />
          <AgGridColumn field="isManual" />
          <AgGridColumn field="isArchived" />
          <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
        </AgGridColumn>
        <AgGridColumn headerName="Classifications">
          <AgGridColumn {...useAgGridEnumCol('devicescopes', 'deviceScope')} />
          <AgGridColumn {...useAgGridEnumCol('deviceroles', 'deviceRole')} />
          <AgGridColumn {...useAgGridEnumCol('devicetypes', 'deviceType')} columnGroupShow="open" />
          <AgGridColumn
            {...useAgGridEnumCol('deviceclasses', 'deviceClass')}
            columnGroupShow="open"
          />
          <AgGridColumn
            {...useAgGridEnumCol('devicestates', 'deviceState')}
            columnGroupShow="open"
          />
          <AgGridColumn
            {...useAgGridEnumCol('devicestatuses', 'deviceStatus')}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Organization">
          <AgGridColumn field="businessUnitName" />
          <AgGridColumn field="city" columnGroupShow="open" />
          <AgGridColumn field="country" columnGroupShow="open" />
          <AgGridColumn field="region" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Annotanions">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
