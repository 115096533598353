import FingerprintIcon from '@material-ui/icons/Fingerprint';
import FingerprintList from './FingerprintList';
import { FingerprintShow, FingerprintEdit, FingerprintCreate } from './FingerprintForm';

export default {
  list: FingerprintList,
  icon: FingerprintIcon,
  show: FingerprintShow,
  edit: FingerprintEdit,
  create: FingerprintCreate,
};
