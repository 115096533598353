import React from 'react';
import { ReferenceInput, SelectInput } from 'ra-ui-materialui';
import { useRefresh } from 'ra-core';
import { useHistory } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { getScope } from '../utils/scopeQuery';

const selectInputStyles = makeStyles((theme) => {
  return {
    input: {
      background: theme.palette.background.paper,
    },
  };
});

const helperTextStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'none',
    },
  };
});

const NoProgressBarSelectInput = (props) => {
  const helperTextClasses = helperTextStyles();

  return (
    <SelectInput
      resettable
      style={{ flex: 1, margin: '2px' }}
      FormHelperTextProps={{ classes: helperTextClasses }}
      {...props}
      label={!props.choices.length ? 'No options' : props.label}
      loading={false}
      disabled={props.loading || props.disabled || !props.choices.length}
      className={selectInputStyles().input}
    />
  );
};

const ScopeDialog = () => {
  const history = useHistory();
  const refresh = useRefresh();

  const saveScope = (params) => {
    params = {
      organizationId: undefined,
      projectId: undefined,
      measurementId: undefined,
      ...params,
    };
    let changeNeeded = false;

    const currentQuery = new URLSearchParams(history.location.search);
    for (let [key, value] of Object.entries(params)) {
      if (currentQuery.get(key) !== value) {
        changeNeeded = true;
        break;
      }
    }

    if (changeNeeded) {
      history.replace({
        pathname: history.location.pathname,
        search: new URLSearchParams(Object.entries(params)),
      });
      refresh();
    }
  };

  const initialValues = getScope();

  return (
    <Box flex="1" display="flex" flexDirection="row">
      <Form
        initialValues={initialValues}
        onSubmit={() => {}}
        render={() => (
          <>
            <FormSpy onChange={(props) => saveScope(props.values)} />
            <FormSpy>
              {(props) => (
                <>
                  <ReferenceInput
                    source="organizationId"
                    reference="organizations"
                    onChange={() => {
                      props.form.change('projectId', undefined);
                      props.form.change('measurementId', undefined);
                    }}
                  >
                    <NoProgressBarSelectInput optionText="organizationName" optionValue="id" />
                  </ReferenceInput>
                  <ReferenceInput
                    source="projectId"
                    reference="projects"
                    filter={{ organizationId: props.values.organizationId }}
                    onChange={() => {
                      props.form.change('measurementId', undefined);
                    }}
                  >
                    <NoProgressBarSelectInput
                      disabled={!props.values.organizationId}
                      optionText="projectName"
                      optionValue="id"
                      loading={false}
                    />
                  </ReferenceInput>
                  <ReferenceInput
                    source="measurementId"
                    reference="measurements"
                    filter={{ projectId: props.values.projectId }}
                  >
                    <NoProgressBarSelectInput
                      disabled={!props.values.projectId}
                      optionText={(e) =>
                        !e.isLocked ? e.measurementName : `${e.measurementName} (LOCKED)`
                      }
                      optionValue="id"
                    />
                  </ReferenceInput>
                </>
              )}
            </FormSpy>
          </>
        )}
      />
    </Box>
  );
};

export default ScopeDialog;
