import { Typography } from '@material-ui/core';
import { getEnumTransformForType } from './enums';
import React from 'react';

export const EnumRenderer = ({ value, resource, property, overrideResource, ...props }) => {
  return (
    <Typography component="span" variant="body2">
      {getEnumTransformForType(overrideResource ?? resource)(property, value)}
    </Typography>
  );
};
