import MeasurementIcon from '@material-ui/icons/TrendingUp';
import MeasurementList from '../Measurement/MeasurementList';
import { MeasurementShow, MeasurementEdit, MeasurementCreate } from '../Measurement/MeasurementForm';

export default {
  list: MeasurementList,
  icon: MeasurementIcon,
  edit: MeasurementEdit,
  create: MeasurementCreate,
  show: MeasurementShow,
};
