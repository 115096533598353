import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Show,
  TabbedShowLayout,
  ReferenceManyField,
  Tab,
  TextField,
  required,
  maxLength,
  FunctionField,
} from 'react-admin';
import DataGrid from '../../../components/DataGrid';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import { AgGridColumn } from 'ag-grid-react';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.legalEntity : ''}</span>;
};

const validateManufacturerKey = [required(), maxLength(25)];

const fields = (
  <SimpleForm redirect="show" toolbar={<EditToolbar message={'supplier'} />}>
    <TextInput source="supplierKey" label="suppluerKey" validate={validateManufacturerKey} />
    <TextInput source="legalEntity" label="legalEntity" validate={required()} />
    <TextInput source="address" label="address" validate={required()} />
    <TextInput source="city" label="city" validate={required()} />
    <TextInput source="postalCode" label="postalCode" validate={required()} />
    <TextInput source="state" label="state" />
    <TextInput source="country" label="country" validate={required()} />
    <TextInput source="continent" label="continent" validate={required()} />
    <TextInput source="region" label="region" validate={required()} />
    <FileUpload source="documents" />
  </SimpleForm>
);

export const SupplierShow = (props) => {
  const [hasDocuments, setHasDocuments] = React.useState(false);

  return (
    <Show title={<PageTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField source="manufacturerKey" label="manufacturerKey" />
          <TextField source="legalEntity" label="legalEntity" />
          <TextField source="address" label="address" />
          <TextField source="postalCode" label="postalCode" />
          <TextField source="city" label="city" />
          <TextField source="state" label="state" />
          <TextField source="country" label="country" />
          <TextField source="continent" label="continent" />
          <TextField source="region" label="region" />
        </Tab>
        <Tab label="Manufacturers">
          <ReferenceManyField reference="suppliers" target="manufacturer" addLabel={false}>
            <DataGrid source="manufacturer">
              <AgGridColumn field="manufacturerKey" headerName="Manufacturer" />
              <AgGridColumn field="legalEntity" />
              <AgGridColumn field="address" />
              <AgGridColumn field="postalCode" />
              <AgGridColumn field="city" />
              <AgGridColumn field="state" />
              <AgGridColumn field="country" />
              <AgGridColumn field="continent" />
              <AgGridColumn field="region" />
            </DataGrid>
          </ReferenceManyField>
        </Tab>
        {hasDocuments && (
          <Tab label="Linked Documents">
            <SimpleForm toolbar={<EmptyToolbar />}>
              <FileUpload source="documents" show />
            </SimpleForm>
          </Tab>
        )}
        <FunctionField
          style={{ diplay: 'none' }}
          label=""
          render={(record) => setHasDocuments(record?.documents?.length > 0)}
        />
      </TabbedShowLayout>
    </Show>
  );
};

export const SupplierEdit = (props) => (
  <Edit undoable={false} title={<PageTitle />} {...props}>
    {fields}
  </Edit>
);

export const SupplierCreate = (props) => (
  <Create title="New" {...props}>
    {fields}
  </Create>
);
