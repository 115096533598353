import React from 'react';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import DeleteIcon from '@material-ui/icons/Delete';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import MeasurementAPI from '../../../providers/APIProviders/measurementProvider';
import constants from '../../../utils/constants';

import {
    ListItemIcon,
    FormControl,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',

    // change background colour if dragging
    background: isDragging ? '#fafafa' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : '#fafafa',
    padding: grid,
    width: '100%',
    listStyle: 'none',
});

const fileArray = constants.IMPORT_TYPES;
const fileArraySelect = constants.IMPORT_TYPES_SELECT;
const statusArray = ['Ready for import', 'Processing', 'Imported', 'Error'];

const WizardDraggable = ({onDragEnd, fileList, isWizard, editRow, setFileList, onRowChange}) => {
    const deleteAction = async (id) => {
        await MeasurementAPI.delete(id);
    };

    const handleRemove = (id) => {
        const newList = fileList.filter((item) => item.id !== id);
        deleteAction(id);

        setFileList(newList);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {fileList.map((file, index) => (
                            <Draggable key={file.id} draggableId={file.id} index={index}>
                                {(provided, snapshot) => (
                                    <>
                                        <ListItem
                                            ContainerComponent="li"
                                            ContainerProps={{ref: provided.innerRef}}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                            <ListItemIcon>
                                                <DragHandleIcon style={{color: '#9e9a8e'}}/>
                                            </ListItemIcon>
                                            <ListItemText primary={file.fileName} style={{width: '30%'}}/>

                                            {isWizard ? null :
                                                <ListItemText primary={statusArray[file.parsingStatus]}/>}
                                            {isWizard ? (
                                                <ListItemText primary={fileArray[file.fileType]}
                                                              style={{width: '25%'}}/>
                                            ) : !editRow ? (
                                                <ListItemText primary={fileArray[file.fileType]}
                                                              style={{width: '25%'}}/>
                                            ) : (
                                                <>
                                                    <FormControl style={{width: 200, marginRight: 100}}>
                                                        <InputLabel style={{color: "black", fontSize: "0.8rem"}} id="demo-simple-select-label">
                                                            {fileArray[file.fileType]}
                                                        </InputLabel>
                                                        <Select
                                                            key={file.id}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            onChange={(e) => onRowChange(e, file)}

                                                        >
                                                            {fileArraySelect.map((item) => (
                                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <ListItemIcon style={{color: 'red', cursor: 'pointer'}}>
                                                        <DeleteIcon onClick={() => handleRemove(file.id)}/>
                                                    </ListItemIcon>
                                                </>
                                            )}

                                            <ListItemSecondaryAction></ListItemSecondaryAction>
                                        </ListItem>
                                    </>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default WizardDraggable;
