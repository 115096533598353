import * as React from 'react';
import { Annotations } from '../../../components/Annotations';
import {
  Edit,
  Show,
  SelectInput,
  TextInput,
  ReferenceInput,
  TextField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  NumberInput,
  DateInput,
  NumberField,
  DateField,
  Labeled,
  Create,
  required,
  SimpleForm,
  FunctionField,
} from 'react-admin';

import { MarkdownField, MarkdownInput } from '@react-admin/ra-markdown';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import DataGrid from '../../../components/DataGrid';
import { NullableDateInput } from '../../../components/NullableDateInput';
import { RuleDisplay, RuleDisplayObservation } from '../../../components/RuleDisplay';
import ActionBarShow from '../../../components/ActionBarShow';
import { NewEnumField } from '../../../types/NewEnumField';
import { NewEnumInput } from '../../../types/NewEnumInput';
import CrudWrapper from '../../../components/CrudWrapper';
import FileUpload, { EmptyToolbar } from '../../../components/FileUpload';
import { AuditLog } from '../../../components/AuditLog';
import { sourceTab } from '../../../components/SourceTab';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter, useAgGridEnumCol } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';
import EditToolbar from '../../../components/EditToolbar';
import { numberColumnFilter } from '../../../utils/filterParams';

const PageTitle = ({ record }) => {
  return <span>{record ? record.deviceName : ''}</span>;
};

export const DeviceShow = (props) => {
  const [hasDocuments, setHasDocuments] = React.useState(false);
  const softwareRuleIndicator = 0;

  const ruleValueGetter = (params) => {
    return params.data?.rules
      ?.filter((rule) => rule.ruleIndicator === softwareRuleIndicator)
      .map((rule) => rule.ruleTag)
      .join(', ');
  };

  return (
    <Show title={<PageTitle />} {...props} actions={<ActionBarShow {...props} />}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField halfWidth source="deviceName" label="deviceName" />
          <TextField halfWidth source="deviceScope" label="deviceScope" />
          <TextField halfWidth source="domain" label="domain" />
          <TextField halfWidth source="ouPath" label="ouPath" />
          <TextField halfWidth source="operatingSystem" label="operatingSystem" />
          <TextField halfWidth source="version" label="version" />
          <NumberField halfWidth source="physicalProcessors" label="physicalProcessors" />
          <NumberField halfWidth source="physicalCores" label="physicalCores" />
          <NumberField halfWidth source="totalCores" label="totalCores" />

          <NumberField halfWidth source="daysSinceLastActivity" label="daysSinceLastActivity" />
          <MarkdownField fullWidth source="notes" label="notes" />
        </Tab>
        <Tab label="Extended">
          <AccordionFormPanel label="Claims">
            <TextField fullwidth source="deviceTag" label="deviceTag" />
            <TextField halfWidth source="deviceName" label="deviceName" />
            <TextField
              halfWidth
              source="fullyQualifiedDomainName"
              label="fullyQualifiedDomainName"
            />
          </AccordionFormPanel>
          <AccordionFormPanel label="Timestamps">
            <DateField halfWidth source="lastActivityDate" label="lastActivityDate" />
            <NumberField halfWidth source="daysSinceLastActivity" label="daysSinceLastActivity" />
            <DateField halfWidth source="WhenCreatedDate" label="WhenCreatedDate" />
            <NumberField halfWidth source="daysSinceCreated" label="daysSinceCreated" />
            <DateField
              halfWidth
              source="collectionDate"
              label="collectionDate"
              validate={required()}
            />
          </AccordionFormPanel>

          <AccordionFormPanel label="Classification">
            <NewEnumField source="deviceScope" reference="devicescopes" />
            <NewEnumField source="deviceRole" reference="deviceroles" />
            <NewEnumField source="deviceType" reference="devicetypes" />
            <NewEnumField source="deviceClass" reference="deviceclasses" />
            <NewEnumField source="deviceState" reference="devicestates" />
            <NewEnumField source="deviceStatus" reference="devicestatuses" />
          </AccordionFormPanel>
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        <Tab label="Software">
          <ReferenceManyField reference="devicesoftware" target="deviceId" addLabel={false}>
            <DataGrid source="devicesoftware">
              <AgGridColumn headerName="Claims">
                <AgGridColumn field="deviceTag" />
                <AgGridColumn field="deviceName" />
                <AgGridColumn field="softwareTag" />
                <AgGridColumn field="fullyQualifiedDomainName" />
              </AgGridColumn>

              <AgGridColumn headerName="Validations">
                <AgGridColumn field="isLicensable" />
                <AgGridColumn field="hasLiability" />
                <AgGridColumn field="isMaintenanceRequired" />
                <AgGridColumn field="isCoveredByHostInstall" />
                <AgGridColumn field="isCostSavingsCandidate" />
                <AgGridColumn field="isCostAvoidanceCandidate" />
                <AgGridColumn field="trackUsage" />
                <AgGridColumn
                  field="processors"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="coresPerProcessor"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="totalCores"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
              </AgGridColumn>
              <AgGridColumn headerName="Classifications">
                <AgGridColumn field="softwareRule" valueGetter={(e) => ruleValueGetter(e)} />
                <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
                <AgGridColumn {...useAgGridEnumCol('devicescopes', 'deviceScope')} />
                <AgGridColumn {...useAgGridEnumCol('deviceroles', 'deviceRole')} />
                <AgGridColumn {...useAgGridEnumCol('devicetypes', 'deviceType')} />
                <AgGridColumn {...useAgGridEnumCol('deviceclasses', 'deviceClass')} />
                <AgGridColumn {...useAgGridEnumCol('devicestates', 'deviceState')} />
                <AgGridColumn {...useAgGridEnumCol('devicestatuses', 'deviceStatus')} />
              </AgGridColumn>
              <AgGridColumn headerName="Install product">
                <AgGridColumn field="installProductName" />
                <AgGridColumn field="installProductPool" />
                <AgGridColumn field="installProductCategory" />
                <AgGridColumn field="installProductGroup" />
                <AgGridColumn field="installProductFamily" />
                <AgGridColumn field="installVersion" />
                <AgGridColumn field="installEdition" />
                <AgGridColumn
                  field="installQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn {...useAgGridEnumCol('installtypes', 'installType')} />
              </AgGridColumn>
              <AgGridColumn headerName="Licensed product">
                <AgGridColumn field="licenseProductName" />
                <AgGridColumn field="licenseProductPool" />
                <AgGridColumn field="licenseProductCategory" />
                <AgGridColumn field="licenseProductGroup" />
                <AgGridColumn field="licenseProductFamily" />
                <AgGridColumn field="licenseVersion" />
                <AgGridColumn field="licenseEdition" />
                <AgGridColumn
                  field="licenseQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'installMetric')} />
                <AgGridColumn {...useAgGridEnumCol('licensemodels', 'installModel')} />
              </AgGridColumn>
              <AgGridColumn headerName="Organization">
                <AgGridColumn field="businessUnitName" />
                <AgGridColumn field="city" />
                <AgGridColumn field="country" />
                <AgGridColumn field="region" />
              </AgGridColumn>
              <AgGridColumn headerName="Annotation">
                <AgGridColumn field="notes" />
                <AgGridColumn field="interpretations" />
                <AgGridColumn field="feedback" />
              </AgGridColumn>
              <AgGridColumn headerName="Sources">
                <AgGridColumn
                  headerName="Created by"
                  field="sources"
                  valueGetter={(e) => createdBy(e)}
                />
                <AgGridColumn
                  field="sources"
                  headerName="Updated by"
                  valueGetter={(e) => updatedBy(e)}
                />
              </AgGridColumn>
            </DataGrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Rules">
          <Labeled label="Classification rules">
            <RuleDisplay
              whereReference="deviceclassificationrules"
              ruleConfigurationKey="DeviceClassification"
              ruleIndicator={0}
            />
          </Labeled>
          <Labeled label="Qualification rules">
            <RuleDisplay
              whereReference="devicequalificationrules"
              ruleConfigurationKey="DeviceQualification"
              ruleIndicator={1}
            />
          </Labeled>
        </Tab>

        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
        {sourceTab}
        {hasDocuments && (
          <Tab label="Linked Documents">
            <SimpleForm toolbar={<EmptyToolbar />}>
              <FileUpload source="documents" show />
            </SimpleForm>
          </Tab>
        )}
        <FunctionField
          style={{ display: 'none' }}
          label=""
          render={(record) => setHasDocuments(record?.documents?.length > 0)}
        />
      </TabbedShowLayout>
    </Show>
  );
};

const inputs = (
  <AccordionForm autoClose redirect="show" toolbar={<EditToolbar message={'device'} />}>
    <AccordionFormPanel label="Claims">
      {/* <TextField halfWidth label="Tag" source="deviceId" /> */}
      <TextInput halfWidth source="deviceName" />
      <TextInput halfWidth source="fullyQualifiedDomainName" />
      <TextInput halfWidth source="distinguishedName" />
      <TextInput halfWidth source="ouPath" />
      <ReferenceInput source="businessUnitId" reference="businessunits" validate={required()}>
        <SelectInput optionText="businessUnitName" />
      </ReferenceInput>
    </AccordionFormPanel>
    <AccordionFormPanel label="Timestamps">
      <DateInput halfWidth source="lastActivityDate" validate={required()} />
      <NumberInput halfWidth source="daysSinceLastActivity" />
      <DateInput halfWidth source="whenCreatedDate" validate={required()} />
      <NumberInput halfWidth source="daysSinceCreated" />
      <NullableDateInput source="collectionDate" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Classification">
      <NewEnumInput source="deviceScope" reference="devicescopes" />
      <NewEnumInput source="deviceRole" reference="deviceroles" />
      <NewEnumInput source="deviceType" reference="devicetypes" />
      <NewEnumInput source="deviceClass" reference="deviceclasses" />
      <NewEnumInput source="deviceState" reference="devicestates" />
      <NewEnumInput source="deviceStatus" reference="devicestatuses" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Notes">
      <MarkdownInput halfWidth source="notes" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput halfWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput halfWidth source="feedback" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Organization">
      <ReferenceInput source="businessUnitId" reference="businessunits">
        <SelectInput optionText="businessUnitName"></SelectInput>
      </ReferenceInput>
    </AccordionFormPanel>
    <AccordionFormPanel label="Link Documents">
      <FileUpload source="documents" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const DeviceEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {inputs}
      </Edit>
    </CrudWrapper>
  );
};

export const DeviceCreate = (props) => {
  return (
    <CrudWrapper>
      <Create title={<PageTitle />} {...props}>
        {inputs}
      </Create>
    </CrudWrapper>
  );
};
