import { TextField } from 'ra-ui-materialui';
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DateTimeFormatter from '../utils/dateTimeFormatter';
import { ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { EnumRenderer } from '../types/EnumRenderer';
import { useFetch } from '../utils/useFetch';

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '10px 0',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const AuditLog = ({ resource, id }) => {
  const [auditLog, setAuditLog] = useState([]);
  const classes = useStyles();
  const fetchAudit = useFetch(`/${resource}/${id}/auditlog`, 'GET');

  useEffect(() => {
    const getAudit = async () => {
      let response = await fetchAudit();
      setAuditLog(response?.reverse());
    };

    getAudit();
  }, []);

  return auditLog?.length > 0 ? (
    auditLog?.map((element) => (
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            <DateTimeFormatter date={element.timestamp} />
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            {element.property}
          </Typography>
          <Typography variant="body2" component="p">
            <strong>New value:</strong>{' '}
            <EnumRenderer
              resource={resource}
              property={element.property}
              value={element.newValue}
            />
            <br />
            <strong>Old value: </strong>{' '}
            <EnumRenderer
              resource={resource}
              property={element.property}
              value={element.oldValue}
            />
            <br />
            {element.platformUserId && (
              <>
                <strong>User: </strong>
                <ReferenceField reference="platformusers" record={element} source="platformUserId">
                  <TextField source="email" />
                </ReferenceField>
              </>
            )}
            {element.ruleId && (
              <>
                <strong>Rule: </strong>
                {element.rule.ruleTag}
              </>
            )}
          </Typography>
        </CardContent>
      </Card>
    ))
  ) : (
    <Typography>No Audit records found</Typography>
  );
};
