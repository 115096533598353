import dataProvider from './dataProvider';
import RuleAPI from './APIProviders/RulesProvider';


export default {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const apiUrl = process.env.REACT_APP_API_CONNECT;

    const oAuthParams = {
      grant_type: 'password',
      username,
      password,
      client_id: 'spa',
      scope: 'api offline_access openid',
    };

    const body = Object.keys(oAuthParams)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(oAuthParams[key]);
      })
      .join('&');

    const request = new Request(apiUrl + '/connect/token', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        Host: apiUrl,
      }),
      body,
    });

    const response = await fetch(request)
    try {
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const access_token = (await response.json())?.access_token;
    localStorage.setItem('token', access_token);
    }
    catch (e) {
      console.log(e)
    }


  },

  // called when the user clicks on the logout button
  logout: () => {
    console.log("logout")

    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const urlSplit = window.location.href.split('/');
    const lastElement = urlSplit[urlSplit.length - 1];
    const path = lastElement.split('?')[0];
    const ignoreRoutes = ['resetpassword'];
    const token = localStorage.getItem('token')

    if (ignoreRoutes.indexOf(path) > -1) {
      return Promise.reject();
    }
    return token ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles

  getPermissions: async () => {

    let role = localStorage.getItem('userRole');
    if (role === null) {
      const res = await dataProvider.getUserRole();
      const response = await RuleAPI.getConfiguration();
      role = res.role;
      localStorage.setItem('userRole', role);
      localStorage.setItem('profile-id', res.sub);
      localStorage.setItem('configuration', JSON.stringify(response.configuration));
    }
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
