import React, { useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import 'ag-grid-enterprise';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import '../App.css';
import AG_GRID_LOCALE_NL from './locale.nl';
import DatagridAPI from '../providers/dataGridProvider';
import { useDataProvider, useLocale, useRefresh, useTranslate } from 'react-admin';
import { useFetch } from '../utils/useFetch';
import * as $ from 'jquery';
import { onRowClicked } from '../utils/DataGridUtils/DataGridRowClicked';
import { selectionChanged } from '../utils/DataGridUtils/DataGridSelectionChanged';
import { autoSizeAll } from '../utils/DataGridUtils/DataGridAutoSizeAll';
import { isObject } from 'lodash';

const GRID_CONFIG_KEY = 'gridConfiguration';
const GRID_COLUMN_STATE_KEY = 'columnState';
const GRID_COLUMN_GROUP_STATE_KEY = 'columnGroupState';
const GRID_FILTER_STATE_KEY = 'filterState';
const GRID_PAGE_SIZE_KEY = 'pageSize';

function setGridConfig(path, key, data) {
  const configItem = localStorage.getItem(GRID_CONFIG_KEY);

  let config;
  try {
    config = JSON.parse(configItem);
    if (!isObject(config)) {
      config = {};
    }
  } catch {
    config = {};
  }

  if (!isObject(config[path])) {
    config[path] = {};
  }
  config[path][key] = data;
  localStorage.setItem(GRID_CONFIG_KEY, JSON.stringify(config));
}

function getGridConfig(path, key) {
  const configItem = localStorage.getItem(GRID_CONFIG_KEY);
  try {
    const config = JSON.parse(configItem);
    return config?.[path]?.[key];
  } catch {
    return null;
  }
}

const AGGridBody = (props) => {
  const { resource } = props;
  const gridRef = useRef();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [gridApi, setGridApi] = useState(null);
  const refresh = useRefresh();
  const locale = useLocale();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentPageSize, setCurrentPageSize] = useState(100);
  const [displayFilter, setDisplayFilter] = useState([]);

  const translations = locale === 'nl' ? AG_GRID_LOCALE_NL : null;
  const path = props.basePath.replace(/\//g, '');
  const fetchConfig = useFetch(`/configurations/` + path, 'GET');

  const dataSource = useMemo(() => {
    return {
      // called by the grid when more rows are required
      getRows: async (params) => {
        try {
          const result = await dataProvider.getLimitOffsetList(resource, {
            limit: params.request.endRow - params.request.startRow,
            offset: params.request.startRow,
            filter: params.request.filterModel,
            sort: params.request.sortModel,
          });
          params.success({
            rowData: result.data,
            rowCount: result.total,
          });
        } catch (e) {
          params.fail();
        }
      },
    };
  }, []);

  const saveFilterModel = async () => {
    if (!gridApi) return;

    const filterModel = gridApi.getFilterModel();

    setGridConfig(path, GRID_FILTER_STATE_KEY, filterModel);
  };

  const resetToolPanel = () => {
    const resetConfig = async (key) => {
      setGridConfig(path, key, {});
      refresh();
    };

    return (
      <div>
        <button style={{ margin: 10 }} onClick={() => resetConfig(GRID_FILTER_STATE_KEY)}>
          Reset filters
        </button>
        <button style={{ margin: 10 }} onClick={() => resetConfig(GRID_COLUMN_STATE_KEY)}>
          Reset columns
        </button>
      </div>
    );
  };

  //ONGRID READY

  const onGridReady = async (params) => {
    if (props.setGridApi) {
      props.setGridApi(params.api);
    }
    setGridApi(params.api);

    if (props.onGridReady) {
      props.onGridReady();
    }
  };

  // TOGGLE FULLSCREEN TABLE

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      $('.list-page .MuiPaper-root').addClass('grid-fullscreen');
      $('body').addClass('overflow-hidden');
      setIsFullscreen(true);
    } else {
      $('.list-page .MuiPaper-root').removeClass('grid-fullscreen');
      $('body').removeClass('overflow-hidden');
      setIsFullscreen(false);
    }
  };

  const saveGridState = (params) => {
    if (params.finished === undefined || params.finished === true) {
      const columnState = params.columnApi.getColumnState();
      setGridConfig(path, GRID_COLUMN_STATE_KEY, columnState);

      const columnGroupState = params.columnApi.getColumnGroupState();
      setGridConfig(path, GRID_COLUMN_GROUP_STATE_KEY, columnGroupState);

      const filterModel = params.api.getFilterModel();
      setGridConfig(path, GRID_FILTER_STATE_KEY, filterModel);
    }
  };

  const onPageSizeChanged = async (e) => {
    var value = e.target.value;

    gridApi.paginationSetPageSize(Number(value));
    setGridConfig(path, GRID_PAGE_SIZE_KEY, Number(value));
  };

  const onFirstDataRendered = (params) => {
    const columnState = getGridConfig(path, GRID_COLUMN_STATE_KEY);
    console.log(columnState);
    if (columnState) {
      params.columnApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    const filterModel = getGridConfig(path, GRID_FILTER_STATE_KEY);
    if (filterModel) {
      params.api.setFilterModel(filterModel);
    }

    const columnGroupState = getGridConfig(path, GRID_COLUMN_GROUP_STATE_KEY);
    if (columnGroupState) {
      params.columnApi.setColumnGroupState(columnGroupState);
    }

    const pageSize = getGridConfig(path, GRID_PAGE_SIZE_KEY);
    if (pageSize) {
      params.api.paginationSetPageSize(Number(pageSize));
      document.getElementById('page-size').value = pageSize;
    }
  };

  const rowHeight = 20;

  function getContextMenuItems(params) {
    var result = [
      /*{
                // custom item
                name: 'Alert ' + params.value,
                action: () => {
                    window.alert('Alerting about ' + params.value);
                },
                cssClasses: ['redFont', 'bold'],
            },*/
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      {
        name: 'Export CSV',
        action: async () => {
          gridRef.current.api.showLoadingOverlay();
          const filters = params.api.getFilterModel();
          const response = await DatagridAPI.exportDataFromTable(props.resource, filters);
          gridRef.current.api.hideOverlay();

          var csvURL = window.URL.createObjectURL(response);
          let tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', 'export');
          tempLink.click();
          setTimeout(() => {
            URL.revokeObjectURL(csvURL);
          }, 0);
        },
      }, // built in separator
    ];

    return result;
  }

  const defaultColDef = useMemo(
    () => ({
      tooltipValueGetter: (params) => {
        return params.value;
      },
      minWidth: 50,
      width: 150,
      sortable: true,
      editable: false,
      rowHeight: 150,
      resizable: true,
      enableRowGroup: true,
      floatingFilter: false,
      headerCheckboxSelectionFilteredOnly: true,
      filter: 'agMultiColumnFilter',
      menuTabs: ['filterMenuTab', 'columnsMenuTab'],
      filterParams: {
        filters: [
          {
            filter: 'agSetColumnFilter',
            filterParams: {
              excelMode: 'windows',
              values: async (params) => {
                const { field } = params.colDef;
                const response = await DatagridAPI.getFilterValues(
                  resource,
                  field,
                  params.api.getFilterModel(),
                );
                params.success(response);
              },
              refreshValuesOnOpen: true,
            },
          },
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              defaultOption: 'contains',
              caseSensitive: false,
            },
          },
        ],
      },
    }),
    [],
  );
  return (
    <div
      onKeyDown={(e) => {
        if (e.key === 'Escape' && isFullscreen) {
          toggleFullscreen();
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <button onClick={() => toggleFullscreen()} class="btn-fullscreen">
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </button>
      </div>
      <div className="ag-theme-balham" style={{ height: isFullscreen ? '93vh' : '72vh' }}>
        <AgGridReact
          //SSR
          rowSelection={'multiple'}
          onRowClicked={(e) => e.node.setSelected(!e.node.isSelected())}
          maxConcurrentDatasourceRequests={2}
          rowModelType="serverSide"
          serverSideStoreType="partial"
          style={{ padding: 0, position: 'sticky', width: '100%', height: '100%', top: 0, left: 0 }}
          allowContextMenuWithControlKey={true}
          getContextMenuItems={getContextMenuItems}
          serverSideDatasource={dataSource}
          //PROPS
          defaultColDef={defaultColDef}
          rowHeight={rowHeight}
          autoSizePadding
          autoGroupColumnDef={{ minWidth: 200 }}
          groupUseEntireRow={true}
          suppressDragLeaveHidesColumns={true}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Exporting...</span>'}
          suppressMakeColumnVisibleAfterUnGroup={true}
          suppressRowClickSelection={true}
          rowGroupPanelShow={'always'}
          suppressCellSelection={true}
          pagination={true}
          ref={gridRef}
          accentedSort={true}
          paginationNumberFormatter={function (params) {
            return params.value.toLocaleString();
          }}
          components={{
            rowNodeIdRenderer: function (params) {
              return params.node.id + 1;
            },
          }}
          enableRangeSelection={true}
          onRowDoubleClicked={(table) => onRowClicked(table, props)}
          //rowData={rowData}
          onSelectionChanged={(e) => selectionChanged(e, props, gridApi)}
          frameworkComponents={{ resetToolPanel: resetToolPanel }}
          localeText={translations}
          onGridReady={onGridReady}
          onFilterChanged={saveGridState}
          onColumnVisible={saveGridState}
          onColumnResized={saveGridState}
          onColumnGroupOpened={saveGridState}
          onColumnMoved={saveGridState}
          onColumnPinned={saveGridState}
          onSortChanged={saveGridState}
          onRowDataUpdated={props.onRowDataUpdated}
          onRowDataChanged={props.onRowDataChanged}
          onFirstDataRendered={onFirstDataRendered}
          statusBar={{
            statusPanels: [
              { statusPanel: 'agFilteredRowCountComponent' },
              { statusPanel: 'agSelectedRowCountComponent' },
              { statusPanel: 'agAggregationComponent' },
            ],
          }}
          sideBar={{
            toolPanels: [
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              },
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
              {
                id: 'reset',
                labelDefault: 'Reset',
                labelKey: 'reset',
                iconKey: 'reset',
                toolPanel: 'resetToolPanel',
              },
            ],
            position: 'right',
            hiddenByDefault: false,
          }}
        >
          {props.children}
        </AgGridReact>
      </div>
      <div
        style={{
          fontSize: '12px',
          padding: '4px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        {displayFilter?.length < 1 ? (
          <div></div>
        ) : (
          <div style={{ fontWeight: '500' }}>
            <strong>Active filters:{'   '} </strong>
            {displayFilter?.map((filter) =>
              filter === displayFilter[displayFilter.length - 1]
                ? ` ${translate(filter)}`
                : ` ${translate(filter)},`,
            )}
          </div>
        )}

        <div>
          Page Size:
          <select onChange={(e) => onPageSizeChanged(e)} id="page-size">
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">500</option>
            <option value="1000">1.000</option>
            <option value="5000">5.000</option>
            <option value="10000">10.000</option>
            <option value="50000">50.000</option>
            <option value="100000">100.000</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default AGGridBody;
