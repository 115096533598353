import ProductPathIcon from '@material-ui/icons/SwapVert';
import ProductPathList from './ProductPathList';
import { ProductPathShow, ProductPathEdit, ProductPathCreate } from './ProductPathForm';

export default {
  list: ProductPathList,
  show: ProductPathShow,
  edit: ProductPathEdit,
  create: ProductPathCreate,
  icon: ProductPathIcon,
};
