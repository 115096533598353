import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { dateFormatter } from '../../../utils/formatters';
import { dateColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Domains" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn
          field="purchaseDate"
          valueFormatter={dateFormatter}
          filter="agMultiColumnFilter"
          filterParams={dateColumnFilter}
        />
        <AgGridColumn field="purchaseNumber" />
        <AgGridColumn field="purchaseDescription" />
      </DataGrid>
    </List>
  );
};
