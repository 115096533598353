import DeviceIcon from '@material-ui/icons/DevicesOther';
import DeviceList from './DeviceList';
import { DeviceShow, DeviceEdit, DeviceCreate } from './DeviceForm';

export default {
  list: DeviceList,
  show: DeviceShow,
  edit: DeviceEdit,
  create: DeviceCreate,
  icon: DeviceIcon,
};
