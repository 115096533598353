import * as React from 'react';
import { Annotations } from '../../../components/Annotations';
import {
  Edit,
  Show,
  Create,
  TextField,
  TabbedShowLayout,
  Tab,
  FunctionField,
  BooleanField,
  BooleanInput,
  SimpleForm,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  SelectArrayInput,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import { AccordionFormPanel } from '@react-admin/ra-form-layout';
import { NewEnumField } from '../../../types/NewEnumField';
import { MarkdownInput } from '@react-admin/ra-markdown';
import CrudWrapper from '../../../components/CrudWrapper';
import { sourceTab } from '../../../components/SourceTab';
import { UserInput } from '../../../components/CustomInputs';
import { AuditLog } from '../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

export const CollaborationUsageShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField source="userName" />
          <ReferenceField source="userAccessId" reference="useraccess">
            <TextField source="userName" />
          </ReferenceField>
          <ReferenceArrayField
            label="Tags"
            reference="collaborationservers"
            source="collaborationServers"
          >
            <SingleFieldList>
              <FunctionField
                label=""
                render={(record) => record.productName + ' ' + record.version}
              />
            </SingleFieldList>
          </ReferenceArrayField>
          <TextField source="sharePointServers" />
          <BooleanField source="enterpriseFeatureEnabled" />
          <BooleanField source="isCostAvoidanceCandidate" />
        </Tab>
        <Tab label="Classification">
          <NewEnumField source="userScope" label="userScope" reference="userscopes" />
          <NewEnumField source="userRole" label="userRole" reference="userroles" />
          <NewEnumField source="userType" label="userType" reference="usertypes" />
          <NewEnumField source="userClass" label="userClass" reference="userclasses" />
          <NewEnumField source="userState" label="userState" reference="userstates" />
          <NewEnumField source="userStatus" label="userStatus" reference="userstatuses" />
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        {sourceTab}
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const CollaborationUsageEdit = (props) => {
  const [userFilter, setUserFilter] = React.useState('');
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        <SimpleForm
          redirect="show"
          autoClose
          toolbar={<EditToolbar message={'collaboration usage'} />}
        >
          <UserInput setUserFilter={setUserFilter} />
          <ReferenceInput
            source="userAccessId"
            reference="useraccess"
            filter={{ userId: userFilter }}
          >
            <SelectInput optionText="userName" />
          </ReferenceInput>
          <ReferenceArrayInput source="collaborationServers" reference="collaborationservers">
            <SelectArrayInput optionText={(record) => record.productName + ' ' + record.version} />
          </ReferenceArrayInput>
          <BooleanInput source="standardFeatureEnabled" />
          <BooleanInput source="enterpriseFeatureEnabled" />
          <BooleanInput source="isCostAvoidanceCandidate" />
          <AccordionFormPanel label="Notes">
            <MarkdownInput fullWidth source="notes" Label="" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Interpretations">
            <MarkdownInput fullWidth source="interpretations" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Feedback">
            <MarkdownInput fullWidth source="feedback" />
          </AccordionFormPanel>
        </SimpleForm>
      </Edit>
    </CrudWrapper>
  );
};

export const CollaborationUsageCreate = (props) => {
  const [userFilter, setUserFilter] = React.useState('');
  return (
    <CrudWrapper>
      <Create title="New" {...props}>
        <SimpleForm redirect="show" autoClose>
          <UserInput setUserFilter={setUserFilter} />
          <ReferenceInput
            source="userAccessId"
            reference="useraccess"
            filter={{ userId: userFilter }}
          >
            <SelectInput optionText="userName" />
          </ReferenceInput>
          <ReferenceArrayInput source="collaborationServers" reference="collaborationservers">
            <SelectArrayInput optionText={(record) => record.productName + ' ' + record.version} />
          </ReferenceArrayInput>
          <BooleanInput source="standardFeatureEnabled" />
          <BooleanInput source="enterpriseFeatureEnabled" />
          <BooleanInput source="isCostAvoidanceCandidate" />
          <AccordionFormPanel label="Notes">
            <MarkdownInput fullWidth source="notes" Label="" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Interpretations">
            <MarkdownInput fullWidth source="interpretations" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Feedback">
            <MarkdownInput fullWidth source="feedback" />
          </AccordionFormPanel>
        </SimpleForm>
      </Create>
    </CrudWrapper>
  );
};
