// in src/MyLoginPage.js
import React from 'react'
//import { useState } from 'react';
import { Login } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        padding: theme.spacing(3)
      },
    },
    inputField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    }
  }));



const SuccessReset = ({ theme }) => {
//    const [email, setEmail] = useState('');
    const classes = useStyles();
    

    

    return (
        <Login className={classes.root}>
            <Typography align="center" variant="subtitle1" component="h2">
            We have e-mailed your password reset link!
            </Typography>
        </Login>
    );
};

export default SuccessReset;

