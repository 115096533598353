import UserSoftwareIcon from '@material-ui/icons/VerifiedUser';
import UserSoftwareList from './UserSoftwareList';
import { UserSoftwareCreate, UserSoftwareEdit, UserSoftwareShow } from './UserSoftwareForm';

export default {
  list: UserSoftwareList,
  icon: UserSoftwareIcon,
  create: UserSoftwareCreate,
  edit: UserSoftwareEdit,
  show: UserSoftwareShow,
};
