import WindowsServerIcon from '@material-ui/icons/LaptopWindows';
import { WindowsServerCreate, WindowsServerEdit, WindowsServerShow } from './WindowsServerForm';
import WindowsServerList from './WindowsServerList';

export default {
  list: WindowsServerList,
  icon: WindowsServerIcon,
  show: WindowsServerShow,
  edit: WindowsServerEdit,
  create: WindowsServerCreate,
};
