import React from 'react';
import { TopToolbar, EditButton, useRefresh, Button, useNotify } from 'react-admin';
import { getScope } from '../utils/scopeQuery';
import { useGetOne } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LockOpen from '@material-ui/icons/LockOpen';
import { useFetch } from '../utils/useFetch';

export const useIsLocked = () => {
  const measurementId = getScope().measurementId;
  const { data } = useGetOne('measurements', measurementId);
  return data?.isLocked;
};

const ActionBarShow = ({ basePath, data }) => {
  return (
    <TopToolbar>
      {!useIsLocked() ? <EditButton basePath={basePath} record={data} /> : null}
    </TopToolbar>
  );
};
export default ActionBarShow;

export const ComplexActionBarShow = ({ oldToolbar, ...props }) => {
  const measurementId = getScope().measurementId;
  const { data } = useGetOne('measurements', measurementId);

  const measurementChecker = props.measurement ? (
    <LockDialog icon={<LockOpen />} label={'unlock'} id={measurementId} data={data} />
  ) : null;

  return <TopToolbar>{!useIsLocked() ? [oldToolbar] : measurementChecker}</TopToolbar>;
};

export const LockDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  let newData = props.data;
  const fetchData = useFetch(`/measurements/update/${props.id}`, 'PUT', newData);

  const handleLock = async () => {
    newData.isLocked = props.label === 'lock' ? true : false;
    await fetchData();
    notify(`Measurement ${props.label}ed!`);
    setOpen(false);
    refresh();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        label={`${props.label} Measurement`}
        children={props.icon}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to ${props.label} this measurement?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLock} label={'Yes'} />
          <Button onClick={handleClose} label={'No'} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const MeasurementDeleteDialog = ({ label, handleDelete, icon }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        label={`${label} content`}
        color={'secondary'}
        children={icon}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to ${label} the contents of this measurement?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDelete();
              handleClose();
            }}
            label={'Yes'}
          />
          <Button onClick={handleClose} label={'No'} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
