import DatagridAPI from '../providers/dataGridProvider';
import { dateFormatter } from './formatters';

const dateFilterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    if (cellValue == null) return -1;
    var cellDate = new Date(cellValue);
    var filterDate = new Date(filterLocalDateAtMidnight);
    if (filterDate.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterDate) {
      return -1;
    }
    if (cellDate > filterDate) {
      return 1;
    }
  },
  browserDatePicker: true,
};

export const dateColumnFilter = {
  filters: [
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
    },
  ],
};

export const numberColumnFilter = {
  filters: [
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
        comparator: (a, b) => {
          const parsedA = parseInt(a);
          const parsedB = parseInt(b);
          if (parsedA === parsedB) return 0;
          return parsedA > parsedB ? 1 : -1;
        },
      },
    },
    {
      filter: 'agNumberColumnFilter',
      filterParams: {
        defaultOption: 'contains',
        caseSensitive: false,
      },
    },
  ],
};

export const serverSideDateColumnFilter = (resource) => ({
  filters: [
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
        valueFormatter: dateFormatter,
        values: async (params) => {
          const { field } = params.colDef;
          const response = await DatagridAPI.getFilterValues(resource, field);
          params.success(response);
        },
        refreshValuesOnOpen: true,
      },
    },
    {
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
    },
  ],
});

export const serverSideNumberColumnFilter = (resource) => ({
  filters: [
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
        values: async (params) => {
          const { field } = params.colDef;
          const response = await DatagridAPI.getFilterValues(resource, field);
          params.success(response);
        },
        refreshValuesOnOpen: true,
      },
    },
    {
      filter: 'agNumberColumnFilter',
      filterParams: {
        defaultOption: 'contains',
        caseSensitive: false,
      },
    },
  ],
});
