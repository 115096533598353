export const onRowClicked = (table, props) => {
    if (!table?.data?.id) return;
    if (table?.event.ctrlKey || table?.event.shiftKey) return;

    if (props.rowDoubleClicked) {
        props.rowDoubleClicked(table.data.id);
    }
    if (props.onRowClickedFullData) {
        props.onRowClickedFullData(table.data);
    }
};
