import * as React from 'react';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  BooleanField,
  BooleanInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import ActionBarShow from '../../../components/ActionBarShow';
import { AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { Annotations } from '../../../components/Annotations';
import { sourceTab } from '../../../components/SourceTab';
import { UserInput } from '../../../components/CustomInputs';
import { AuditLog } from '../../../components/AuditLog';
import { NewEnumField } from '../../../types/NewEnumField';
import CrudWrapper from '../../../components/CrudWrapper';
import { RuleDisplayObservation } from '../../../components/RuleDisplay';
import EditToolbar from '../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.productName : ''}</span>;
};

export const CommunicationUsageShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField source="userName" />
          <BooleanField source="standardFeatureEnabled" />
          <BooleanField source="enterpriseFeatureEnabled" />
          <BooleanField source="plusFeatureEnabled" />
          <BooleanField source="unifiedMessagingEnabled" />
          <BooleanField source="enterpriseVoiceEnabled" />
          <BooleanField source="audioVideoEnabled" />
          <BooleanField source="enabledForRichPresence" />
          <TextField source="dialPlan" />
          <TextField source="lineServer" />
          <TextField source="voicePolicy" />
          <TextField source="conferencingPolicy" />
          <TextField source="externalAccessPolicy" />
          <TextField source="registarPool" />
          <BooleanField source="isCostAvoidanceCandidate" />
        </Tab>
        <Tab label="Classification">
          <NewEnumField source="userScope" label="userScope" reference="userscopes" />
          <NewEnumField source="userRole" label="userRole" reference="userroles" />
          <NewEnumField source="userType" label="userType" reference="usertypes" />
          <NewEnumField source="userClass" label="userClass" reference="userclasses" />
          <NewEnumField source="userState" label="userState" reference="userstates" />
          <NewEnumField source="userStatus" label="userStatus" reference="userstatuses" />
        </Tab>
        <Tab label="Observations">
          <RuleDisplayObservation />
        </Tab>
        <Tab label="Annotations">
          <Annotations />
        </Tab>
        {sourceTab}
        <Tab label="Audit">
          <AuditLog resource={props.resource} id={props.id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const CommunicationUsageEdit = (props) => {
  const [userFilter, setUserFilter] = React.useState('');
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        <SimpleForm
          redirect="show"
          autoClose
          toolbar={<EditToolbar message={'communication usage'} />}
        >
          <UserInput setUserFilter={setUserFilter} />
          <ReferenceInput
            source="userAccessId"
            reference="useraccess"
            filter={{ userId: userFilter }}
          >
            <SelectInput optionText="userName" />
          </ReferenceInput>
          <AccordionFormPanel label="Basic">
            <TextInput source="dialPlan" />
            <TextInput source="lineServer" />
            <TextInput source="voicePolicy" />
            <TextInput source="conferencingPolicy" />
            <TextInput source="externalAccessPolicy" />
            <TextInput source="registarPool" />
            <BooleanInput source="isCostAvoidanceCandidate" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Properties">
            <BooleanInput source="standardFeatureEnabled" />
            <BooleanInput source="enterpriseFeatureEnabled" />
            <BooleanInput source="plusFeatureEnabled" />
            <BooleanInput source="unifiedMessagingEnabled" />
            <BooleanInput source="enterpriseVoiceEnabled" />
            <BooleanInput source="audioVideoEnabled" />
            <BooleanInput source="enabledForRichPresence" />
          </AccordionFormPanel>

          <AccordionFormPanel label="Notes">
            <MarkdownInput source="notes" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Interpretations">
            <MarkdownInput source="interpretations" />
          </AccordionFormPanel>
          <AccordionFormPanel label="FeedBack">
            <MarkdownInput source="feedback" />
          </AccordionFormPanel>
        </SimpleForm>
      </Edit>
    </CrudWrapper>
  );
};

export const CommunicationUsageCreate = (props) => {
  const [userFilter, setUserFilter] = React.useState('');
  return (
    <CrudWrapper>
      <Create title="New" {...props}>
        <SimpleForm redirect="show" autoClose>
          <UserInput setUserFilter={setUserFilter} />
          <ReferenceInput
            source="userAccessId"
            reference="useraccess"
            filter={{ userId: userFilter }}
          >
            <SelectInput optionText="userName" />
          </ReferenceInput>
          <AccordionFormPanel label="Basic">
            <TextInput source="dialPlan" />
            <TextInput source="lineServer" />
            <TextInput source="voicePolicy" />
            <TextInput source="conferencingPolicy" />
            <TextInput source="externalAccessPolicy" />
            <TextInput source="registarPool" />
            <BooleanInput source="isCostAvoidanceCandidate" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Properties">
            <BooleanInput source="standardFeatureEnabled" />
            <BooleanInput source="enterpriseFeatureEnabled" />
            <BooleanInput source="plusFeatureEnabled" />
            <BooleanInput source="unifiedMessagingEnabled" />
            <BooleanInput source="enterpriseVoiceEnabled" />
            <BooleanInput source="audioVideoEnabled" />
            <BooleanInput source="enabledForRichPresence" />
          </AccordionFormPanel>

          <AccordionFormPanel label="Notes">
            <MarkdownInput source="notes" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Interpretations">
            <MarkdownInput source="interpretations" />
          </AccordionFormPanel>
          <AccordionFormPanel label="FeedBack">
            <MarkdownInput source="feedback" />
          </AccordionFormPanel>
        </SimpleForm>
      </Create>
    </CrudWrapper>
  );
};
