import * as React from 'react';
import DataGrid from '../../../../components/DataGrid';
import { List } from 'react-admin';

import { useHistory } from 'react-router';
import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { dateFormatter } from '../../../../utils/formatters';
import { observationValueGetter, useAgGridEnumCol } from '../../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../../types/SourceColumns';
import { dateColumnFilter } from '../../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Agreements" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn field="agreementNumber" />
        <AgGridColumn field="manufacturerKey" />
        <AgGridColumn field="supplierKey" />
        <AgGridColumn field="parentAgreementNumber" />
        <AgGridColumn {...useAgGridEnumCol('agreementclasses', 'agreementClass')} />
        <AgGridColumn {...useAgGridEnumCol('agreementtypes', 'agreementType')} />
        <AgGridColumn {...useAgGridEnumCol('agreementstatuses', 'agreementStatus')} />
        <AgGridColumn {...useAgGridEnumCol('agreementscopes', 'agreementScope')} />
        <AgGridColumn
          field="startDate"
          valueFormatter={dateFormatter}
          filter="agMultiColumnFilter"
          filterParams={dateColumnFilter}
        />
        <AgGridColumn
          field="endDate"
          valueFormatter={dateFormatter}
          filter={'agMultiColumnFilter'}
          filterParams={dateColumnFilter}
        />
        <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
        <AgGridColumn headerName="Annotanions">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
