import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Manufacturers" actions={<ListActions />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn field="manufacturerKey" headerName="Manufacturer" />
        <AgGridColumn field="legalEntity" />
        <AgGridColumn field="address" />
        <AgGridColumn field="postalCode" />
        <AgGridColumn field="city" />
        <AgGridColumn field="state" />
        <AgGridColumn field="country" />
        <AgGridColumn field="continent" />
        <AgGridColumn field="region" />
      </DataGrid>
    </List>
  );
};
