import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';
import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';
import { numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton
        basePath={basePath}
        onClick={() => sessionStorage.setItem('agreementId', props.agreementId)}
      />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();
  const url = props.location.pathname;
  const urlArray = url.split('/');
  const agreementId = urlArray[2];

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List {...props} title="Pricing Options" actions={<ListActions agreementId={agreementId} />}>
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="domainName" />
          <AgGridColumn field="agreement.agreementNumber" headerName="Agreement number" />
          <AgGridColumn
            {...useAgGridEnumCol('agreementtypes', 'agreementType')}
            columnGroupShow="open"
          />
          <AgGridColumn
            {...useAgGridEnumCol('agreementlinetypes', 'agreementLineType')}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Products">
          <AgGridColumn
            field="productCategories"
            valueGetter={(params) => {
              return params.data?.productCategories?.map((item) => `${item}`).join(', ');
            }}
          />
          <AgGridColumn
            field="productGroups"
            valueGetter={(params) => {
              return params.data?.productGroups?.map((item) => `${item}`).join(', ');
            }}
          />
          <AgGridColumn
            field="editions"
            valueGetter={(params) => {
              return params.data?.editions?.map((item) => `${item}`).join(', ');
            }}
          />
          <AgGridColumn headerName="Properties"></AgGridColumn>
          <AgGridColumn field="isPlatform" />
          <AgGridColumn field="bundles" />
          <AgGridColumn field="currencyCode" />
          <AgGridColumn field="priceType" />
          <AgGridColumn field="priceLevelApplicationPool" />
          <AgGridColumn
            field="priceLevelServerPool"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="priceLevelSystemPool"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn field="purchasePeriod" columnGroupShow="open" />
          <AgGridColumn field="priceCategory" columnGroupShow="open" />
          <AgGridColumn
            field="discount"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn field="vat" columnGroupShow="open" />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
