import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List, useRefresh } from 'react-admin';
import { useHistory } from 'react-router';
import { TopToolbar, Button, sanitizeListRestProps } from 'react-admin';
import { getScope } from '../../../utils/scopeQuery';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../types/NewEnumCell';
import { dateFormatter } from '../../../utils/formatters';
import { useFetch } from '../../../utils/useFetch';
import { dateColumnFilter, numberColumnFilter } from '../../../utils/filterParams';

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const refresh = useRefresh();
  const checkScope = getScope().measurementId;
  const getData = useFetch(
    `/measurements/regenerateentitlements/${checkScope}`,
    'POST',
    null,
    true,
  );

  const regenerateEntitlements = async () => {
    await getData();
    refresh();
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button onClick={regenerateEntitlements} label="Regenerate entitlements" />
    </TopToolbar>
  );
};

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  return (
    <List
      {...props}
      title="Entitlements"
      actions={<ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions />} />}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked} source="entitlements">
        <AgGridColumn headerName="Claims">
          <AgGridColumn field="context" />
          <AgGridColumn field="manufacturer" />
          <AgGridColumn field="productFamily" columnGroupShow="open" />
          <AgGridColumn field="productName" />
          <AgGridColumn field="edition" />
          <AgGridColumn field="version" />
          <AgGridColumn field="productPool" columnGroupShow="open" />
          <AgGridColumn field="productCategory" columnGroupShow="open" />
          <AgGridColumn field="productGroup" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Properties">
          <AgGridColumn field="excludeFromReports" />
          <AgGridColumn field="licenseType" columnGroupShow="open" />
          <AgGridColumn field="isContract" columnGroupShow="open" />
          <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
          <AgGridColumn
            {...useAgGridEnumCol('agreementtypes', 'agreementType')}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Quantity">
          <AgGridColumn
            field="licenseQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="upgradeQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="upgradeWithMainenanceQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="initialMaintenanceQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="activeMaintenanceQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="automaticAllocatedQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="manualAllocatedQuantity"
            columnGroupShow="open"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="availableQuantity"
            filter={'agMultiColumnFilter'}
            filterParams={numberColumnFilter}
          />
          <AgGridColumn
            field="maintenanceExpirationDate"
            valueFormatter={dateFormatter}
            filter={'agMultiColumnFilter'}
            filterParams={dateColumnFilter}
            columnGroupShow="open"
          />
        </AgGridColumn>
        <AgGridColumn headerName="Organization">
          <AgGridColumn field="businessUnitName" />
          <AgGridColumn field="city" columnGroupShow="open" />
          <AgGridColumn field="country" columnGroupShow="open" />
          <AgGridColumn field="region" columnGroupShow="open" />
        </AgGridColumn>
        <AgGridColumn headerName="Annotation">
          <AgGridColumn field="notes" />
          <AgGridColumn field="interpretations" columnGroupShow="open" />
          <AgGridColumn field="feedback" columnGroupShow="open" />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
