import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useIsLocked } from '../../../components/ActionBarShow';
import MeasurementInventoryModal from './MeasurementInventoryModal';
import { useTranslate } from 'react-admin';
import DateTimeFormatter from '../../../utils/dateTimeFormatter';
import Button from '@material-ui/core/Button';
import constants from '../../../utils/constants';
import RotateIcon from '@material-ui/icons/RotateLeft';
import { IconButton } from '@material-ui/core';
import MeasurementAPI from '../../../providers/APIProviders/measurementProvider';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  greenBtn: {
    color: 'green',
  },
});

const fileArray = constants.IMPORT_TYPES;
const statusArray = ['Ready for import', 'Processing', 'Imported', 'View error', 'View warning'];

const MeasurementInventoryTable = ({ listOfFiles, measurementId }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const locked = useIsLocked();

  const resetParseStatus = async (file) => {
    const newStatus = [{ parsingStatus: 0, id: file.id, fileType: file.fileType }];
    await MeasurementAPI.updateSingleFile(measurementId, newStatus);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{translate('File Name')}</TableCell>
            <TableCell align="right">{translate('File Type')}</TableCell>
            <TableCell align="right">{translate('User')}</TableCell>
            <TableCell align="right">{translate('Date')}</TableCell>
            <TableCell align="right">{translate('Progress')}</TableCell>
            <TableCell align="right">{translate('Status')}</TableCell>
            {!locked && <TableCell align="right">{translate('Reset')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {listOfFiles.map((file) => {
            return (
              <TableRow key={file.id}>
                <TableCell component="th" scope="row">
                  {file.fileName}
                </TableCell>
                <TableCell align="right">{fileArray[file.fileType]}</TableCell>
                <TableCell align="right">
                  {file.user === null ? '-' : file.user.firstName + ' ' + file.user.lastName}
                </TableCell>
                <TableCell align="right">
                  <DateTimeFormatter date={file.created} />
                </TableCell>
                <TableCell align="right">{Math.round(file.progress * 100).toFixed(2)} %</TableCell>
                <TableCell align="right">
                  {file.parsingStatus !== 3 && file.parsingStatus !== 4 ? (
                    file.parsingStatus === 2 ? (
                      <Button className={classes.greenBtn}>
                        {statusArray[file.parsingStatus]}
                      </Button>
                    ) : (
                      <Button color="primary">{statusArray[file.parsingStatus]}</Button>
                    )
                  ) : file.parsingStatus === 4 ? (
                    <MeasurementInventoryModal
                      title={file.fileName}
                      error={file.parseResult.parseErrors}
                      errorType={'warning'}
                      btnText={statusArray[file.parsingStatus]}
                    />
                  ) : (
                    <MeasurementInventoryModal
                      title={file.fileName}
                      error={file.parseResult.parseErrors}
                      btnText={statusArray[file.parsingStatus]}
                    />
                  )}
                </TableCell>
                {!locked && (
                  <TableCell align="right">
                    {file.parsingStatus === 3 ||
                    file.parsingStatus === 2 ||
                    file.parsingStatus === 4 ? (
                      <IconButton
                        onClick={() => resetParseStatus(file)}
                        children={<RotateIcon />}
                      />
                    ) : (
                      <IconButton children={<RotateIcon />} disabled />
                    )}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MeasurementInventoryTable;
