import * as React from 'react';
import { Annotations } from '../../../../components/Annotations';
import {
  Edit,
  Show,
  Create,
  TextInput,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  DateInput,
  NumberInput,
  BooleanInput,
  required,
  TopToolbar,
  CreateButton,
  FunctionField,
} from 'react-admin';
import { useHistory } from 'react-router';
import { AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import CrudWrapper from '../../../../components/CrudWrapper';
import DataGrid from '../../../../components/DataGrid';
import { NewEnumField } from '../../../../types/NewEnumField';
import { NewEnumInput } from '../../../../types/NewEnumInput';
import FileUpload, { EmptyToolbar } from '../../../../components/FileUpload';
import { NullableDateInput } from '../../../../components/NullableDateInput';
import { sourceTab } from '../../../../components/SourceTab';
import { AuditLog } from '../../../../components/AuditLog';
import { RuleDisplayObservation } from '../../../../components/RuleDisplay';
import { AgGridColumn } from 'ag-grid-react';
import { useAgGridEnumCol } from '../../../../types/NewEnumCell';
import { dateFormatter } from '../../../../utils/formatters';
import EditToolbar from '../../../../components/EditToolbar';
import { dateColumnFilter, numberColumnFilter } from '../../../../utils/filterParams';

const PageTitle = ({ record }) => {
  return <span>{record ? record.rawProductName : ''}</span>;
};

const basicinputs = (
  <SimpleForm toolbar={<EditToolbar message={'agreement'} />}>
    <ReferenceInput source="manufacturerId" label="manufacturerKey" reference="manufacturers">
      <SelectInput optionText="manufacturerKey" optionValue="id" validate={required()} />
    </ReferenceInput>
    <ReferenceInput source="parentAgreementId" label="Parent agreement" reference="agreements">
      <SelectInput optionText="agreementNumber" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput source="supplierId" abel="supplierKey" reference="suppliers">
      <SelectInput optionText="supplierKey" />
    </ReferenceInput>
    <TextInput halfWidth source="agreementNumber" validate={required()} />

    <NewEnumInput source="agreementScope" reference="agreementscopes" />
    <NewEnumInput source="agreementType" reference="agreementtypes" />
    <NewEnumInput source="agreementClass" reference="agreementclasses" />
    <NewEnumInput source="agreementStatus" reference="agreementstatuses" />

    <DateInput halfWidth source="startDate" label="startDate" validate={required()} />
    <DateInput halfWidth source="endDate" label="endDate" validate={required()} />
    <NullableDateInput halfWidth source="nextAnniversaryDate" label="nextAnniversaryDate" />
    <BooleanInput halfWidth source="isAutomaticRenewal" label="isAutomaticRenewal" />
    <NumberInput
      halfWidth
      source="alertInDaysBeforeAnniversary"
      label="Alert anniversary (months)"
    />
    <NumberInput halfWidth source="alertInDaysBeforeRenewal" label="Alert renewal (months)" />
    <NullableDateInput halfWidth source="signedAtContractor" label="signedAtContractor" />
    <NullableDateInput halfWidth source="signedAtOrganization" label="signedAtOrganization" />
    <AccordionFormPanel label="Notes">
      <MarkdownInput fullWidth source="notes" Label="" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Interpretations">
      <MarkdownInput fullWidth source="interpretations" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Feedback">
      <MarkdownInput fullWidth source="Feedback" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Link Documents">
      <FileUpload source="documents" />
    </AccordionFormPanel>
  </SimpleForm>
);

export const AgreementShow = (props) => {
  const history = useHistory();
  const url = props.location.pathname;
  const urlArray = url.split('/');
  const agreementId = urlArray[2];
  const onAgreementLineRowClicked = (id) => {
    history.push(`/agreementlines/${id}/show`);
  };
  const [hasDocuments, setHasDocuments] = React.useState(false);

  const PostEditActions = () => (
    <TopToolbar>
      <CreateButton
        basePath={'/agreementlines'}
        onClick={() => sessionStorage.setItem('agreementId', agreementId)}
      />
    </TopToolbar>
  );
  return (
    <>
      <Show title={<PageTitle />} {...props}>
        <TabbedShowLayout>
          <Tab label="Basic">
            <TextField source="agreementNumber" label="agreementNumber" />
            <ReferenceField
              source="organizationId"
              label="organizationName"
              reference="organizations"
            >
              <TextField source="organizationName" />
            </ReferenceField>
            {/*           <TextField source="parentAgreement" label="Parent agreement" />
             */}{' '}
            <TextField source="parentAgreementNumber" label="Parent agreement number" />
            <TextField halfWidth source="manufacturerId" label="manufacturerKey" />
            <TextField halfWidth source="supplierKey" label="supplierKey" />
            <NewEnumField source="agreementScope" reference="agreementscopes" />
            <NewEnumField source="agreementType" reference="agreementtypes" />
            <NewEnumField source="agreementClass" reference="agreementclasses" />
            <NewEnumField source="agreementStatus" reference="agreementstatuses" />
            <DateField halfWidth source="startDate" label="startDate" />
            <DateField halfWidth source="endDate" label="endDate" />
          </Tab>
          <Tab label="Extended">
            <AccordionFormPanel label="Claims" defaultExpanded="True">
              <TextField source="agreementNumber" label="agreementNumber" />
            </AccordionFormPanel>
            <AccordionFormPanel label="Properties">
              <ReferenceField
                source="organizationId"
                label="organizationName"
                reference="organizations"
              >
                <TextField source="organizationName" />
              </ReferenceField>
              <TextField source="manufacturerKey" label="manufacturerKey" />
              <TextField source="supplierKey" label="supplierKey" />
              <DateField source="startDate" label="startDate" />
              <DateField source="endDate" label="endDate" />
            </AccordionFormPanel>
            <AccordionFormPanel label="Agreement Specific">
              <DateField halfWidth source="nextAnniversaryDate" label="nextAnniversaryDate" />
              <BooleanField halfWidth source="isAutomaticRenewal" label="isAutomaticRenewal" />
              <NumberField
                halfWidth
                source="alertInDaysBeforeAnniversary"
                label="alertInDaysBeforeAnniversary"
              />
              <NumberField
                halfWidth
                source="alertInDaysBeforeRenewal"
                label="alertInDaysBeforeRenewal"
              />
              <DateField halfWidth source="signedAtContractor" label="signedAtContractor" />
              <DateField halfWidth source="signedAtOrganization" label="signedAtOrganization" />
            </AccordionFormPanel>
          </Tab>
          <Tab label="Observations">
            <RuleDisplayObservation />
          </Tab>
          <Tab label="Annotations">
            <Annotations />
          </Tab>
          <Tab label="Agreement Lines">
            <PostEditActions />
            <ReferenceManyField reference="agreementlines" target="agreementId" addLabel={false}>
              <DataGrid source="agreementlines" rowDoubleClicked={onAgreementLineRowClicked}>
                <AgGridColumn field="sku" />
                <AgGridColumn field="productName" />
                <AgGridColumn field="productVersion" />
                <AgGridColumn {...useAgGridEnumCol('licensemetrics', 'licenseMetric')} />
                <AgGridColumn {...useAgGridEnumCol('licensemodels', 'licenseModel')} />
                <AgGridColumn field="currencyCode" />
                <AgGridColumn
                  field="licenseQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="packageQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="netUnitPrice"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="extendedAmount"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn field="countryOfUsage" />
                <AgGridColumn
                  field="usageStartDate"
                  valueFormatter={dateFormatter}
                  filter={'agMultiColumnFilter'}
                  filterParams={dateColumnFilter}
                />
                <AgGridColumn {...useAgGridEnumCol('agreementlineusagetypes', 'usageTypeCode')} />
                <AgGridColumn
                  {...useAgGridEnumCol('agreementlinemeasureunits', 'measureUnitCode')}
                />
                <AgGridColumn {...useAgGridEnumCol('agreementstatuses', 'statusCode')} />
                <AgGridColumn {...useAgGridEnumCol('agreementlinetypes', 'typeCode')} />
                <AgGridColumn
                  field="measureUnitQuantity"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="futureUnitPriceA"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="futureUnitPriceB"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="futureUnitPriceC"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />
                <AgGridColumn
                  field="futureUnitPriceD"
                  filter={'agMultiColumnFilter'}
                  filterParams={numberColumnFilter}
                />

                <AgGridColumn headerName="Annotanions">
                  <AgGridColumn field="notes" />
                  <AgGridColumn field="interpretations" />
                  <AgGridColumn field="feedback" />
                </AgGridColumn>
              </DataGrid>
            </ReferenceManyField>
          </Tab>

          {sourceTab}
          <Tab label="Audit">
            <AuditLog resource={props.resource} id={props.id} />
          </Tab>
          {hasDocuments && (
            <Tab label="Linked Documents">
              <SimpleForm toolbar={<EmptyToolbar />}>
                <FileUpload source="documents" show />
              </SimpleForm>
            </Tab>
          )}
          <FunctionField
            style={{ diplay: 'none' }}
            label=""
            render={(record) => setHasDocuments(record?.documents?.length > 0)}
          />
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export const AgreementEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {basicinputs}
      </Edit>
    </CrudWrapper>
  );
};

export const AgreementCreate = (props) => {
  return (
    <CrudWrapper>
      <Create title="New" {...props}>
        {basicinputs}
      </Create>
    </CrudWrapper>
  );
};
