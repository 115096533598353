import React, { useEffect } from 'react';
import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
} from 'ra-ui-materialui';
import { Form, useForm } from 'react-final-form';
import { useInput, useReference } from 'react-admin';
import { Grid } from '@material-ui/core';

const NoProgressBarSelectInput = (props) => {
  return (
    <AutocompleteInput
      resettable
      style={{ flex: 1, margin: '2px', background: 'white' }}
      {...props}
      label={!props.choices.length ? 'No options' : props.label}
      loading={false}
      disabled={props.loading || props.disabled || !props.choices.length}
      helperText={false}
    />
  );
};

const NoProgressBarSelectArrayInput = (props) => {
  return (
    <AutocompleteArrayInput
      resettable
      style={{ flex: 1, margin: '2px', background: 'white' }}
      {...props}
      label={!props.choices.length ? 'No options' : props.label}
      loading={false}
      disabled={props.loading || props.disabled || !props.choices.length}
      helperText={false}
    />
  );
};

const nullId = '00000000-0000-0000-0000-000000000000';

const Fields = (props) => {
  const form = useForm();
  const reference = useReference({
    reference: 'products',
    id: props.array ? props.nestedInput.input.value?.[0] : props.nestedInput.input.value,
  });
  const productSourceName = props.nestedInput.input.name;

  useEffect(() => {
    form.change('manufacturerId', reference.referenceRecord?.manufacturerId);
    form.change('productGroup', reference.referenceRecord?.productGroup);
    if (props.array) {
      form.change(productSourceName, props.nestedInput.input.value);
    } else {
      form.change(productSourceName, reference.referenceRecord?.id);
    }
  }, [reference.loaded]);
  const { manufacturerId, productGroup } = form.getState().values;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <ReferenceInput
            source="manufacturerId"
            reference="manufacturers"
            onChange={() => {
              form.change('productGroup', undefined);
              form.change(productSourceName, null);
              props.nestedInput.input.onChange(null);
            }}
          >
            <NoProgressBarSelectInput resettable optionText="manufacturerKey" />
          </ReferenceInput>
        </Grid>
        <Grid item>
          <ReferenceInput
            source="productGroup"
            reference="products/productgroups"
            onChange={() => {
              form.change(productSourceName, null);
              props.nestedInput.input.onChange(null);
            }}
            disabled={!manufacturerId}
            filter={
              !manufacturerId
                ? { id: nullId }
                : props.specialFilter
                ? { manufacturerId, bundle: true }
                : { manufacturerId }
            }
          >
            <NoProgressBarSelectInput optionText="id" />
          </ReferenceInput>
        </Grid>
        {!props.array && (
          <Grid item>
            <ReferenceInput
              reference="products"
              source={productSourceName}
              filter={
                !manufacturerId || !productGroup
                  ? { id: nullId }
                  : props.specialFilter
                  ? { productGroup, manufacturerId, bundle: true }
                  : { productGroup, manufacturerId }
              }
              disabled={!manufacturerId || !productGroup}
              {...props.nestedInput.input}
            >
              <NoProgressBarSelectInput
                optionText={(record) => record.productName + ' ' + record.version}
              />
            </ReferenceInput>
          </Grid>
        )}
      </Grid>
      {props.array && (
        <Grid container spacing={2}>
          <Grid item>
            <ReferenceArrayInput
              reference="products"
              label="Products"
              source={productSourceName}
              filter={
                !manufacturerId || !productGroup
                  ? { id: nullId }
                  : props.specialFilter
                  ? { productGroup, manufacturerId, bundle: true }
                  : { productGroup, manufacturerId }
              }
              disabled={!manufacturerId || !productGroup}
              {...props.nestedInput.input}
            >
              <NoProgressBarSelectArrayInput
                optionText={(record) =>
                  record ? record.productName + ' ' + record.version : 'Loading...'
                }
              />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
      )}
    </>
  );
};
const FilteringProducts = (props) => {
  const nestedInput = useInput(props);
  return (
    <>
      <Form
        onSubmit={() => {}}
        render={() => (
          <Fields
            nestedInput={nestedInput}
            specialFilter={props.specialFilter}
            array={props.array}
          />
        )}
      />
    </>
  );
};
export default FilteringProducts;
