import React from 'react';
import { useHistory } from 'react-router';
import {
  Edit,
  TextInput,
  SelectInput,
  Create,
  Show,
  TabbedShowLayout,
  ReferenceManyField,
  Tab,
  TextField,
  required,
  maxLength,
  Button,
  TopToolbar,
  EditButton,
  usePermissions,
  regex,
  BooleanInput,
} from 'react-admin';
import AssignUsers, { AssignUsersShow } from '../../../../components/AssignUsers';
import DataGrid from '../../../../components/DataGrid';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import ArchiveIcon from '@material-ui/icons/Archive';
import { NewEnumField } from '../../../../types/NewEnumField';
import { NewEnumInput } from '../../../../types/NewEnumInput';
import { useRefresh } from 'ra-core';
import { AgGridColumn } from 'ag-grid-react';
import { dateFormatter } from '../../../../utils/formatters';
import { useAgGridEnumCol } from '../../../../types/NewEnumCell';
import { useFetch } from '../../../../utils/useFetch';
import EditToolbar from '../../../../components/EditToolbar';
import { dateColumnFilter } from '../../../../utils/filterParams';

const PageTitle = ({ record }) => {
  return <span>{record ? record.organizationName : ''}</span>;
};

const validateOrganizationName = [required(), maxLength(100)];

const inputs = (
  <AccordionForm>
    <AccordionFormPanel label="Organization details" defaultExpanded>
      <TextInput source="organizationName" validate={validateOrganizationName} />
      <TextInput source="city" label="city" validate={required()} />
      <TextInput source="country" label="country" validate={required()} />
      <TextInput source="continent" label="continent" validate={required()} />
      <TextInput source="region" validate={required()} />
      <TextInput
        source="crm"
        label="CRM"
        validate={regex(
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
          'CRM format not valid.',
        )}
      />
    </AccordionFormPanel>
    <AccordionFormPanel label="Location details">
      <TextInput source="addressLine1" label="Address Line 1" validate={required()} />
      <TextInput source="addressLine2" label="Address Line 2" />
      <TextInput source="corporateDomain" label="Corporate Domain" validate={required()} />
      <TextInput
        source="currencyCode"
        label="Currency Code"
        validate={[required(), maxLength(3)]}
      />
      <SelectInput
        source="languageTag"
        label="Language Tag"
        optionText="name"
        optionValue="name"
        choices={[{ id: 0, name: 'en-US' }]}
        validate={required()}
      />
      <TextInput source="postalCode" label="Postal Code" validate={required()} />
      <TextInput source="state" label="State" validate={required()} />
    </AccordionFormPanel>

    <AccordionFormPanel label="Organization codes">
      <NewEnumInput reference="organizationscopes" source="organizationScope" />
      <NewEnumInput reference="organizationtypes" source="organizationType" />
      <NewEnumInput reference="organizationclasses" source="organizationClass" />
      <NewEnumInput reference="organizationstatuses" source="organizationStatus" />
      <NewEnumInput reference="organizationsizes" source="organizationSize" />
      <NewEnumInput reference="organizationrevenues" source="organizationRevenue" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Set default Business Unit">
      <TextInput
        source="businessUnit.businessUnitName"
        validate={required()}
        label="businessUnitName"
      />
      <TextInput source="businessUnit.city" validate={required()} label="city" />
      <TextInput source="businessUnit.country" validate={required()} label="country" />
      <TextInput source="businessUnit.continent" validate={required()} label="continent" />
      <TextInput source="businessUnit.region" validate={required()} label="region" />
      <TextInput halfWidth source="businessUnit.languageTag" label="languageTag" />
      <BooleanInput
        style={{ display: 'none' }}
        source="businessUnit.isDefaultBusinessUnit"
        label="isDefaultBusinessUnit"
        defaultValue
      />
    </AccordionFormPanel>
    <AccordionFormPanel label="Add Members">
      <AssignUsers />
    </AccordionFormPanel>
  </AccordionForm>
);

export const OrganizationShow = (props) => {
  const history = useHistory();
  const { permissions } = usePermissions();
  const fetchArchive = useFetch(`/organizations/delete/${props.id}`, 'DELETE', null, true);

  const onProjectRowClicked = (id) => {
    const url = `/projects/${id}/show`;
    history.push(url);
  };

  const fields = (
    <TabbedShowLayout>
      <Tab label="Basic">
        <TextField source="organizationName" label="organizationName" />
        <TextField source="city" label="city" />
        <TextField source="country" label="country" />
        <TextField source="crm" label="CRM" />
      </Tab>
      <Tab label="Extended">
        <AccordionForm redirect="show">
          <AccordionFormPanel label="Claims" defaultExpanded="True">
            <TextField source="organizationName" label="organizationName" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Address">
            <TextField source="addressLine1" label="addressLine1" />
            <TextField source="addressLine2" label="addressLine2" />
            <TextField source="postalCode" label="postalCode" />
            <TextField source="city" label="city" />
            <TextField source="state" label="state" />
            <TextField source="country" label="country" />
            <TextField source="continent" label="continent" />
            <TextField source="region" label="region" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Properties" defaultExpanded="True">
            <TextField source="corporateDomain" label="corporateDomain" />
            <NewEnumField reference="organizationscopes" source="organizationScope" />
            <NewEnumField reference="organizationtypes" source="organizationType" />
            <NewEnumField reference="organizationclasses" source="organizationClass" />
            <NewEnumField reference="organizationstatuses" source="organizationStatus" />
            <NewEnumField reference="organizationsizes" source="organizationSize" />
            <NewEnumField reference="organizationrevenues" source="organizationRevenue" />
            <TextField halfWidth source="languageTag" label="languageTag" />
          </AccordionFormPanel>
        </AccordionForm>
      </Tab>
      <Tab label="Projects">
        <ReferenceManyField reference="projects" target="organization" addLabel={false}>
          <DataGrid source="projects" rowDoubleClicked={onProjectRowClicked}>
            <AgGridColumn field="projectName" />
            <AgGridColumn field="organizationName" />
            <AgGridColumn
              field="startDate"
              valueFormatter={dateFormatter}
              filter={'agMultiColumnFilter'}
              filterParams={dateColumnFilter}
            />
            <AgGridColumn
              field="endDate"
              valueFormatter={dateFormatter}
              filter={'agMultiColumnFilter'}
              filterParams={dateColumnFilter}
            />
            <AgGridColumn {...useAgGridEnumCol('projectscopes', 'projectScope')} />
            <AgGridColumn {...useAgGridEnumCol('projecttypes', 'projectType')} />
            <AgGridColumn {...useAgGridEnumCol('projectclasses', 'projectClass')} />
            <AgGridColumn {...useAgGridEnumCol('projectstatuses', 'projectStatus')} />
          </DataGrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Members">
        <AssignUsersShow />
      </Tab>
    </TabbedShowLayout>
  );

  const handleArchive = async () => {
    fetchArchive();
  };

  const PostShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
      {(permissions === 'admin' || permissions === 'planner') && (
        <>
          <Button
            startIcon={<ArchiveIcon style={{ marginRight: '-10px' }} />}
            onClick={handleArchive}
            label="Archive"
          />
          <EditButton basePath={basePath} record={data} />
        </>
      )}
    </TopToolbar>
  );

  return (
    <Show actions={<PostShowActions />} title={<PageTitle />} {...props}>
      {fields}
    </Show>
  );
};

export const OrganizationEdit = (props) => (
  <Edit undoable={false} title={<PageTitle />} {...props}>
    <AccordionForm toolbar={<EditToolbar message={'organization'} />}>
      <AccordionFormPanel label="Organization details" defaultExpanded>
        <TextInput source="organizationName" validate={validateOrganizationName} />
        <TextInput source="city" label="city" validate={required()} />
        <TextInput source="country" label="country" validate={required()} />
        <TextInput source="continent" label="continent" validate={required()} />
        <TextInput source="region" validate={required()} />
        <TextInput
          source="crm"
          label="CRM"
          validate={regex(
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
            'CRM format not valid.',
          )}
        />
      </AccordionFormPanel>
      <AccordionFormPanel label="Location details">
        <TextInput source="addressLine1" label="Address Line 1" validate={required()} />
        <TextInput source="addressLine2" label="Address Line 2" />
        <TextInput source="corporateDomain" label="Corporate Domain" validate={required()} />
        <TextInput
          source="currencyCode"
          label="Currency Code"
          validate={[required(), maxLength(3)]}
        />
        <SelectInput
          source="languageTag"
          label="Language Tag"
          optionText="name"
          optionValue="name"
          choices={[{ id: 0, name: 'en-US' }]}
          validate={required()}
        />
        <TextInput source="postalCode" label="Postal Code" validate={required()} />
        <TextInput source="state" label="State" validate={required()} />
      </AccordionFormPanel>

      <AccordionFormPanel label="Organization codes">
        <NewEnumInput reference="organizationscopes" source="organizationScope" />
        <NewEnumInput reference="organizationtypes" source="organizationType" />
        <NewEnumInput reference="organizationclasses" source="organizationClass" />
        <NewEnumInput reference="organizationstatuses" source="organizationStatus" />
        <NewEnumInput reference="organizationsizes" source="organizationSize" />
        <NewEnumInput reference="organizationrevenues" source="organizationRevenue" />
      </AccordionFormPanel>

      <AccordionFormPanel label="Add Members">
        <AssignUsers />
      </AccordionFormPanel>
    </AccordionForm>
  </Edit>
);

export const OrganizationCreate = (props) => {
  const refresh = useRefresh();
  const history = useHistory();
  return (
    <Create
      onSuccess={(value) => {
        history.push(`${value.data.id}/show`);
        refresh();
      }}
      title="New"
      {...props}
    >
      {inputs}
    </Create>
  );
};
