import BusinessUnitIcon from '@material-ui/icons/HomeWork';
import BusinessUnitList from './BusinessUnitList';
import { BusinessUnitShow, BusinessUnitEdit, BusinessUnitCreate } from './BusinessUnitForm';

export default {
  list: BusinessUnitList,
  icon: BusinessUnitIcon,
  edit: BusinessUnitEdit,
  create: BusinessUnitCreate,
  show: BusinessUnitShow,
};
