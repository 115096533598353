import * as React from 'react';
import { Annotations } from '../../../../components/Annotations';
import {
  Create,
  DateField,
  Edit,
  maxLength,
  NumberField,
  NumberInput,
  required,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';
import CrudWrapper from '../../../../components/CrudWrapper';
import { NewEnumField } from '../../../../types/NewEnumField';
import { NullableDateInput } from '../../../../components/NullableDateInput';
import { NewEnumInput } from '../../../../types/NewEnumInput';
import FilteringProducts from '../../../../components/FilteringProducts';
import ActionBarShow from '../../../../components/ActionBarShow';
import EditToolbar from '../../../../components/EditToolbar';

const PageTitle = ({ record }) => {
  return <span>{record ? record.rawProductName : ''}</span>;
};

const fields = (
  <TabbedShowLayout>
    <Tab label="Basic">
      <TextField source="agreementNumber" />
      <TextField source="sku" />
      <NewEnumField source="licenseMetric" reference="licensemetrics" />
      <NewEnumField source="licenseModel" reference="licensemodels" />
      <TextField source="version" />
      <TextField source="currencyCode" />
      <NumberField source="licenseQuantity" />
      <NumberField source="packageQuantity" />
      <NumberField source="extendedAmount" />
      <TextField source="countryOfUsage" />
      <NumberField source="netUnitPrice" />
      <NewEnumField source="usageTypeCode" reference="agreementlineusagetypes" />
      <NewEnumField source="measureUnitCode" reference="agreementlinemeasureunits" />
      <NewEnumField source="statusCode" reference="agreementstatuses" />
      <NewEnumField source="typeCode" reference="agreementlinetypes" />
      <DateField showTime source="usageStartDate" />
      <NumberField source="measureUnitQuantity" />
      <NumberField source="futureUnitPriceA" />
      <NumberField source="futureUnitPriceB" />
      <NumberField source="futureUnitPriceC" />
      <NumberField source="futureUnitPriceD" />
    </Tab>
    <Tab label="Annotations">
      <Annotations />
    </Tab>
  </TabbedShowLayout>
);

const basicinputs = (
  <AccordionForm redirect="show" toolbar={<EditToolbar message={'agreement line'} />}>
    <AccordionFormPanel label="Basic" defaultExpanded>
      <FilteringProducts validate={required()} source="productId" />
      <TextInput source="sku" defaultValue="" />
      <NewEnumInput source="licenseMetric" validate={required()} reference="licensemetrics" />
      <NewEnumInput source="licenseModel" validate={required()} reference="licensemodels" />
      <NewEnumInput source="statusCode" validate={required()} reference="agreementstatuses" />
      <NewEnumInput source="typeCode" validate={required()} reference="agreementlinetypes" />
      <TextInput source="currencyCode" validate={[required(), maxLength(3)]} />
      <NumberInput source="licenseQuantity" validate={required()} />
      <NumberInput source="packageQuantity" validate={required()} />
      <NumberInput source="extendedAmount" />
      <TextInput source="countryOfUsage" />
      <NumberInput source="netUnitPrice" />
      <NewEnumInput
        source="usageTypeCode"
        reference="agreementlineusagetypes"
        validate={required()}
      />
      <NewEnumInput
        source="measureUnitCode"
        reference="agreementlinemeasureunits"
        validate={required()}
      />
      <NumberInput source="measureUnitQuantity" />
      <NullableDateInput source="usageStartDate" />
      <NumberInput source="futureUnitPriceA" />
      <NumberInput source="futureUnitPriceB" />
      <NumberInput source="futureUnitPriceC" />
      <NumberInput source="futureUnitPriceD" />
    </AccordionFormPanel>
    <AccordionFormPanel label="Annotations">
      <MarkdownInput source="notes" />

      <MarkdownInput source="interpretations" />

      <MarkdownInput source="feedback" />
    </AccordionFormPanel>
  </AccordionForm>
);

export const AgreementLineShow = (props) => {
  return (
    <Show title={<PageTitle />} actions={<ActionBarShow {...props} />} {...props}>
      {fields}
    </Show>
  );
};

export const AgreementLineEdit = (props) => {
  return (
    <CrudWrapper>
      <Edit undoable={false} title={<PageTitle />} {...props}>
        {basicinputs}
      </Edit>
    </CrudWrapper>
  );
};

export const AgreementLineCreate = (props) => {
  const agreementId = sessionStorage.getItem('agreementId');
  return (
    <CrudWrapper>
      <Create title="New" {...props}>
        <AccordionForm redirect="show">
          <AccordionFormPanel label="Basic" defaultExpanded>
            <TextInput
              source="agreementId"
              initialValue={agreementId}
              style={{ display: 'none' }}
            />
            <FilteringProducts source="productId" />
            <TextInput source="sku" defaultValue="" />
            <NewEnumInput source="licenseMetric" reference="licensemetrics" validate={required()} />
            <NewEnumInput source="licenseModel" reference="licensemodels" validate={required()} />
            <NewEnumInput source="statusCode" reference="agreementstatuses" validate={required()} />
            <NewEnumInput source="typeCode" reference="agreementlinetypes" validate={required()} />
            <TextInput source="currencyCode" validate={[required(), maxLength(3)]} />
            <NumberInput source="licenseQuantity" validate={required()} />
            <NumberInput source="packageQuantity" validate={required()} />
            <NumberInput source="extendedAmount" />
            <TextInput source="countryOfUsage" />
            <NumberInput source="netUnitPrice" />
            <NewEnumInput
              reference="agreementlineusagetypes"
              source="usageTypeCode"
              validate={required()}
            />
            <NewEnumInput
              reference="agreementlinemeasureunits"
              source="measureUnitCode"
              validate={required()}
            />
            <NumberInput source="measureUnitQuantity" />
            <NullableDateInput source="usageStartDate" />
            <NumberInput source="futureUnitPriceA" />
            <NumberInput source="futureUnitPriceB" />
            <NumberInput source="futureUnitPriceC" />
            <NumberInput source="futureUnitPriceD" />
          </AccordionFormPanel>
          <AccordionFormPanel label="Annotations">
            <MarkdownInput source="notes" />

            <MarkdownInput source="interpretations" />

            <MarkdownInput source="feedback" />
          </AccordionFormPanel>
        </AccordionForm>
      </Create>
    </CrudWrapper>
  );
};
