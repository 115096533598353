import * as React from 'react';
import {
  ArrayField,
  ArrayInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FormDataConsumer,
  FunctionField,
  ReferenceArrayField,
  ReferenceField,
  SaveButton,
  Show,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  Toolbar,
  TopToolbar,
  usePermissions,
  useRefresh,
} from 'react-admin';
import './style.css';
import CrudWrapper from '../../../components/CrudWrapper';
import ActionBarShow from '../../../components/ActionBarShow';
import { ObservationDropdown, SetFields, WhereFields } from '../DynamicInputs';
import { useFetch } from '../../../utils/useFetch';
import EditToolbar from '../../../components/EditToolbar';

export const GenericRuleShow = (props) => {
  const [config, setConfig] = React.useState();
  const ruleConfigurationKey = props.options.ruleConfigurationKey;
  const { permissions } = usePermissions();
  const fetchConfig = useFetch('/rules/configuration', 'GET');

  const configStorage = JSON.parse(localStorage.getItem('configuration'));
  const setObjectWhere = configStorage[ruleConfigurationKey].models;
  React.useEffect(() => {
    const getConfig = async () => {
      const response = await fetchConfig();
      const setObject = response.configuration?.[ruleConfigurationKey].set;

      setConfig(setObject);
    };

    getConfig();
  }, []);

  const admin = props.options.isSystem;
  const PostShowActions = ({ basePath, data }) => (
    <TopToolbar>
      {(permissions === 'admin' || (permissions === 'analyst' && !admin)) && (
        <EditButton basePath={basePath} record={data} />
      )}
    </TopToolbar>
  );
  return (
    <Show
      actions={
        admin && permissions === 'admin' ? (
          <ActionBarShow {...props} />
        ) : (
          <PostShowActions {...props} />
        )
      }
      title=" "
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="Basic">
          <ArrayField source="where">
            <Datagrid>
              <TextField fullWidth source="joinOperator" label="Join Operator" />
              <TextField fullWidth source="source" label="Source" />
              <TextField fullWidth source="field" label="Field" />
              <TextField fullWidth source="operator" label="Operator" />
              <FunctionField
                fullWidth
                render={(record) =>
                  setObjectWhere[record.source]?.[record?.field]?.values?.[record.value] ??
                  record.value ??
                  null
                }
                label="Value"
              />
            </Datagrid>
          </ArrayField>
          <h4>Set</h4>
          {config
            ? Object.entries(config).map(([fieldName, fieldConfig]) => {
                if (fieldConfig.values) {
                  return (
                    <FunctionField
                      label={fieldName}
                      render={(record) => fieldConfig.values?.[record.set?.[fieldName]]}
                    />
                  );
                } else {
                  switch (fieldConfig.type) {
                    case 'BusinessUnit':
                      return (
                        <ReferenceField reference="businessunits" source={`set.${fieldName}`}>
                          <TextField source="businessUnitName" />
                        </ReferenceField>
                      );
                    case 'Boolean':
                      return <TextField source={`set.${fieldName}`} label={fieldName} />;
                    default:
                      return <TextField source={`set.${fieldName}`} label={fieldName} />;
                  }
                }
              })
            : undefined}
          {props.resource !== 'globalfingerprintrules' && (
            <ReferenceArrayField reference="baseobservation" source="observations">
              <SingleFieldList>
                <FunctionField
                  render={(record) => (
                    <ChipField
                      record={{ name: `${record.observationTag} - ${record.observation}` }}
                      source="name"
                    />
                  )}
                />
              </SingleFieldList>
            </ReferenceArrayField>
          )}
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const GenericRuleEdit = (props) => {
  const [config, setConfig] = React.useState({ models: [], set: [] });
  const ruleConfigurationKey = props.options.ruleConfigurationKey;
  const [observation, setObservation] = React.useState([]);
  const fetchConfig = useFetch('/rules/configuration', 'GET');
  const fetchObservations = useFetch('/baseobservation', 'GET');
  const isAdmin = props.options.isSystem;

  React.useEffect(() => {
    const getConfig = async () => {
      const response = await fetchConfig();
      setConfig(response.configuration?.[ruleConfigurationKey]);

      const observationRes = await fetchObservations();
      const observationArray = observationRes.map((item) => ({
        name: item.action + ' - ' + item.observation,
        id: item.id,
        observationObject: item,
      }));

      setObservation(observationArray);
    };

    getConfig();
  }, []);

  const fields = (
    <SimpleForm redirect="show" toolbar={<EditToolbar message={'rule'} />}>
      <ArrayInput
        source="where"
        class={
          'form-iterator MuiFormControl-root MuiFormControl-marginNormal MuiFormControl-fullWidth'
        }
        defaultValue={[initialWhereState]}
      >
        <SimpleFormIterator>
          <FormDataConsumer>
            {(formControl) => {
              return (
                <WhereFields
                  models={config?.models}
                  getSource={formControl.getSource}
                  scopedFormData={formControl.scopedFormData}
                  first={formControl.id === 'where[0]'}
                />
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <SetFields config={config.set} />
      {props.resource !== 'globalfingerprintrules' && (
        <ObservationDropdown observations={observation} />
      )}
      <BooleanInput label="Enabled" source="enabled" />
    </SimpleForm>
  );

  return !isAdmin ? (
    <CrudWrapper>
      <Edit undoable={false} title="New" {...props}>
        {fields}
      </Edit>
    </CrudWrapper>
  ) : (
    <Edit undoable={false} title="New" {...props}>
      {fields}
    </Edit>
  );
};

const initialWhereState = {
  source: null,
  field: null,
  value: null,
  operators: null,
};

export const GenericRuleCreate = (props) => {
  const [config, setConfig] = React.useState({ models: [], set: [] });
  const [observation, setObservation] = React.useState([]);
  const refresh = useRefresh();
  const fetchConfig = useFetch('/rules/configuration', 'GET');
  const fetchObservations = useFetch('/baseobservation', 'GET');
  const ruleConfigurationKey = props.options.ruleConfigurationKey;
  const isAdmin = props.options.isSystem;

  React.useEffect(() => {
    const getConfig = async () => {
      const response = await fetchConfig();
      setConfig(response.configuration?.[ruleConfigurationKey]);

      const observationRes = await fetchObservations();
      const observationArray = observationRes.map((item) => ({
        name: item.action + ' - ' + item.observation,
        id: item.id,
        observationObject: item,
      }));

      setObservation(observationArray);
    };

    getConfig();
  }, []);

  const fields = (
    <SimpleForm redirect="show" toolbar={<PostCreateToolbar refresh={() => refresh()} />}>
      <ArrayInput
        source="where"
        class={
          'form-iterator MuiFormControl-root MuiFormControl-marginNormal MuiFormControl-fullWidth'
        }
        defaultValue={[initialWhereState]}
      >
        <SimpleFormIterator>
          <FormDataConsumer>
            {(formControl) => {
              return (
                <WhereFields
                  models={config?.models}
                  getSource={formControl.getSource}
                  scopedFormData={formControl.scopedFormData}
                  first={formControl.id === 'where[0]'}
                />
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <SetFields config={config?.set} isSystem={props.options.isSystem} />
      {props.resource !== 'globalfingerprintrules' && (
        <ObservationDropdown observations={observation} />
      )}
      <BooleanInput initialValue="true" label="Enabled" source="enable" />
    </SimpleForm>
  );

  return !isAdmin ? (
    <CrudWrapper>
      <Create title="New" {...props}>
        {fields}
      </Create>
    </CrudWrapper>
  ) : (
    <Create title="New" {...props}>
      {fields}
    </Create>
  );
};

const PostCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    <SaveButton
      style={{ marginLeft: '1.6rem' }}
      label="Save and create new"
      redirect="create"
      submitOnEnter={true}
      onClick={props.refresh}
    />
  </Toolbar>
);
