import {
  AutocompleteArrayInput,
  Datagrid,
  ReferenceArrayInput,
  ReferenceArrayField,
  TextField,
} from 'ra-ui-materialui';
import React from 'react';

const AssignUsers = () => {
  return (
    <div>
      <ReferenceArrayInput
        source="users"
        reference="platformusers"
        label="Assign members"
        defaultValue={[]}
      >
        <AutocompleteArrayInput
          optionText={(record) => `${record?.lastName} ${record?.firstName} - ${record?.email}`}
        />
      </ReferenceArrayInput>
    </div>
  );
};

export default AssignUsers;

export const AssignUsersShow = () => {
  return (
    <div style={{ padding: '2rem' }}>
      <ReferenceArrayField reference="platformusers" source="users">
        <Datagrid>
          <TextField fullWidth source="firstName" label="First Name" />
          <TextField fullWidth source="lastName" label="Last Name" />
          <TextField fullWidth source="email" label="Email" />
          <TextField fullWidth source="role" label="Role" />
        </Datagrid>
      </ReferenceArrayField>
    </div>
  );
};
