import CollaborationUsageIcon from '@material-ui/icons/MeetingRoom';
import {
  CollaborationUsageCreate,
  CollaborationUsageEdit,
  CollaborationUsageShow,
} from './CollaborationUsageForm';
import CollaborationUsageList from './CollaborationUsageList';

export default {
  list: CollaborationUsageList,
  icon: CollaborationUsageIcon,
  show: CollaborationUsageShow,
  edit: CollaborationUsageEdit,
  create: CollaborationUsageCreate,
};
