import * as React from 'react';
import DataGrid from '../../../components/DataGrid';
import { List } from 'react-admin';
import { useHistory } from 'react-router';

import { useListContext, TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { ComplexActionBarShow } from '../../../components/ActionBarShow';
import { AgGridColumn } from 'ag-grid-react';
import { observationValueGetter } from '../../../types/NewEnumCell';
import { createdBy, updatedBy } from '../../../types/SourceColumns';

export default (props) => {
  const history = useHistory();

  const onRowClicked = (id) => {
    const url = `${props.basePath}/${id}/show`;
    history.push(url);
  };

  const ListActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const { basePath } = useListContext();
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <CreateButton basePath={basePath} />
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      title="Business Units"
      actions={<ComplexActionBarShow id={props.id} {...props} oldToolbar={<ListActions />} />}
    >
      <DataGrid {...props} rowDoubleClicked={onRowClicked}>
        <AgGridColumn field="organization.organizationName" />
        <AgGridColumn field="businessUnitTag" />
        <AgGridColumn field="businessUnitName" />
        <AgGridColumn field="city" />
        <AgGridColumn field="country" />
        <AgGridColumn field="continent" />
        <AgGridColumn field="region" />
        <AgGridColumn field="languageTag" />
        <AgGridColumn field="isDefaultBusinessUnit" />
        <AgGridColumn field="observations" valueGetter={(e) => observationValueGetter(e)} />
        <AgGridColumn headerName="Sources">
          <AgGridColumn headerName="Created by" field="sources" valueGetter={(e) => createdBy(e)} />
          <AgGridColumn field="sources" headerName="Updated by" valueGetter={(e) => updatedBy(e)} />
        </AgGridColumn>
      </DataGrid>
    </List>
  );
};
