import ArticlesIcon from '@material-ui/icons/Assignment';
import ArticlesList from './ArticlesList';
import { ArticlesShow, ArticlesEdit, ArticlesCreate } from './ArticlesForm';

export default {
  list: ArticlesList,
  icon: ArticlesIcon,
  show: ArticlesShow,
  edit: ArticlesEdit,
  create: ArticlesCreate,
};
