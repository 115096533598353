// in src/MyLoginPage.js
import React from 'react'
import { useState } from 'react';
import { Login, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory , } from "react-router-dom";
import {Button, Typography, TextField} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        padding: theme.spacing(3)
      },
    },
    inputField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    }
  }));

  
  
  const ResetPassword = ({ theme }) => {
      const [email, setEmail] = useState('');
      const classes = useStyles();
      const apiUrl = process.env.REACT_APP_API_URL; 
      const notify = useNotify()
      let history = useHistory();
      const url =  apiUrl +  '/platformusers/requestpasswordreset';      
      const data = {
        "email" :  email
      }

      
      const request = new Request( url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data) 
        
      
      });
      

    const handleClick = (e) => {
        e.preventDefault()
        if (!/\S+@\S+\.\S+/.test(email)){
            notify("Please enter email address!")
        } else {


          return fetch(request)
            .then((response) => {
              if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
              }
              notify("We have e-mailed your password reset link!")
              history.push("/login")
              return response.json();
            })
            .catch(() => {
              console.log("err");
            });
    }}
    

    return (
        <Login className={classes.root}>
            <Typography align="center" variant="subtitle1" component="h2">
                Please enter your email address <br/> below to receive a password reset link.
            </Typography>

            <form onSubmit={handleClick} noValidate autoComplete="off">

                <TextField className={classes.inputField} onChange={e => setEmail(e.target.value)} fullWidth id="email" label="Email" type="email" />
                <Button variant="contained" type="submit" fullWidth color="primary" disableElevation>
                Reset password
                </Button>
            </form>
        </Login>
    );
};

export default ResetPassword;
